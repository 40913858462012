import React from "react";
import styled from "styled-components";
import { Icon, Progress } from "antd";
import Upload from "./Upload";
import _ from "lodash";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

const SortableItem = SortableElement(
  ({
    image,
    selectMode,
    seletedImage,
    setValue,
    value,
    abc,
    setSelectImage,
  }) => (
    <div
      onClick={(e) => {
        if (!selectMode) {
          return true;
        }
        e.preventDefault();
        setSelectImage(image);
      }}
      style={{
        border:
          seletedImage === image ? "2px dashed blue" : "1px solid #f5f5f5",
        position: "relative",
        cursor: "move",
        pointerEvents: "auto",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={image.url} alt="" />
      </div>
      {!selectMode && (
        <div className="image-tools" style={{ position: "absolute" }}>
          <Icon
            type="delete"
            style={{ fontSize: 16, color: "red" }}
            onClick={(e) => {
              e.preventDefault();
              value.splice(abc, 1);
              setValue(value);
            }}
          />
        </div>
      )}
    </div>
  )
);

const SortableList = SortableContainer(
  ({
    value,
    selectMode,
    seletedImage,
    setValue,
    setSelectImage,
    loading,
    percent,
    onUpload,
    onProcess,
    props,
  }) => {
    return (
      <div
        style={{
          display: "grid",
          gap: 10,
          gridTemplateColumns: !selectMode
            ? `repeat(auto-fit, minmax(min(200px,200px),200px))`
            : `repeat(auto-fit, minmax(min(150px,150px),150px))`,
        }}
      >
        {value.map((image, index) => (
          <SortableItem
            key={index}
            image={image}
            selectMode={selectMode}
            seletedImage={seletedImage}
            setValue={setValue}
            value={value}
            abc={index}
            setSelectImage={setSelectImage}
            index={index}
          />
        ))}
        <div>
          <div>
            <Upload
              onUpload={onUpload}
              onProcess={onProcess}
              fileList={false}
              disabled={loading}
              {..._.pickBy(
                props,
                (value, key) =>
                  ["multiple", "accept", "fileList"].indexOf(key) !== -1
              )}
            >
              {loading ? (
                <Progress
                  type="circle"
                  percent={percent}
                  width={40}
                  style={{ padding: selectMode ? 30 : 60 }}
                />
              ) : (
                <Icon
                  type="plus"
                  style={{
                    fontSize: 18,
                    cursor: "pointer",
                    padding: selectMode ? 50 : 80,
                  }}
                />
              )}
              <div className="drag-photo"></div>
            </Upload>
          </div>
        </div>
      </div>
    );
  }
);

const Container = styled.div`
  .image-tools {
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  img {
    max-width: 100%;
  }
  .row-dragging {
    z-index: 2;
  }
`;

export default class UploadImages extends React.Component {
  state = {
    loading: false,
    percent: 0,
    value: this.props.value || [],
    seletedImage: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  onProcess = (e) => {
    this.setState({
      loading: true,
      percent: Math.round((e.loaded / e.total) * 100),
    });
  };

  onUpload = (image) => {
    const { value } = this.state;
    value.push(image);
    this.setState({ value, loading: false }, this.handleOnChange);
  };

  handleOnChange = () => {
    const { onChange } = this.props;
    if (typeof onChange === "function") {
      onChange(this.state.value);
    }
  };
  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { value } = this.state;
    let newvalue = await arrayMove(value, oldIndex, newIndex);
    this.setState({ value: newvalue }, this.handleOnChange);
  };

  render() {
    const { loading, percent, value, seletedImage } = this.state;
    const { selectMode } = this.props || false;

    return (
      <Container>
        {/* <Row gutter={5} type="flex"> */}
        <SortableList
          value={value}
          selectMode={selectMode}
          seletedImage={seletedImage}
          setValue={(value) => {
            this.setState({ value: value }, this.handleOnChange);
          }}
          axis="xy"
          distance={1}
          lockToContainerEdges={true}
          onSortEnd={this.onSortEnd}
          helperClass="row-dragging"
          setSelectImage={(image) => {
            this.setState({ seletedImage: image }, () => {
              const { onSelect } = this.props;
              if (typeof onSelect === "function") {
                onSelect(this.state.seletedImage);
              }
            });
          }}
          loading={loading}
          percent={percent}
          onUpload={this.onUpload}
          onProcess={this.onProcess}
          props={this.props}
        />
        {/* {value.map((image, index) => (
            <Col span={12} sm={8} md={6} key={image.key || image.url}>
              <div
                onClick={(e) => {
                  if (!selectMode) {
                    return true;
                  }
                  e.preventDefault();
                  this.setState({ seletedImage: image }, () => {
                    const { onSelect } = this.props;
                    if (typeof onSelect === "function") {
                      onSelect(this.state.seletedImage);
                    }
                  });
                }}
                style={{
                  border:
                    seletedImage === image
                      ? "2px dashed blue"
                      : "1px solid #f5f5f5",
                }}
              >
                <div>
                  <img src={image.url} alt="" />
                </div>
                {!selectMode && (
                  <div className="image-tools">
                    <Icon
                      type="delete"
                      style={{ fontSize: 16, color: "red" }}
                      onClick={(e) => {
                        e.preventDefault();
                        const { value } = this.state;
                        value.splice(index, 1);
                        this.setState({ value }, this.handleOnChange);
                      }}
                    />
                  </div>
                )}
              </div>
            </Col>
          ))} */}
        {/* <Col span={12} sm={8} md={6}>
            <div>
              <div>
                <Upload
                  onUpload={this.onUpload}
                  onProcess={this.onProcess}
                  fileList={false}
                  disabled={loading}
                  {..._.pickBy(
                    this.props,
                    (value, key) =>
                      ["multiple", "accept", "fileList"].indexOf(key) !== -1
                  )}
                >
                  {loading ? (
                    <Progress type="circle" percent={percent} width={40} />
                  ) : (
                    <Icon
                      type="plus"
                      style={{
                        fontSize: 18,
                        cursor: "pointer",
                        padding: "100px",
                      }}
                    />
                  )}
                  <div className="drag-photo"></div>
                </Upload>
              </div>
            </div>
          </Col> */}
        {/* </Row> */}
      </Container>
    );
  }
}
