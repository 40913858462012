import React from 'react'
import styled from 'styled-components'
import gql from "../../api/gql"
import {Table, Button, Icon, Divider, Row, Col, Popconfirm, message, Switch, notification, Input} from 'antd'
import { Link } from 'react-router-dom'
import _ from "lodash"

const { Search } = Input
const Container = styled.div``
class ListNews extends React.Component {
  state = {
    data: [],
    showDrawer:false,
    updateNews: false,
    createNews:false,
    total: 0,
    checked:[],
    filter: {
      offset: 0,
      limit: 20,
      status:true,
      // ...this.props.filter
    },
    page: 1,
    loading: false
  }
  handleChange =(checked)=>{
    console.log(checked)
  }
  componentDidMount() {
    this.getListNews();
  }
  getListNews() {
    const query = `query news($filter: NewsFilter){
      news(filter:$filter){
        count
        hits{
          id
          title
          content
          status
          notification
          send_mail
          creater{
            id
            first_name
            last_name
            email
            address
            level{
              id
              name
              order_total
              discount
            }
          }
        }
      }
    }`
    this.setState({loading: true})
    gql.request(query, {
      filter: this.state.filter
    }).then(res => {
      this.setState({
        data: res.news.hits || [],
        total: res.news.count,
        loading: false
      })
    })
  }
  deleteNews = id => {
    this.setState({ loading: false })
    const mutation = `
        mutation deleteNews($id: Int!){
          deleteNews(id: $id)
        }
      `;
    gql.request(mutation, { id })
      .then(
        res => {
          // this.setState({ Status: true })
          if (res.deleteNews) {
            message["success"]("Delete success!");
            this.getListNews();
          } else {
            message["error"](_.get(res.errors, "[0].message"));
          }
        })
      .catch(err => {
        this.setState({ loading: false })
        message["error"](_.get(err, "[0].message"));
      });
  };

  UpdateNews=(checked,type, id)=>{
    let input;
    let message
    if(type === "notification"){
      message = "Update notification successfully!"
      input = {
        notification: checked
      }
    }else{
      message = "Update send mail successfully!"
      input = {
        send_mail: checked
      }
    }
    const mutation = `mutation updateNews($id: Int!,$input:EditNews!){
      updateNews(id: $id,input: $input)
        {
          id 
          title 
          notification
          send_mail
          status
        }
      }`
    this.setState({ loading: true })
    gql.request(mutation, {
      input: input,
      id: id
    }).then(res => {
      this.setState({ loading: false })
      notification.success({message: message})
      this.getListNews()
    }).catch(err => {
      notification['error']({
        message: _.get(err, '[0].message')
      })
      this.setState({ loading: false })
    })
  }


  render(){
    const {data, loading } = this.state
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (text, record) =>(
        <div><Link to={`/news/${record.id}`}>{text}</Link></div>
        )
      },
      {
        title: 'Notification',
        dataIndex: 'notification',
        key: 'notification',
        width: 150,
        align:"center",
        render: (data, record) =>(
          <div><Switch size = "small"  defaultChecked={data} onChange={(checked) => this.UpdateNews(checked, "notification", record.id)} /></div>
        )
      },
      {
        title: 'Send Mail',
        dataIndex: 'send_mail',
        key: 'send_mail',
        width: 140,
        align:"center",
        render: (data, record) =>(
          <div><Switch size = "small" defaultChecked={data} onChange={(checked) => this.UpdateNews(checked, "sendMail", record.id)} /></div>
        )
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: 120,
        render: (text, record) => (
          <Container>
            <Link to={`/admin/news/edit/${record.id}`}>
              <Icon type="edit" style={{color:"#1890ff"}}/>
            </Link>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure delete this news?"
              onConfirm={() => this.deleteNews(record.id)}
              okText="Yes"
              cancelText="No">
              <Button size="small"><Icon type="delete" style={{color:"red"}}/></Button>
            </Popconfirm>
          </Container>
        )
      }
    ]
    // const pagination = {
    //   pageSize: filter.limit,
    //   total: total,
    //   current: page,
    //   showTotal: (total, range) => `${range} of ${total}`,
    //   onChange: (page, pageSize) => {
    //     this.setState({
    //       page: page,
    //       filter: {
    //         ...filter,
    //         offset: (page - 1) * pageSize
    //       }
    //     }, () => this.getListNews())
    //   }
    // }
    return(
      <Container>
      <div>
        <Row>
          <Col md ={12}>
          {/* <Input style ={{width:'50%'}}placeholder="Search" value={filter.search} 
              onChange={e => this.setState({ filter: { ...this.state.filter, search: e.target.value }},()=> this.getListNews())} /> */}
          <Search style={{ width: "50%" }} placeholder="Search..." 
          onSearch={value => this.setState({ filter: { ...this.state.filter, search: value } }, () => this.getListNews())} enterButton />
          </Col>
          <Col md={12} style={{ textAlign: "right", marginBottom: 15 }}>
          <Button type="primary" onClick={() => this.setState({ createNews: true })} >
              <Link to="/admin/news/addnews"><Icon type="plus"/> New News</Link>
          </Button>
          </Col>
        </Row>
        <Table 
          columns={columns} 
          dataSource={data}
          //pagination={pagination} 
          rowKey={({id}) => id} 
          loading={loading}/>
      </div>
      </Container>
    )
  }
}
export default ListNews