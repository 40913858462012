import { Button, Input, PageHeader, Table } from "antd";
import React, { useEffect, useState } from "react";
import { history } from "../../history";
import { CLOUDFRONT_URL } from "../../config";
import { useQuery } from "@apollo/react-hooks";
import { LIST_IMPORT_HISTORY } from "../../graphql/query/ImportHistory";
import moment from "moment";
import DateRange from "../../components/DateRange";
import { connect } from "react-redux";

const ImportHistory = (props) => {
  const [filter, setFilter] = useState({
    offset: 0,
    limit: 20,
    site_id: localStorage.getItem("apiOrderCurrentSite"),
    from: null,
    to: null,
  });
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const { data } = useQuery(LIST_IMPORT_HISTORY, {
    fetchPolicy: "no-cache",
    variables: {
      filter: filter,
    },
  });
  useEffect(() => {
    setFilter({
      ...filter,
      site_id: localStorage.getItem("apiOrderCurrentSite"),
    });
  }, [props.currentSite?.id]);
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      width: 200,
    },
    {
      title: "Succeeded",
      dataIndex: "done",
      width: 150,
      render: (success) => <div style={{ padding: 20 }}>{success}</div>,
    },
    {
      title: "failed",
      dataIndex: "failed",
      width: 100,
      render: (failed) => <div style={{ padding: 12 }}>{failed}</div>,
    },
    {
      title: "Origin File",
      dataIndex: "origin_key",
      render: (origin_key, { origin_name }) => (
        <div>
          <a href={`${CLOUDFRONT_URL}/${origin_key}`}> {origin_name}</a>
        </div>
      ),
    },
    {
      title: "Result",
      dataIndex: "imported_key",
      render: (key) => (
        <div>
          <a href={`${CLOUDFRONT_URL}/${key}`}> Download</a>
        </div>
      ),
    },
    {
      title: "Imported date",
      width: 200,
      dataIndex: "created_at",
      render: (created_at) => moment(created_at).format("MMM DD, YYYY"),
    },
  ];
  const pagination = {
    current: page,
    total: data?.listImportHistory.count,
    pageSize: filter.limit,
    onChange: (page, pageSize) => {
      setPage(page);
      setFilter({ ...filter, offset: (page - 1) * pageSize });
    },
  };
  const onChangeDate = (range) => {
    if (range.from !== null && range.to !== null) {
      setFilter({
        ...filter,
        from: moment(range.from).format("YYYY-MM-DD[T]00:00:00[Z]"),
        to: moment(range.to).format("YYYY-MM-DD[T]00:00:00[Z]"),
      });
    } else {
      setFilter({
        filter: {
          ...filter,
          from: null,
          to: null,
        },
      });
    }
  };

  return (
    <div>
      <PageHeader
        style={{ padding: "16px 0px" }}
        title="Import history"
        onBack={() => {
          history.goBack();
        }}
      />
      <div
        style={{ width: "100%", display: "flex", gap: 10, marginBottom: 10 }}
      >
        <div
          style={{
            maxWidth: 280,
            width: "100%",
          }}
        >
          <DateRange
            defaultDate={
              !filter.from && !filter.to
                ? [
                    moment().startOf("day").add(-6, "day"),
                    moment().endOf("day"),
                  ]
                : null
            }
            onChange={(dateRange) => onChangeDate(dateRange)}
          ></DateRange>
        </div>
        <Input.Search
          placeholder="search..."
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            if (e.target.value.length === 0) {
              setFilter({ ...filter, search: "" });
            }
          }}
          onSearch={(e) => {
            setFilter({ ...filter, search: search });
          }}
        />
        <Button
          type="primary"
          onClick={() => {
            setFilter({ ...filter, search: search });
          }}
        >
          Search
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data?.listImportHistory.hits}
        pagination={pagination}
        rowKey={(record, id) => record.id}
      />
    </div>
  );
};

export default connect((state) => ({
  currentSite: state.app.currentSite,
}))(ImportHistory);
