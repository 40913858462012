import React from 'react'

import GuidelinePolicyForm from '../../components/admin/guideline-policy/form'
import Page404 from '../404'

const GuidelinePolicyCreate = (props) => {
    let isAdmin = props.currentUser.roles.find((el) => ['Administrator'].includes(el.name))

    return isAdmin ? <GuidelinePolicyForm /> : <Page404 />
}

export default GuidelinePolicyCreate
