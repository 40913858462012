import React, { Fragment } from "react";
import { Form, Button, notification, Alert, DatePicker } from "antd";
import gql from "../../api/gql";
import _ from "lodash";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
class FetchOrders extends React.Component {
  state = {
    comments: [],
    submitting: false,
    successOrders: [],
    faildOrders: [],
  };

  componentDidMount() {}

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      const { site_id } = this.props;
      if (!err) {
        const mutation = `mutation fetchMultipleOrders($site_id: Int!, $order_ids: [String!], $range_time: DateTimeFetchOrder){
                  fetchMultipleOrders(
                    site_id: $site_id, order_ids: $order_ids,range_time: $range_time)
                }`;
        this.setState({ submitting: true });
        gql
          .request(mutation, {
            site_id: site_id,
            order_ids: values.orderIds ? values.orderIds.split(",") : null,
            range_time: values.range_time
              ? {
                  from: moment(values.range_time[0]).utcOffset(0).set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                  }),
                  to: moment(values.range_time[1]).utcOffset(0).set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                  }),
                }
              : null,
          })
          .then((res) => {
            this.setState({ submitting: false });
            this.props.form.resetFields();
            this.props.refetch();
            this.setState({});
          })
          .catch((err) => {
            this.setState({ submitting: false });
            // const mes = `${id}: ${_.get(err, "[0].message")}`;
            // failOrders.push(mes);
            // this.setState({ faildOrders: failOrders });

            //console.log("this.state.faildOrders", this.state.faildOrders)
            notification["error"]({
              message: _.get(err, "[0].message"),
            });
          });
      }
    });
  };
  render() {
    const { comments, submitting } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item help="Enter list of order ids separated by comma. For example: 123456,654321,456789,987654">
            {getFieldDecorator("orderIds", {
              initialValue: "",
              // rules: [
              //   {
              //     required: true,
              //     message: "Please input order ids",
              //   },
              // ],
            })(<TextArea rows={3} />)}
          </Form.Item>
          {this.props.currentSite.platform !== "shopify" && (
            <Form.Item label="Range Time">
              {getFieldDecorator("range_time")(
                <DatePicker.RangePicker
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss"),
                    ],
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              )}
            </Form.Item>
          )}
          <Form.Item>
            <Button htmlType="submit" loading={submitting} type="primary">
              Fetch Orders
            </Button>
          </Form.Item>
        </Form>

        {this.state.faildOrders &&
          this.state.faildOrders.map((mess, i) => (
            <Alert
              message={mess}
              type="error"
              key={i}
              style={{ marginTop: 10, marginBottom: 10 }}
            ></Alert>
          ))}

        <span>
          <i>
            *After fetching orders, close the form to refresh the order list.
          </i>
        </span>
      </Fragment>
    );
  }
}

export default Form.create({ form_id: "order_fetchorders_form" })(FetchOrders);
