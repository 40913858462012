import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import styled from "styled-components";
import { Form, Input, notification, Button, message } from "antd";
import _ from "lodash";
import gql from "../../api/gql";

const Container = styled.div`
margin: auto;
text-align: center;
.register-form{
  .ant-row{
    margin-bottom: 5px;
    .ant-form-item-label{
      line-height: 25px;
    }
    label{
      display: none;
      font-weight: 500; 
      &:before{
        position: absolute;
        right: 0;
        top: 5px;
      }
      &:after{
        display: none;
      }
    }
  }
}
`;
class LoginPage extends Component {
  state = {
    loading: false,
    redirectToLogin: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        const query = `mutation register($input: UserRegister!){
          register(input: $input){
            id
          }
        }`;
        gql
          .request(query, {
            input: {
              ..._.omit(values, ["confirm"])
            }
          })
          .then(user => {
            this.setState({ redirectToLogin: true });
            message["success"]("A welcome message with further instructions has been sent to your email address.", 5)
          })
          .catch(err => {
            const msg = _.get(err, "[0].message", "Register error");
            if (msg.search(/email_UNIQUE/)) {
              notification["error"]({
                message: _.get(err, "[0].message")
              });
            } else {
              notification["error"]({
                message: msg
              });
            }
            this.setState({ loading: false });
          });
      }
    });
  };

  handleConfirmBlur = e => {
    const { values } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!values });
  };

  compareToFirstPassword = (rule, values, callback) => {
    const { form } = this.props;
    if (values && values !== form.getFieldValue("pass")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    if (this.state.redirectToLogin) {
      return <Redirect to="/login" />;
    }
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <div layout="register">
        <Container className={"register-container"}>
        <img src="/logo-black.png" style={{width:150}} alt="POD"/> 
        <h1 style={{color: '#8397A7', fontSize: 20,margin: '10px 0'}}>Sign Up</h1>
          <Form
            onSubmit={this.handleSubmit}
            className="register-form"
            layout="horizontal"
            style={{textAlign: 'initial' }}
          >
            <Form.Item>
              {getFieldDecorator("first_name", {
                rules: [
                  {
                    required: true,
                    message: "Please input your first name!"
                  },
                  { max: 50, message: 'First Name must maximum 50 characters.' },
                ]
              })(<Input placeholder="First name" />)}
            </Form.Item>
            <Form.Item label={"Last name:"} >
              {getFieldDecorator("last_name", {
                rules: [
                  {
                    required: true,
                    message: "Please input your last name!"
                  },
                  { max: 50, message: 'Last Name must maximum 50 characters.' },
                ]
              })(<Input placeholder="Last name" />)}
            </Form.Item>
            <Form.Item label={"Email:"} >
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    type: "email",
                    message: "Please input your email!"
                  }
                ]
              })(<Input placeholder="Email" />)}
            </Form.Item>
            <Form.Item label={"Password:"} >
              {getFieldDecorator("pass", {
                rules: [
                  {
                    required: true,
                    message: "Please input your password!"
                  },
                  { max: 50, message: 'Password must maximum 50 characters.' },
                ]
              })(<Input placeholder="Password" type="password" />)}
            </Form.Item>
            <Form.Item label={"Password"}>
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: "Please confirm your password!"
                  },
                  {
                    validator: this.compareToFirstPassword
                  },
                  { max: 50, message: 'Confirm password must maximum 50 characters.' },
                ]
              })(
                <Input.Password
                  placeholder="Confirm password"
                  onBlur={this.handleConfirmBlur}
                />
              )}
            </Form.Item>
            <Form.Item label="Phone">
            {getFieldDecorator('phone', {
              rules: [{
                required: true,
                message: "Please input phone"
              },
              { max: 11, message: 'Phone must maximum 11 characters.' },
            ]
            })(<Input placeholder="Phone number"/>)}
            </Form.Item>
            <Form.Item>
              <Button type="primary" icon="check" />
              <span style={{paddingLeft: 5}}>I accept the</span>
              <a href="#" target="_blank"> Terms & Conditions</a> 
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button btn-primary"
                loading={this.state.loading}
                style={{ width: "100%", height: 40 }}
              >
                Submit
              </Button>
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
            Already have an account? <a href="/login"> Sign In</a>
            </Form.Item>
          </Form>
        </Container>
      </div>
    );
  }
}

const LoginPageWrapper = Form.create({ name: "normal_login" })(LoginPage);

function mapStateToProps(state) {
  const { currentUser } = state.app;
  return { currentUser };
}

export default connect(mapStateToProps)(LoginPageWrapper);
