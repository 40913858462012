import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Button, Card, Col, Drawer, Empty, Icon, notification, PageHeader, Popconfirm, Row, Spin, Tooltip } from 'antd'
import { DELETE_GUIDELINE_POLICY } from '../../../graphql/mutation/guideline-policy'
import { history } from '../../../history'

import styled from 'styled-components'
import _ from 'lodash'

const BoxItemGuideLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    &:not(:last-child) {
        margin-bottom: 15px;
    }
    h4 {
        margin: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 60%;
    }

    button,
    i {
        border-color: #40a9ff;
        color: #40a9ff;
    }
`

const TitleView = styled.div`
    margin-bottom: 10px;
    .title {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 2px;
    }
`

const GuidelinePolicyList = (props) => {
    const { currentUser } = props

    const [state, setState] = useState({
        loading: true,
        dataGuideline: [],
        dataPolicy: [],
        dataSource: [...props.dataSource],
        visible: false,
        dataView: {},
    })

    const [deleteGuidelinePolicy] = useMutation(DELETE_GUIDELINE_POLICY)

    useEffect(() => {
        let dataGuideline = state.dataSource.length > 0 ? state.dataSource.filter((x) => x.type === 1) : []
        let dataPolicy = state.dataSource.length > 0 ? state.dataSource.filter((x) => x.type === 2) : []

        setState((s) => ({
            ...s,
            dataGuideline: _.orderBy(dataGuideline, ['sorting'], ['asc']),
            dataPolicy: _.orderBy(dataPolicy, ['sorting'], ['asc']),
            loading: false,
            dataSource: state.dataSource,
        }))
    }, [props.dataSource, state.dataSource])

    // useEffect({})

    const onShowView = (item) => {
        setState((s) => ({ ...s, visible: true, dataView: { ...item } }))
    }

    const onCloseView = () => {
        setState((s) => ({ ...s, visible: false, dataView: {} }))
    }

    const onShowRemove = (id) => {
        deleteGuidelinePolicy({
            variables: {
                id,
            },
        })
            .then(() => {
                let newDataSource = [...state.dataSource]
                let index = newDataSource.findIndex((x) => x.id === id)
                if (index !== -1) {
                    newDataSource.splice(index, 1)
                    notification.success({ message: 'Delete Guideline & Policy success' })
                    setState((s) => ({ ...s, dataSource: [...newDataSource], visible: false }))
                }
            })
            .catch((err) => {
                notification.error({ message: err.message })
            })
    }

    if (state.loading) return <Spin />

    let isAdmin = currentUser.roles.find((el) => ['Administrator'].includes(el.name))

    const renderTypeGuidelinePolicy = (type) => {
        switch (true) {
            case type === 1:
                return 'Guideline'
            case type === 2:
                return 'Policy'
            default:
                return ''
        }
    }

    const renderItemGuidelinePolicy = (item, index) => {
        return (
            <BoxItemGuideLine key={index}>
                <h4 title={item?.title || ''}>{item?.title || ''}</h4>
                <div>
                    <Button icon='check' style={{ marginRight: '10px' }} onClick={() => onShowView(item)}>
                        View
                    </Button>
                    {isAdmin && (
                        <>
                            <Tooltip placement='top' title='Edit'>
                                <Link className='ant-dropdown-link' to={`/guideline-policy/${item.id}`} style={{ marginRight: '10px' }}>
                                    <Icon type='edit' />
                                </Link>
                            </Tooltip>
                            <Tooltip placement='top' title='Delete'>
                                <Popconfirm
                                    title='Are you sure delete this task?'
                                    onConfirm={() => onShowRemove(item.id)}
                                    okText='Yes'
                                    cancelText='No'>
                                    <Icon type='delete' />
                                </Popconfirm>
                            </Tooltip>
                        </>
                    )}
                </div>
            </BoxItemGuideLine>
        )
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, marginBottom: 20 }}>
                <PageHeader
                    style={{ padding: '16px 0px' }}
                    title='Guideline & Policy'
                    onBack={() => {
                        history.goBack()
                    }}
                />
                {isAdmin && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <Link className='ant-dropdown-link' to='/guideline-policy/create'>
                            <Button icon='user-add' type='primary'>
                                Add New
                            </Button>
                        </Link>
                    </div>
                )}
            </div>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Card title={'Guideline'} style={{ marginBottom: 20 }}>
                        {state.dataGuideline.length > 0 ? state.dataGuideline.map((x, i) => renderItemGuidelinePolicy(x, i)) : <Empty />}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={'Policy'} style={{ marginBottom: 20 }}>
                        {state.dataPolicy.length > 0 ? state.dataPolicy.map((x, i) => renderItemGuidelinePolicy(x, i)) : <Empty />}
                    </Card>
                </Col>
            </Row>
            <Drawer title='Info Guideline & Policy' placement='right' onClose={onCloseView} visible={state.visible} width='100vw'>
                <TitleView>
                    <p className='title'>Title:</p> {state.dataView?.title || ''}
                </TitleView>
                <TitleView>
                    <p className='title'>Type:</p> {renderTypeGuidelinePolicy(state.dataView?.type)}
                </TitleView>
                <TitleView>
                    <p className='title'>Description:</p>
                    <div dangerouslySetInnerHTML={{ __html: state.dataView?.description || '' }} />
                </TitleView>
            </Drawer>
        </>
    )
}

export default GuidelinePolicyList
