import React from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { setCurrentSite } from "../../actions";

class SiteSelector extends React.Component {
  state = {
    sites: [],
  };

  componentDidMount() {
    const { sites, setCurrentSite, flatform } = this.props;
    let currentSiteID = localStorage.getItem("currentSiteID");
    let currentPlatform = sites.find(
      (site) => site.id.toString() === currentSiteID?.toString()
    )?.platform;

    if (flatform && flatform === "api") {
      if (currentPlatform === "api") {
        localStorage.setItem("apiOrderCurrentSite", currentSiteID);
      } else {
        let newSite = sites.filter((item) => item.platform === "api");
        setCurrentSite(newSite[0]);
        localStorage.setItem("apiOrderCurrentSite", newSite[0]?.id);
      }
    } else if (flatform && flatform === "not api") {
      if (currentPlatform !== "api") {
        localStorage.setItem("notApiSite", currentSiteID);
      } else {
        let newSite = sites.filter((item) => item.platform !== "api");
        setCurrentSite(newSite[0]);
        localStorage.setItem("notApiSite", newSite[0]?.id);
      }
    } else {
      if (
        currentSiteID &&
        sites.find((site) => site.id.toString() === currentSiteID.toString())
      ) {
        setCurrentSite(
          sites.find(
            (site) =>
              site.status && site.id.toString() === currentSiteID.toString()
          )
        );
      } else if (sites.length) {
        setCurrentSite(sites[0]);
      }
    }

    // if (
    //   currentSiteID &&
    //   sites.find((site) => site.id.toString() === currentSiteID.toString())
    // ) {
    //   setCurrentSite(
    //     sites.find(
    //       (site) =>
    //         site.status && site.id.toString() === currentSiteID.toString()
    //     )
    //   );
    // } else if (sites.length) {
    //   setCurrentSite(sites[0]);
    // }
  }

  onSiteChange = (site_id) => {
    const { setCurrentSite, sites, flatform } = this.props;
    setCurrentSite(sites.find((site) => site.id === site_id));
    if (flatform && flatform === "api") {
      localStorage.setItem("apiOrderCurrentSite", site_id);
    } else if (flatform && flatform === "not api") {
      localStorage.setItem("notApiSite", site_id);
    } else {
    }
  };

  render() {
    const { currentUser, currentSite, sites, flatform } = this.props;
    if (!currentUser) return null;
    if (!currentUser.roles.find((role) => role.name === "Seller")) return null;
    // console.log(typeof localStorage.getItem("apiOrderCurrentSite"));
    return (
      <div>
        {/* {flatform === "api" ? (
          <Select
            onChange={(site_id) => {
              localStorage.setItem("apiOrderCurrentSite", site_id);
              this.setState({ apiSite: site_id });
            }}
            style={{ minWidth: 250 }}
            value={this.state.apiSite}
          >
            {sites
              .filter((item) => item.platform === "api")
              .map((site) => (
                <Select.Option key={site.id} value={site.id}>
                  {site.title}
                </Select.Option>
              ))}
          </Select>
        ) : flatform === "not api" ? (
          "not api"
        ) : ( */}
        <Select
          style={{ minWidth: 250 }}
          onChange={this.onSiteChange}
          value={currentSite ? currentSite.id : null}
        >
          {flatform === "api"
            ? sites
                .filter((item) => item.platform === "api")
                .map((site) => (
                  <Select.Option key={site.id} value={site.id}>
                    {site.title}
                  </Select.Option>
                ))
            : flatform === "not api"
            ? sites
                .filter((item) => item.platform !== "api")
                .map((site) => (
                  <Select.Option key={site.id} value={site.id}>
                    {site.title}
                  </Select.Option>
                ))
            : sites.map((site) => (
                <Select.Option key={site.id} value={site.id}>
                  {site.title}
                </Select.Option>
              ))}
          {/* {sites.map((site) => (
            <Select.Option key={site.id} value={site.id}>
              {site.title}
            </Select.Option>
          ))} */}
        </Select>
        {/* // )} */}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      currentUser: state.app.currentUser,
      currentSite: state.app.currentSite,
      sites: state.app.sellerSites || [],
    };
  },
  { setCurrentSite }
)(SiteSelector);
