import React from "react";
import { CLOUDFRONT_URL } from "../config";

export default class S3Image extends React.Component {
  state = {
    src: null,
    alt: "",
  };

  render() {
    const { src, alt, style } = this.props;
    return (
      <img
        alt={alt}
        // backgroundImage: `url(${fileUrl ? fileUrl : '/no-preview.png'})`,
        {...this.props}
        style={{ ...style }}
        src={`${src ? CLOUDFRONT_URL : ""}/${src ? src : "no-preview.png"}`}
      />
    );
  }
}
