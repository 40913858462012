import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Popconfirm,
    Button,
    PageHeader,
    Icon,
    Table,
    Divider,
    notification,
    Select,
    Row,
    Col,
} from "antd";
import { history } from "../../../history";
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { ATTRIBUTE_TERMS } from "../../../graphql/query/attributeTerm";
import { ATTRIBUTES } from "../../../graphql/query/attribute";
import { DELETEATTRIBUTETERM } from "../../../graphql/mutation/attributeTerm";

AttributeTermsList.propTypes = {

};

function AttributeTermsList(props) {

    const [filter, setFilter] = useState({
        selectedAttrID: null,
        offset: 0,
        limit: 20,
        page: 1,
    })

    // init instance func call api delete atribute term
    const [deleteAttributeTerm, { }] = useMutation(DELETEATTRIBUTETERM)

    // init instance func call api get all attributes
    const [getAttributes, { data: attributes }] = useLazyQuery(ATTRIBUTES, {
        fetchPolicy: "no-cache",
        variables: {
            limit: null,
            offset: null
        }
    })

    // init instance func call api get list attribute terms
    const [getAttributeTerms, { data: attrTerms, loading }] = useLazyQuery(ATTRIBUTE_TERMS, {
        fetchPolicy: "no-cache",
        variables: {
            attribute_id: filter.selectedAttrID,
            limit: filter.limit,
            offset: filter.offset
        }
    })

    // call api fetch data when first render
    useEffect(() => {

        getAttributes()

        getAttributeTerms()

    }, [])

    // func handle event when client click btn delete attribute term
    function handleDeleteAttributeTerm(attrTermID) {

        // check attribute term ID is exist
        if (!attrTermID) {
            notification.warning({ message: "You have not selected attribute term to delete!" });
            return
        }

        // call api delete attribute term and return result
        deleteAttributeTerm({
            variables: {
                id: attrTermID,
            }
        }).then(
            () => {
                notification.success({ message: "Delete attribute Term is successful!" });
                getAttributeTerms()
            }
        ).catch(
            err => {
                notification.error({ message: err.message });
            }
        )
    }

    // func handle event when client select attribute
    function changeAttribute(attrID) {
        if (attrID === null || attrID === 0) {
            setFilter({
                ...filter,
                selectedAttrID: null,
                page: 1,
                offset: 0,
                limit: 20,
            })
        } else {
            setFilter({
                ...filter,
                selectedAttrID: attrID,
                page: 1,
                offset: 0,
                limit: 20,
            })
        }
    }

    // init column name for table data
    const columns = [
        {
            title: "Name",
            key: "option_name",
            dataIndex: "option_name",
        },
        {
            title: "SKU",
            key: "sku",
            dataIndex: "sku",
        },
        {
            title: "Attribute Name",
            render: (record) => record.attribute.name,
        },
        {
            title: "Action",
            width: 120,
            render: (record) => (
                <div>
                    <Button
                        size="small"
                        onClick={() => history.push(`/admin/attribute-term/edit/${record.id}`)}
                    >
                        <Icon type="edit" style={{ color: "#1890ff" }} />
                    </Button>
                    <Divider type="vertical"></Divider>
                    <Popconfirm
                        placement="top"
                        title="Are you sure to delete this attribute term?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDeleteAttributeTerm(record.id)}
                    >
                        <Button size="small">
                            <Icon type="delete" style={{ color: "red" }} />
                        </Button>
                    </Popconfirm>
                </div>
            ),
        }
    ];

    // init pagination for table
    const pagination = {
        current: filter.page,
        total: attrTerms?.getAttributeTerms?.count,
        pageSize: filter.limit,
        onChange: (page, pageSize) => {
            setFilter({
                ...filter,
                offset: (page - 1) * pageSize,
                page: page,
            })
        }
    };

    return (
        <div>
            <PageHeader
                style={{ padding: "16px 0px" }}
                title="Attribute Terms"
                onBack={() => {
                    history.goBack();
                }}
                extra={
                    <Button
                        type="primary"
                        icon="plus"
                        onClick={() => history.push(`/admin/attribute-term/add`)}
                    >
                        Add Attribute Term
                    </Button>
                }
            />

            <Row type="flex" style={{ width: "calc(100%)", marginBottom: 20 }}>
                <Col span={24} lg={20}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Select
                        defaultValue={0}
                        placeholder="Attributes"
                        style={{ width: 180 }}
                        onChange={changeAttribute}
                    >
                        <Select.Option key={0} value={0}>{'All Attributes'}</Select.Option>
                        {attributes?.getAttributes?.hits.map((attr) => (
                            <Select.Option
                                key={attr.id}
                                value={attr.id}
                            >{attr.name}</Select.Option>
                        ))}
                    </Select>
                </Col>
            </Row>

            <div>
                <Table
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={attrTerms?.getAttributeTerms?.hits}
                    pagination={pagination}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default AttributeTermsList;