import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, PageHeader, Card, Input, Divider, notification } from "antd";
import { history } from "../../../history";
import { CREATEATTRIBUTE, UPDATEATTRIBUTE } from "../../../graphql/mutation/attribute";
import { GETATTRIBUTEBYID } from "../../../graphql/query/attribute";
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from "@apollo/react-hooks";

AttributesForm.propTypes = {

};

function AttributesForm(props) {

    const [params, setParams] = useState({
        loading: false,
        attrID: props.match.params.id ? props.match.params.id : null,
    })

    const { data } = useQuery(GETATTRIBUTEBYID, {
        skip: params.attrID ? false : true,
        fetchPolicy: "no-cache",
        variables: {
            id: params.attrID,
        }
    })

    // init function create attribute to call api
    const [createAttribute, { }] = useMutation(CREATEATTRIBUTE)
    const [updateAttribute, { }] = useMutation(UPDATEATTRIBUTE)

    const { getFieldDecorator } = props.form;

    // handle event client submit form and proccessing data
    function SaveAttribute(e) {

        e.preventDefault();

        // check validate all field in form
        props.form.validateFields(
            (err, value) => {
                if (!err) {

                    if (params.attrID) {
                        // call api update attribute if attrID is not null
                        updateAttribute({
                            variables: {
                                input: {
                                    id: data.getAttributeById.id,
                                    name: value.name
                                }
                            }
                        }).then(
                            // return result and come back list page
                            () => {
                                notification.success({ message: "Update Attribute is successful!" });
                                history.goBack();
                            }
                        ).catch(
                            // show error if proccessing have error
                            err => {
                                notification.error({ message: err.message });
                            }
                        )
                    } else {
                        // call api create attribute if attrID is null
                        createAttribute({
                            variables: {
                                input: {
                                    name: value.name
                                }
                            }
                        }).then(
                            () => {
                                // show error if proccessing have error
                                notification.success({ message: "Create Attribute is successful!" });
                                history.goBack();
                            }
                        ).catch(
                            err => {
                                notification.error({ message: err.message });
                            }
                        )
                    }
                }
            }
        )
    }

    return (
        <div>
            <PageHeader
                style={{ padding: "16px 0px" }}
                title={params.attrID ? "Edit Attribute" : "Add Attribute"}
                onBack={() => {
                    history.goBack();
                }}
            />

            <Form onSubmit={SaveAttribute}>
                <Card title="Detail" style={{ marginBottom: 20 }}>
                    <Form.Item label="Title">
                        {
                            getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input name"
                                    },
                                ],
                                initialValue: data ? data.getAttributeById.name : ""
                            })(<Input />)
                        }
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={params.loading}>Save</Button>
                        <Divider type="vertical" />
                        <Button onClick={() => history.goBack()}>Cancel</Button>
                    </Form.Item>
                </Card>
            </Form>
        </div>
    );
}

export default Form.create({ name: "form" })(AttributesForm);