import { Select } from "antd";
import React, { useState } from "react";

export const VariantPartnerMappingPrintAreaSelect = ({area, partnerAreas, onChange, defaultValue}) => {
    const [selectValue, setSelectValue] = useState(defaultValue?.key)

    const handleChange = (v) => {
        setSelectValue(v)
        onChange({
            print_area_id: area?.id ?? null,
            print_area_title: area?.title ?? null,
            key: v,
        })
    }

    return (
        <>
            <div key={Math.random(99999)}>
                <b>{area.title} :</b>

                <Select
                    showSearch
                    value={selectValue}
                    placeholder={`partner print area`}
                    onChange={(v) => handleChange(v)}
                    style={{
                        width: '300px'
                    }}
                >
                    {partnerAreas && partnerAreas.map((area) => (
                            <Select.Option key={area}>{area}</Select.Option>
                        )
                    )}
                </Select>
            </div>
        </>
    )
}