import React from 'react'
import styled from 'styled-components'
import DateRange from '../DateRange'
import { Row, Col, Card, PageHeader } from 'antd'
import gql from '../../api/gql'
import { connect } from 'react-redux'
import { setCurrentUser } from '../../actions'
import { history } from '../../history'
import _, { result } from 'lodash'
import PrintFilePreview from '../file/PrintFilePreview'
import moment from 'moment'

const Container = styled.div``

const _targetBlank = {
  target: '_blank',
  rel: 'noopener noreferrer'
}

class SellerDashboard extends React.Component {

  state = {

    loading: false,

    siteIDs: [],

    // internal orders will container a field internalOrder in result of request
    internalOrders: {
      all: 0,
      pending: 0,
      pending_design: 0,
      processing: 0,
      ready: 0,
      in_production: 0,
      fulfilled: 0,
      cancelled: 0,
      error: 0,
    },

    // external orders will container a field externalOrder in result of request
    externalOrders: {
      all: 0,
    }
  }

  componentDidMount() {

    new Promise(
      (resolve, _) => {
        var result = this.getSites()
        result.then(
          _ => {
            resolve()
          }
        )
      }
    ).then(
      _ => this.getDashboard()
    )
  }

  // get list site id
  getSites() {

    // get current user logged
    const currentUser = this.props

    if (!currentUser) {
      return null
    }

    // create a query for request to graphql
    const query = `query sites($filter: SiteFilter){
      sites(filter: $filter){
        hits{
          id
        }
      }
    }`

    // set state loading is true to show loading bar
    this.setState({ loading: true })

    // use the query and call to api graphql and check result
    return gql.request(query, {
      filter: {
        ...this.setState.filter,
        user_id: currentUser.id,
        verified: true,
        status: true
      }
    }).then(
      res => {
        var sites = []
        res.sites.hits.forEach(site => {
          sites.push(site.id)
        });

        this.setState({
          loading: false,
          siteIDs: sites || []
        })

        return true
      }
    ).catch(
      err => {
        this.setState({
          loading: false
        })
      }
    )
  }

  // get all data for admin dashboard
  getDashboard() {

    const siteIDs = this.state.siteIDs

    const query = `query dashboard($filter: DashboardFilter){
      dashboard(filter: $filter) {
        internal_order {
          all
          pending
          pending_design
          processing
          ready
          in_production
          fulfilled
          cancelled
          error
        }
        external_order {
          all
        }
      }
    }`

    this.setState({ loading: true })

    // call a request to api dashboard to get data
    gql.request(query, {
      filter: {
        site_ids: siteIDs
      }
    }).then(
      res => {
        this.setState({
          internalOrders: res.dashboard.internal_order,
          externalOrders: res.dashboard.external_order,
          loading: false
        })
      }
    ).catch (
      err => {
        this.setState({
          loading: false
        })
      }
    )
  }

  render() {
    return (
      <Container>

        {/* ---start Page Header--- */}
        <PageHeader
          style={{ padding: "16px 0px" }}
          title="Seller Dashboard"
          onBack={() => {
            history.goBack();
          }}
        />
        {/* ---end Page Header--- */}

        {/* ---start Table Statistic--- */}
        <Row gutter={16}>
          <Col span={8}>
            <Card title="Internal Order" bordered={true}>
              <p>All: {this.state.internalOrders.all}</p>
              <p>Pending: <span style={{ color: '#f94' }}>{this.state.internalOrders.pending}</span></p>
              <p>Pending Design: <span style={{ color: '#f94' }}>{this.state.internalOrders.pending_design}</span></p>
              <p>Processing: <span style={{ color: '#f94' }}>{this.state.internalOrders.processing}</span></p>
              <p>Ready: <span style={{ color: '#1890ff' }}>{this.state.internalOrders.ready}</span></p>
              <p>In Production: <span style={{ color: '#1890ff' }}>{this.state.internalOrders.in_production}</span></p>
              <p>Fulfilled: <span style={{ color: '#52c41a' }}>{this.state.internalOrders.fulfilled}</span></p>
              <p >Cancelled: <span style={{ color: '#ff5500' }}>{this.state.internalOrders.cancelled}</span></p>
              <p>Error: <span style={{ color: '#ff5500' }}>{this.state.internalOrders.error}</span></p>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="External Order" bordered={false}>
              <p>All: {this.state.externalOrders.all}</p>
            </Card>
          </Col>
        </Row>
        {/* ---end Table Statistic--- */}

      </Container>
    )
  }
}

export default connect(
  state => {
    return {
      currentUser: state.app.currentUser
    }
  },
  { setCurrentUser }
)(SellerDashboard)