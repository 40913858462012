import rest from '../api/rest'
import _ from 'lodash'
import shortuuid from 'short-uuid'

export const Shopbase = {
  async pushProduct(site_id, productTypes, product) {
    return new Promise(async (resolve, reject) => {
      let productAttributes = []
      let variantImages = []
      let uuidProduct = shortuuid.generate().substring(0, 10).toUpperCase()
      let productSku =  `PGS-${uuidProduct}-${productTypes.map(type => type.sku).join("-")}`
      var _attributes = [].concat.apply([], product.variants.map(variant => variant.attributes))
      _attributes.forEach(attr => {
        let tmpAttr = productAttributes.find(at => at.slug === attr.slug) || { name: attr.name, slug: attr.slug, terms: [] }
        tmpAttr.terms = _.union([...tmpAttr.terms, attr.option])
        productAttributes = _.uniqBy([...productAttributes, tmpAttr], 'slug')
      })

      if (productTypes.length > 1) {
        let productTypeAttribute = {
          name: "Type",
          terms: productTypes.map(type => type.title)
        }
        productAttributes = [productTypeAttribute, ...productAttributes]
      }
      var shopbaseProduct = {
        product: {
          title: product.title,
          body_html: product.description,
          //published_scope: 'global',
          product_type: 'POD',
          vendor: 'POD',
          tags: product.tags.join(','),
          images: product.images.map(image => ({ src: image.url })),
          options: productAttributes.map(attr => ({
            name: attr.name,
            values: attr.terms
          })),
          metafields:
          [{
            namespace: 'pod',
            value_type: 'json_string',
            key: 'pod_product_types',
            value: JSON.stringify(productTypes.map(type => type.id.toString()))
          },
          {
            namespace: 'pod_sku',
            value_type: 'json_string',
            key: 'pod_product_sku',
            value: productSku,
          }]
        }
      }
      var pushProduct = _.omit(shopbaseProduct, ['product.metafields'])
      //pushProduct.product.variants = pushProduct.product.variants.map(variant => _.omit(variant, ['metafields']));
      //Create variants
      let variants = []
      product.variants.map((variant, i) => {
        let options = {}
        let variant_att_sku = variant.attributes.map(attr => attr.option.replaceAll(" ","-" )).join("-")
        productAttributes.forEach((attr, index) => {
          let existsAttr = variant.attributes.find(at => at.name === attr.name)
          if (existsAttr) {
            options[`option${index + 1}`] = existsAttr.option
          } else {
            if (attr.name === "Type" && productTypes.length > 1) {
              options[`option${index + 1}`] = productTypes.find(type => type.id === variant.product_type_id).title
            } else {
              options[`option${index + 1}`] = ""
            }
          }
        })
        //variant
        var pushVariant = {
          compare_at_price: variant.retail_cost,
          price: variant.sale_cost,
          sku: `PGS-${uuidProduct}-${productTypes.find(type => type.id === variant.product_type_id).sku}-${variant_att_sku}`,
          ...options,
          position: i + 1,
          metafields:
          {
            namespace: 'pod',
            value_type: 'integer',
            key: 'pod_product_type_variant_id',
            value: variant.id.toString()
          }
        }
        let variantImageIndex = variant.image ? _.findIndex(product.images, image => image.key === variant.image.key) : null
        variantImages.push({ position: i + 1, index: variantImageIndex })

        variants.push(pushVariant)
      })

      pushProduct.product.variants = variants.map(variant => _.omit(variant, ['metafields']));
      var sbProduct = await rest.request('post', site_id, '/admin/products.json', pushProduct).then(res => {
        return res.product
      }).catch(err => {
        reject(err)
      })

      //Update variant image
      sbProduct && sbProduct.variants.forEach(variant => {
        return new Promise(async (resolve, reject) => {
          let variantImageIndex = _.find(variantImages, { position: variant.position })
          var shopbaseVariant = null
          if (variantImageIndex && variantImageIndex.index !== null) {
            shopbaseVariant = await rest.request("Put", site_id, `/admin/variants/${variant.id}.json`, {
              variant: {
                image_id: variantImageIndex.index !== null ? sbProduct.images[variantImageIndex.index].id : null,
                title: variant.title
              }
            }).then(createVariant => {
              return createVariant
            }).catch(err => {
              reject(err)
            })
          }
          resolve(shopbaseVariant)
        })
      });
      shopbaseProduct.variants = variants
      var result = {
        product: sbProduct,
        shopbaseProduct: shopbaseProduct
      }
      resolve(result)
    })
  },
  async deleteProduct(site_id, origin_id) {
    await rest.request("DELETE", site_id, `/admin/products/${origin_id}.json`).catch(err => Promise.reject(err))
    return Promise.resolve(origin_id)
  }
}