import gql from "graphql-tag";

export const MAPPING_ORDER = gql`
  mutation createNewOrder($input: NewMappingData!) {
    createNewOrder(input: $input)
  }
`;

export const MAPPING_ORDER_WITH_PRODUCT_ID = gql`
  mutation createNewOrderWithProductId($input: NewMappingDataWithProductId!) {
    createNewOrderWithProductId(input: $input)
  }
`;

export const REMOVE_TRACKING_ORDER = gql`
  mutation removeMappingOrder($ids: [ID!]) {
    removeMappingOrder(ids: $ids)
  }
`;
