import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, InputNumber, Radio, Row, Col } from 'antd'
import styled from 'styled-components'

const SupplierFormItem = styled.div`
    .ant-form-item-control {
        line-height: 0px;
    }
`
class VariantSupplierCost extends Component {
    state = {
        defaultVariantSuppiler: this.props.supplierCosts?.find((item) => item.selected === true)
            ? this.props.supplierCosts?.find((item) => item.selected === true).user_id
            : this.props.suppliers[0]?.id,

        defaultSuppiler: null,
    }

    componentDidMount() {
        const { suppliers, supplierCosts } = this.props
        for (let i = 0; i < supplierCosts.length; i++) {
            if (supplierCosts[i].selected) {
                this.setState({ defaultVariantSuppiler: supplierCosts[i].user_id })
            }
        }
        for (let i = 0; i < suppliers.length; i++) {
            if (suppliers[i].selected) {
                this.setState({ defaultSuppiler: suppliers[i].user_id })
            }
        }
    }
    render() {
        const { suppliers, supplierCosts } = this.props
        if (!suppliers.length) {
            return null
        }
        // let mapCostByUserId = {}
        // let mapIdByUserId = {}
        // for (let i = 0; i < supplierCosts.length; i++) {
        //   mapCostByUserId[supplierCosts[i].user_id] = supplierCosts[i].cost
        //   mapIdByUserId[supplierCosts[i].user_id] = supplierCosts[i].id
        // }
        // let data = suppliers.map((supplier) => {
        //   const userId = supplier.id
        //   const id = mapIdByUserId[userId] ? mapIdByUserId[userId] : null
        //   return {
        //     id,
        //     user_id: userId,
        //     cost: mapCostByUserId[userId] ? mapCostByUserId[userId] : null,
        //   }
        // })

        // if (!suppliers.length) {
        //   return null
        // }

        let mapCostBySuppilerId = {}
        let mapIdBySuppilerId = {}
        let mapDefaultSuppilerrId = {}
        let hasVariantSelected = false

        for (let i = 0; i < supplierCosts.length; i++) {
            mapCostBySuppilerId[supplierCosts[i].user_id] = supplierCosts[i].cost
            mapIdBySuppilerId[supplierCosts[i].user_id] = supplierCosts[i].id
            //mapIdByCarrierId[carrierCosts[i].carrier_id] = carrierCosts[i].id
            mapDefaultSuppilerrId[supplierCosts[i].user_id] = supplierCosts[i].selected
            if (supplierCosts[i].selected) {
                hasVariantSelected = true
            }
        }

        let data = suppliers.map((supplier) => {
            const supplierId = supplier.id
            const id = mapIdBySuppilerId[supplierId] ? mapIdBySuppilerId[supplierId] : null
            let cost = null
            if (mapCostBySuppilerId[supplierId] !== null && mapCostBySuppilerId[supplierId] !== '' && mapCostBySuppilerId[supplierId] >= 0) {
                cost = mapCostBySuppilerId[supplierId]
            }

            return {
                id,
                user_id: supplierId,
                cost: cost,
                selected: mapDefaultSuppilerrId[supplierId] ? mapDefaultSuppilerrId[supplierId] : hasVariantSelected ? false : suppliers.selected ?? true,
            }
        })

        return (
            <div className={'supplier-cost-container'}>
                {
                    <Radio.Group
                        style={{ width: '100%' }}
                        value={this.state.defaultVariantSuppiler.toString()}
                        onChange={(e) => {
                            this.setState({ defaultVariantSuppiler: e.target.value })
                            if (this.props.onChange) {
                                data.map((item) => {
                                    if (item.user_id === e.target.value) {
                                        return (item.selected = true)
                                    } else {
                                        item.selected = false
                                    }
                                })
                                this.props.onChange(data)
                            }
                        }}>
                        {suppliers.map((supplier, index) => {
                            const userId = supplier.id
                            const defaultCostValue = mapCostBySuppilerId[userId] ? mapCostBySuppilerId[userId] : 0

                            return (
                                <div style={{ display: 'inline-block', width: '100%' }} key={index}>
                                    <Row gutter={24} type='flex' style={{ alignItems: 'center' }}>
                                        <Col span={14} style={{ display: 'flex' }}>
                                            <Radio
                                                style={{ paddingTop: 20, display: 'block', marginBottom: 5 }}
                                                value={supplier.id}
                                                key={supplier?.id}
                                            ></Radio>
                                            {supplier.first_name + ' ' + supplier.last_name}
                                        </Col>
                                        <Col
                                            style={{
                                                textAlign: 'left',
                                                paddingRight: 0,
                                                marginBottom: 5,
                                            }}
                                            span={10}>
                                            <SupplierFormItem>
                                                <Form.Item>
                                                    <InputNumber
                                                        disabled={supplier.id == this.state.defaultVariantSuppiler ? false : true}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        onChange={(newValue) => {
                                                            if (this.props.onChange) {
                                                                data.map((item) => {
                                                                    if (item.user_id === userId) {
                                                                        return (item.cost = newValue)
                                                                    }
                                                                })
                                                                this.props.onChange(data)
                                                            }
                                                        }}
                                                        value={defaultCostValue}
                                                    />
                                                </Form.Item>
                                            </SupplierFormItem>
                                        </Col>
                                    </Row>
                                    {/* <Form.Item
                      key={index}
                      label={`${supplier.first_name} ${supplier.last_name}`}>
                    <InputNumber
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g,',')}
                        onChange={(newValue) => {
                          if (this.props.onChange) {
                            data.map((item) => {
                              if (item.user_id === userId) {
                                return item.cost = newValue
                              }
                            })
                            this.props.onChange(data)
                          }
                        }}
                        value={defaultCostValue}
                    />
                  </Form.Item> */}
                                </div>
                            )
                        })}
                    </Radio.Group>
                }
            </div>
        )
    }
}

VariantSupplierCost.propTypes = {
    suppliers: PropTypes.array,
    supplierCosts: PropTypes.array,
    onChange: PropTypes.func,
}

export default VariantSupplierCost
