import { Avatar, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import PageHeader from "antd/es/page-header";
import { ADMIN_EXTERNAL_ORDERS } from "../../graphql/query/adminExternalOrder";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import { history } from "../../history";
import { useSelector } from "react-redux";

const AdminExternalOrders = () => {
  const [filter, setFilter] = useState({
    offset: 0,
    limit: 20
  });

  const { data } = useQuery(ADMIN_EXTERNAL_ORDERS, {
    fetchPolicy: "no-cache",
    variables: {
      filter: filter,
    },
  });

  useEffect(() => {
    setFilter({ ...filter });
  }, [localStorage.getItem("currentSiteID")]);
  const [page, setPage] = useState(1);
  const columns = [
    { title: "Id", key: "id", dataIndex: "id", width: 100 },
    {
      title: "Order ID",
      key: "orderId",
      dataIndex: "order_origin_id",
      width: 120,
    },
    {
      title: "Product",
      key: "product",
      dataIndex: "product",
      width: 300,
      render: (product, record) => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "100px auto",
            gap: 10,
            alignItems: "center",
          }}
        >
          <Avatar
            shape="square"
            size={100}
            src={record?.images && record?.images[0]}
          />
          <span>{product?.name ? product?.name : product?.product?.title}</span>
        </div>
      ),
    },
    {
      title: "Site",
      key: "site",
      dataIndex: "site",
      width: 180,
      render: (site) => (
        <div>
          <p>
            {site ? site?.title : null}
          </p>
          <p style={{ marginBottom: 0, fontStyle: "italic" }}>
            <a
              target="_blank"
              className="ant-dropdown-link"
              href={site ? site?.domain : null}
            >
              {site ? site?.domain : null}
            </a>
          </p>
        </div>
      ),
    },
    {
      title: "Platform",
      key: "platform",
      dataIndex: "platform",
      width: 180,
      render: (platform) => {
        switch (platform) {
          case "shopbase":
            return <img src="/shopbase-logo.svg" width="80"></img>;
            break;
          case "shopify":
            return <img src="/shopify-logo.png" width="80"></img>;
            break;
          case "woocommerce":
            return <img src="/logo-woocommerce.png" width="100"></img>;
            break;
          default:
            return <img src="/import-site.png" width="100"></img>;
            break;
        }
      },
    },
    {
      title: "Meta Data",
      key: "tags",
      dataIndex: "tags",
      render: (tags) => (
        <>
          {tags}
        </>
      )
    },
    {
      title: "Personalized",
      key: "personalized",
      dataIndex: "personalized",
      render: (personalized, { print_file_url }) => {
        if (personalized) {
          return (
            <a href={print_file_url} target="_blank" rel="noopener noreferrer">
              <Tag color="blue" style={{ cursor: "pointer" }}>
                personalized
              </Tag>
            </a>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "created_at",
      width: 150,
      render: (date) => moment(date).format("MMM DD, YYYY"),
    }
  ];
  const pagination = {
    current: page,
    total: data?.adminExternalOrders?.count,
    pageSize: filter?.limit,
    onChange: (page, pageSize) => {
      setPage(page);
      setFilter({ ...filter, offset: (page - 1) * pageSize });
    },
  };

  return (
    <div>
      <PageHeader
        style={{ padding: "16px 0px" }}
        title="External Orders"
        onBack={() => {
          history.goBack();
        }}
      />
      <Table
        rowKey={(record, id) => record.id}
        columns={columns}
        dataSource={data?.adminExternalOrders?.hits}
        pagination={pagination}
      />
    </div>
  );
};

export default AdminExternalOrders;
