import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Icon, Tooltip } from "antd";

export const GET_FILE_URL = gql`
  query getFileUrl($file_id: Int!) {
    getFileUrl(file_id: $file_id)
  }
`;

const ButtonDownload = (props) => {
  const {fileId } = props;
  const { data } = useQuery(GET_FILE_URL, {
    variables: { file_id: fileId },
  });
  return (
    <a
      href={data?.getFileUrl}
      target="_blank"
      title="Download Print File"
      rel="noopener noreferrer"
    >
      <Tooltip placement="topRight" title="Download print file">
        <Icon type={`download`}></Icon>
      </Tooltip>
    </a>
  );
};

export default ButtonDownload;
