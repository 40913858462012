import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Popconfirm, Button, PageHeader, Icon, Table, Divider, notification, Tooltip } from 'antd'
import { history } from '../../../history'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ATTRIBUTES } from '../../../graphql/query/attribute'
import { DELETEATTRIBUTE } from '../../../graphql/mutation/attribute'

AttributesList.propTypes = {}

function AttributesList(props) {
  const [filter, setFilter] = useState({
    offset: 0,
    limit: 20,
    page: 1,
  })

  const [deleteAttribute, {}] = useMutation(DELETEATTRIBUTE)

  const { data, loading, error, refetch } = useQuery(ATTRIBUTES, {
    fetchPolicy: 'no-cache',
    variables: {
      limit: filter.limit,
      offset: filter.offset,
    },
  })

  // proccessing event client click delete attribute btn
  function handleDeleteAttribute(attrID) {
    if (!attrID) {
      notification.warning({ message: 'You have not selected attribute to delete!' })
      return
    }

    // call api delete attribute and return result
    deleteAttribute({
      variables: {
        id: attrID,
      },
    })
      .then(() => {
        notification.success({ message: 'Delete attribute is successful!' })
        refetch()
      })
      .catch((err) => {
        notification.error({ message: err.message })
      })
  }

  // init column name for table data
  const columns = [
    {
      title: 'Title',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Action',
      width: 120,
      render: (record) => (
        <div>
          <Tooltip placement='top' title='Edit'>
            <Button size='small' onClick={() => history.push(`/admin/attribute/edit/${record.id}`)}>
              <Icon type='edit' style={{ color: '#1890ff' }} />
            </Button>
          </Tooltip>
          <Divider type='vertical'></Divider>
          <Popconfirm
            placement='top'
            title='Are you sure to delete this attribute?'
            okText='Yes'
            cancelText='No'
            onConfirm={() => handleDeleteAttribute(record.id)}>
            <Tooltip placement='top' title='Delete'>
              <Button size='small'>
                <Icon type='delete' style={{ color: 'red' }} />
              </Button>
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ]

  const pagination = {
    current: filter.page,
    total: data?.getAttributes.count,
    pageSize: filter.limit,
    onChange: (page, pageSize) => {
      setFilter({
        ...filter,
        offset: (page - 1) * pageSize,
        page: page,
      })
    },
  }

  return (
    <div>
      <PageHeader
        style={{ padding: '16px 0px' }}
        title='Attributes'
        onBack={() => {
          history.goBack()
        }}
        extra={
          <Button type='primary' icon='plus' onClick={() => history.push(`/admin/attribute/add`)}>
            Add Attribute
          </Button>
        }
      />

      <div>
        <Table rowKey={(record) => record.id} columns={columns} dataSource={data?.getAttributes?.hits} pagination={pagination} loading={loading} />
      </div>
    </div>
  )
}

export default AttributesList
