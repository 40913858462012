import React, { Component } from 'react'
import gql from '../../api/gql'
import { notification, Form, Select, Input, Button, Upload, Table, Row, Col, Modal, InputNumber, Tabs, Icon, Avatar, Alert, Divider } from 'antd'
import _ from 'lodash'
import Papa from "papaparse"
import ProductTypeSelector from '../product_type/ProductTypeSelector';
import { DndProvider } from 'react-dnd';
import HTML5Backend from "react-dnd-html5-backend";
import CurrencyFormat from 'react-currency-format'
import { ORDER_FIELD } from '../admin/Orders';
import MappingOrderFileds from './MappingOrderFileds';
import styled from 'styled-components'
import ProductTypeAttributeSelector from '../product_type/ProductTypeAttributeSelector';

const Container = styled.div`
`

class ImportOrders extends Component {
  state = {
    site_id: null,
    loading: false,
    orders: [],
    data: [],
    headers: [],
    selectedOrders: [],
    product_types: [],
    variations: [],
    step: 0,
    selectedVariants: [], // Check here to configure the default column
    editVariantsPrice: false,
    bulkPrices: {
      retail_cost: null,
      sale_cost: null
    },
    selectedVariations: [],
    importingLoading: false,
    mappingOrderFields: false,
    showMessage: false,
    selectedVariantId: null
  }

  componentDidMount() {
    var { match: { params: { id } } } = this.props
    this.setState({ site_id: id })
  }

  // handleUpload = file => {
  //   this.setState({
  //     showTrackings: true
  //   })
  //   const reader = new FileReader()
  //   reader.onload = evt => {
  //     const bstr = evt.target.result
  //     const wb = XLSX.read(bstr, { type: 'binary' })
  //     this.setState({
  //       worksheet: wb,
  //       activeSheet: 0
  //     }, () => this.loadSheet())
  //   }
  //   reader.readAsBinaryString(file)
  //   return false
  // }

  handleUpload = file => {
    this.setState({
      showTrackings: true
    })
    const reader = new FileReader()
    reader.onload = evt => {
      const bstr = evt.target.result
      const parsed = Papa.parse(bstr,{skipEmptyLines: true,})
      this.setState({
        data: parsed.data
      }, () => this.loadSheet())
    }
    reader.readAsBinaryString(file)
    return false
  }

  // loadSheet = () => {
  //   const { activeSheet, worksheet, site_id } = this.state
  //   const wsname = worksheet.SheetNames[activeSheet]
  //   const ws = worksheet.Sheets[wsname]
  //   var orders = []
  //   const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
  //   this.setState({
  //     data: data,
  //     headers: data[0],
  //     mappingOrderFields: true
  //   })
  //   //console.log("data", data)
  // }

  loadSheet = () => {
    const { data } = this.state
    this.setState({
      data: data,
      headers: data[0],
      mappingOrderFields: true
    })
  }


  importOrders = (id) => {
    //e.preventDefault()
    const { selectedOrders, product_types} = this.state
    console.log("selectedOrders", selectedOrders)
    this.setState({ importingLoading: true })
    Promise.all(selectedOrders.map(order => {
      console.log("data", {
        product_type_ids: product_types.map(p => p.id),
        product_origin_id: order.product_origin_id,
        product_title: order.product_title,
        selected_variant_type_id: id,
        order: _.omit(order, ["product_origin_id", "product_title", "product_variation"]),
      })
      return new Promise((resolve) => {
        console.log("order", order)
        let mutation = `mutation importOrder($data: ImportOrder!){
            ImportOrder(data: $data){
              ${ORDER_FIELD}
            }
            }`
        gql.request(mutation, {
          data: {
            product_type_ids: product_types.map(p => p.id),
            product_origin_id: order.product_origin_id,
            product_title: order.product_title,
            selected_variant_type_id: id,
            order: _.omit(order, ["product_origin_id", "product_title", "product_variation"]),
          }
        }).then(res => {
          notification.success({ message: `Successfuly to import order #${order.origin_id} item ${order.item}` })
          const orders = _.reject(this.state.orders, function (el) { return el.origin_id === order.origin_id && el.item === order.item; });
          this.setState({ orders: orders })
          resolve(true)
        }).catch(err => {
          notification.error({ message: _.get(err, '[0].message') })
          resolve(false)
        })
      })
    })).then(res => {
      this.setState({ importingLoading: false, selectedOrders: [] })
    })
  }

  loadOrders = (values) => {
    const { data, orders, site_id } = this.state
    //console.log("test", values.product_origin_id)'
    let mappingOrders = []
    Promise.all(data.map((order, index) => {
      return new Promise((resolve) => {
        if (index > 0 && order[0]) {
          // const billing = {
          //   address1: !!order[values.billing_address1] ? order[values.billing_address1].toString() : '',
          //   address2: !!order[values.billing_address2] ? order[values.billing_address2].toString() : '',
          //   city: !!order[values.billing_city] ? order[values.billing_city].toString() : '',
          //   country: !!order[values.billing_country] ? order[values.billing_country].toString() : '',
          //   country_code: !!order[values.billing_country_code] ? order[values.billing_country_code].toString() : '',
          //   first_name: !!order[values.billing_first_name] ? order[values.billing_first_name].toString() : '',
          //   last_name: !!order[values.billing_last_name] ? order[values.billing_last_name].toString() : '',
          //   zip: !!order[values.billing_zip] ? order[values.billing_zip].toString() : '',
          //   state: !!order[values.billing_state] ? order[values.billing_state].toString() : '',
          //   state_code: !!order[values.billing_state_code] ? order[values.billing_state_code].toString() : '',
          //   company: !!order[values.billing_company] ? order[values.billing_company].toString() : ''
          // }
          const shipping = {
            address1: !!order[values.shipping_address1] ? order[values.shipping_address1].toString() : '',
            address2: !!order[values.shipping_address2] ? order[values.shipping_address2].toString() : '',
            city: !!order[values.shipping_city] ? order[values.shipping_city].toString() : '',
            country: !!order[values.shipping_country] ? order[values.shipping_country].toString() : '',
            country_code: !!order[values.shipping_country_code] ? order[values.shipping_country_code].toString() : '',
            first_name: !!order[values.shipping_first_name] ? order[values.shipping_first_name].toString() : '',
            last_name: !!order[values.shipping_last_name] ? order[values.shipping_last_name].toString() : '',
            zip: !!order[values.shipping_zip] ? order[values.shipping_zip].toString() : '',
            state: !!order[values.shipping_state] ? order[values.shipping_state].toString() : '',
            state_code: !!order[values.shipping_state_code] ? order[values.shipping_state_code].toString() : '',
            company: ''
          }
          const customer = {
            first_name: !!order[values.shipping_first_name] ? order[values.shipping_first_name].toString() : '',
            last_name: !!order[values.shipping_last_name] ? order[values.shipping_last_name].toString() : '',
            email: !!order[values.customer_email] ? order[values.customer_email].toString() : '',
            phone: !!order[values.customer_phone] ? order[values.customer_phone].toString() : '',
          }
          mappingOrders.push({
            product_origin_id: !!order[values.product_origin_id] ? order[values.product_origin_id].toString() : '',
            origin_id: !!order[values.origin_id] ? order[values.origin_id].toString() : '',
            site_id: site_id,
            quantity: !!order[values.quantity] ? order[values.quantity].toString() : '',
            price: '0',
            customer: customer,
            billing: shipping,
            shipping: shipping,
            status: !!order[values.status] ? order[values.status].toString() : '',
            //sku: !!order[values.quantity] ? order[33].toString() : '',
            item: !!order[values.item] ? order[values.item].toString() : '',
            product_title: !!order[values.product_title] ? order[values.product_title].toString() : '',
            product_variation: !!order[values.product_variation] ? order[values.product_variation].toString() : '',
            item: !!order[values.item] ? order[values.item].toString() : '',
          })
        }
        resolve(true)
      })
    }))
    const query = `query CheckExistOrders($site_id: Int!, $inputs: [CheckOrderExists!]){
      CheckExistOrders(site_id: $site_id, inputs: $inputs){
        origin_id
        item
      }
    }`
    this.setState({ loading: true })
    const existsOrders = mappingOrders.map(o => {
      return {
        origin_id: o.origin_id,
        item: o.item
      }
    })
    gql.request(query, { site_id: parseInt(site_id), inputs: existsOrders })
      .then(res => {
        //console.log("existsOrders", res)
        this.setState({ loading: false })
        if (res.CheckExistOrders && res.CheckExistOrders.length > 0) {
          res.CheckExistOrders.map(e => {
            mappingOrders = mappingOrders.filter(o => !(o.origin_id == e.origin_id && o.item == e.item))
          })
        }
        this.setState({ orders: mappingOrders })
      })
      .catch(err => {
        notification["error"]({
          message: 'Error',
          description: _.get(err, "[0].message")
        })
        this.setState({ loading: false })
      })
    this.setState({ showMessage: true })
  }

  onImport = (order) => {
    const { site_id } = this.state
    let query = `query p($site_id: Int!, $origin_id: String!){productCheckOriginID(site_id: $site_id, origin_id: $origin_id){
      variants{
        product_type{
          id,
          title
          sku
          images
          categories{
            id, title
          }
          attributes{
            name
            slug
            options
          }
          product_type_variants{
            id
            product_type_id
            sku
            base_cost
            sale_cost
            retail_cost
            attributes{
              name
              slug
              option
            }
          }
        }
      }
    }
    }`
    gql.request(query, {
      site_id: parseInt(site_id),
      origin_id: order.product_origin_id
    }).then(res => {
      let product = res.productCheckOriginID
      if (product) {
        const types = [product.variants[0].product_type]
        this.setState({
          product_types: types,
          step: 2
        })
        //console.log("product_types", this.state.product_types)
      } else {
        this.setState({
          product_types: [],
          step: 1
        })
      }
    })
  }
  render() {
    const { orders, loading, selectedOrders, step, product_types, selectedVariants, headers, mappingOrderFields, site_id, showMessage, selectedVariantId } = this.state
    // const rowSelection = {
    //   selectedRowKeys: selectedOrders,
    //   onChange: selectedOrders => this.setState({ selectedOrders }),
    // }
    console.log("selectedOrders", selectedOrders)
    const columns = [
      {
        title: 'Action',
        width: 150,
        dataIndex: "order",
        render: (_, order) =>
          <Button style={{ padding: 0 }} type="link" icon="import" onClick={e => {
            e.preventDefault()
            this.setState({ selectedOrders: [...this.state.selectedOrders, order] })
            this.onImport(order)
          }}> Import </Button>
      },
      {
        title: 'Origin ID',
        dataIndex: 'origin_id',
        width: 150
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        width: 150,
      },
      {
        title: 'Price',
        dataIndex: 'price',
        width: 150
      },
      // {
      //   title: 'Status',
      //   dataIndex: 'status',
      //   width: 150
      // },
      {
        title: 'Item',
        dataIndex: 'item',
        width: 100
      },
      {
        title: 'Product ID',
        dataIndex: 'product_origin_id',
        width: 150
      },
      {
        title: 'Product Title',
        dataIndex: 'product_title',
        width: 200
      },
      {
        title: 'Product Variation',
        dataIndex: 'product_variation',
        width: 200
      },
      // {
      //   title: 'Address1 (billing)',
      //   dataIndex: 'billing.address1',
      //   width: 250,
      // },
      // {
      //   title: 'Address2 (billing)',
      //   dataIndex: 'billing.address2',
      //   width: 250,
      // },
      // {
      //   title: 'City (billing)',
      //   dataIndex: 'billing.city',
      //   width: 150,
      // },
      // {
      //   title: 'Country Code (billing)',
      //   dataIndex: 'billing.country_code',
      //   width: 150,
      // },
      // {
      //   title: 'First Name (billing)',
      //   dataIndex: 'billing.first_name',
      //   width: 200,
      // },
      // {
      //   title: 'Last Name (billing)',
      //   dataIndex: 'billing.last_name',
      //   width: 200,
      // },
      // {
      //   title: 'Postcode (billing)',
      //   dataIndex: 'billing.zip',
      //   width: 150,
      // },
      // {
      //   title: 'State Code (billing)',
      //   dataIndex: 'billing.state_code',
      //   width: 150,
      // },
      // {
      //   title: 'Email (billing)',
      //   dataIndex: 'customer.email',
      //   width: 200,
      // },
      // {
      //   title: 'Phone (billing)',
      //   dataIndex: 'customer.phone',
      //   width: 200,
      // },
      {
        title: 'First Name',
        dataIndex: 'shipping.first_name',
        width: 200,
      },
      {
        title: 'Last Name',
        dataIndex: 'shipping.last_name',
        width: 200,
      },
      {
        title: 'Address1',
        dataIndex: 'shipping.address1',
        width: 250,
      },
      {
        title: 'Address2',
        dataIndex: 'shipping.address2',
        width: 250,
      },
      {
        title: 'City',
        dataIndex: 'shipping.city',
        width: 200,
      },
      {
        title: 'Country Code',
        dataIndex: 'shipping.country_code',
        width: 150,
      },
      {
        title: 'Postcode',
        dataIndex: 'shipping.zip',
        width: 150,
      },
      {
        title: 'State Code',
        dataIndex: 'shipping.state_code',
        width: 150,
      },
    ]
    return (
      <Container>
        <Row gutter={24}>
          {/* <Col className="gutter-row" span={12}>
            <Button disabled={selectedOrders.length === 0} type="primary" onClick={e => {
              this.setState({
                step: 1
              })
            }}>Import Orders</Button>
          </Col> */}
          <Col className="gutter-row" span={18}>
            {showMessage && <Alert message="Please review carefully to make sure every field are mapped before importing order" type="warning" />}
          </Col>
          <Col className="gutter-row" span={6} style={{ textAlign: "right" }}>
            <Upload
              accept=".csv"
              showUploadList={false}
              beforeUpload={this.handleUpload}
              multiple={false}
              customRequest={file => { }}
            >
              <Button icon="import" type="primary">Select CSV</Button>
            </Upload>          </Col>
        </Row>
        <Table style={{ marginTop: 20 }} scroll={{ x: 2000 }} columns={columns} dataSource={orders} pagination={{ pageSize: 20 }} rowKey={row => `${row.origin_id}-${row.item}`} loading={loading} />
        <Modal
          visible={step === 1 && selectedOrders.length > 0}
          width={800}
          maskClosable={false}
          title="Select Product Type"
          okText="Next"
          okButtonProps={{ disabled: product_types.length === 0 }}
          onOk={e => this.setState({ step: 2 })}
          onCancel={e => this.setState({ step: 0, selectedOrders:[] })}
          destroyOnClose={true}
        >
          <ProductTypeSelector isSingleSelect={true} onSelect={types => {
            console.log("types", types)
            this.setState({
              product_types: types,
              // variations: [].concat.apply([], types.map(type => type.product_type_variants)),
              // selectedVariations: (types && types.length) ? types[0].product_type_variants : []
            })
          }} />
        </Modal>
        {/* <Modal
          title="Update variants price"
          visible={editVariantsPrice}
          onCancel={e => this.setState({ editVariantsPrice: false })}
          onOk={e => {
            e.preventDefault()
            const { variations, selectedVariants } = this.state
            if (bulkPrices.retail_cost) {
              variations.forEach(v => {
                if (selectedVariants.includes(v.id)) {
                  v.retail_cost = bulkPrices.retail_cost
                }
              });
            }
            if (bulkPrices.sale_cost) {
              variations.forEach(v => {
                if (selectedVariants.includes(v.id)) {
                  v.sale_cost = bulkPrices.sale_cost
                }
              });
            }
            this.setState({ variations, editVariantsPrice: false })
          }}
        >
          <Form.Item label="Regular Price">
            <InputNumber formatter={value => `$${value}`} value={bulkPrices.retail_cost} onChange={v => this.setState({
              bulkPrices: { ...bulkPrices, retail_cost: v }
            })} />
          </Form.Item>
          <Form.Item label="Sale Price">
            <InputNumber formatter={value => `$${value}`} value={bulkPrices.sale_cost} onChange={v => this.setState({
              bulkPrices: { ...bulkPrices, sale_cost: v }
            })} />
          </Form.Item>
        </Modal> */}
        <Modal
          visible={step === 2 && selectedOrders.length > 0 && product_types.length > 0}
          width={800}
          maskClosable={false}
          title="Variants"
          okText="Submit"
          okButtonProps={{ disabled: product_types.length === 0, form: 'attribute-variant', key: 'submit', htmlType: 'submit' }}
          //onOk={this.importOrders}
          onCancel={e => this.setState({ step: 0 ,selectedOrders: []})}
          confirmLoading={this.state.importingLoading}

        >
          {product_types && product_types.map((product_type, i) => (
            <Row gutter={24} style={{ marginBottom: 15 }} key={i} type="flex">
              <Col className="gutter-row" span={10} style={{ textAlign: "left", borderRight: "solid 1px #eee"}}>
                <h3>Product Information</h3>
                <Divider type="horizontal" style={{marginTop: 10, marginBottom: 10}}/>
                <div style={{ marginBottom: "20px" }}>
                  <div><b>Product Type:</b></div>
                  <span> {product_type.title}</span>
                </div>
                {selectedOrders.length > 0 && selectedOrders[0].product_title && <div style={{ marginBottom: "20px" }}>
                  <div><b>Product Origin Title:</b></div>
                  <span> {selectedOrders[0].product_title}</span>
                </div>}
                {selectedOrders.length > 0 && selectedOrders[0].product_variation && <div>
                  <div><b>Origin Variant:</b></div>
                  <span> {selectedOrders[0].product_variation}</span>
                </div>}
              </Col>
              <Col className="gutter-row" span={14}>
                <h3>Select Variant</h3>
                <Divider type="horizontal" style={{marginTop: 10, marginBottom: 10}}/>
                <ProductTypeAttributeSelector productType={product_type} onSubmit={(id) => {
                  this.importOrders(id)
                }} />
              </Col>
            </Row>
          ))
          }
        </Modal>
        <Modal
          className="mapping-orders"
          visible={mappingOrderFields}
          width={800}
          maskClosable={false}
          destroyOnClose={true}
          title="Mapping Order Fields"
          okButtonProps={{ form: 'mapping-order', key: 'submit', htmlType: 'submit' }}
          okText="Ok"
          onCancel={e => this.setState({ mappingOrderFields: false, selectedOrders: [] })}
        >
          <MappingOrderFileds site_id={site_id} headers={headers} onSubmit={(values) => {
            this.loadOrders(values)
            this.setState({ mappingOrderFields: false })
          }
          } />
        </Modal>
      </Container>
    )
  }
}

export default ImportOrders;