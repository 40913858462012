import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setOrdersFilter, setOrderId } from "../../actions";
import {
  Button,
  Drawer,
  Tabs,
  Badge,
  message,
  Row,
  Col,
  Modal,
  PageHeader,
  Avatar,
  Carousel,
  Tooltip,
  notification,
} from "antd";
import Table from "../Table";
import moment from "moment";
import gql from "../../api/gql";
import ButtonGroup from "antd/lib/button/button-group";
import CustomerInfo from "../orders/CustomerInfo";
import Comments from "../orders/Comments";
import UploadDesigns from "../products/UploadDesigns";
import _ from "lodash";
import SupplierOrderFilter from "./SupplierOrderFilter";
import SupplierOrderToolbar from "../orders/SupplierOrderToolbar";
import { ORDER_FIELD } from "../admin/Orders";
import AddTracking from "../orders/AddTracking";
import styled from "styled-components";
import EntityLogs from "../orders/EntityLogs";
import { history } from "../../history";
import UploadDesignPersonal from "../products/UploadDesignPersonal";
import { PushToDtf } from "../admin/PushToDtf";
const TrackingWrapper = styled.div`
  .tracking-actions {
    font-size: 12px;
    opacity: 0;
    button {
      font-size: 12px;
    }
  }
  &:hover {
    .tracking-actions {
      opacity: 1;
    }
  }
`;
const ProductCarousel = styled.div`
  .ant-carousel .slick-dots li button {
    background: #1890ff;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #364d79;
  }
`;

const PER_PAGE = 20;
class SuplierApiOrder extends Component {
  state = {
    orders: [],
    aggs: [],
    selectedOrder: null,
    total: 0,
    filter: {
      ...this.props.filter,
      offset: 0,
      limit: PER_PAGE,
      aggs_by: "status",
      api_order: true,
      status: ["ready", "in_production", "fulfilled", "refunded"],
      supplier_ids: [this.props.currentUser.id],
    },
    page: 1,
    loading: false,
    showUpdateCustomer: false,
    showOrderComments: false,
    showUploadDesign: false,
    selectCarrier: null,
    tracking_code: "",
    carrier_id: null,
    showAddTracking: false,
    addingTracking: false,
    showEntityLogsTracking: false,
    showImage: false,
    loadingOk: false,
    loadingSave: false,
    disbaleTracking: false,
    pushDtfState: {},
    pushDtfData: {},
    btnPushToDtfLoading: false,
    dtfId: 193,
    orderSelect: [],
    disableBtnPushDtf: false,
  };

  componentDidMount() {
    this.loadOrders();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState(
        {
          page: 1,
          filter: {
            ...this.props.filter,
            offset: 0,
            limit: PER_PAGE,
            aggs_by: "status",
          },
        },
        () => this.loadOrders()
      );
    }
  }

  loadOrders() {
    const query = `query orders($filter: OrderFilter){
      orders(filter: $filter){
        count
        hits{
          ${ORDER_FIELD}
        },
        aggs{
          key
          doc_count
        }
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(query, {
        filter: _.omit(this.state.filter),
      })
      .then((res) => {
        this.setState({
          orders: res.orders.hits || [],
          aggs: res.orders.aggs || [],
          total: res.orders.count,
          loading: false,
        });
      })
      .catch((err) => {
        // notification["error"]({
        //   message: _.get(err, "[0].message"),
        // });
        this.setState({ loading: false });
      });
  }

  statusFilter = (status) => {
    const { setOrdersFilter } = this.props;
    setOrdersFilter({
      ...this.state.filter,
      status:
        status === "all"
          ? ["ready", "in_production", "fulfilled", "refunded"]
          : [status],
    });
  };

  pauseOrResumeOrder = (id, status) => {
    const name = status === "pending" ? "resumeOrder" : "pauseOrder";
    const mutation = `mutation ${name}($order_id: Int!){
      ${name}(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { order_id: id })
      .then((res) => {
        message["success"](
          `Order has been ${status === "pending" ? "resumed " : "paused"}`
        );
        const orders = this.state.orders.map((el) => {
          if (el.id === id) {
            if (name === "pauseOrder") {
              return res.pauseOrder;
            } else {
              return res.resumeOrder;
            }
          } else return el;
        });
        this.setState({ orders, loading: false });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };

  cancelOrder = (id) => {
    const mutation = `mutation cancelOrder($order_id: Int!){
      cancelOrder(order_id: $order_id)
    }`;
    gql
      .request(mutation, { order_id: id })
      .then((res) => {
        message["success"](`Order has been cancel`);
        this.loadOrders();
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  };

  assignOrderToSupplier = (id) => (value) => {
    const mutation = `mutation AssignOrderSupplier($order_id: Int!, $supplier_id: Int!){
      AssignOrderSupplier(order_id: $order_id, supplier_id: $supplier_id){
        ${ORDER_FIELD}
      }
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { order_id: +id, supplier_id: +value })
      .then((res) => {
        const orders = this.state.orders.map((el) => {
          if (el.id === id) {
            return res.AssignOrderSupplier;
          } else return el;
        });
        message["success"]("Assign supplier successfully");
        this.setState({ loading: false, orders: orders });
      });
  };
  updateTracking = (tracking_id) => {
    const mutation = `mutation reTrack($id: ID!){
     reTrack(id: $id)
    }`;
    this.setState({ loading: true });
    gql
      .request(mutation, { id: tracking_id })
      .then((res) => {
        message["success"](`Retrack tracking success!`);
        this.setState({ loading: false }, () => this.loadOrders());
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
        this.setState({ loading: false });
      });
  };

  pushToDtf = () => {
    this.setState({
      disableBtnPushDtf: true
    })

    if (this.state.orderSelect.length < 1 && parseInt(this.props.currentUser.id) === this.state.dtfId) {
      const mutationPushToDtf = `mutation dtfPushAllOrderReady(){dtfPushAllOrderReady}`

      gql.request(mutationPushToDtf, {})
      .then((res) => {
          console.log(res)

          if (res?.dtfPushAllOrderReady) {
              notification.success({ message: `Processing push all ready orders.` })

              this.setState({
                disableBtnPushDtf: false
              })
          }
      })
      .catch((err) => {
          notification.error({ message: _.get(err, '[0].message') })

          this.setState({
            disableBtnPushDtf: false
          })
      })

    } else {
      
      if (Object.entries(this.state.pushDtfData).length !== this.state.orderSelect.length) {
          notification.error({ message: "Complete order mapping."})

          return
      }

      const orderNotDtf = this.state.orderSelect.find((selected) => parseInt(selected?.supplier?.id) !== this.state.dtfId)

      if (orderNotDtf) {
          notification.error({ message: "Order " + orderNotDtf?.id + " is not from Color Ecommerce."})

          return
      }

      if (Object.keys(this.state.pushDtfData).length === 0) {
          notification.error({ message: "No mapping, please check again."})

          return
      }

      const orderMappingFailed = Object.entries(this.state.pushDtfData)
          .find(([key, value]) => (typeof value?.sku === 'undefined') || (typeof value?.shipping_method === 'undefined') || (value?.placement?.length <1))

      if (orderMappingFailed) {
          const orderIdFailed = orderMappingFailed[0] ?? ""
          notification.error({ message: "Order "+orderIdFailed+" is mapping failed."})

          return
      }

      this.setState({btnPushToDtfLoading: true})

      let data = []

      Object.entries(this.state.pushDtfData).forEach(([key, value]) => {
          let group = data?.find((item) => item.origin_number === value?.origin_number)
          let index = data?.findIndex((item) => item.origin_number === value?.origin_number)

          if (group) {
              const o = group?.orders?.find((i) => i?.order_id === key)

              if (! o) {
                  group.orders.push({
                      order_id: parseInt(key),
                      sku: value?.sku,
                      placement: value?.placement,
                      shipping_method: value?.shipping_method
                  })
              } 

              if (index !== -1) {
                  data.splice(index, 1)
              } 

              data.push(group)
          } else {
              let orders = []
              
              orders.push({
                  order_id: parseInt(key),
                  sku: value?.sku,
                  placement: value?.placement,
                  shipping_method: value?.shipping_method
              })

              data.push({
                  origin_number: value?.origin_number,
                  orders: orders,
              })
          } 
      });

      const mutationPushToDtf = `mutation dtfPushOrder($input: DtfRequestData!){
          dtfPushOrder(input: $input){
              success
              origin_number
              message
          }
      }`

      gql.request(mutationPushToDtf, { 
          input: {
              groups: data
          }
      })
      .then((res) => {
          let dtfPushOrder = res?.dtfPushOrder ?? []

          if (dtfPushOrder?.length > 0) {
              dtfPushOrder.forEach((order) => {
                  if (order?.success) {
                      notification.success({ message: `Order #${order?.origin_number} ${order?.message}` })
                  } else {
                      notification.error({ message: `Order #${order?.origin_number} ${order?.message}` })
                  }
              })

              this.setState({
                  pushDtfData: {}, 
                  orderSelect: [],
                  btnPushToDtfLoading: false,
                  disableBtnPushDtf: false
              })
          
              this.loadOrders()

              return
          }

          if (res?.errors?.length > 0) {
              this.setState({
                  pushDtfData: {}, 
                  orderSelect: [],
                  btnPushToDtfLoading: false,
                  disableBtnPushDtf: false,
              })

              const resErr = res?.errors[0]
              notification.error({ message: resErr })

              return
          }
      })
      .catch((err) => {
          this.setState({
              pushDtfData: {}, 
              orderSelect: [],
              btnPushToDtfLoading: false
          })

          notification.error({ message: _.get(err, '[0].message') })
      })
    }
  }

  handleChangePushToDtf = (id, data) => {
    if (data?.sku && data?.placement?.length > 0 && data?.shipping_method) {
        this.setState({pushDtfState: {
            ...this.state.pushDtfState,
            [id]: {
                ...this.state.pushDtfState[id],
                btnPushDtfDisable: false
            }
        }})
    } else {
        this.setState({pushDtfState: {
            ...this.state.pushDtfState,
            [id]: {
                ...this.state.pushDtfState[id],
                btnPushDtfDisable: true
            }
        }})
    }

    this.setState({pushDtfData: {
        ...this.state.pushDtfData,
        [id]: data
    }})
  }

  render() {
    const {
      orders,
      aggs,
      total,
      filter,
      loading,
      page,
      selectedOrder,
      showUpdateCustomer,
      showOrderComments,
      showUploadDesign,
      showAddTracking,
      showEntityLogsTracking,
      showImage,
      loadingOk,
      loadingSave,
      pushDtfState,
    } = this.state;
    
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        width: 100,
        render: (id, order) => (
          <div>
            <p>{`N${order.id.padStart(6, "0")}`}</p>
          </div>
        ),
      },
      {
        title: "Origin Number / Origin ID",
        dataIndex: "origin_number",
        width: 200,
        render: (origin_number, order) => (
          <div>
            {origin_number && <p>{`${origin_number}`}</p>}
            <p style={{ fontStyle: "italic" }}>{`${order.origin_id}`}</p>
          </div>
        ),
      },
      {
        title: "Image Mockup",
        dataIndex: "variant_image",
        width: 200,
        render: (variant_image) => (
          <Avatar
            icon="picture"
            src={variant_image}
            shape="square"
            size={60}
            style={{ marginRight: "10px" }}
          />
        ),
      },
      {
        title: "product",
        dataIndex: "product_type_variant",
        width: 200,
        render: (product_type_variant, order) => (
            <>
              {product_type_variant?.attributes && (
                <div>
                  <p>
                    <b>Product type</b>: {product_type_variant?.product_type?.title}
                  </p>
                  <div>
                    {product_type_variant?.attributes.map((a, index) => {
                        return (
                          <Fragment key={index}>
                            <p style={{ marginBottom: 0 }}>
                              <b>{a.slug}</b>: {a.option}{" "}
                            </p>
                          </Fragment>
                        );
                      })}
                  </div>
                </div>
              )}
            </>
        ),
      },
      {
        title: "Sku",
        dataIndex: "sku",
        width: 200,
        render: (_, order) => (
          <p style={{ marginBottom: 5 }}>
            {order.product_sku ? order.product_sku : order.product.sku}{" "}
          </p>
        ),
      },
      {
        title: "QTY",
        dataIndex: "price",
        key: "price",
        width: 150,
        render: (id, order) => (
          <div>
            <p>{`${order.quantity}`}</p>
            <p style={{ marginBottom: 0, fontStyle: "italic" }}>
              {moment(order.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
        ),
      },
      {
          title: "COST",
          dataIndex: "base_cost",
          key: "base_cost",
          width: 80,
          render: (id, order) => (
              <>
                  {order.base_cost > 0 && (
                    <div>
                      {`$${order.base_cost}`}
                    </div>
                  )}
              </>
          ),
      },
      {
          title: "SHIPPING COST",
          dataIndex: "shipping_cost",
          key: "shipping_cost",
          width: 120,
          render: (id, order) => (
              <>
                  {order.shipping_cost > 0 && (
                    <div>
                      {`$${order.shipping_cost}`}
                    </div>
                  )}
              </>
          ),
      },
      {
        title: "Shipping Method",
        width: 150,
        key: "carrier",
        render: (_, order) => (
          <>
            {order?.carrier?.name}

            {order?.shipping?.method_title && (
                <p style={{ marginBottom: 0}}>
                    {order?.shipping?.method_title}
                </p>
            )}
          </>
        ),
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        width: 250,
        render: (id, order) => (
          <div>
            <p>{`${order.customer.first_name} ${order.customer.last_name}`}</p>
            <p style={{ marginBottom: 0, fontStyle: "italic" }}>
              {order.customer.email}
            </p>
          </div>
        ),
      },
      {
        title: "Seller",
        dataIndex: "seller",
        key: "site",
        width: 150,
        render: (id, order) => (
          <div>
            <p>{`${
              order.site && order.site.user ? order.site.user.first_name : ""
            } ${
              order.site && order.site.user ? order.site.user.last_name : ""
            }`}</p>
            <p style={{ marginBottom: 0, fontStyle: "italic" }}>
              {order.site.title}
            </p>
          </div>
        ),
      },
      {
        title: "Tracking code",
        width: 200,
        dataIndex: "tracking",
        align: "center",
        render: (tracking, order) => {
          if (tracking) {
            let trackingUrl = order.carrier ? order.carrier.url : "";
            if (trackingUrl) {
              trackingUrl = trackingUrl.replace(
                "TRACKING_NUMBER",
                tracking.tracking_code
              );
            }
            return (
              <Fragment>
                <TrackingWrapper>
                  <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
                    {tracking.tracking_code}{" "}
                  </a>
                  <span>
                    <Button
                      type="link"
                      style={{ padding: 0 }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          carrier_id: order.carrier ? order.carrier.id : 0,
                          tracking_code: tracking.tracking_code,
                          showAddTracking: true,
                          selectedOrder: order,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  </span>
                  <div className="tracking-actions">
                    <a
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          selectedOrder: order,
                          showEntityLogsTracking: true,
                        });
                      }}
                    >
                      View logs
                    </a>{" "}
                    |{" "}
                    <Button
                      type="link"
                      size="small"
                      // loading={updateTrackingLoadings[tracking.id]}
                      onClick={(e) => {
                        e.preventDefault();
                        this.updateTracking(tracking.id);
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </TrackingWrapper>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                {order.status === "in_production" && (
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showAddTracking: true,
                        selectedOrder: order,
                      });
                    }}
                  >
                    Add Tracking
                  </Button>
                )}
              </Fragment>
            );
          }
        },
      },
      {
        title: "Status",
        width: 120,
        dataIndex: "status",
        align: "center",
        fixed: "right",
        render: (status, order) => {
          const item = orderStatus.find((el) => [status].includes(el.key))
          const partner_error = order?.partner_error?.[0] ?? null
          return (
              <>
                  {item  && (
                      <span style={{ color: `${item.color}` }}>
                          {item.title} 
                      </span>
                  )}

                  {item.key === 'error' && (
                      <div style={{ color: `${item.color}` }}>{partner_error}</div>
                  )} 
              </>
          )
        },
      },
      {
        title: "Actions",
        width: 240,
        dataIndex: "id",
        key: "actions",
        align: "center",
        fixed: "right",
        render: (id, order) => {
          let status =
            order.personalized || order.custom_order
              ? order.design_status
              : order.variant.design_status;
          return (
            <div>
              <ButtonGroup>
                <Button
                  style={{ width: 80 }}
                  type="info"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      showUpdateCustomer: true,
                      selectedOrder: order,
                    });
                  }}
                >
                  Info
                </Button>
                {status ? (
                  <Button
                    style={{ width: 80 }}
                    type="primary"
                    ghost
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showUploadDesign: true,
                        selectedOrder: order,
                      });
                    }}
                  >
                    Design
                  </Button>
                ) : (
                  <Button
                    style={{ width: 80 }}
                    type="danger"
                    ghost
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showUploadDesign: true,
                        selectedOrder: order,
                      });
                    }}
                  >
                    Upload
                  </Button>
                )}
              </ButtonGroup>

              {(
                  ['ready', 'error'].includes(order.status) && 
                      parseInt(order.supplier.id) === this.state.dtfId
                  ) &&
                  (
                      <>
                          <Tooltip title='DTF data mapping'>
                              <Button
                                  onClick={() => {
                                      this.setState({pushDtfState: {
                                          ...pushDtfState,
                                          [order.id]: {
                                              ...pushDtfState[order.id],
                                              show: true
                                          }
                                      }})
                                  }}
                              >
                                  DTF
                              </Button>
                          </Tooltip>

                          <Modal
                              title='DTF data mapping, complete all'
                              visible={pushDtfState[order.id]?.show}
                              onCancel={() => {
                                  this.setState({pushDtfState: {
                                      ...pushDtfState,
                                      [order.id]: {
                                          ...pushDtfState[order.id],
                                          show: false
                                      }
                                  }})
                              }}
                              footer={[
                                  <Button
                                      disabled={pushDtfState[order.id]?.btnPushDtfDisable ?? true}
                                      key={"modal-push-to-dtf"+order.id}
                                      type='primary'
                                      onClick={() => {
                                          this.setState({pushDtfState: {
                                              ...pushDtfState,
                                              [order.id]: {
                                                  ...pushDtfState[order.id],
                                                  show: false
                                              }
                                          }})
                                      }}
                                  >
                                      Save
                                  </Button>
                              ]}
                          >
                              <PushToDtf
                                  partnerFulfillmentId={order.supplier.partner_fulfillment_id}
                                  printFiles={order.personalized ? order.print_files?.map(item => ({
                                      id: item?.product_type_print_file_id,
                                      title: item?.title,
                                      file_id: item?.fileID,
                                  })) : order.variant.print_files}
                                  onChange={(data) => {
                                      data.origin_number = order?.origin_number
                                      this.handleChangePushToDtf(order.id, data)
                                  }}
                                  stateValue={this.state.pushDtfData[order.id]}
                              />
                          </Modal>    
                      </>
                  )} 
            </div>
          );
        },
      },
    ];
    const orderStatus = [
      {
        key: "ready",
        title: "Ready",
        color: "blue",
      },
      {
        key: "in_production",
        title: "In Production",
        color: "blue",
      },
      {
        key: "fulfilled",
        title: "Fulfilled",
        color: "#52c41a",
      },
      {
          key: 'error',
          title: 'Error',
          color: '#ff5500',
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    const pagination = {
      pageSize: filter.limit,
      total: total,
      current: page,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState(
          {
            page: page,
            filter: {
              ...filter,
              offset: (page - 1) * pageSize,
            },
          },
          () => this.loadOrders()
        );
      },
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.props.setOrderId(selectedRowKeys);
        this.setState({ orderSelect: selectedRows })
      },
    };

    console.log(parseInt(this.props.currentUser.id))

    const extraPageHeader = <div style={{display:'flex', alignItems:'center'}}>
            <SupplierOrderToolbar
                menu={true}
                onDownload={() => {
                  this.loadOrders();
                }}
              />

            <Button
                type={'primary'}
                loading={this.state.btnPushToDtfLoading} 
                disabled={this.state.disableBtnPushDtf || (parseInt(this.props.currentUser.id) === this.state.dtfId ? false : this.state.orderSelect?.length < 1)}
                onClick={() => this.pushToDtf()}
            >
                Push DTF
            </Button>
        </div>

    return (
      <div>
        <PageHeader
          title="Api Orders"
          onBack={() => {
            history.goBack();
          }}
          style={{ padding: "16px 0px" }}
          extra={extraPageHeader}
        />
        <Row type="flex" style={{ width: "calc(100%)" }}>
          <Col
            span={24}
            // lg={19}
            style={{ display: "flex", alignItems: "center" }}
          >
            <SupplierOrderFilter
              {...this.props}
              status={["ready", "in_production", "fulfilled", "refunded"]}
            />
          </Col>
          {/* <Col span={0} lg={5} style={{ textAlign: "right" }}>
            <SupplierOrderToolbar
              onDownload={() => {
                this.loadOrders();
              }}
            />
          </Col> */}
        </Row>
        <Tabs
          onChange={this.statusFilter}
          style={{ marginBottom: 0 }}
          activeKey={
            filter.status && filter.status.length > 1
              ? "all"
              : filter.status
              ? filter.status[0]
              : "all"
          }
        >
          <Tabs.TabPane
            tab={
              <Badge
                overflowCount={9999999}
                count={_.sum(
                  aggs
                    .filter((agg) =>
                      [
                        "ready",
                        "in_production",
                        "fulfilled",
                        "refunded",
                      ].includes(agg.key)
                    )
                    .map((el) => el.doc_count)
                )}
                style={{
                  background: "#fff",
                  color: "#000",
                  border: "1px solid #f5f5f5",
                  right: -20,
                }}
              >
                All
              </Badge>
            }
            key="all"
          ></Tabs.TabPane>
          {orderStatus.map((s) => {
            if (!s.hide) {
              return (
                <Tabs.TabPane
                  key={s.key}
                  tab={
                    <Badge
                      overflowCount={9999999}
                      count={_.sum(
                        aggs
                          .filter((agg) => agg.key === s.key)
                          .map((a) => a.doc_count)
                      )}
                      style={{ backgroundColor: s.color }}
                    >
                      {s.title}
                    </Badge>
                  }
                />
              );
            }
          })}
        </Tabs>
        <Table
          scroll={{ x: tableWidth }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={orders}
          pagination={pagination}
          rowKey={({ id }) => id}
          loading={loading}
          isShowScroll={true}
        />
        <Drawer
          title={`Update customer info #${
            selectedOrder ? selectedOrder.origin_id : ""
          }`}
          onClose={() =>
            this.setState({
              showUpdateCustomer: false,
              selectedOrder: null,
            })
          }
          width="500px"
          visible={showUpdateCustomer}
        >
          {selectedOrder && (
            <CustomerInfo
              onClose={() =>
                this.setState({
                  showUpdateCustomer: false,
                  selectedOrder: null,
                })
              }
              onUpdate={() =>
                this.setState(
                  { showUpdateCustomer: false, selectedOrder: null },
                  () => this.loadOrders()
                )
              }
              order={selectedOrder}
            />
          )}
        </Drawer>
        <Drawer
          title={`Comments #${selectedOrder ? selectedOrder.origin_id : ""}`}
          onClose={() =>
            this.setState({
              showOrderComments: false,
              selectedOrder: null,
            })
          }
          width="500px"
          visible={showOrderComments}
        >
          {selectedOrder && <Comments order_id={selectedOrder.id} />}
        </Drawer>
        <Drawer
          width="500px"
          title="View logs"
          onClose={(e) =>
            this.setState({
              selectedOrder: null,
              showEntityLogsTracking: false,
            })
          }
          visible={showEntityLogsTracking}
        >
          <EntityLogs
            tracking="Tracking"
            selectedOrder={selectedOrder?.tracking}
          />
        </Drawer>
        <Drawer
          width="800px"
          title="Upload Designs"
          onClose={(e) =>
            this.setState({ selectedOrder: null, showUploadDesign: false })
          }
          visible={showUploadDesign}
        >
          {/* {selectedOrder && (
            <UploadDesigns
              product_id={selectedOrder.product.id}
              product_type_id={selectedOrder.variant.product_type_id}
              onClose={(e) =>
                this.setState({ selectedOrder: null, showUploadDesign: false })
              }
            />
          )} */}
          {selectedOrder &&
            !selectedOrder.personalized &&
            !selectedOrder.custom_order && (
              <UploadDesigns
                product_id={selectedOrder.product.id}
                product_variant_id={selectedOrder.variant.id}
                product_type_id={selectedOrder.variant.product_type_id}
                onClose={(e) =>
                  this.setState(
                    {
                      selectedOrder: null,
                      showUploadDesign: false,
                      selectedOrder: null,
                    },
                    () => this.loadOrders()
                  )
                }
              />
            )}
          {selectedOrder &&
            (selectedOrder.personalized || selectedOrder.custom_order) && (
              <UploadDesignPersonal
                selectedOrder={selectedOrder}
                onClose={(e) =>
                  this.setState({
                    selectedOrder: null,
                    showUploadDesign: false,
                    selectedOrder: null,
                  })
                }
              />
            )}
        </Drawer>
        {/**Add/Edit tracking code*/}
        <Modal
          title="Add/Edit tracking code"
          visible={showAddTracking}
          destroyOnClose={true}
          footer={[
            <Button
              disabled={
                loadingOk ? loadingOk : loadingSave ? loadingSave : false
              }
              onClick={(e) =>
                this.setState({
                  showAddTracking: false,
                  disbaleTracking: false,
                })
              }
            >
              Cancel
            </Button>,
            <Button
              hidden={
                !selectedOrder?.tracking &&
                selectedOrder?.status === "in_production"
              }
              disabled={loadingOk || this.state.disbaleTracking}
              loading={loadingSave}
              onClick={() => this.setState({ loadingSave: true })}
            >
              {"Save & Re-Submit TM"}
            </Button>,
            <Button
              disabled={loadingSave || this.state.disbaleTracking}
              form="add-tracking"
              key="submit"
              htmlType="submit"
              type="primary"
              onClick={(e) => this.setState({ loadingOk: true })}
              loading={loadingOk}
            >
              Save
            </Button>,
          ]}
          onCancel={(e) => this.setState({ showAddTracking: false })}
          // confirmLoading={addingTracking}
          // onOk={(e) => this.setState({ addingTracking: true })}
        >
          {selectedOrder && (
            <AddTracking
              setDisbaleTracking={(value) => {
                this.setState({ disbaleTracking: value });
              }}
              order={selectedOrder}
              onOk={loadingSave}
              done={() =>
                this.setState({ loadingSave: false, showAddTracking: false })
              }
              onClose={() =>
                this.setState(
                  {
                    showAddTracking: false,
                    loadingSave: false,
                    loadingOk: false,
                  },
                  () => this.loadOrders()
                )
              }
              setLoading={(value) => {
                this.setState({ loadingOk: value });
              }}
            />
          )}
        </Modal>
        <Modal
          title="Image product"
          visible={showImage ? true : false}
          footer={false}
          onCancel={() => {
            this.setState({ showImage: false });
          }}
        >
          <ProductCarousel>
            {showImage && (
              <Carousel autoplay>
                {showImage?.map((item, index) => (
                  <img key={index} src={item} width={400} alt="" />
                ))}
              </Carousel>
            )}
          </ProductCarousel>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    filter: state.orders.filter,
    currentUser: state.app.currentUser,
  }),
  { setOrdersFilter, setOrderId }
)(SuplierApiOrder);
