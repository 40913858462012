import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { Button, notification, Modal, Tag, Input, Icon } from 'antd'
import { EXPORTTRACKING } from '../../graphql/mutation/trackingMutation'

ExportTracking.propTypes = {}

function ExportTracking(props) {
    // regex string to validate email input
    const regexEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g)

    // get props field value from list tracking
    const { filter, currentUser } = props

    const [modalExportTracking, setModalExportTracking] = useState({
        modalVisible: false,
        loadingConfirm: false,
        inputVisible: false,
        inputAddEmail: '',
    })

    const [receiverEmail, setReceiverEmail] = useState([])

    // init array email when render and first item is email of user
    useEffect(() => {
        if (currentUser.email && regexEmail.test(currentUser.email)) {
            setReceiverEmail([currentUser.email])
        }
    }, [])

    // init instance api export tracking
    const [exportTracking, {}] = useMutation(EXPORTTRACKING)

    // call api export tracking and return response
    function showExportTracking() {
        setModalExportTracking({
            ...modalExportTracking,
            modalVisible: true,
        })
    }

    // cancel modal export tracking and reset all value
    function cancelExportTracking() {
        setModalExportTracking({
            ...modalExportTracking,
            modalVisible: false,
            loadingConfirm: false,
        })
    }

    // processing data and call api export tracking
    function handleExportTracking() {
        if (receiverEmail == null || receiverEmail.length == 0) {
            notification.warning({ message: "you have not entered the receiver's email address" })
            return
        }

        setModalExportTracking({
            ...modalExportTracking,
            loadingConfirm: true,
        })

        // cal api export tracking with params and show notification
        exportTracking({
            variables: {
                filter: {
                    field: filter.field,
                    search: filter.search,
                    site_ids: filter?.site_ids ? filter.site_ids : null,
                    status: filter?.status ? filter.status : null,
                    from: filter?.from ? filter.from : null,
                    to: filter?.to ? filter.to : null,
                    emails: receiverEmail,
                },
            },
        })
            .then(() => {
                notification.success({ message: 'Export tracking success! Please check your email again' })
                setReceiverEmail([currentUser.email])
                setModalExportTracking({
                    ...modalExportTracking,
                    loadingConfirm: false,
                    modalVisible: false,
                    inputVisible: false,
                    inputAddEmail: '',
                })
            })
            .catch((err) => {
                notification.error({ message: err.message })
                setReceiverEmail([currentUser.email])
                setModalExportTracking({
                    ...modalExportTracking,
                    loadingConfirm: false,
                    modalVisible: false,
                    inputVisible: false,
                    inputAddEmail: '',
                })
            })
    }

    // remove email that client inputed
    function removeEmail(selectedEmail) {
        const arrEmail = receiverEmail.filter((email) => email != selectedEmail)

        setReceiverEmail(arrEmail)
    }

    // show input add email when client click btn add email
    function showInputAddEmail() {
        setModalExportTracking({
            ...modalExportTracking,
            inputVisible: true,
            inputFocus: true,
        })
    }

    // get value of input
    function changeInputAddEmail(e) {
        setModalExportTracking({
            ...modalExportTracking,
            inputAddEmail: e.target.value,
        })
    }

    // handle when client input email and push it into list email
    function handleInputAddEmail() {
        const newEmail = modalExportTracking.inputAddEmail

        const arrEmail = receiverEmail

        if (newEmail && receiverEmail.indexOf(newEmail) === -1 && regexEmail.test(newEmail) && receiverEmail.length < 5) {
            arrEmail.push(newEmail)
        }

        setReceiverEmail(arrEmail)

        setModalExportTracking({
            ...modalExportTracking,
            inputVisible: false,
            inputAddEmail: '',
        })
    }

    return (
        <div>
            <Button type='primary' icon='export' onClick={() => showExportTracking()}>
                Export tracking
            </Button>

            <Modal
                title='Receiver Email'
                visible={modalExportTracking.modalVisible}
                onOk={() => handleExportTracking()}
                confirmLoading={modalExportTracking.loadingConfirm}
                onCancel={() => cancelExportTracking()}>
                <div>
                    {/* DOM show list email */}
                    {receiverEmail.map((email) => {
                        const element = (
                            <Tag color='#2db7f5' style={{ marginBottom: '10px' }} key={email} closable={true} onClose={() => removeEmail(email)}>
                                {email}
                            </Tag>
                        )

                        return element
                    })}

                    {/* DOM insert email */}
                    <div>
                        {modalExportTracking.inputVisible && (
                            <Input
                                type='text'
                                size='small'
                                style={{ width: '100%' }}
                                value={modalExportTracking.inputAddEmail}
                                onChange={changeInputAddEmail}
                                onBlur={handleInputAddEmail}
                                onPressEnter={handleInputAddEmail}
                            />
                        )}

                        {!modalExportTracking.inputVisible && (
                            <Tag onClick={showInputAddEmail} style={{ background: '#fff', borderStyle: 'dashed' }}>
                                <Icon type='plus' /> Add Email
                            </Tag>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ExportTracking
