import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { setOrdersFilter } from '../../actions'
import {
    Button,
    Popconfirm,
    Drawer,
    Tabs,
    Badge,
    message,
    Dropdown,
    Menu,
    Icon,
    Divider,
    Row,
    Col,
    notification,
    Alert,
    Tooltip,
    PageHeader,
    Avatar,
    Modal,
    Carousel,
} from 'antd'
import Table from '../Table'
import moment from 'moment'
import gql from '../../api/gql'
import CustomerInfo from '../orders/CustomerInfo'
import Comments from '../orders/Comments'
import UploadDesigns from '../products/UploadDesigns'
import _ from 'lodash'
import SellerOrderFilter from './SellerOrderFilter'
import OrdersToolbar from '../orders/OrdersToolbar'
import FetchOrders from '../orders/FetchOrders'
import { ORDER_FIELD } from '../admin/Orders'
import EntityLogs from '../orders/EntityLogs'
import styled from 'styled-components'
import { history } from '../../history'
import ChangeVariant from '../orders/ChangeVariant'
import ChangeQuantity from '../orders/ChangeQuantity'
import UploadDesignPersonal from '../products/UploadDesignPersonal'
import { CLOUDFRONT_URL } from '../../config'
import OrderInfo from '../orders/OrderInfo'
import UploadLabel from '../orders/UploadLabel'
const TrackingWrapper = styled.div`
    .tracking-actions {
        font-size: 12px;
        opacity: 0;
        button {
            font-size: 12px;
        }
    }
    &:hover {
        .tracking-actions {
            opacity: 1;
        }
    }
`
const ContainerHover = styled.div`
    .ant-btn {
        display: none;
    }
    &:hover {
        .ant-btn {
            display: block;
        }
    }
`
const ProductCarousel = styled.div`
    .ant-carousel .slick-dots li button {
        background: #1890ff;
    }
    .ant-carousel .slick-dots li.slick-active button {
        background: #364d79;
    }
`

const PER_PAGE = 20
class Orders extends Component {
    state = {
        orders: [],
        aggs: [],
        selectedOrder: null,
        total: 0,
        filter: {
            ...this.props.filter,
            offset: 0,
            limit: PER_PAGE,
            aggs_by: 'status',
            api_order: false,
            site_ids: localStorage.getItem('notApiSite') ? [localStorage.getItem('notApiSite')] : null,
        },
        page: 1,
        loading: false,
        showUpdateCustomer: false,
        showOrderComments: false,
        showUploadDesign: false,
        showFetchOrders: false,
        showEntityLogs: false,
        showEntityLogsTracking: false,
        showImage: false,
        showInfoOrder: false,
        selectedOrderCarrier: null,
        carriers: [],
        loadingMenuIcon: 'down',
        initSetting: [],
        changeVariant: false,
        changeQuantity: false,
    }

    componentDidMount() {
        if (this.props.filter.site_ids) {
            this.loadOrders()
        }
        this.loadAppSetting()
    }

    componentDidUpdate(prevProps) {
        if ((this.props.filter.site_ids && prevProps.filter !== this.props.filter) || this.props.currentSite?.id !== prevProps.currentSite?.id) {
            this.setState(
                {
                    page: 1,
                    filter: {
                        ...this.props.filter,
                        offset: 0,
                        limit: PER_PAGE,
                        aggs_by: 'status',
                        api_order: false,
                        site_ids: [localStorage.getItem('notApiSite')],
                    },
                },
                () => this.loadOrders(),
            )
        }
    }

    loadOrders() {
        const query = `query orders($filter: OrderFilter){
      orders(filter: $filter){
        count
        hits{
          ${ORDER_FIELD}
        },
        aggs{
          key
          doc_count
        }
      }
    }`
        this.setState({ loading: true })
        gql.request(query, {
            filter: {
                ...this.state.filter,
                site_ids: [localStorage.getItem('notApiSite')],
            },
        })
            .then((res) => {
                this.setState({
                    orders: res.orders.hits || [],
                    aggs: res.orders.aggs || [],
                    total: res.orders.count,
                    loading: false,
                })
            })
            .catch((err) => {
                // notification["error"]({
                //   message: _.get(err, "[0].message"),
                // });
                this.setState({ loading: false })
            })
    }

    loadOrderCarriers = (order_id) => {
        let query = `query order{
      order(id: ${order_id}){
        id,
        carrier{
          id
          name
        }
        variant{
          product_type_variant{
            id
            carrier_costs{
              id
              carrier_id
              cost
              default
            }
          }
          product_type{
            state
            product_type_carriers{
              carrier{
                id
                name
              }
              price
            }
          }
        }
        product_type_variant{
          product_type{
            product_type_carriers{
              carrier{
                id
                name
              }
              price
            }
          }
        }
      }
    }`
        this.setState({ loadingMenuIcon: 'loading' })
        gql.request(query)
            .then((res) => {
                let carriers = res.order.custom_order
                    ? res.order.product_type_variant.product_type.product_type_carriers
                    : res.order.variant.product_type.product_type_carriers
                let variantCarrierCosts = res.order.variant.product_type_variant.carrier_costs
                carriers.map((cr) => {
                    const cost = variantCarrierCosts.filter((c) => c.carrier_id === cr.carrier.id)
                    if (cost && cost.length > 0) {
                        cr.price = cost[0].cost
                    }
                    return cr
                })
                this.setState({
                    carriers: carriers,
                    loadingMenuIcon: 'down',
                })
            })
            .catch((err) => {
                this.setState({
                    loadingMenuIcon: 'down',
                })
                notification.error({
                    message: _.get(err, '[0].message'),
                })
            })
    }

    statusFilter = (status) => {
        const { setOrdersFilter } = this.props
        setOrdersFilter({
            ...this.state.filter,
            status: status === 'all' ? null : [status],
        })
    }

    pauseOrResumeOrder = (id, status) => {
        const name = status === 'pending' ? 'resumeOrder' : 'pauseOrder'
        const mutation = `mutation ${name}($order_id: Int!){
      ${name}(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`
        this.setState({ loading: true })
        gql.request(mutation, { order_id: id })
            .then((res) => {
                message['success'](`Order has been ${status === 'pending' ? 'resumed ' : 'paused'}`)
                // this.setState({ loading: false }),
                // this.loadOrders()
                const orders = this.state.orders.map((el) => {
                    if (el.id === id) {
                        if (name === 'pauseOrder') {
                            return res.pauseOrder
                        } else {
                            return res.resumeOrder
                        }
                    } else return el
                })
                this.setState({ orders, loading: false })
                this.loadOrders()
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
            })
    }

    cancelOrder = (id) => {
        const mutation = `mutation cancelOrder($order_id: Int!){
      cancelOrder(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`
        gql.request(mutation, { order_id: id })
            .then((res) => {
                message['success'](`Order has been cancel`)
                //this.loadOrders()
                const orders = this.state.orders.map((el) => {
                    if (el.id === id) {
                        return res.cancelOrder
                    } else return el
                })
                this.setState({ orders, loading: false })
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
            })
    }

    processingOrder = (id) => {
        const mutation = `mutation updateOrderStatus($order_id: Int!, $status: orderStatus){
            updateOrderStatus(order_id: $order_id, status: $status){
                ${ORDER_FIELD}
            }
        }`
        gql.request(mutation, { 
                order_id: id,
                status: "processing"
            })
            .then((res) => {
                message['success'](`The order has been transferred to processing`)
                const orders = this.state.orders.map((el) => {
                    if (el.id === id) {
                        return res.updateOrderStatus
                    } else return el
                })
                this.setState({ orders, loading: false })
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
            })
    }

    assignOrderToSupplier = (id) => (value) => {
        const mutation = `mutation AssignOrderSupplier($order_id: Int!, $supplier_id: Int!){
      AssignOrderSupplier(order_id: $order_id, supplier_id: $supplier_id){
        ${ORDER_FIELD}
      }
    }`
        this.setState({ loading: true })
        gql.request(mutation, { order_id: +id, supplier_id: +value }).then((res) => {
            const orders = this.state.orders.map((el) => {
                if (el.id === id) {
                    return res.AssignOrderSupplier
                } else return el
            })
            message['success']('Assign supplier successfully')
            this.setState({ loading: false, orders: orders })
        })
    }

    assignOrderToCarrier = (carrier, id) => {
        const mutation = `mutation assignOrderCarrier($order_ids: [Int!], $carrier_id: Int!, $price: Float!){
      assignOrderCarrier(order_ids: $order_ids, carrier_id: $carrier_id, price: $price){
        ${ORDER_FIELD}
      }
    }`
        this.setState({ loading: true })
        gql.request(mutation, {
            order_ids: [id],
            carrier_id: carrier.carrier.id,
            price: carrier.price,
        }).then((res) => {
            const orders = this.state.orders.map((el) => {
                if (el.id === id) {
                    return res.assignOrderCarrier[0]
                } else return el
            })
            this.setState({ orders, loading: false })
        })
    }

    fetchOrdersHandle = () => {
        this.setState({ showFetchOrders: true })
    }

    loadAppSetting() {
        const query = `query getAppSettings($input: [String!]){
      getAppSettings(input: $input){
        name
        value
      }
    }`
        const input = ['seller_message']
        this.setState({ loading: true })
        gql.request(query, { input: input })
            .then((res) => {
                this.setState({
                    initSetting: res.getAppSettings || [],
                    loading: false,
                })
            })
            .catch((err) => {
                notification['error']({
                    message: 'Error',
                    description: _.get(err, '[0].message'),
                })
                this.setState({ loading: false })
            })
    }
    enterPayment = (id) => {
        const mutation = `mutation enterPayment($order_id: Int!){
      enterPayment(order_id: $order_id)
    }`
        this.setState({ loading: true })
        gql.request(mutation, { order_id: id })
            .then((res) => {
                message['success'](`Order has been moved to ready`)
                this.setState({ loading: false }, () => this.loadOrders())
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
                this.setState({ loading: false })
            })
    }
    updateTracking = (tracking_id) => {
        const mutation = `mutation reTrack($id: ID!){
     reTrack(id: $id)
    }`
        this.setState({ loading: true })
        gql.request(mutation, { id: tracking_id })
            .then((res) => {
                message['success'](`Retrack tracking success!`)
                this.setState({ loading: false }, () => this.loadOrders())
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
                this.setState({ loading: false })
            })
    }
    render() {
        const {
            orders,
            aggs,
            total,
            filter,
            loading,
            page,
            selectedOrder,
            showUpdateCustomer,
            showOrderComments,
            showUploadDesign,
            showEntityLogs,
            showEntityLogsTracking,
            showFetchOrders,
            showInfoOrder,
            loadingMenuIcon,
            initSetting,
            showImage,
            changeVariant,
            changeQuantity,
        } = this.state
        const setting = initSetting && initSetting.reduce((init, item) => ({ ...init, [item.name]: item.value }), {})
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 100,
                render: (_, order) => (
                    <div>
                        <p>{`N${order.id.padStart(6, '0')}`}</p>
                    </div>
                ),
            },
            {
                title: 'Origin Number / Origin ID',
                dataIndex: 'origin_number',
                width: 160,
                render: (origin_number, order) => (
                    <div>
                        {origin_number && <p>{`${origin_number}`}</p>}
                        <p style={{ fontStyle: 'italic' }}>{`${order.origin_id}`}</p>
                    </div>
                ),
            },
            {
                title: 'Amazon Order Id',
                dataIndex: 'amazon_order_id',
                width: 160,
                render: (amazon_order_id, order) => (
                    <Fragment>
                        {amazon_order_id && <p>{`${amazon_order_id}`}</p>}
                    </Fragment>
                ),
            },
            {
                title: 'Product / Variant',
                dataIndex: 'product',
                width: 350,
                render: (product, order) => (
                    <div style={{ display: 'grid', gridTemplateColumns: '70px auto' }}>
                        <Avatar
                            icon='picture'
                            src={
                                order.variant_image && order.variant_image.indexOf('http') === 0
                                    ? order.variant_image
                                    : product.images
                                    ? product.images[0].indexOf('http') === 0
                                        ? product.images[0]
                                        : `${CLOUDFRONT_URL}/${product.images[0]}`
                                    : null
                            }
                            // src={product.images ? product.images[0] : null}
                            shape='square'
                            size={60}
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                this.setState({ showImage: product.images })
                                console.log(product.images)
                            }}
                        />
                        <div>
                            <p>{`${order.product.title}`}</p>
                            <ContainerHover style={{ display: 'flex', gap: 5 }}>
                                {order.variant.product_type_variant.attributes &&
                                    order.variant.product_type_variant.attributes.map((a, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <p style={{ marginBottom: 5, fontStyle: 'italic' }}>
                                                    <b>{a.slug}</b>: {a.option}{' '}
                                                </p>
                                            </Fragment>
                                        )
                                    })}
                                <Button
                                    hidden={!_.includes(['pending_design', 'pending', 'ready', 'processing'], order.status)}
                                    size='small'
                                    type='link'
                                    onClick={() => {
                                        this.setState({ changeVariant: order })
                                    }}>
                                    <Icon type='edit' theme='twoTone' />
                                </Button>
                            </ContainerHover>
                            {/* <p style={{ marginBottom: 5 }}>
                <b>Sku</b>:{" "}
                {order.product_sku ? order.product_sku : product.sku}{" "}
              </p> */}
                            <p>
                                <b>Origin sku</b>: {order.origin_sku}
                            </p>
                        </div>
                    </div>
                ),
            },
            {
                title: 'Customer',
                dataIndex: 'customer',
                key: 'customer',
                width: 210,
                render: (id, order) => (
                    <div>
                        <p>{`${order.customer.first_name} ${order.customer.last_name}`}</p>
                        <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{order.customer.email}</p>
                    </div>
                ),
            },
            {
                title: 'Tracking code',
                width: 180,
                dataIndex: 'tracking',
                key: 'tracking',
                render: (tracking, order) => {
                    if (tracking) {
                        let trackingUrl = order.carrier ? order.carrier.url : ''
                        if (trackingUrl) {
                            trackingUrl = trackingUrl.replace('TRACKING_NUMBER', tracking.tracking_code)
                        }
                        return (
                            <TrackingWrapper>
                                <a href={trackingUrl} target='_blank'>
                                    {tracking.tracking_code}{' '}
                                </a>
                                <div className='tracking-actions'>
                                    <a
                                        href='/#'
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.setState({
                                                showEntityLogsTracking: true,
                                                selectedOrder: order,
                                            })
                                        }}>
                                        View logs
                                    </a>{' '}
                                    |{' '}
                                    <Button
                                        type='link'
                                        size='small'
                                        // loading={updateTrackingLoadings[tracking.id]}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.updateTracking(tracking.id)
                                        }}>
                                        Update
                                    </Button>
                                </div>
                            </TrackingWrapper>
                        )
                    }
                },
            },
            {
                title: 'Designs',
                dataIndex: 'designs',
                width: 130,
                render: (designs, order) => {
                    let status = order.personalized ? order.design_status : order.variant.design_status
                    return status ? (
                        <Button
                            icon='check'
                            type='primary'
                            ghost
                            onClick={(e) => {
                                e.preventDefault()
                                this.setState({
                                    showUploadDesign: true,
                                    selectedOrder: order,
                                })
                            }}>
                            View
                        </Button>
                    ) : (
                        <Button
                            icon='warning'
                            type='danger'
                            ghost
                            onClick={(e) => {
                                e.preventDefault()
                                this.setState({
                                    showUploadDesign: true,
                                    selectedOrder: order,
                                })
                            }}>
                            Upload
                        </Button>
                    )
                },
            },
            {
                title: 'Label',
                dataIndex: 'label_image_id',
                width: 120,
                render: (_, order) => {
                    return (
                        <UploadLabel 
                            orderId={order?.id}
                            fileId={order?.label_image_id} 
                        />
                    )
                }
            },
            {
                title: 'Sku',
                dataIndex: 'sku',
                width: 150,
                render: (_, order) => <p style={{ marginBottom: 5 }}>{order.product_sku ? order.product_sku : order.product.sku} </p>,
            },
            {
                title: 'Status',
                width: 140,
                // fixed: "right",
                dataIndex: 'status',
                render: (status) => {
                    const item = orderStatus.find((el) => [status].includes(el.key))
                    return item && <span style={{ color: `${item.color}` }}>{item.title}</span>
                },
            },
            {
                title: 'QTY',
                dataIndex: 'supplier_cost',
                key: 'supplier_cost',
                width: 130,
                render: (id, order) => (
                    <div>
                        <ContainerHover style={{ display: 'flex', gap: 5 }}>
                            <span style={{ margin: 5 }}>{order.quantity}</span>
                            <Button
                                size='small'
                                type='link'
                                hidden={!_.includes(['pending_design', 'pending', 'ready', 'processing'], order.status)}
                                onClick={() => {
                                    this.setState({ changeQuantity: order })
                                }}>
                                <Icon type='edit' theme='twoTone' />
                            </Button>
                        </ContainerHover>
                        <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{moment(order.created_at).format('MMM DD, YYYY')}</p>
                    </div>
                ),
            },
            {
                title: "COST",
                dataIndex: "base_cost",
                key: "base_cost",
                width: 80,
                render: (id, order) => (
                    <>
                        {order.base_cost > 0 && (
                          <div>
                            {`$${order.base_cost}`}
                          </div>
                        )}
                    </>
                ),
            },
            {
                title: "SHIPPING COST",
                dataIndex: "shipping_cost",
                key: "shipping_cost",
                width: 120,
                render: (id, order) => (
                    <>
                        {order.shipping_cost > 0 && (
                          <div>
                            {`$${order.shipping_cost}`}
                          </div>
                        )}
                    </>
                ),
            },
            {
                title: 'Shipping Method',
                width: 150,
                key: 'carrier',
                render: (_, order) => {
                    const carriers = this.state.carriers //order.variant.product_type.product_type_carriers
                    const menu = (
                        <Menu
                            onClick={(item) => {
                                let carrier = carriers.find((el) => el.carrier.id == item.key)
                                //console.log('item', carrier, item)
                                this.assignOrderToCarrier(carrier, order.id)
                            }}>
                            {carriers &&
                                carriers.map((el) => (
                                    <Menu.Item key={el.carrier ? el.carrier.id : 0} value={el.carrier}>
                                        {el.carrier ? el.carrier.name : ''} ({el.price > 0 ? '$' + el.price : 'Free'})
                                    </Menu.Item>
                                ))}
                        </Menu>
                    )
                    if (!order.carrier) {
                        return (
                            <Dropdown
                                overlay={menu}
                                trigger={['click']}
                                onVisibleChange={(visible) => {
                                    if (visible) {
                                        this.setState({ carriers: [], selectedOrder: order })
                                        this.loadOrderCarriers(order.id)
                                    }
                                }}>
                                <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
                                    Select Carrier{' '}
                                    <Icon
                                        type={
                                            loadingMenuIcon === 'loading' && selectedOrder && order && selectedOrder.id === order.id
                                                ? 'loading'
                                                : 'down'
                                        }
                                    />
                                </a>
                            </Dropdown>
                        )
                    } else {
                        return (
                            <div>
                                {/* {`${order.carrier.name} (${order.shipping_cost > 0 ? '$' + order.shipping_cost : 'Free'}) `} */}
                                {order.carrier.name}

                                {order?.shipping?.method_title && (
                                    <p style={{ marginBottom: 0}}>
                                        {order?.shipping?.method_title}
                                    </p>
                                )}

                                {['pending', 'pending_design', 'processing'].includes(order.status) && (
                                    <Dropdown
                                        overlay={menu}
                                        trigger={['click']}
                                        onVisibleChange={(visible) => {
                                            if (visible) {
                                                this.setState({ carriers: [], selectedOrder: order })
                                                this.loadOrderCarriers(order.id)
                                            }
                                        }}>
                                        <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
                                            Edit{' '}
                                            <Icon
                                                type={
                                                    loadingMenuIcon === 'loading' && selectedOrder && order && selectedOrder.id === order.id
                                                        ? 'loading'
                                                        : 'down'
                                                }
                                            />
                                        </a>
                                    </Dropdown>
                                )}
                            </div>
                        )
                    }
                },
            },
            {
                title: 'Actions',
                dataIndex: 'id',
                key: 'actions',
                align: 'left',
                fixed: 'right',
                width: 210,
                render: (id, order) => (
                    <div key={id}>
                        {/* <OrderAction id={id} order={order} /> */}
                        {order.variant.product_type.state !== 'paused' && ['pending', 'pending_design', 'processing'].includes(order.status) && (
                            <>
                                <Tooltip title={order.status !== 'pending' ? 'Pause Order' : 'Resume Order'}>
                                    <Button
                                        icon={order.status !== 'pending' ? 'pause' : 'step-forward'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.pauseOrResumeOrder(id, order.status)
                                        }}
                                        style={{ color: '#1890ff' }}
                                    />
                                </Tooltip>
                                <Divider type='vertical' />
                            </>
                        )}
                        {['processing'].includes(order.status) && (
                            <Fragment>
                                <Tooltip title='Enter Payment'>
                                    <Popconfirm title='Enter Payment for this order?' onConfirm={() => this.enterPayment(id)}>
                                        <Button icon='dollar' />
                                    </Popconfirm>
                                </Tooltip>
                                <Divider type='vertical' />
                            </Fragment>
                        )}
                        {['pending', 'pending_design', 'processing'].includes(order.status) && (
                            <Fragment>
                                <Tooltip title='Cancel Order'>
                                    <Popconfirm title='Cancel this order?' onConfirm={() => this.cancelOrder(id)}>
                                        <Button style={{ color: 'red' }} icon='close' />
                                    </Popconfirm>
                                </Tooltip>
                                <Divider type='vertical' />
                            </Fragment>
                        )}
                        {['pending_design'].includes(order.status) && (
                            <Fragment>
                                <Tooltip title='Processing'>
                                    <Popconfirm title='To processing?' onConfirm={() => this.processingOrder(id)}>
                                        <Button style={{ color: 'green' }} icon='right' />
                                    </Popconfirm>
                                </Tooltip>
                                <Divider type='vertical' />
                            </Fragment>
                        )}
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item key='1'>
                                        <Button
                                            type={'link'}
                                            style={{ width: '100%', color: 'rgba(0,0,0,0.56)' }}
                                            icon='idcard'
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({
                                                    showUpdateCustomer: true,
                                                    selectedOrder: order,
                                                })
                                            }}>
                                            Customer Info
                                        </Button>
                                    </Menu.Item>
                                    {/* <Menu.Item key="3">
                  <Button icon="message" style={{width: "100%"}}
                   onClick={e => {
                    e.preventDefault()
                    this.setState({
                      showOrderComments: true,
                      selectedOrder: order
                    })
                  }}>Notes</Button>
                </Menu.Item> */}
                                    <Menu.Item key='2'>
                                        <Button
                                            type={'link'}
                                            style={{
                                                color: 'rgba(0,0,0,0.56)',
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({
                                                    showEntityLogs: true,
                                                    selectedOrder: order,
                                                })
                                            }}
                                            icon='message'>
                                            History
                                        </Button>
                                    </Menu.Item>
                                    <Menu.Item key='4'>
                                        <Button
                                            type={'link'}
                                            style={{ width: '100%', color: 'rgba(0,0,0,0.56)' }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.setState({
                                                    showInfoOrder: true,
                                                    selectedOrder: order,
                                                })
                                            }}
                                            icon='info-circle'>
                                            Show Full Info
                                        </Button>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}>
                            <Icon type='dash' />
                        </Dropdown>
                    </div>
                ),
            },
        ]
        const orderStatus = [
            {
                key: 'pending',
                title: 'Pending',
                color: '#ffd1d1',
            },
            {
                key: 'pending_design',
                title: 'Pending Designs',
                color: '#ffaeb4',
            },
            {
                key: 'processing',
                title: 'Processing',
                color: '#69ebf9',
            },
            {
                key: 'ready',
                title: 'Ready',
                color: '#66cccc',
            },
            {
                key: 'in_production',
                title: 'In Production',
                color: '#00cfff',
            },
            {
                key: 'fulfilled',
                title: 'Fulfilled',
                color: '#52c41a',
            },
            // {
            //   key: 'refunded',
            //   title: 'Refunded',
            //   color: '#ff5500'
            // },
            {
                key: 'cancelled',
                title: 'Cancelled',
                color: 'red',
            },
            {
                key: 'error',
                title: 'Error',
                color: 'red',
            },
        ]
        const tableWidth = _.sum(columns.map((c) => c.width))
        const pagination = {
            pageSize: filter.limit,
            total: total,
            current: page,
            showTotal: (total, range) => `${range} of ${total}`,
            onChange: (page, pageSize) => {
                this.setState(
                    {
                        page: page,
                        filter: {
                            ...filter,
                            offset: (page - 1) * pageSize,
                        },
                    },
                    () => this.loadOrders(),
                )
            },
        }
        const alert = <div dangerouslySetInnerHTML={{ __html: setting['seller_message'] }} />
        return (
            <div>
                {/* <SellerOrderFilter {...this.props} /> */}
                <PageHeader
                    title='Internal Orders'
                    onBack={() => {
                        history.goBack()
                    }}
                    style={{ padding: '16px 0px' }}
                    extra={
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <OrdersToolbar />
                            {this.props.currentSite?.type !== 'offline' && (
                                <Button
                                    onClick={this.fetchOrdersHandle}
                                    type='primary'
                                    icon='cloud-download'
                                    style={{ marginLeft: 5 }}
                                    disabled={this.props.currentSite === null || this.props.currentSite === undefined}>
                                    Fetch Orders
                                </Button>
                            )}
                        </div>
                    }
                />
                {setting && setting['seller_message'] && <Alert style={{ marginBottom: 20 }} type='error' message={alert} />}
                <Row type='flex' style={{ width: 'calc(100%)' }}>
                    <Col
                        span={24}
                        // lg={16}
                        // md={14}
                        style={{ display: 'flex', alignItems: 'center' }}>
                        <SellerOrderFilter {...this.props} />
                    </Col>
                    {/* <Col span={0} lg={8} md={10} style={{ textAlign: "right" }}></Col> */}
                    {/* <Col span={0} lg={2} md={3} style={{ textAlign: "right" }}>
            <Button onClick={this.fetchOrdersHandle} type="primary" icon="cloud-download" style={{ marginLeft: 5 }}>Fetch Orders</Button>
          </Col> */}
                </Row>
                <Tabs onChange={this.statusFilter} style={{ marginBottom: 0 }} activeKey={filter.status ? filter.status[0] : 'all'}>
                    <Tabs.TabPane
                        tab={
                            <Badge
                                overflowCount={9999999}
                                count={_.sum(aggs.map((agg) => agg.doc_count))}
                                style={{
                                    background: '#fff',
                                    color: '#000',
                                    border: '1px solid #f5f5f5',
                                    right: -20,
                                }}>
                                All
                            </Badge>
                        }
                        key='all'></Tabs.TabPane>
                    {orderStatus.map((s) => (
                        <Tabs.TabPane
                            key={s.key}
                            tab={
                                <Badge
                                    overflowCount={9999999}
                                    count={_.sum(aggs.filter((agg) => agg.key === s.key).map((a) => a.doc_count))}
                                    style={{ backgroundColor: s.color }}>
                                    {s.title}
                                </Badge>
                            }
                        />
                    ))}
                </Tabs>
                <Table
                    scroll={{ x: tableWidth }}
                    columns={columns}
                    dataSource={orders}
                    pagination={pagination}
                    rowKey={({ id }) => id}
                    // rowKey={record => record.id}
                    loading={loading}
                    isShowScroll={true}
                />
                <Drawer
                    title={`Update customer info #${selectedOrder ? selectedOrder.origin_id : ''}`}
                    onClose={() =>
                        this.setState({
                            showUpdateCustomer: false,
                            selectedOrder: null,
                        })
                    }
                    width='500px'
                    visible={showUpdateCustomer}>
                    {selectedOrder && (
                        <CustomerInfo
                            onClose={() =>
                                this.setState({
                                    showUpdateCustomer: false,
                                    selectedOrder: null,
                                })
                            }
                            onUpdate={() => this.setState({ showUpdateCustomer: false, selectedOrder: null }, () => this.loadOrders())}
                            order={selectedOrder}
                        />
                    )}
                </Drawer>
                <Drawer
                    title={`Comments #${selectedOrder ? selectedOrder.origin_id : ''}`}
                    onClose={() =>
                        this.setState({
                            showOrderComments: false,
                            selectedOrder: null,
                        })
                    }
                    width='500px'
                    visible={showOrderComments}>
                    {selectedOrder && <Comments order_id={selectedOrder.id} />}
                </Drawer>
                <Drawer
                    width='800px'
                    title='Upload Designs'
                    onClose={(e) => this.setState({ selectedOrder: null, showUploadDesign: false })}
                    visible={showUploadDesign}>
                    {/* {selectedOrder && (
            <UploadDesigns
              product_id={selectedOrder.product.id}
              product_type_id={selectedOrder.variant.product_type_id}
              onClose={(e) =>
                this.setState(
                  { selectedOrder: null, showUploadDesign: false },
                  () => this.loadOrders()
                )
              }
            />
          )} */}
                    {selectedOrder && !selectedOrder.personalized && (
                        <UploadDesigns
                            product_id={selectedOrder.product.id}
                            product_variant_id={selectedOrder.variant.id}
                            product_type_id={selectedOrder.variant.product_type_id}
                            variant_image={selectedOrder.variant_image}
                            onClose={(e) =>
                                this.setState(
                                    {
                                        selectedOrder: null,
                                        showUploadDesign: false,
                                        selectedOrder: null,
                                    },
                                    () => this.loadOrders(),
                                )
                            }
                        />
                    )}
                    {selectedOrder && selectedOrder.personalized && (
                        <UploadDesignPersonal
                            selectedOrder={selectedOrder}
                            onClose={(e) =>
                                this.setState({
                                    selectedOrder: null,
                                    showUploadDesign: false,
                                    selectedOrder: null,
                                })
                            }
                        />
                    )}
                </Drawer>
                <Drawer
                    width='500px'
                    title='Activities'
                    onClose={(e) => this.setState({ selectedOrder: null, showEntityLogs: false })}
                    visible={showEntityLogs}>
                    <EntityLogs order='Order' selectedOrder={selectedOrder} />
                </Drawer>
                <Drawer
                    width='500px'
                    title='View logs'
                    onClose={(e) =>
                        this.setState({
                            selectedOrder: null,
                            showEntityLogsTracking: false,
                        })
                    }
                    visible={showEntityLogsTracking}>
                    <EntityLogs tracking='Tracking' selectedOrder={selectedOrder?.tracking} />
                </Drawer>
                <Drawer
                    title={`Order #${selectedOrder ? selectedOrder.origin_id : ''}`}
                    onClose={() =>
                        this.setState({
                            showInfoOrder: false,
                            selectedOrder: null,
                        })
                    }
                    width='500px'
                    visible={showInfoOrder}>
                    {selectedOrder && (
                        <OrderInfo
                            onClose={() =>
                                this.setState({
                                    showInfoOrder: false,
                                    selectedOrder: null,
                                })
                            }
                            order={selectedOrder}
                        />
                    )}
                </Drawer>
                {this.props.currentSite?.type !== 'offline' && (
                    <Drawer
                        width='500px'
                        title='Fetch Orders'
                        onClose={(e) => {
                            this.setState({ showFetchOrders: false })
                        }}
                        visible={showFetchOrders}>
                        <FetchOrders
                            site_id={this.props.currentSite?.id}
                            onClose={(e) => this.setState({ showFetchOrders: false })}
                            refetch={() => this.loadOrders()}
                            currentSite={this.props.currentSite}
                        />
                    </Drawer>
                )}
                <Modal
                    title='Image product'
                    visible={showImage ? true : false}
                    footer={false}
                    onCancel={() => {
                        this.setState({ showImage: false })
                    }}>
                    <ProductCarousel>
                        {showImage && (
                            <Carousel autoplay>
                                {showImage?.map((item, index) => (
                                    <img key={index} src={item} width={400} />
                                ))}
                            </Carousel>
                        )}
                    </ProductCarousel>
                </Modal>
                {changeVariant && (
                    <Modal
                        title='Change variant of product'
                        visible={changeVariant ? true : false}
                        onCancel={() => {
                            this.setState({ changeVariant: false })
                        }}
                        footer={false}>
                        <ChangeVariant
                            order={changeVariant}
                            refetch={() => {
                                this.loadOrders()
                                this.setState({ changeVariant: false })
                            }}
                        />
                    </Modal>
                )}
                {changeQuantity && (
                    <Modal
                        title='Change quantity'
                        visible={changeQuantity ? true : false}
                        onCancel={() => {
                            this.setState({ changeQuantity: false })
                        }}
                        footer={false}>
                        <ChangeQuantity
                            order={changeQuantity}
                            refetch={() => {
                                this.setState({ changeQuantity: false })
                                this.loadOrders()
                            }}
                        />
                    </Modal>
                )}
            </div>
        )
    }
}

export default connect(
    (state) => ({
        filter: state.orders.filter,
    }),
    { setOrdersFilter },
)(Orders)
