import React from 'react'
import gql from '../../api/gql'
import { Form, Select, message } from 'antd'
import _ from 'lodash'
const { Option } = Select
class CategoriesSelect extends React.Component {
    state = {
        categories: [],
        value: this.props.value || [],
    }

    componentDidMount() {
        this.getCategories()
    }

    getCategories = () => {
        const query = `query categories{
      categories(filter:{status:true, limit: 9999}){
        hits{
          id title
        }
      }
    }`
        gql.request(query)
            .then((res) => {
                this.setState({
                    categories: res.categories.hits || [],
                })
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
                this.setState({ loading: false })
            })
    }

    render() {
        const { categories } = this.state

        return (
            <div>
                <Select
                    placeholder='Select...'
                    value={this.state.value}
                    mode='multiple'
                    onChange={(value) => this.setState({ value }, () => this.props.onChange(value))}>
                    {categories.map((item) => (
                        <Option key={item.id}>{item.title}</Option>
                    ))}
                </Select>
            </div>
        )
    }
}
export default Form.create({ name: 'categoriesSelect' })(CategoriesSelect)
