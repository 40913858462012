import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_DISCOUNT_TYPES } from '../../../graphql/query/adminDiscount'

import DiscountCreateComponent from '../../../components/admin/discount/create'

const DiscountCreatePage = () => {
    const { loading, error, data } = useQuery(GET_DISCOUNT_TYPES)

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    return <DiscountCreateComponent dataDiscountTypes={data?.discountTypes || []} />
}

export default DiscountCreatePage
