import { Avatar } from "antd";
import React, { useMemo } from "react";
import { CLOUDFRONT_THUMB_URL, S3_BUCKET } from "../config";

export const getS3ThumbnailUrl = (key, width) => {
  const config = {
    bucket: S3_BUCKET,
    key: key,
  };
  if (width) {
    config.edits = {
      resize: {
        width: width,
        fit: "contain",
      },
    };
  }
  return CLOUDFRONT_THUMB_URL + "/" + btoa(JSON.stringify(config));
};

const S3Thumbnail = ({
  src,
  width,
  preview = true,
  style,
  onClick = () => {},
  className,
  fallback,
}) => {
  const imageUrl = useMemo(() => {
    return getS3ThumbnailUrl(src, width);
  }, [src, width]);

  return (
    <Avatar
      src={imageUrl}
      className={className}
      alt=""
      style={style}
      icon="picture"
      shape="square"
      size={width}
    />
  );
};

export default S3Thumbnail;
