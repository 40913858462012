import React from "react";
import { Button, Upload, Modal, Table, Icon, notification, Alert } from "antd";
import Papa from "papaparse";
import gql from "../../api/gql";
import _ from "lodash";

export default class ImportTrackings extends React.Component {
  state = {
    trackings: [],
    loading: false,
    showTrackings: false,
    hasFailed: false,
    downloading: false,
  };

  handleUpload = (file) => {
    this.setState({
      showTrackings: true,
    });
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const parsed = Papa.parse(bstr, { skipEmptyLines: true });
      this.setState(
        {
          data: parsed.data,
        },
        () => this.loadSheet()
      );
    };
    reader.readAsBinaryString(file);
    return false;
  };

  loadSheet = () => {
    const { data } = this.state;
    let trackings = [];
    let orderNumberIdx = 0;
    let carrierCodeIdx = 0;
    let trackingCodeIdx = 0;
    let totalCostIdx = 0;
    let shippingCostIdx = 0;
    const header = data[0];
    for (var i = 0; i < header.length; i++) {
      switch (header[i]) {
        case "ORDER ID":
          orderNumberIdx = i;
          break;
        case "EXPRESS":
          carrierCodeIdx = i;
          break;
        case "TRACKING NO":
          trackingCodeIdx = i;
          break;
        case "TOTAL COST":
          totalCostIdx = i;
          break;
        case "SHIPPING COST":
          shippingCostIdx = i;
          break;
        default:
          break;
      }
    }
    data.splice(0, 1);
    for (var i = 0; i < data.length; i++) {
      if (data[i].length) {
        if (!!data[i][trackingCodeIdx]) {
          trackings.push({
            order_id: !!data[i][orderNumberIdx]
              ? data[i][orderNumberIdx].toString()
              : "",
            carrier_code: !!data[i][carrierCodeIdx]
              ? data[i][carrierCodeIdx].toString()
              : "",
            tracking_code: !!data[i][trackingCodeIdx]
              ? data[i][trackingCodeIdx].toString()
              : "",
            total_cost: !!data[i][totalCostIdx]
              ? parseFloat(data[i][totalCostIdx].toString())
              : "",
            shipping_cost: !!data[i][shippingCostIdx]
              ? parseFloat(data[i][shippingCostIdx].toString())
              : "",
          });
        }
      }
    }
    this.setState({
      trackings: trackings,
    });
  };

  importTrackings = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const query = `mutation importTracking($input:[TrackingInput!]!){
      importTracking(input: $input){
        id
        order_id
        carrier_code
        tracking_code
        total_cost
        shipping_cost
      }
    }`;

    gql
      .request(query, {
        input: this.state.trackings,
      })
      .then((res) => {
        //  console.log("res.importTracking", res.importTracking)
        const failedTrackings = _.xorBy(
          this.state.trackings,
          res.importTracking,
          "order_id"
        );
        if (failedTrackings.length > 0) {
          //this.state.trackings = res.importTracking
          this.setState({
            trackings: failedTrackings,
            loading: false,
            hasFailed: true,
          });
        } else {
          notification["success"]({
            message: "Imported successful.",
          });
          this.setState({ loading: false, showTrackings: false });
        }
        //console.log("failedTracking", failedTracking)
      })
      .catch((err) => {
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
  };

  downloadFailedTracking = (e) => {
    e.preventDefault();
    this.setState({
      downloading: true,
    });
    let query = `mutation downloadTracking($trackings: [TrackingInput!]!){
      downloadTracking(trackings: $trackings)
    }`;

    gql
      .request(query, {
        trackings: this.state.trackings,
      })
      .then((res) => {
        window.location = res.downloadTracking;
        this.setState({
          downloading: false,
        });
      })
      .catch((err) => {
        this.setState({
          downloading: false,
        });
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
      });
  };

  handleCancel = () => {
    this.setState({ hasFailed: false, showTrackings: false, trackings: [] });
  };
  handleSubmit = (e) => {
    this.importTrackings(e);
  };
  render() {
    const {
      trackings,
      showTrackings,
      loading,
      downloading,
      hasFailed,
    } = this.state;
    const { displayBtn } = this.props;

    return (
      <div>
        <Upload
          accept=".csv"
          showUploadList={false}
          beforeUpload={this.handleUpload}
          multiple={false}
          customRequest={(file) => {}}
        >
          {displayBtn ? (
            <Button icon="import"> Import Trackings</Button>
          ) : (
            "Import Trackings"
          )}
        </Upload>
        <Modal
          maskClosable={false}
          visible={showTrackings}
          confirmLoading={loading}
          //onOk={this.importTrackings}
          width="800px"
          title="Import Trackings"
          //onCancel={() => this.setState({ hasFailed:false, showTrackings: false, trackings: [] })}
          footer={[
            <Button
              key="cancel"
              onClick={this.downloadFailedTracking}
              loading={downloading}
              disabled={!hasFailed}
            >
              Download
            </Button>,
            <Button key="cancel" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={this.handleSubmit}
            >
              Submit
            </Button>,
          ]}
        >
          {hasFailed && (
            <Alert
              type="error"
              message="Order really in In Production or Fulfilled, Order Id format: N123456 or 123456"
            />
          )}

          {trackings.length ? (
            <Table
              dataSource={trackings}
              columns={[
                {
                  title: "Order ID",
                  dataIndex: "order_id",
                },
                {
                  title: "Carrier Code",
                  dataIndex: "carrier_code",
                },
                {
                  title: "Tracking Code",
                  dataIndex: "tracking_code",
                },
                {
                  title: "Total Cost",
                  dataIndex: "total_cost",
                },
                {
                  title: "Shipping Cost",
                  dataIndex: "shipping_cost",
                },
              ]}
              rowKey={(row, index) => index}
            />
          ) : (
            <Icon type="loading" />
          )}
        </Modal>
      </div>
    );
  }
}
