import { PageHeader } from "antd";
import React from "react";
import ExportOrders from "../../components/orders/ExportOrders";
import { history } from "../../history";

class ExportOrder extends React.Component {
  render() {
    return (
      <div>
        <PageHeader
          style={{ padding: "16px 0px" }}
          title="Exported CSV"
          onBack={() => {
            history.goBack();
          }}
          extra={null}
        />
        <ExportOrders />
      </div>
    );
  }
}
export default ExportOrder;
