import gql from "graphql-tag";

export const PRODUCT_TYPE_VARIANT = gql`
  query productVariant($product_id: Int!) {
    productVariant(product_id: $product_id) {
      id
      sku
      product_id
      product_type_id
      image
      product {
        id
        title
        variants {
          id
          product_type_variant {
            id
            attributes {
              slug
              option
            }
          }
        }
      }
      product_type {
        id
        title
        sku
        product_type_variants {
          id
          attributes {
            name
            slug
            option
          }
        }
      }
      product_type_variant {
        id
        product_type_id
        sku
        attributes {
          name
          slug
          option
        }
      }
    }
  }
`;
