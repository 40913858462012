import React from "react";
import { VariantPartnerSKU } from "./VariantPartnerSKU";

export const VariantPartners = ({partnerFulfillments, partnerApiCatalogs, partnerVariantValues, variantPrintAreas, onChange, onMappingChange, partnerMappingPrintAreasState}) => {

  const handleSkuChange = (id, partnerId, value) => {
    onChange(id, partnerId, value)
  }

  const handleMappingChange = (v) => {
    onMappingChange(v)
  }

  return (
    <div>
      {partnerFulfillments.length > 0 && partnerFulfillments.map((partner, index) => {
        const existValue = partnerVariantValues.find(el => el.partner_fulfillment_id === partner.id)
        const apiCatalogsData = partnerApiCatalogs.find(el => el.id === partner.id)?.data?.map(item => ({
          value: item?.product_id,
          label: item?.blank + ": " + item?.size + (item?.color ? " - " + item?.color : ''),
          placement: item?.placement,
        }))

        return (
          <VariantPartnerSKU
            key={partner.id + '-' +index}
            partner={partner}
            onChange={(val) => handleSkuChange(existValue?.id ?? null, partner.id, val)}
            existValue={existValue ?? null}
            apiCatalogsData={apiCatalogsData ?? null}
            variantPrintAreas={variantPrintAreas ?? null}
            onMappingChange={(v) => handleMappingChange(v)}
            partnerMappingPrintAreasState={partnerMappingPrintAreasState?.filter(el => el.partner_id === partner.id)}
          />
        )
      })}
    </div>
  )
}