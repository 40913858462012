import { useMutation } from '@apollo/react-hooks'
import { Divider, Drawer, Icon, notification, Tooltip, Popconfirm, Button } from 'antd'
import React, { useState } from 'react'
import EntityLogs from '../orders/EntityLogs'
import { RETRACK, RESUBMITTRACKINGMORE } from '../../graphql/mutation/trackingMutation'
import InfoTracking from '../orders/InfoTracking'
const TrackingAction = ({ record, refetch }) => {
    const [select, setSelect] = useState(null)
    const [showEntityLogs, setShowEntityLogs] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [retrack, { loading }] = useMutation(RETRACK)
    const [resubmitTM, { loading: loadSubmitTM }] = useMutation(RESUBMITTRACKINGMORE)
    const handleRetrack = () => {
        retrack({
            variables: {
                id: `${record.id}`,
            },
        })
            .then(() => {
                notification.success({ message: 'Retrack tracking success!' })
                refetch()
            })
            .catch((err) => notification.error({ message: err.message }))
    }
    const handleReSubmitTM = () => {
        resubmitTM({
            variables: {
                id: `${record.id}`,
            },
        })
            .then(() => {
                notification.success({ message: 'In process, please waiting few minutes' })
                refetch()
            })
            .catch((err) => notification.error({ message: err.message }))
    }

    return (
        <div style={{ alignItems: 'center', textAlign: 'left' }}>
            <Tooltip title='Info'>
                <Icon
                    type='info-circle'
                    onClick={(e) => {
                        e.preventDefault()
                        setSelect(record)
                        setShowInfo(true)
                    }}
                />
            </Tooltip>
            <Divider type='vertical' />
            <Tooltip title='History'>
                <Icon
                    type='message'
                    onClick={(e) => {
                        e.preventDefault()
                        setSelect(record)
                        setShowEntityLogs(true)
                    }}
                />
            </Tooltip>
            {record.submitted ? (
                <span>
                    <Divider type='vertical' />
                    {loading ? (
                        <Icon type='loading' />
                    ) : (
                        <Tooltip title='Retrack'>
                            <Icon onClick={() => handleRetrack()} type='redo' />
                        </Tooltip>
                    )}
                    <Divider type='vertical' />
                    {loadSubmitTM ? (
                        <Icon type='loading' />
                    ) : (
                        <Tooltip title='ResubmitTM'>
                            <Popconfirm
                                title='Are you sure to re-submit tracking code to TrackingMore?'
                                onConfirm={() => handleReSubmitTM()}
                                okText='Yes'
                                cancelText='No'>
                                <Icon type='exclamation-circle' />
                            </Popconfirm>
                        </Tooltip>
                    )}
                </span>
            ) : null}
            <Drawer
                visible={showEntityLogs}
                width='500px'
                title='Activities'
                onClose={(e) => {
                    setSelect(null)
                    setShowEntityLogs(false)
                }}>
                <EntityLogs selectedOrder={select} tracking='Tracking' />
            </Drawer>
            <Drawer
                visible={showInfo}
                width='500px'
                title={`Order #${select?.order_id || ''}`}
                onClose={(e) => {
                    setSelect(null)
                    setShowInfo(false)
                }}>
                <InfoTracking order={select?.order} />
            </Drawer>
        </div>
    )
}

export default TrackingAction
