import gql from 'graphql-tag'

export const CREATE_GUIDELINE_POLICY = gql`
    mutation createPolicy($input: PolicyInput!) {
        createPolicy(input: $input) {
            id
            title
            description
            type
        }
    }
`

export const UPDATE_GUIDELINE_POLICY = gql`
    mutation updatePolicy($id: Int!, $input: PolicyInput!) {
        updatePolicy(id: $id, input: $input) {
            id
            title
            description
            type
        }
    }
`

export const DELETE_GUIDELINE_POLICY = gql`
    mutation deletePolicy($id: Int!) {
        deletePolicy(id: $id)
    }
`
