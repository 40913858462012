import React from 'react'
import { Table, Icon, Button, Popconfirm, Select } from 'antd'
import { DndProvider, DragSource, DropTarget } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import styled from 'styled-components'
import gql from "../../api/gql";
import _ from "lodash";

const SortTable = styled.div`
  table tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }
  table tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
`

let dragingIndex = -1;

const { Option } = Select;

class BodyRow extends React.Component {

  render() {
    const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    );
  }
}


const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

export default class DragSortingTable extends React.Component {

  state = {
    data: this.props.value,
    attributes: [],
    attributesName: []
  }

  componentDidMount() {
    this.getAttributes()
  }

  getAttributes() {
    const query = `query getAttributes{
      getAttributes(limit: 9999){
        count
        hits {
          id
          name
        }
      }
    }`

    gql.request(query, {}).then(
      res => {
        this.setState({
          attributes: res.getAttributes.hits,
          attributesName: _.map(res.getAttributes.hits, 'name')
        })
        if (this.state.data.length) {
          this.updateAttributeTerms()
        }
      }
    ).catch(
      err => {
        console.log('err', err)
        // this.openNotification("Error", "Get List Attribute Fail", "error")
      }
    )
  }

  getAttributeTerms(attr_id, idx) {
    const query = `query getAttributeTerms{
      getAttributeTerms(attribute_id: ${attr_id}){
        count
        hits {
          id
          attribute_id
          option_name
          sku
        }
      }
    }`

    gql.request(query, {}).then(
      res => {
        const { data } = this.state
        data[idx].attributeTerms = res.getAttributeTerms.hits
        data[idx].loading = false
        this.setState({ data }, () => this.props.onChange(this.state.data))
      }
    ).catch(
      err => {
        const { data } = this.state
        data[idx].attributeTerms = []
        data[idx].loading = false
        this.setState({ data }, () => this.props.onChange(this.state.data))
        // this.openNotification("Error", "Get List Attribute Fail", "error")
      }
    )
  }

  updateAttributeTerms() {
    this.state.data.map((el, idx) => {
      const attribute = _.find(this.state.attributes, ['name', el.name])
      this.getAttributeTerms(attribute.id, idx)
    })
  }

  changeAttribute = (attr, idx) => {
    const attribute = _.find(this.state.attributes, ['name', attr])
    const { data } = this.state
    data[idx].name = attribute.name
    data[idx].slug = attribute.name
    data[idx].options = []
    data[idx].loading = true
    this.setState({ data }, () => this.props.onChange(this.state.data))
    this.getAttributeTerms(attribute.id, idx)
  }

  handleDelete = index => {
    const { data } = this.state
    this.setState({ data: data.filter((item, i) => i !== index) }, () => this.props.onChange(this.state.data))
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  footer = () => {
    return (
      <Button
        icon="plus" onClick={(e) => {
          e.preventDefault()
          const { data } = this.state
          data.push({ name: "", slug: "", options: [], attributeTerms: [], loading: false })
          this.setState({ data })
        }}
        type="primary"
      >
        Add
      </Button>
    )
  }

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
      () => {
        this.props.onChange(this.state.data)
      }
    );
  };


  render() {
    const columns = [
      {
        title: 'Attribute',
        dataIndex: 'attribute',
        key: 'attribute',
        width: 250,
        render: (text, record, index) => (
          <Select
            style={{ width: 200 }}
            value={record.name}
            placeholder="Select Attribute"
            onChange={value => {
              this.changeAttribute(value, index)
            }}>
            {this.state.attributesName && this.state.attributesName.map(attribute => (
              <Option
                value={attribute}
                key={attribute}
                disabled={_.map(this.state.data, 'name').includes(attribute)}
              >
                {this.capitalizeFirstLetter(attribute)}
              </Option>
            ))
            }
          </Select >
        )
      },
      {
        title: 'Options',
        dataIndex: 'options',
        render: (options, row, index) => (
          <Select
            showSearch
            optionFilterProp="children"
            value={options}
            mode="multiple"
            placeholder="Please select options"
            style={{ width: '100%' }}
            disabled={(this.state.data[index].attributeTerms.length && !this.state.data[index].loading) ? false : true}
            onChange={value => {
              const { data } = this.state
              data[index].options = value
              this.setState({ data }, () => this.props.onChange(this.state.data))
            }}
          >
            {this.state.data[index].attributeTerms && this.state.data[index].attributeTerms.map(term => (
              <Option value={term.option_name} key={term.id}>{term.option_name}</Option>
            ))}
          </Select>
        )
      },
      {
        title: '',
        width: 50,
        render: (_, row, i) =>
          this.state.data.filter.length >= 1 ? (
            <Popconfirm title="Are you sure to delete?" onConfirm={() => this.handleDelete(i)}>
              <Icon type="delete" style={{ color: 'red' }} />
            </Popconfirm>
          ) : null

      }
    ];

    return (
      <div className="attribute">
        <SortTable>
          <DndProvider backend={HTML5Backend}>
            <Table
              footer={this.footer}
              columns={columns}
              dataSource={this.state.data}
              components={this.components}
              onRow={(row, index) => ({
                index,
                moveRow: this.moveRow,
              })}
              rowKey={(row, index) => index}
              pagination={false}

            />
          </DndProvider>
        </SortTable>
      </div>
    );
  }
}
