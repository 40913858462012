import gql from "graphql-tag";

export const CREATEATTRIBUTE = gql`
    mutation createAttribute($input: NewAttribute!) {
        createAttribute(input: $input) {
            id
            name
        }
    }
`

export const UPDATEATTRIBUTE = gql`
    mutation updateAttribute($input: UpdateAttribute!) {
        updateAttribute(input: $input) {
            id
            name
        }
    }
`

export const DELETEATTRIBUTE = gql`
    mutation deleteAttribute($id: Int!) {
        deleteAttribute(id: $id)
    }
`