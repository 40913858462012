import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import gql from '../../api/gql'
import { Link } from 'react-router-dom'
import { message, Row, Tooltip, Col, Icon, Button, Drawer, Skeleton } from 'antd'
import _ from 'lodash'
import Upload from '../../components/Upload'
import UploadMyProfile from './UploadMyProfile'
import moment from 'moment'

const Container = styled.div`
  .name {
    display: flex;
    justify-content: space-between;
  }
`

class ProfilePage extends Component {
  _isMounted = false;

  state = {
    loading: true,
    me: null
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.getProfile();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getProfile() {
    this.setState({ loading: true })
    const query = `query me{me{ id, first_name, last_name, email, birthday, phone, avatar{url},address}}`
    gql.request(query).then(res => {
      this.setState({ me: res.me || {}, loading: false })
    }).catch(err => {
      message['error'](_.get(err, '[0].message'))
      this.setState({ loading: false })
    })
  }

  // onUploadAvatar = file => {
  //   const query = `mutation addFile($input: NewFile!){
  //     addFile(input: $input){
  //       id, url
  //     }
  //   }`
  //   gql.request(query, {
  //     input: {
  //       source: 'aws',
  //       key: file.key,
  //       file_name: file.file.name,
  //       file_size: file.file.size,
  //       file_mime: file.file.type,
  //       url: file.url
  //     }
  //   }).then(res => {
  //     const {id} = res.addFile
  //     if (id) {
  //       const query = `mutation updateUser($id: String!, $input: UpdateUser!){
  //         updateUser(id: $id, input: $input){
  //           id
  //         }
  //       }`
  //       gql.request(query, {
  //         id: this.state.me.id,
  //         input: { image: id }
  //       }).then(res => {
  //         notification['success']({
  //           message: 'Avatar updated'
  //         })
  //       })
  //     }
  //   })
  // }

  render() {
    const { me } = this.state
    if (!me) {
      return <Skeleton/>
    }
    return (
      <Container>
        <Row>
          <Col span={5}>
            <Upload 
              style={{ cursor: 'pointer' }} 
              fileList={false}
              onUpload={this.onUploadAvatar}
              >
              <Tooltip title="Update Avatar">
              <img style={{ width: 200 }} alt="" src={'https://mannatthemes.com/metrica/light/assets/images/users/user-4.jpg'} icon="user" size={80} />
              </Tooltip>
            </Upload>
            <div>
              <p style={{ margin: '10px 0' }}>ASK ME ABOUT</p>
              <Link to=''>My job</Link><br />
              <Link to=''>Manage Employment</Link><br />
              <Link to=''>Intellectual Property Rights</Link><br />
            </div>
          </Col>
          <Col span={19}>
            <div className="name">
            <h2 style={{ textTransform: 'uppercase' }}>
              {me.first_name} {me.last_name}
            </h2>
            <Button type="primary" onClick={this.showDrawer}>
              Edit My Profile
            </Button>
            <Drawer
              title="Edit My Profile"
              placement="right"
              closable={false}
              onClose={this.onClose}
              visible={this.state.visible}
              width={480}
              bodyStyle={{ paddingBottom: 80 }} >
                <UploadMyProfile me={me} onLoad={() => this.getProfile()}/>
            </Drawer>
            </div>
            <h3>
              PRODUCT MANAGER
            </h3>
            <p><span style={{ color: '#3CA6F2' }}>Technical</span> department, <span style={{ color: '#3CA6F2' }}>PODGASUS</span> office</p>
            <p><Icon type="enviroment" theme="twoTone" />Currently in <span style={{ color: '#3CA6F2' }}>{me.address}</span></p>
            <div style={{ backgroundColor: '#f4f4f4', width: '100%', height: 135, padding: '6px 20px', marginBottom: 20 }}>
              <p>
                <Tooltip title="Phone Number">
                  <Icon type="phone" theme="filled" style={{ color: '#3CA6F2', paddingRight: 20 }} />{me.phone}
                </Tooltip>
              </p>
              <p>
                <Tooltip title="Birthday">
                  <Icon type="calendar" style={{ color: '#3CA6F2', paddingRight: 20 }} />{moment(me.birthday).format('MMM DD, YYYY')}
                </Tooltip>
              </p>
              <p>
                <Tooltip title="Credit Card">
                  <Icon type="credit-card" theme="filled" style={{ color: '#3CA6F2', paddingRight: 20 }} />
                </Tooltip>4221 4986 6048 1293
              </p>
              <p>
                <Tooltip title="Email">
                  <Icon type="message" theme="filled" style={{ color: '#3CA6F2', paddingRight: 20 }} />{me.email}
                </Tooltip>
              </p>
            </div>
            <p style={{ textTransform: 'capitalize' }}><span style={{ fontSize: 18 }}>About {me.last_name}</span><br /> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisl tincidunt eget nullam non.</p>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default connect(
  state => {
    return {
    }
  }
)(ProfilePage)