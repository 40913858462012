import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import styled from 'styled-components'
import { Layout } from 'antd'
import { Helmet } from "react-helmet"
import background from '../assets/images/background.png';

const { Content } = Layout;
const Container = styled.div`
  min-height: 100vh;
  display: flex;
  .pod-login-form{
    z-index: 99;
    width: 94%;
    max-width: 400px;
    top: 50%;
    left: 50%;
    position: absolute;
    padding: 25px;
    transform: translate(-50%, -50%);
    -webkit-box-transform: translate(-50%, -50%);
    -moz-box-transform: translate(-50%, -50%);
    background: rgba(255,255,255,0.9);
    text-align: center;
    border-radius: 15px;
    border: 1px solid #e0e6ed;
    -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);

  }
`

class blankLayout extends React.Component {
  state = {
    collapsed: false,
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <Container>
        {this.props.title && <Helmet><title>{this.props.title}</title></Helmet>}
        <Layout style={{
          display: 'grid',
          justifyContent: 'start',
        }}>
          <Layout
            style={{
              backgroundImage: `url(${background})`,
              position: 'absolute',
              backgroundSize: 'cover',
              width: '100%',
              top: 0,
              left: 0,
              height: '100%',
              zIndex: 1,
            }}
          >
          </Layout>
          <Content className="pod-login-form">
            <div style={{
              justifyContent: 'end',
              alignContent: 'center',
            }}>
              <Route {...this.props} />
            </div>
            <Layout.Footer style={{ textAlign: 'center', backgroundColor: 'white', color: '#A1A9B4' }}>PODGASUS ©2021. </Layout.Footer>
          </Content>
        </Layout>
      </Container>
    )
  }
}

export default connect(
  state => ({})
)(blankLayout)