import { Select } from 'antd'
import graphql from '../../api/gql'
import React, { useEffect, useState } from 'react'
import { VariantPartnerMappingPrintAreaSelect } from '../products/VariantPartnerMappingPrintAreaSelect'

export const PushToDtf = ({partnerFulfillmentId, printFiles, onChange, stateValue}) => {

    const [catalogs, setCatalogs] = useState([])
    const [catalogValue, setCatalogValue] = useState(stateValue?.sku)
    const [placement, setPlacement] = useState([])
    const [placementValue, setPlacementValue] = useState([])
    const [shippingMethod, setShippingMethod] = useState(stateValue?.shipping_method)

    useEffect(() => {
        if (partnerFulfillmentId) {
            dtfCatalogs(partnerFulfillmentId)
        }
    }, [partnerFulfillmentId])

    const dtfCatalogs = (id) => {
        let query = `query partnerCatalogs{partnerCatalogs(PartnerFulfillmentID:${id}){
            count
            hits {
                product_id
                blank
                sku
                quantity
                type
                size
                color
                method
                placement
            }
        }}`

        graphql
            .request(query, {})
            .then((res) => {
                let resCatalogs = res.partnerCatalogs

                if (resCatalogs?.hits.length > 0) {
                    setCatalogs(resCatalogs?.hits?.map(item => ({
                        value: item?.product_id,
                        label: item?.blank + ": " + item?.size + (item?.color ? " - " + item?.color : ''),
                        placement: item?.placement,
                    })))
                }
            })
    }

    const handleCatalogChange = (v) => {
        setCatalogValue(v)
        setPlacement(catalogs.find(catalog => catalog.value === v)?.placement)

        onChange({
            sku: v,
            placement: placementValue,
            shipping_method: shippingMethod,
        })
    }

    const handleShippingMethodChange = (v) => {
        setShippingMethod(v)

        onChange({
            sku: catalogValue,
            placement: placementValue,
            shipping_method: v,
        })
    }

    const handleSelectChange = (v, fileId) => {
        v.file_id = fileId
        
        let areas = placementValue

        // Find exits and remove, add new after 
        let index = areas.findIndex(a => ((a?.print_area_id && a?.print_area_id === v?.print_area_id) || a?.print_area_title === v?.print_area_title))

        if (index !== -1) {
            areas.splice(index, 1)
        } 

        areas.push(v)

        setPlacementValue(areas)

        onChange({
            sku: catalogValue,
            placement: areas,
            shipping_method: shippingMethod,
        })
    }

    return (
        <>
            <div>
                <b>Shipping method <span style={{color: 'red'}}>*</span></b>
            </div>
            <Select
                style={{width: '100%'}}
                value={shippingMethod}
                showSearch
                onChange={(v) => handleShippingMethodChange(v)}
                placeholder={"DTF shipping method"}
            >
                <Select.Option key={"method-standard"} value={"standard"} style={{fontSize: "12px"}}>{"Standard"}</Select.Option>
                <Select.Option key={"method-express"} value={"express"} style={{fontSize: "12px"}}>{"Express"}</Select.Option>
                <Select.Option key={"method-economy"} value={"economy"} style={{fontSize: "12px"}}>{"Economy"}</Select.Option>
                <Select.Option key={"method-priority"} value={"priority"} style={{fontSize: "12px"}}>{"Priority"}</Select.Option>
            </Select>

            <div style={{marginTop: '15px'}}>
                <b>Product <span style={{color: 'red'}}>*</span></b>
            </div>
            <Select
                style={{width: '100%'}}
                value={catalogValue}
                showSearch
                optionFilterProp="children"
                onChange={(v) => handleCatalogChange(v)}
                placeholder={"DTF product"}
            >
                {catalogs?.map((item) => (
                    <Select.Option key={item.value} style={{fontSize: "12px"}}>{item.label}</Select.Option>
                ))}
            </Select>

            {placement?.length > 0 && (
                <div>
                    <div
                        style={{margin: "10px 0 10px 0"}}
                    >
                        <b>Mapping designs - placement <span style={{color: 'red'}}>*</span></b>    
                    </div>
                    {printFiles?.map((area) => {
                        return (
                            <>
                                {area?.file_id && (
                                    <VariantPartnerMappingPrintAreaSelect
                                        key={"placement-area"+area.id}
                                        area={area}
                                        partnerAreas={placement}
                                        onChange={(selectValue) => handleSelectChange(selectValue, area?.file_id)}
                                        defaultValue={stateValue?.placement?.find(e => e?.print_area_title === area?.title)}
                                    />
                                )}

                                {! area?.file_id && (
                                    <div>
                                        <b>{area?.title}</b> - Design not found 
                                    </div>
                                )}
                            </>
                        )
                    })}
                </div>
            )}
        </>
    )
}