import gql from "graphql-tag";

export const RETRACK = gql`
  mutation reTrack($id: ID!) {
    reTrack(id: $id)
  }
`;

export const RESUBMITTRACKINGMORE = gql`
  mutation reSubmitTrackingMore($id: ID!) {
    reSubmitTrackingMore(id: $id)
  }
`;

export const EXPORTTRACKING = gql`
  mutation exportTracking($filter: ExportTrackingFilter!) {
    exportTracking(filter: $filter)
  }
`