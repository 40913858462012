import React, { useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { CATALOG_PRODUCT_TYPES } from '../../graphql/query/productTypesQuery'

import CatalogList from '../../components/catalog'
import Page404 from '../404'

const Catalog = (props) => {
    const { currentUser } = props
    const [filter] = useState({
        limit: 20,
        offset: 0,
        personalized: false,
        status: true,
        search: '',
    })

    const { loading, error, data } = useQuery(CATALOG_PRODUCT_TYPES, {
        variables: {
            filter,
        },
    })

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    return (
        <>
            {!currentUser?.is_special ? (
                <Page404 />
            ) : (
                <CatalogList dataSource={data?.productTypes?.hits || []} count={data?.productTypes?.count || 0} filter={filter} />
            )}
        </>
    )
}

export default Catalog
