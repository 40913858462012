import React, { Fragment } from 'react'
import { Form, Button, Input, Select, message, InputNumber, Radio, Icon, Popover, notification } from 'antd'
import gql from '../../api/gql'
import TextArea from 'antd/lib/input/TextArea'
import PaypalExpressBtn from 'react-paypal-express-checkout'
import { PAYPAL_ENV, PAYPAL_SANDBOX_KEY, PAYPAL_PRODUCTION_KEY, CURRENCY } from '../../config'
import * as _ from 'lodash'
import styled from 'styled-components'

const BoxLabelMethod = styled.div`
    background: none;
    border: 0;
    font-size: 16px;
    line-height: 2em;
    position: relative;
    width: 7em;
    height: 2em;
    display: flex;
    justify-content: center;
    &:before {
        transform: skew(45deg);
        top: 0;
    }
    &:after {
        transform: skew(-45deg);
        bottom: 0;
    }
    &:before,
    &:after {
        background: #979797;
        border-radius: 2px;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        transition: all 1s;
        height: 1em;
    }
    p {
        color: #fff;
        text-decoration: none;
        position: relative;
        z-index: 10;
    }
`

class NewDepositForm extends React.Component {
    state = {
        loading: false,
        sites: [],
        site_id: null,
        siteFilter: {
            user_id: null,
        },
        payMethod: '1',
        depositAmount: 1000,
        balanceAmount: 0,
    }

    componentDidMount() {
        this.loadSites()
        this.loadAppSetting()
    }

    loadAppSetting() {
        const query = `query getAppSettings($input: [String!]){
      getAppSettings(input: $input){
        name
        value
      }
    }`
        const input = ['payment_mode', 'payment_sandbox_key', 'payment_production_key']
        this.setState({ loading: true })
        gql.request(query, { input: input })
            .then((res) => {
                this.setState({ initSetting: res.getAppSettings || [], loading: false })
            })
            .catch((err) => {
                notification['error']({
                    message: 'Error',
                    description: _.get(err, '[0].message'),
                })
                this.setState({ loading: false })
            })
    }

    onClose = () => {
        this.props.onClose()
    }

    onhandleSelect = (value) => {
        this.setState({
            site_id: value,
        })
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.site_id = parseInt(this.state.site_id)
            let amount = parseFloat(values.amount)
            if (this.state.payMethod === '2') {
                amount = (parseFloat(values.amount) - parseFloat(values.amount) * 0.029).toFixed(2)
            }
            if (!err) {
                const mutation = `mutation createBalance($input: NewBalance!){
          createBalance(input: $input){
            id
            action
            actionNotes
            amount
            transaction_id
            site{
              id
              title
              domain
              platform
            }
          }
        }`
                this.setState({ loading: true })
                gql.request(mutation, {
                    input: {
                        transaction_id: values.transaction_id,
                        site_id: values.site_id,
                        amount: amount,
                        action_notes: values.action_notes,
                        approved: false,
                    },
                }).then((res) => {
                    this.setState({
                        loading: false,
                        balanceAmount: 0,
                    })
                    message['success']('Create Deposit success!')
                    this.props.onLoad()
                    this.onClose()
                    this.props.form.resetFields()
                })
                this.props.onLoad()
                this.onClose()
            }
        })
    }

    loadSites() {
        const { currentUser } = this.props
        if (!currentUser) return null
        const admin = currentUser.roles.find((role) => role.name === 'Administrator')
        const query = `query sites($filter: SiteFilter) {
      sites(filter: $filter){
        hits {
          id
          title
        }
      } 
    }`
        this.setState({
            loading: true,
        })
        gql.request(query, {
            filter: {
                ...this.state.siteFilter,
                user_id: admin ? null : +this.props.currentUser.id,
            },
        }).then((res) => {
            this.setState({
                sites: res.sites.hits || [],
                loading: false,
                site_id: res.sites.hits ? res.sites.hits[0].id : 0,
            })
        })
    }

    onPayMethodChange = (value) => {
        this.setState({ payMethod: value.target.value })
    }

    onPaypalDepositSuccess = (payment) => {
        const siteId = this.state.site_id
        const inputAmount = parseFloat(this.state.depositAmount)
        const amount = inputAmount - (inputAmount * 0.039 + 0.3)
        //Get payer information then add to notes
        // const address = payment.address
        // const notes = `Payer Name: ${address.recipient_name}. Address: ${address.line1}, ${address.city}, ${address.state}, ${address.postal_code}, ${address.country_code}`
        if (siteId && payment && payment.paid) {
            const mutation = `mutation createBalance($input: NewBalance!){
          createBalance(input: $input){
            id
            action
            actionNotes
            amount
            transaction_id
            site{
              id
              title
              domain
              platform
            }
          }
        }`
            this.setState({ loading: true })
            gql.request(mutation, {
                input: {
                    transaction_id: payment.paymentID,
                    site_id: siteId,
                    amount: amount,
                    action_notes: 'Paypal Express pay',
                    approved: true,
                },
            }).then((res) => {
                this.setState({
                    loading: false,
                })
                this.props.onLoad()
                message['success']('Create Deposit success!')
            })
        } else {
            message['error']('Create Deposit failed. Please contact Administrator for details!')
        }
    }

    handleAmountChange = (value) => {
        this.setState({ balanceAmount: (value - value * 0.029).toFixed(2) })
    }
    render() {
        const {
            form: { getFieldDecorator },
        } = this.props
        const { sites, payMethod, initSetting } = this.state
        const setting = initSetting && initSetting.reduce((init, item) => ({ ...init, [item.name]: item.value }), {})
        const paypal_env = setting ? setting['payment_mode'] : PAYPAL_ENV
        const paypal_client = {
            sandbox: setting ? setting['payment_sandbox_key'] : PAYPAL_SANDBOX_KEY,
            production: setting ? setting['payment_production_key'] : PAYPAL_PRODUCTION_KEY,
        }
        //console.log('state', paypal_env, paypal_client)

        const onAmoutChange = (value) => {
            this.setState({ depositAmount: value })
            // console.log('amount', this.state.depositAmount)
        }
        const onSuccess = (payment) => {
            // Congratulation, it came here means everything's fine!
            //console.log("The payment was succeeded!", payment);
            this.onPaypalDepositSuccess(payment)
            // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
        }

        const onCancel = (data) => {
            // User pressed "cancel" or close Paypal's popup!
            console.log('The payment was cancelled!', data)
            // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
        }

        const onError = (err) => {
            // The main Paypal's script cannot be loaded or somethings block the loading of that script!
            console.log('Error!', err)
            // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
            // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
        }

        return (
            <Fragment>
                <BoxLabelMethod>
                    <p>Payoneer</p>
                </BoxLabelMethod>
                <br />
                {this.state.payMethod === '1' && (
                    <h3 style={{ marginTop: 20 }}>
                        Please make the payment to PODGASUS Company via Payoneer account
                        <span style={{ color: 'red', fontWeight: 'bold', padding: '0 5px' }}>ctopeagle@hotmail.com</span>
                        first then submit the form below.
                    </h3>
                )}
                {this.state.payMethod === '2' && (
                    <h3 style={{ marginTop: 20 }}>
                        Please send Mass payment to
                        <span style={{ color: 'red', fontWeight: 'bold', padding: '0 5px' }}>ctopeagle@hotmail.com</span>
                        first then submit the form below.
                    </h3>
                )}
                {/* {this.state.payMethod === "2" && <h3 style={{ marginTop: 20, marginBottom: 20 }}>
          <span style={{ color: "red", fontWeight: "bold", paddingTop: 20 }}>
            The fee for each transaction is 3,9% + $0.30.
            </span>
        </h3>} */}
                <Form hideRequiredMark onSubmit={this.onSubmit}>
                    <Form.Item label='Site'>
                        {getFieldDecorator('site_id', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input site!',
                                },
                            ],
                        })(
                            <Select onChange={this.onhandleSelect}>
                                {sites.map((site) => (
                                    <Select.Option key={site.id} value={site.id}>
                                        {site.title}
                                    </Select.Option>
                                ))}
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item label={this.state.payMethod === '1' ? 'Amount' : 'Paypal Amount'}>
                        {getFieldDecorator('amount', {
                            initialValue: 1000,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input amount!',
                                },
                            ],
                        })(
                            <InputNumber
                                style={{ width: '100%' }}
                                // formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                formatter={(value) => `${value > 0 ? `$ ${value}` : value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                step={0.1}
                                min={1}
                                onChange={this.handleAmountChange}
                            />,
                        )}
                    </Form.Item>
                    {this.state.payMethod === '2' && (
                        <Form.Item label='Balance Amount' help='Balance Amount = Paypal Amount - 2.9%'>
                            <InputNumber
                                readOnly
                                value={this.state.balanceAmount}
                                style={{ width: '100%' }}
                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                step={0.1}
                            />
                        </Form.Item>
                    )}
                    <Form.Item label='Transaction ID'>
                        {getFieldDecorator('transaction_id', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input transaction ID!',
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label='Notes'>
                        {getFieldDecorator('action_notes', {
                            initialValue: '',
                        })(
                            <TextArea
                                placeholder='Please note your transaction information following the syntax:&#10;Store Name_Date of transaction (dd/mm/yyyy)_Amount'
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' loading={this.state.loading}>
                            Submit
                        </Button>
                        <Button
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.onClose()
                            }}
                            loading={this.state.loading}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>

                {/* {payMethod === "2" && <div>
          <Select onChange={this.onhandleSelect} style={{ width: 200, marginRight: 10 }} defaultValue={sites ? sites[0].id : 0}>
            {sites.map((site) => (
              <Select.Option key={site.id} value={site.id}>{site.title}</Select.Option>
            ))}
          </Select>
          <InputNumber
            defaultValue={this.state.depositAmount}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            onChange={onAmoutChange}
            style={{ marginRight: 10 }}
          />
          <div>Please select site and input amount before clicking on the Paypal button</div>

          <div style={{ display: "flex", marginTop: 20 }}>
            <PaypalExpressBtn env={paypal_env} client={paypal_client} currency={CURRENCY} total={this.state.depositAmount} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
            <Popover content="After payment sucessful, system will create balance automaticaly" placement="rightTop">
              <Icon type="info-circle" style={{ lineHeight: "30px", marginLeft: 15 }} />
            </Popover>
            <br />
          </div>
        </div>} */}
            </Fragment>
        )
    }
}

export default Form.create({ name: 'create-a-deposit' })(NewDepositForm)
