import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { setCurrentUser } from '../../actions'
import Dashboard from '../admin/Dashboard'
import SupplierDashboard from './SupplierDashboard'
import SellerDashboard from '../seller/SellerDashboard'

const Container = styled.div``

class Dashboards extends React.Component {

  render() {

    const { currentUser } = this.props

    return (
      <Container>
        {currentUser.roles.find(role => ['Administrator', 'Supporter'].includes(role.name)) ?
          <Dashboard currentUser={currentUser} />
          :
          currentUser.roles.find(role => ['Supplier'].includes(role.name)) ?
            <SupplierDashboard />
            : <SellerDashboard currentUser={currentUser} />
        }
      </Container>
    )
  }
}

export default connect(
  state => {
    return {
      currentUser: state.app.currentUser
    }
  },
  { setCurrentUser }
)(Dashboards)