export const API_URL = 'https://podgasus.com/api'
export const APP_URL = 'https://podgasus.com'
export const SHOPIFY_API = '9434073c4c3a6c019b08c89a8d350d5c'
export const SHOPBASE_API = 'xxx'
export const CLOUDFRONT_URL = 'https://d1g779buciuc32.cloudfront.net' //https://d2f5j5fv445hwa.cloudfront.net'//'https://assets.podgasus.com'
export const PAYPAL_ENV = 'sandbox' //(MODE === 'dev' && origin === 'http://localhost:3000') ? 'sandbox' : 'production'; // you can set here to 'production' for production (sandbox)
export const CURRENCY = 'USD' // or you can set this value from your props or state
// Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/
export const PAYPAL_SANDBOX_KEY = 'xxx'
export const PAYPAL_PRODUCTION_KEY = 'xxx'
export const S3_BUCKET = 'podgasus1'
export const CLOUDFRONT_THUMB_URL = 'https://d2f5j5fv445hwa.cloudfront.net'//'https://images.podgasus.com'
export const URL_ORDER = 'https://d1g779buciuc32.cloudfront.net' //'https://d2f5j5fv445hwa.cloudfront.net'//"https://assets.podgasus.com"