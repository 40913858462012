import React from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Form,
  InputNumber,
  notification,
} from "antd";
import _ from "lodash";
import { UPDATE_ORDER_QUANTITY } from "../../graphql/mutation/ChangeQuantity";

const ChangeQuantity = (props) => {

    const [changeQuantity,{loading}]=useMutation(UPDATE_ORDER_QUANTITY)
  const onSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
          console.log(values);
        changeQuantity({
          variables: {
            orderId: order.id,
            quantity: values.quantity,
          },
        }).then(res=>{
            notification.success({message:"Change Variant Success"})
            refetch()
        }).catch(err =>{
          notification["error"]({
            message: 'Error',
            description: _.get(err, "[0].message")
          })
        })
      }
    });
  };

  const { order, refetch } = props;
  const { getFieldDecorator, validateFields } = props.form;
  return (
    <div>
      <Form onSubmit={onSubmit}>
        <Form.Item name="quantity" label="Quantity">
          {getFieldDecorator("quantity", {
            initialValue: order?.quantity,
            // rules: [{ min: 1, message: 'Order quantity must be greater than 1' }],
           
          })(<InputNumber min={1} style={{width:"100%"}} />)}
        </Form.Item>
        <div style={{display:"flex", justifyContent:"flex-end"}}>
        <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default Form.create({ name: "changeQuantity" })(ChangeQuantity);
