import React from 'react'
import { connect } from 'react-redux'
import { setOrdersFilter } from '../../actions'
import { Select, Input, Button, Row, Col, message } from 'antd'
import gql from '../../api/gql'
import _ from 'lodash'
import moment from 'moment'
import DateRange from '../DateRange'

class SupplierOrdersFilter extends React.Component {
    state = {
        sites: [],
        productTypes: [],
        suppliers: [],
        sellers: [],
        filter: { ...this.props.filter },
    }

    componentDidMount() {
        this.loadSites()
        this.loadProductType()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filter !== this.props.filter) {
            this.setState({
                filter: { ...this.props.filter },
            })
        }
    }

    loadSites() {
        const query = `query sites{sites{hits{id,title}}}`
        gql.request(query).then((res) => {
            this.setState({ sites: res.sites.hits || [] })
        })
    }

    loadProductType() {
        const query = `query productTypes{
      productTypes(filter: {limit: 9999}){
        hits{
          id title
        }
      }
    }`
        gql.request(query)
            .then((res) => {
                this.setState({ productTypes: res.productTypes.hits || [] })
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
            })
    }

    onChangeDate = (range, callback = () => {}) => {
        if (range.from !== null && range.to !== null) {
            this.setState(
                {
                    filter: {
                        ...this.state.filter,
                        from: moment(range.from).format('YYYY-MM-DD[T]00:00:00[Z]'),
                        to: moment(range.to).format('YYYY-MM-DD[T]00:00:00[Z]'),
                    },
                },
                callback,
            )
        } else {
            this.setState(
                {
                    filter: {
                        ...this.state.filter,
                        from: null,
                        to: null,
                    },
                },
                callback,
            )
        }
    }

    handleChange(key, value, callback = () => {}) {
        if (_.isArray(value)) {
            value = value.filter((item) => item !== null)
        }
        const { filter } = this.state
        filter[key] = value
        this.setState({ filter: { ...filter } }, callback)
    }

    applyFilter = () => {
        const { setOrdersFilter } = this.props
        setOrdersFilter(this.state.filter)
    }

    resetFilter = () => {
        const { setOrdersFilter, status } = this.props
        setOrdersFilter({
            api_order: status ? true : false,
            site_ids: null,
            product_type_ids: null,
            supplier_ids: null,
            seller_ids: null,
            from: null,
            to: null,
            search: null,
            status: status ? status : [],
            field: 'all',
        })
    }

    render() {
        const { sellers, suppliers, sites, productTypes, filter } = this.state
        const { currentUser } = this.props
        return (
            <div style={{ width: '100%', display: 'flex', gap: 10 }}>
                <div style={{ marginBottom: 10 }}>
                    <DateRange onChange={(dateRange) => this.onChangeDate(dateRange, this.applyFilter)}></DateRange>
                </div>
                <Select
                    value={filter.product_type_ids}
                    placeholder='Product types'
                    style={{
                        maxWidth: 250,
                        width: '100%',
                        marginBottom: 10,
                    }}
                    onChange={(v) => this.handleChange('product_type_ids', [v], this.applyFilter)}>
                    <Select.Option value={null}>All Product Types</Select.Option>
                    {productTypes.map((type) => (
                        <Select.Option key={type.id}>{type.title}</Select.Option>
                    ))}
                </Select>
                <Input.Group compact>
                    <Select
                        showSearch
                        optionFilterProp='children'
                        value={filter.field}
                        placeholder='Select Field'
                        style={{
                            width: 150,
                        }}
                        onChange={(v) => {
                            this.handleChange('field', v, null)
                            if (filter.search) {
                                this.applyFilter()
                            }
                        }}>
                        <Select.Option value='all'>All Fields</Select.Option>
                        <Select.Option value='origin_id'>Origin ID</Select.Option>
                        <Select.Option value="amazon_order_id">Amazon Order Id</Select.Option>
                        <Select.Option value='id'>POD Order ID</Select.Option>
                        <Select.Option value='sku'>SKU</Select.Option>
                    </Select>
                    <Input.Search
                        style={{ width: 'calc(100% - 150px)' }}
                        placeholder='Search...'
                        value={filter.search}
                        onChange={(e) => this.handleChange('search', e.target.value)}
                        onSearch={(e) => {
                            this.applyFilter()
                        }}
                    />
                </Input.Group>

                {/* <Button
                    type='default'
                    onClick={(e) => {
                        e.preventDefault()
                        this.resetFilter()
                    }}>
                    Reset
                </Button> */}
            </div>
        )
    }
}

export default connect(
    (state) => ({
        filter: state.orders.filter,
    }),
    { setOrdersFilter },
)(SupplierOrdersFilter)
