import React, { Component } from 'react'
import { Form, Input, Button, Checkbox, notification } from 'antd'
import validator from 'validator'
import { PropTypes } from 'prop-types'
import gql from '../../api/gql'
import _ from 'lodash'

class SiteEdit extends Component {
    state = {
        loading: false,
        sites: this.props.sites,
        user: this.props.user,
    }
    updateSite = (e) => {
        e.preventDefault()
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const mutation = `mutation updateSite($site_id: Int!, $updateSiteInput: UpdateSiteInput!){
          updateSite(site_id: $site_id, updateSiteInput: $updateSiteInput){
            id
            title
            domain
            platform
            domain_name
            rebrandly_domain
            is_auto_fetch_order
          }
        }`
                this.setState({ loading: true })
                gql.request(mutation, {
                    site_id: this.props.site.id,
                    updateSiteInput: {
                        title: values.title,
                        domain_name: values.domain_name,
                        rebrandly_domain: values.rebrandly_domain,
                        is_auto_fetch_order: values.is_auto_fetch_order,
                        email_support: values.email_support,
                    },
                })
                    .then((res) => {
                        notification['success']({ message: ' Site has been updated' })
                        this.setState({ loading: false })
                        this.onLoad()
                        this.onClose()
                    })
                    .catch((err) => {
                        notification['error']({
                            message: _.get(err, '[0].message'),
                        })
                        this.setState({ loading: false })
                    })
            }
        })
    }
    onLoad = () => {
        this.props.onLoad()
    }
    onClose = () => {
        this.props.onClose(false)
    }

    render() {
        const { site, form, user } = this.props
        const { loading } = this.state
        const { getFieldDecorator } = form
        const isAdministrator = user.roles.find((role) => role.name === 'Administrator')
        return (
            <Form onSubmit={this.updateSite}>
                <Form.Item label='Site Name'>
                    {getFieldDecorator('title', {
                        initialValue: site.title,
                        rules: [{ required: true }],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label='Email Support'>
                    {getFieldDecorator('email_support', {
                        initialValue: site.email_support || '',
                    })(<Input />)}
                </Form.Item>
                <Form.Item label='Site Url'>
                    {getFieldDecorator('domain', {
                        initialValue: site.domain,
                    })(<Input placeholder='https://' disabled={true} />)}
                </Form.Item>
                {site.platform == 'api' && (
                    <Form.Item label='API KEY'>
                        {getFieldDecorator('api_key', {
                            initialValue: site.api_key,
                        })(<Input disabled={true} />)}
                    </Form.Item>
                )}
                {isAdministrator && site.platform == 'shopify' && (
                    <Form.Item label='Domain Name'>
                        {getFieldDecorator('domain_name', {
                            initialValue: site.domain_name,
                            rules: [
                                {
                                    validator: (rule, value, callback) => {
                                        if (validator.isURL(value, { protocols: ['https'] }) !== true) {
                                            callback(new Error('Invalid Url. Url must start with https'))
                                        }
                                        return true
                                    },
                                },
                            ],
                        })(<Input placeholder='https://' />)}
                    </Form.Item>
                )}
                {isAdministrator && (
                    <Form.Item label='Rebrandly Domain'>
                        {getFieldDecorator('rebrandly_domain', {
                            initialValue: site.rebrandly_domain,
                            rules: [
                                {
                                    validator: (rule, value, callback) => {
                                        if (validator.isURL(value, { protocols: ['https'] }) !== true) {
                                            callback(new Error('Invalid Url. Url must start with https'))
                                        }
                                        return true
                                    },
                                },
                            ],
                        })(<Input placeholder='https://' />)}
                    </Form.Item>
                )}
                {isAdministrator && (
                    <Form.Item label='Auto Fetch Orders'>
                        {getFieldDecorator('is_auto_fetch_order', {
                            valuePropName: 'checked',
                            initialValue: site.is_auto_fetch_order,
                        })(<Checkbox>Auto Fetch Orders Daily</Checkbox>)}
                    </Form.Item>
                )}
                <Form.Item>
                    <Button type='primary' htmlType='submit' loading={loading}>
                        Save
                    </Button>{' '}
                    <Button onClick={() => this.props.onClose()}>Cancel</Button>
                </Form.Item>
            </Form>
        )
    }
}

SiteEdit.propTypes = {
    site: PropTypes.object,
    onCompleted: PropTypes.func,
    onError: PropTypes.func,
}

export default Form.create({ name: 'form' })(SiteEdit)
