import { Query } from "@apollo/react-components";
import React, { useEffect } from "react";
import { useState } from "react";
import gql from "graphql-tag";
import { Skeleton, Timeline } from "antd";
import moment from "moment";
const entityLogs = gql`
  query entityLogs($filter: EntityLogFilter!) {
    entityLogs(filter: $filter) {
      count
      hits {
        id
        created_at
        entity_id
        entity_name
        message
      }
    }
  }
`;
const EntityLogs = ({ selectedOrder, order, tracking }) => {
  const [filter, setFilter] = useState({
    id: selectedOrder?.id,
    entity_name: order || tracking,
  });
  useEffect(() => {
    if (selectedOrder) {
      setFilter({ ...filter, id: selectedOrder?.id });
    }
  }, [selectedOrder]);
  return (
    <div>
      <Query
        query={entityLogs}
        variables={{ filter: { ...filter } }}
        fetchPolicy="no-cache"
      >
        {({ error, loading, data }) => {
          if (error) return <div>{error.toString()}</div>;
          if (loading)
            return (
              <div>
                <Skeleton />
              </div>
            );
          const dataLog = data?.entityLogs?.hits;
          return (
            <div>
              <Timeline>
                {dataLog.map((log) => (
                  <Timeline.Item key={log.id}>
                    <div>
                      <div>{log.message}</div>
                      <span style={{ color: "rgb(153, 153, 153)" }}>
                        {moment(log.created_at).format("MMM DD, YYYY hh:mm")}
                      </span>
                    </div>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default EntityLogs;
