import gql from "graphql-tag";

export const UPDATE_ORDER_QUANTITY = gql`
  mutation updateOrderQuantity($orderId: Int!, $quantity: Int!) {
    updateOrderQuantity(orderId: $orderId, quantity: $quantity){
        id
        origin_id
    }
  }
`;
