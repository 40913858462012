import React from "react";
import { DatePicker, Menu, Dropdown, Icon } from "antd";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 30px;
  -webkit-box-align: center;
  align-items: center;
  a.ant-dropdown-link {
    display: inline-block;
    height: 32px;
    width: 30px;
    text-align: center;
    border: 1px solid #d9d9d9;
    position: relative;
    margin-left: -2px;
    background: #fff;
    line-height: 29px;
    border-radius: 0 4px 4px 0;
  }
  .ant-dropdown-menu-item a {
    font-size: 12px;
    color: #cdcdcd;
  }
  .ant-calendar-picker {
    .ant-input {
      border-radius: 4px 0px 0px 4px;
    }
  }
`;

const dateFormat = "MMM DD, YYYY";
const { RangePicker } = DatePicker;

// const initState = {
//   startDate: moment().startOf('day').add(-6, 'day'),
//   endDate: moment().endOf('day'),
//   dateRange: 'all'
// }
export default class DateRange extends React.Component {
  state = {
    startDate: this.props.from,
    endDate: this.props.to,
    dateRange: "custom",
    range: this.props.range,
  };

  componentDidMount() {
    const { defaultValue } = this.props;
    if (defaultValue != null) {
      this.setDateRange(defaultValue);
    }
  }

  applyFilter = () => {
    const { startDate, endDate, range } = this.state;
    if (startDate && endDate) {
      this.props.onChange({
        from: startDate.format("YYYY-MM-DD[T]HH:mm:ss-07:00"),
        to: endDate.format("YYYY-MM-DD[T]HH:mm:ss-07:00"),
        range: {
          from: startDate.format("YYYY-MM-DD[T]HH:mm:ss-07:00"),
          to: endDate.format("YYYY-MM-DD[T]HH:mm:ss-07:00"),
        },
      });
    } else {
      this.props.onChange({
        from: null,
        to: null,
        range: null,
      });
    }
  };

  setDateRange = (value) => {
    switch (value) {
      case "today":
        this.setState(
          {
            dateRange: value,
            startDate: moment().startOf("day"),
            endDate: moment().endOf("day"),
          },
          () => this.applyFilter()
        );
        break;
      case "yesterday":
        this.setState(
          {
            dateRange: value,
            startDate: moment().startOf("day").add(-1, "day"),
            endDate: moment().endOf("day").add(-1, "day"),
          },
          () => this.applyFilter()
        );
        break;
      case "last7days":
        this.setState(
          {
            dateRange: value,
            startDate: moment().startOf("day").add(-6, "day"),
            endDate: moment().endOf("day"),
          },
          () => this.applyFilter()
        );
        break;
      case "last30days":
        this.setState(
          {
            dateRange: value,
            startDate: moment().startOf("day").add(-30, "day"),
            endDate: moment().endOf("day"),
          },
          () => this.applyFilter()
        );
        break;
      case "thismonth":
        this.setState(
          {
            dateRange: value,
            startDate: moment().startOf("month"),
            endDate: moment().endOf("month"),
          },
          () => this.applyFilter()
        );
        break;
      case "lastmonth":
        this.setState(
          {
            dateRange: value,
            startDate: moment().startOf("month").add(-1, "month"),
            endDate: moment().endOf("month").add(-1, "month"),
          },
          () => this.applyFilter()
        );
        break;
      case "last3months":
        this.setState(
          {
            dateRange: value,
            startDate: moment().startOf("month").add(-3, "month"),
            endDate: moment().endOf("month").add(-1, "month"),
          },
          () => this.applyFilter()
        );
        break;
      case "all":
        this.setState(
          {
            dateRange: value,
            startDate: null,
            endDate: null,
          },
          () => this.applyFilter()
        );
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <Container>
        <RangePicker
          style={{ width: "100%" }}
          defaultValue={[this.state.startDate, this.state.endDate]}
          value={
            !this.props.defaultDate
              ? [this.state.startDate, this.state.endDate]
              : this.props.defaultDate
          }
          format={dateFormat}
          onChange={(dates) => {
            if (dates.length) {
              this.setState(
                {
                  startDate: dates[0].startOf("day"),
                  endDate: dates[1].endOf("day"),
                  dateRange: "custom",
                },
                () => this.applyFilter()
              );
            } else {
              this.setState(
                {
                  dateRange: "all",
                  startDate: null,
                  endDate: null,
                },
                () => this.applyFilter()
              );
            }
          }}
        />
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="today">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setDateRange("today");
                  }}
                  style={{ fontSize: "12px", color: "#CDCDCD" }}
                >
                  <span style={{ fontSize: "14px", color: "#1890FF" }}>
                    Today
                  </span>
                  {` ${moment().format(dateFormat)}`}
                </Link>
              </Menu.Item>
              <Menu.Item key="yesterday">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setDateRange("yesterday");
                  }}
                  style={{ fontSize: "12px", color: "#CDCDCD" }}
                >
                  <span style={{ fontSize: "14px", color: "#1890FF" }}>
                    Yesterday
                  </span>
                  {` ${moment().subtract(1, "days").format(dateFormat)}`}
                </Link>
              </Menu.Item>
              <Menu.Item key="last7days">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setDateRange("last7days");
                  }}
                  style={{ fontSize: "12px", color: "#CDCDCD" }}
                >
                  <span style={{ fontSize: "14px", color: "#1890FF" }}>
                    Last 7 days
                  </span>
                  {` ${moment()
                    .subtract(6, "days")
                    .format(dateFormat)} - ${moment().format(dateFormat)}`}
                </Link>
              </Menu.Item>
              <Menu.Item key="last30days">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setDateRange("last30days");
                  }}
                  style={{ fontSize: "12px", color: "#CDCDCD" }}
                >
                  <span style={{ fontSize: "14px", color: "#1890FF" }}>
                    Last 30 days
                  </span>
                  {` ${moment()
                    .subtract(30, "days")
                    .format(dateFormat)} - ${moment().format(dateFormat)}`}
                </Link>
              </Menu.Item>
              <Menu.Item key="thismonth">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setDateRange("thismonth");
                  }}
                  style={{ fontSize: "12px", color: "#CDCDCD" }}
                >
                  <span style={{ fontSize: "14px", color: "#1890FF" }}>
                    This month
                  </span>
                  {` ${moment()
                    .startOf("month")
                    .format(dateFormat)} - ${moment()
                    .endOf("month")
                    .format(dateFormat)}`}
                </Link>
              </Menu.Item>
              <Menu.Item key="lastmonth">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setDateRange("lastmonth");
                  }}
                  style={{ fontSize: "12px", color: "#CDCDCD" }}
                >
                  <span style={{ fontSize: "14px", color: "#1890FF" }}>
                    Last month
                  </span>
                  {` ${moment()
                    .subtract(1, "months")
                    .startOf("month")
                    .format(dateFormat)} - ${moment()
                    .subtract(1, "months")
                    .endOf("month")
                    .format(dateFormat)}`}
                </Link>
              </Menu.Item>
              <Menu.Item key="last3months">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setDateRange("last3months");
                  }}
                  style={{ fontSize: "12px", color: "#CDCDCD" }}
                >
                  <span style={{ fontSize: "14px", color: "#1890FF" }}>
                    Last 3 months
                  </span>
                  {` ${moment()
                    .subtract(3, "months")
                    .startOf("month")
                    .format(dateFormat)} - ${moment()
                    .subtract(1, "months")
                    .endOf("month")
                    .format(dateFormat)}`}
                </Link>
              </Menu.Item>
              <Menu.Item key="all">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setDateRange("all");
                  }}
                  style={{ fontSize: "12px", color: "#CDCDCD" }}
                >
                  <span style={{ fontSize: "14px", color: "#1890FF" }}>
                    All time
                  </span>
                  {` From the beginning until now`}
                </Link>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
        >
          <Link
            className="ant-dropdown-link"
            to="#"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Icon type="align-right" />
          </Link>
        </Dropdown>
      </Container>
    );
  }
}
