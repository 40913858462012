import React from "react";
import {
  Progress,
  Tooltip,
  Icon
} from "antd";
import Upload from "../Upload";
import gql from "../../api/gql";
import PrintFilePreview from "../file/PrintFilePreview";
import { CLOUDFRONT_URL } from "../../config";

class UploadImage extends React.Component {
    state = {
        fileId: this.props?.fileId,
        uploading: false,
        percent: 0,
        fileKey: "",
    };

    onUpload = (file) => {
        let query = `mutation createFile($input: NewFile!){
            createFile(input: $input){id}
        }`;
        
        gql
        .request(query, {
            input: {
                source: "aws",
                key: file.key,
                file_name: file.file.name,
                file_mime: file.file.type,
                file_size: file.file.size,
            },
        })
        .then(({ createFile }) => {
            this.setState({
                ...this.state, 
                uploading: false,
                fileId: createFile.id
            });
            
            this.props.onChange(this.state.fileId)
         })
        .catch((err) => {
            this.setState({
                ...this.state,
                uploading: false,
                percent: 0,
            });
        });
    };

    render() {
        return (
            <>
                <div
                    style={{
                        position:"relative"
                    }}
                >
                    <Upload
                        onUpload={this.onUpload}
                        onProcess={(e) => {
                            this.setState({
                                ...this.state,
                                uploading: true,
                                percent: Math.round((e.loaded / e.total) * 100),
                            });
                        }}
                        fileList={false}
                        accept=".jpg, .png, .tiff, .psd, .dxf, .pdf"
                        className="upload-wrapper"
                    >
                        <div className="upload-area">
                            {
                                this.state.uploading 
                                ? 
                                    (
                                        <Progress
                                            type="circle"
                                            percent={this.state.percent}
                                            strokeColor="#1890ff"
                                            width={50}
                                        />
                                    ) 
                                : 
                                    this.state.fileId 
                                    ? 
                                        (
                                            <div style={{height:"80px"}}>
                                                <PrintFilePreview 
                                                    file_id={this.state.fileId} 
                                                    onKey={(key) => {
                                                        this.setState({...this.state, fileKey: encodeURIComponent(`${key}`)});
                                                    }}
                                                />
                                            </div>
                                        ) 
                                    : 
                                        (
                                            <span style={{ textAlign: "center", fontSize: "11px", padding: "3px" }}>
                                                Click upload or drag Label
                                            </span>
                                        )
                            }
                        </div>
                    </Upload>

                    {this.state.fileKey !== "" && (
                        <div 
                            style={{
                                position:"absolute",
                                right:0,
                                top:0
                            }}
                        >
                            <a
                                href={`${CLOUDFRONT_URL}/${this.state.fileKey}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Download"
                            >
                            <Tooltip
                                placement="topRight"
                                title="Download"
                            >
                                <Icon type="download"></Icon>
                            </Tooltip>
                            </a>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default class UploadLabel extends React.Component {
    state = {
        orderId: this.props?.orderId,
        fileId: this.props?.fileId
    };

    componentDidMount() {
        // Action After render
    }

    saveLabelImageId() {
        if (! this.state.fileId) return
        
        let query = `mutation UpdateOrderLabelImageId($order_id: Int!, $label_image_id: Int!){
            updateOrderLabelImageId(order_id: $order_id, label_image_id: $label_image_id)
        }`;

        gql
        .request(query, {
            order_id: this.state.orderId,
            label_image_id: this.state.fileId,
        })
        .then(({ res }) => {
            console.log(res)
        })
        .catch((err) => {
            this.setState({
                ...this.state,
                uploading: false
            });
        });
    }

    render() {
        return (
            <>
                <UploadImage
                    fileId={this.state.fileId}
                    onChange={(id) => {
                        this.setState({...this.state, fileId: id});
                        this.saveLabelImageId()
                    }}
                />
            </>
        );
    }
}
