import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from '../../api/gql'
import { history } from "../../history";
import styled, { css } from 'styled-components'
import {
    Button,
    Row,
    Col,
    PageHeader,
    Select,
    Card,
    Input,
    Table,
    Tag,
    notification
} from "antd";
import { v4 } from "uuid"

// start initialize a DOM for template
const Container = styled.div``;

const colStyle = {
    textAlign: 'left',
    padding: '10px 20px 10px 20px'
};

const labelStyled = {
    padding: '0px 0px 10px 5'
};

const { Option } = Select;
// end initialize a DOM for template

class TranferOrder extends Component {

    constructor(props) {
        super(props);

        this.state = {

            // variable status of loading dom
            loading: false,

            disableSelect: true,

            selectedProductType: null,

            selectedFromSupplier: null,

            selectedToSupplier: null,

            selectedAttribute: null,

            amountOrder: 0,

            arrProductTypes: [],

            arrSuppliers: [],

            arrAttribute: [],

            // set value for state dataOrders
            dataOrders: [],

            // total of record was got by api get orders by suppliers
            totalOrders: 0,

            pageOrder: 1,

            filterOrder: {
                offset: 0,
                limit: 20,
            },
        }
    }

    componentWillMount() {

    }

    componentDidMount() {

        // get data product type
        this.getProductType()

        // get data orders by suppliers
        this.getOrdersBySupplier()

    }

    // get list orders by product_type_id and supplier_id
    getOrdersBySupplier() {

        const {
            filterOrder,
            selectedProductType,
            selectedFromSupplier,
            selectedToSupplier,
            selectedAttribute,
        } = this.state

        const product_type_ids = []
        const supplier_ids = []
        const product_type_variant_ids = []

        if (selectedProductType != null) {
            product_type_ids.push(selectedProductType)
        }

        if (selectedFromSupplier != null) {
            supplier_ids.push(selectedFromSupplier)
        }

        if (selectedToSupplier != null) {
            supplier_ids.push(selectedToSupplier)
        }

        if (selectedAttribute != null) {
            product_type_variant_ids.push(selectedAttribute)
        }

        const query = `
            query getOrderBySupplier($filter: OrderFilterBySupplier)
            {
                getOrderBySupplier(filter: $filter) {
                    count
                    hits {
                        supplier_id
                        supplier_email
                        supplier_firstname
                        supplier_lastname
                        product_type_id
                        product_type_name
                        order_status
                        total_orders
                    }
                }
            }
        `

        this.setState({ loading: true })
        gql.request(query, {
            filter: {
                product_type_ids: product_type_ids,
                supplier_ids: supplier_ids,
                product_type_variant_ids: product_type_variant_ids,
                offset: filterOrder.offset,
                limit: filterOrder.limit,
            }
        }).then(
            res => {

                // create a array to contain list order return
                const arrOrders = []
                res.getOrderBySupplier.hits.map(
                    order => {

                        // create a key for each row in array order
                        order.key = v4()
                        order.supplier_fullname = order.supplier_firstname + " " + order.supplier_lastname

                        // push this order into arrOrders
                        arrOrders.push(order)
                    }
                )

                // set again value for state
                this.setState({
                    dataOrders: arrOrders,
                    totalOrders: res.getOrderBySupplier.count,
                    loading: false
                })
            }
        ).catch(
            err => {
                this.setState({ loading: false })
                this.openNotification("Error", "Get List Orders Fail", "error")
            }
        )
    }

    getProductType() {
        const query = `query {
                productTypes(
                filter: {
                    offset: 0
                    limit: 9999
                }
                ){
                count
                hits {
                    id
                    title
                    suppliers {
                        id
                        first_name
                        last_name
                    }
                    product_type_variants {
                        id
                        sku
                    }
                }
                }
            }
        `

        this.setState({ loading: true })

        gql.request(query, {}).then(
            res => {
                this.setState({
                    arrProductTypes: res.productTypes.hits,
                    loading: false
                })
            }
        ).catch(
            err => {
                this.setState({ loading: false })
                this.openNotification("Error", "Get List Product Type Fail", "error")
            }
        )
    }

    // submit number of order need transfer to supplier
    async transferOrdersBySupplier(productTypeID, fromSupplierID, toSupplierID, amountOrder, productTypeVariantIDs) {

        const mutation = `
            mutation ($TransferOrder: TransferOrder!) {
                transferOrdersBySupplier(input: $TransferOrder)
            }
        `

        this.setState({ loading: true })

        return gql.request(mutation, {
            TransferOrder: {
                product_type_id: productTypeID,
                from_supplier_id: fromSupplierID,
                to_supplier_id: toSupplierID,
                order_amount: amountOrder,
                product_type_variant_ids: productTypeVariantIDs,
            }
        }).then(
            res => {
                this.setState({
                    loading: false,
                })

                return res
            }
        ).catch(
            error => {
                this.setState({
                    loading: false,
                })

                return error
            }
        )
    }

    changeProductType = async (id) => {

        const productTypes = this.state.arrProductTypes

        await productTypes.map(
            productType => {
                if (productType.id === id) {
                    this.setState({
                        selectedProductType: id,
                        selectedFromSupplier: null,
                        selectedToSupplier: null,
                        selectedAttribute: null,
                        arrSuppliers: productType.suppliers,
                        arrAttribute: productType.product_type_variants,
                        disableSelect: false
                    })
                }
            }
        )

        this.getOrdersBySupplier()
    }

    checkAmountOrder = (e) => {
        const val = e.target.value;

        if (e.target.validity.valid) {

            if (val === 'e' || val < 0) {
                return false
            }

            this.setState({ amountOrder: e.target.value });
        }
        else if (val === '' || val === '-') {
            this.setState({ amountOrder: val });
        }
    }

    changeFromSupplier = async (id) => {

        // processing have to wait update state then get order by supplier again
        await this.setState({
            selectedFromSupplier: id,
            pageOrder: 1,
        })

        // get order by supplier to update data in table
        this.getOrdersBySupplier()
    }

    changeToSupplier = async (id) => {

        await this.setState({
            selectedToSupplier: id,
            pageOrder: 1
        })

        this.getOrdersBySupplier()
    }

    changeAttribute = async (value) => {

        if (value === "all") {
            await this.setState({
                selectedAttribute: null,
                pageOrder: 1
            })
        } else {
            await this.setState({
                selectedAttribute: value,
                pageOrder: 1
            })
        }

        this.getOrdersBySupplier()
    }

    // event user click btn submit, this func will check validate and call api transfer orders
    submitTranferOrder = async () => {

        // get parameters in state
        const {
            selectedProductType,
            selectedFromSupplier,
            selectedToSupplier,
            amountOrder,
            selectedAttribute,
        } = this.state

        // validate parammeters input
        if (
            selectedProductType === null ||
            selectedFromSupplier === null ||
            selectedToSupplier === null ||
            amountOrder === 0 || amountOrder === null) {
            this.openNotification("Missing Params Tranfer Orders", "Please select all required information", "warning")
            return false
        }

        if (selectedFromSupplier === selectedToSupplier) {
            this.openNotification("Incorrect Params Transfer Orders", "Form supplier is not same to supplier", "warning")
            return false
        }

        // create a array attribute
        const productTypeVariantIDs = []
        if (selectedAttribute != null) {
            productTypeVariantIDs.push(selectedAttribute)
        }

        // call func transfer orders and pass parameters was gotten
        this.transferOrdersBySupplier(
            selectedProductType,
            selectedFromSupplier,
            selectedToSupplier,
            amountOrder,
            productTypeVariantIDs
        ).then(
            res => {
                if (res.transferOrdersBySupplier === true) {

                    const contentNotice = "transferred " + amountOrder + " orders"
                    this.openNotification("Tranfer Orders Success", contentNotice, "success")

                    this.setState({
                        selectedProductType: null,
                        selectedFromSupplier: null,
                        selectedToSupplier: null,
                        selectedAttribute: null,
                        amountOrder: 0,
                        disableSelect: true,
                    })

                    this.getProductType()
                    this.getOrdersBySupplier()

                } else {

                    res.map(
                        err => {
                            this.openNotification("Tranfer Orders Fail", err.message, "error")
                        }
                    )

                    this.setState({
                        loading: false
                    })

                }
            }
        )
    }

    // show notification when event click button
    openNotification = (title, description, type) => {

        // set type icon for notification
        if (type != "success" && type != "info" && type != "warning" && type != "error") {
            type = "info"
        }

        notification[type]({
            message: title,
            description: description,
        })
    }

    render() {

        // create variable from state for DOM
        const {
            loading,
            dataOrders,
            disableSelect,
            arrProductTypes,
            arrSuppliers,
            arrAttribute,
            selectedProductType,
            selectedFromSupplier,
            selectedToSupplier,
            selectedAttribute,
            amountOrder,
            totalOrders,
            pageOrder,
            filterOrder,
        } = this.state;

        // set value for state columnOrders
        const columnOrders = [
            {
                title: 'Supplier Full Name',
                dataIndex: 'supplier_fullname',
                key: 'supplier_fullname',
            },
            {
                title: 'Supplier Email',
                dataIndex: 'supplier_email',
                key: 'supplier_email',
            },
            {
                title: 'Product Type Name',
                dataIndex: 'product_type_name',
                key: 'product_type_name',
            },
            {
                title: 'Order Status',
                key: 'order_status',
                dataIndex: 'order_status',
                render: order_status => {
                    let color
                    switch (order_status) {
                        case 'pending':
                            {
                                color = '#f94';
                                break;
                            }
                        case 'processing':
                            {
                                color = '#28e0d4';
                                break;
                            }
                        case 'ready':
                            {
                                color = 'blue';
                                break;
                            }
                    }
                    return (
                        <Tag color={color} key={order_status}>
                            {order_status}
                        </Tag>
                    )
                },
            },
            {
                title: 'Total Orders',
                dataIndex: 'total_orders',
                key: 'total_orders',
            },
        ];

        // set pagination for table orders
        const pagination = {
            pageSize: filterOrder.limit,
            total: totalOrders,
            current: pageOrder,
            showTotal: (total, range) => `${range} of ${total}`,
            onChange: (page, pageSize) => {
                this.setState(
                    {
                        pageOrder: page,
                        filterOrder: {
                            ...filterOrder,
                            offset: (page - 1) * pageSize,
                        },
                    },
                    () => this.getOrdersBySupplier()
                );
            },
        };

        return (
            <Container>

                {/* page header */}
                <PageHeader
                    title="Tranfer Orders"
                    onBack={() => {
                        history.goBack();
                    }}
                    style={{ padding: "16px 0px" }}
                />

                {/* form choose product type and supplier */}
                <Row type="flex" style={{ width: "calc(100%)" }}>
                    <Card title="Transfer New Orders" bordered={false} style={{ width: "calc(100%)" }}>

                        {/* select product type */}
                        <Col style={colStyle} span={5}>

                            <div style={labelStyled}>Product Type</div>

                            <Select
                                value={selectedProductType}
                                onChange={this.changeProductType}
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Select a product type"
                                optionFilterProp="children"
                            >
                                {
                                    arrProductTypes.map(
                                        productType => (
                                            <Option
                                                key={productType.id}
                                                value={productType.id}
                                            >
                                                {productType.title}
                                            </Option>
                                        )
                                    )
                                }
                            </Select>
                        </Col>

                        {/* select supplier from */}
                        <Col style={colStyle} span={4}>

                            <div style={labelStyled}>From Supplier</div>

                            <Select
                                value={selectedFromSupplier}
                                disabled={disableSelect}
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Select a supplier"
                                optionFilterProp="children"
                                onChange={this.changeFromSupplier}
                            >
                                {
                                    arrSuppliers.map(
                                        (supplier) => (
                                            <Option
                                                key={supplier.id}
                                                value={supplier.id}
                                            >
                                                {supplier.first_name + " " + supplier.last_name}
                                            </Option>
                                        )
                                    )
                                }
                            </Select>
                        </Col>

                        {/* select supplier to */}
                        <Col style={colStyle} span={4}>

                            <div style={labelStyled}>To Supplier</div>

                            <Select
                                value={selectedToSupplier}
                                disabled={disableSelect}
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Select a supplier"
                                optionFilterProp="children"
                                onChange={this.changeToSupplier}
                            >
                                {
                                    arrSuppliers.map(
                                        supplier => (
                                            <Option
                                                key={supplier.id}
                                                value={supplier.id}
                                            >
                                                {supplier.first_name + " " + supplier.last_name}
                                            </Option>
                                        )
                                    )
                                }
                            </Select>
                        </Col>

                        {/* input order amount */}
                        <Col style={colStyle} span={4}>

                            <div style={labelStyled}>Order Amount</div>

                            <Input
                                type="number"
                                value={amountOrder}
                                onChange={this.checkAmountOrder}
                                pattern="^-?[0-9]\d*\.?\d*$"
                            />
                        </Col>

                        {/* select supplier to */}
                        <Col style={colStyle} span={7}>

                            <div style={labelStyled}>Attribute</div>

                            <Row>
                                <Col span={16}>
                                    <Select
                                        value={selectedAttribute}
                                        disabled={disableSelect}
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Select a attribute"
                                        optionFilterProp="children"
                                        onChange={this.changeAttribute}
                                    >
                                        <Option value={"all"}>{"All Attribute"}</Option>
                                        {
                                            arrAttribute.map(
                                                attribute => (
                                                    <Select.Option
                                                        key={attribute.id}
                                                        value={attribute.id}
                                                    >
                                                        {attribute.sku}
                                                    </Select.Option>
                                                )
                                            )
                                        }
                                    </Select>
                                </Col>

                                <Col style={{ textAlign: "center" }} span={8}>
                                    <Button onClick={this.submitTranferOrder} type="primary">Submit</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Card>
                </Row>

                {/* table show supplier and total orders */}
                <Row type="flex" style={{ width: "calc(100%)", marginTop: "30px" }}>
                    <Card title="Current New Order Stats" bordered={false} style={{ width: "calc(100%)" }}>
                        <Table
                            loading={loading}
                            dataSource={dataOrders}
                            columns={columnOrders}
                            rowKey={"key"}
                            pagination={pagination}
                        />;
                    </Card>
                </Row>

            </Container>
        );
    }
}

TranferOrder.propTypes = {

};

export default TranferOrder;