import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { Card, Col, PageHeader, Row, Tag } from 'antd'
import { history } from '../../../history'

import _ from 'lodash'
import S3Image from '../../S3Image'
import styled from 'styled-components'

const BoxImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
`

const TextPageCost = styled.span`
    color: #2f0054;
    font-size: 16px;
`

const PriceCatalog = styled.h2`
    color: rgba(220, 38, 38, 1);
    font-size: 30px;
    font-weight: 700;
    margin: 0;
`

const DesCatalog = styled.small`
    display: inline-block;
    margin-bottom: 12px;
    font-size: 13px;
    color: rgba(220, 38, 38, 1);
`

const BoxAttributesItem = styled.div`
    margin-bottom: 8px;
    .option-item {
        height: 32px;
        line-height: 1;
        padding: 9px 15px;
        font-size: 12px;
        border-radius: 3px;
        border: 1px solid #dcdfe6;
        color: #606266;
        font-weight: 600;
        background: #fff;
        cursor: pointer;
        margin-bottom: 8px;
        &:hover {
            color: #003f70;
            border-color: #b3c5d4;
            background-color: #e6ecf1;
        }
        &.active {
            color: #fff;
            background-color: #003f70;
            border-color: #003f70;
        }
        &.disable {
            pointer-events: none;
            color: #00000040;
            border-color: #d9d9d9;
            background: #f5f5f5;
            text-shadow: none;
            box-shadow: none;
            cursor: not-allowed;
            &:hover {
                color: #00000040;
                border-color: #d9d9d9;
                background: #f5f5f5;
            }
        }
    }
`

const AttributesItemTitle = styled.div`
    margin-bottom: 8px;
    font-size: 16px;
    color: #2f0054;
    text-transform: capitalize;
`

const SKU = styled.div`
    margin-top: 16px;
    margin-bottom: 8px;
`

const PriceAttributes = styled.div`
    margin-bottom: 8px;
`

const PriceWorldwide = styled.div`
    margin-bottom: 12px;
`

const BoxDescription = styled.div`
    p {
        color: #000;
        font-size: 16px;
        font-weight: 400;
    }
`

const BoxDesign = styled.div`
    font-size: 16px;
    a {
        color: #111827;
        text-decoration: underline;
        font-weight: 500;
    }
`

const isEmpty = (obj) => {
    return obj === undefined || obj === null || Object.keys(obj).length === 0
}

const renderTextCarrier = (array) => {
    let text = ''
    if (array.length > 0) {
        array.forEach((element) => {
            text = `${text}/${element?.item?.name || ''}`
        })
    }
    return text.slice(1)
}

const getArraysIntersection = (list1, list2) => {
    const result = []

    for (let i = 0; i < list1.length; i++) {
        let item1 = list1[i]
        let index = list2.findIndex((x) => x.name === item1.name)

        if (index === -1) {
            result.push(item1)
        }
    }

    return result
}

const Index = (props) => {
    const [state, setState] = useState({
        dataSource: props.dataSource,
        optionSelect: [],
        dataDetailAttributes: null,
    })
    const { dataSource } = state
    const variantsBaseCost = _.minBy(dataSource?.variants, 'base_cost')?.base_cost || 0

    useEffect(() => {
        if (state.optionSelect.length === dataSource?.attributes?.length) {
            let dataDetailAttributes = {
                sku: '',
                base_cost: 0,
                other_base_cost: 0,
                fee_ship: 0,
            }

            for (var i = 0; i < dataSource.variants.length; i++) {
                let element = dataSource.variants[i]

                if (_.isEqual(_.sortBy(state.optionSelect, 'name'), _.sortBy(element.attributes, 'name'))) {
                    dataDetailAttributes = element
                    break
                }
            }
            setState((s) => ({ ...s, dataDetailAttributes }))
        } else {
            if (state.optionSelect.length === 1) {
                let dataSource = state.dataSource
                let newAttributes = dataSource.attributes

                for (let index = 0; index < newAttributes.length; index++) {
                    let element = newAttributes[index]

                    newAttributes[index] = {
                        ...element,
                        isCheckDisabled: [],
                    }
                }

                dataSource['attributes'] = [...newAttributes]
                let newData = getArraysIntersection(dataSource?.attributes, state.optionSelect)

                let attributes = {}
                for (let i = 0; i < newData.length; i++) {
                    attributes[[newData[i].name]] = [...newData[i].options]
                }

                let attrs = []

                for (const [attr, values] of Object.entries(attributes)) attrs.push(values.map((v) => ({ name: attr, option: v })))

                let newData2 = []
                if (attrs.length === 1) {
                    attrs[0].forEach((v) => {
                        let arr = [{ ...v, __typename: 'ProductTypeVariantAttribute' }]
                        state.optionSelect.forEach((element) => {
                            arr.push(element)
                        })
                        newData2.push([...arr])
                    })
                } else {
                    attrs.reduce((a, b) =>
                        a.flatMap((d) =>
                            b.map((e) => {
                                let arr = [
                                    { ...d, __typename: 'ProductTypeVariantAttribute' },
                                    { ...e, __typename: 'ProductTypeVariantAttribute' },
                                ]
                                state.optionSelect.map((x) => {
                                    arr.push(x)
                                })
                                newData2.push(arr)
                            }),
                        ),
                    )
                }

                for (let index = 0; index < newData2.length; index++) {
                    let element = newData2[index]
                    let index2 = dataSource.variants.findIndex((x) => _.isEqual(_.sortBy(x.attributes, 'name'), _.sortBy(element, 'name')))
                    if (index2 !== -1) {
                        for (let stt = 0; stt < state.optionSelect.length; stt++) {
                            let indexRemove = element.findIndex((x) => x.name === state.optionSelect[stt].name)

                            if (indexRemove !== -1) {
                                element.splice(indexRemove, 1)
                            }
                        }

                        for (let stt = 0; stt < element.length; stt++) {
                            let { name, option } = element[stt]
                            let indexDisable = dataSource?.attributes.findIndex((x) => x.name === name)
                            if (indexDisable !== -1) {
                                let index = dataSource?.attributes[indexDisable]?.isCheckDisabled
                                    ? dataSource?.attributes[indexDisable].isCheckDisabled.findIndex((x) => x === option)
                                    : -1
                                if (index === -1) {
                                    // eslint-disable-next-line no-unused-expressions
                                    dataSource?.attributes[indexDisable]['isCheckDisabled'].push(option)
                                }
                            }
                        }
                    }
                }

                setState((s) => ({ ...s, dataSource: state.dataSource }))
            } else if (state.optionSelect.length === 0) {
                let newAttributes = state.dataSource.attributes

                for (let index = 0; index < newAttributes.length; index++) {
                    let element = newAttributes[index]

                    newAttributes[index] = {
                        ...element,
                        isCheckDisabled: [],
                    }
                }

                state.dataSource['attributes'] = [...newAttributes]

                setState((s) => ({ ...s, dataSource: state.dataSource }))
            }

            if (!isEmpty(state.dataDetailAttributes)) {
                setState((s) => ({ ...s, dataDetailAttributes: {} }))
            }
        }
    }, [state.optionSelect])

    const onSelectOption = (item, nameAtt) => {
        let optionSelect = [...state.optionSelect]
        let indexActive = state.optionSelect.findIndex((x) => x.name === nameAtt && x.option === item)
        if (indexActive !== -1) {
            optionSelect.splice(indexActive, 1)
        } else {
            let index = state.optionSelect.findIndex((x) => x.name === nameAtt)
            let itemNew = {
                name: nameAtt,
                option: item,
                __typename: 'ProductTypeVariantAttribute',
            }
            if (index !== -1) {
                optionSelect[index] = itemNew
            } else {
                optionSelect.push(itemNew)
            }
        }

        setState((s) => ({ ...s, optionSelect: [...optionSelect], dataSource: state.dataSource }))
    }

    const renderOptionItem = (item, index, nameAtt, disabled) => {
        let activeClass = state.optionSelect.findIndex((x) => x.name === nameAtt && x.option === item)
        let indexDisabled =
            state.optionSelect.length === 1 && disabled.length === 0 && state.optionSelect.some((x) => x.name !== nameAtt)
                ? -1
                : disabled?.length > 0
                ? disabled.findIndex((x) => x === item)
                : 1
        let className = indexDisabled === -1 ? 'option-item disable' : `${activeClass !== -1 ? 'option-item active' : 'option-item'}`

        return (
            <Tag disabled={true} className={className} key={index} onClick={() => onSelectOption(item, nameAtt)}>
                {item || ''}
            </Tag>
        )
    }

    const renderAttributesItem = (item, index) => {
        return (
            <BoxAttributesItem key={index}>
                <AttributesItemTitle>{item?.name || ''}:</AttributesItemTitle>
                {item.options.length > 0 && item.options.map((v, i) => renderOptionItem(v, i, item?.name, item?.isCheckDisabled))}
            </BoxAttributesItem>
        )
    }

    return (
        <div>
            <PageHeader
                style={{ padding: '16px 0px' }}
                title={dataSource?.title || ''}
                onBack={() => {
                    history.goBack()
                }}
            />

            <Card style={{ marginBottom: 20 }}>
                <Row gutter={[18, 18]}>
                    <Col span={10}>
                        <BoxImage>
                            <S3Image
                                src={dataSource.images ? dataSource.images[0] : null}
                                style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto', display: 'inline-block', verticalAlign: 'middle' }}
                            />
                        </BoxImage>
                    </Col>

                    <Col span={14}>
                        <TextPageCost>Base Cost:</TextPageCost>
                        <PriceCatalog>{`$${variantsBaseCost || 0}`}</PriceCatalog>
                        <DesCatalog>Included shipping fee</DesCatalog>
                        {dataSource?.attributes?.length > 0 && dataSource?.attributes?.map((v, i) => renderAttributesItem(v, i))}
                        {!isEmpty(state.dataDetailAttributes) && (
                            <>
                                <SKU>
                                    SKU: <b>{state.dataDetailAttributes?.sku || ''}</b>
                                </SKU>
                                <PriceAttributes>
                                    Price: <b>${state.dataDetailAttributes?.base_cost || '0'}</b>
                                </PriceAttributes>
                                <PriceWorldwide>
                                    Worldwide shipping:
                                    <b>
                                        First: ${state.dataDetailAttributes?.other_base_cost || '0'}/ Additional: +$
                                        {state.dataDetailAttributes?.fee_ship || '0'}
                                    </b>
                                </PriceWorldwide>
                            </>
                        )}
                    </Col>
                </Row>
            </Card>
            <Card title='Description' style={{ marginBottom: 20 }}>
                <BoxDescription>
                    <div className='text-description' dangerouslySetInnerHTML={{ __html: dataSource?.description_special || '' }} />
                </BoxDescription>
            </Card>
            <Card title='Shipping information' style={{ marginBottom: 20 }}>
                <BoxDesign>
                    <ul>
                        <li>Made in {dataSource?.origin || ''}</li>
                        <li>Production time: {dataSource?.production_time || ''}</li>
                        <li>Shipping time: {dataSource?.shipping_time || ''}</li>
                        <li>Carrier: {renderTextCarrier(dataSource.carriers)}</li>
                    </ul>
                </BoxDesign>
            </Card>
            <Card title='Design & Fulfillment Guideline' style={{ marginBottom: 20 }}>
                <BoxDesign>
                    <ul>
                        <li>Files Type Supported: {dataSource?.file_type_supported || ''}</li>
                        <li>DPI: {dataSource?.dpl || ''}</li>
                        <li>
                            <Link target='_blank' to={{ pathname: `${!isEmpty(dataSource?.link_template) ? dataSource.link_template : ''}` }}>
                                Download Template Here
                            </Link>
                        </li>
                    </ul>
                </BoxDesign>
            </Card>
        </div>
    )
}

export default Index
