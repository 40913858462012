import React from "react";
import TrackingList from "../../components/trackings/TrackingList";

const Trackings = () => {
  return (
    <div>
      <TrackingList />
    </div>
  );
};

export default Trackings;
