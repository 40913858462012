import React from 'react'
import gql from '../../api/gql'
import {
    Tabs,
    Icon,
    Input,
    InputNumber,
    Row,
    Col,
    Card,
    Form,
    Table,
    Menu,
    Dropdown,
    Modal,
    Button,
    Skeleton,
    notification,
    Avatar,
} from 'antd'
import styled from 'styled-components'
import Wysiwyg from '../wysiwyg'
import CurrencyFormat from 'react-currency-format'
import UploadImages from '../UploadImages'
import { connect } from 'react-redux'
import { Woocommerce } from '../../helper/woocommerce'
import { Shopify } from '../../helper/shopify'
import WooCategories from '../../components/woocommerce/Categories'
import WooTags from '../../components/woocommerce/Tags'
import Collections from '../shopify/Collections'
import ShopifyTags from '../shopify/Tags'
import DraggableTabs from './DraggableTabs'
import { DraggableBodyRow } from './DraggableRow'
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import UploadDesigns from './UploadDesigns2'
import { Shopbase } from '../../helper/shopbase'
import ShopbaseCategories from '../shopbase/ShopbaseCategories'
import ShopbaseTags from '../shopbase/ShopbaseTags'
import { setProduct } from '../../actions'
import { CLOUDFRONT_URL } from '../../config'

const { TabPane } = Tabs
const { confirm } = Modal

const Container = styled.div`
    .delete-action {
        position: relative;
        font-size: 20px;
        color: #f2f2f2;
        height: calc(100% - 15px);
        cursor: pointer;
    }
    .icon-action-delete {
        position: absolute;
        right: 5px;
        width: 20px;
        background: rgba(0, 0, 0, 0.65);
        border-radius: 20px;
        height: 20px;
        top: 5px;
        svg {
            margin-top: 5px;
            width: 10px;
            height: 10px;
        }
    }
    .img-item {
        padding: 5px;
    }
    .image-product {
        display: inline-block;
        float: left;
        min-width: 33%;
    }
`

class PushStepTwo extends React.Component {
    state = {
        productTypes: [],
        current: 0,
        images: [],
        data: [],
        visible: false,
        printFiles: [],
        categories: [],
        tags: [],
        variations: [],
        showPrintfile: false,
        showButtonUpload: 1,
        loading: true,
        selectedVariants: [], // Check here to configure the default column
        editVariantsImage: false,
        editVariantsPrice: false,
        selectedImage: null,
        bulkPrices: {
            retail_cost: null,
            sale_cost: null,
        },
        productTypeIndex: 0,
        selectedVariations: [],
        type: null,
    }

    components = {
        body: {
            row: DraggableBodyRow,
        },
    }

    moveRow = (dragIndex, hoverIndex) => {
        const { selectedVariations } = this.state

        const dragRow = selectedVariations[dragIndex]

        this.setState(
            update(this.state, {
                selectedVariations: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                },
            }),
        )
    }

    // func handle proccessing when submit form clone product

    onSubmit = async (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { productTypes, variations, categories, tags, images } = this.state
                const { currentSite } = this.props
                let platform = null

                switch (currentSite.platform) {
                    case 'woocommerce':
                        platform = Woocommerce
                        break
                    case 'shopify':
                        platform = Shopify
                        break
                    case 'shopbase':
                        platform = Shopbase
                        break
                    default:
                        return
                }

                this.setState({ loading: true })
                values['description'] = values.description.replaceAll('{EMAIL_SUPPORT}', currentSite.email_support)
                console.log(values, 'values')
                platform
                    .pushProduct(currentSite.id, productTypes, {
                        ...values,
                        variants: variations,
                        images: images,
                        categories: categories,
                        tags: tags,
                    })
                    .then((product) => {
                        const { printFiles } = this.state
                        const { shopbaseProduct } = product
                        let originProduct = null
                        let productId = null

                        if (shopbaseProduct) {
                            originProduct = {
                                metafields: shopbaseProduct.product.metafields,
                                variants: shopbaseProduct.variants.map((variant) => {
                                    let obj = {}
                                    obj.position = variant.position
                                    obj.metafields = variant.metafields
                                    return obj
                                }),
                            }
                            productId = product.product.id
                        } else {
                            productId = product.id
                        }

                        const query = `mutation fetchProduct($print_files: [ProductPrintFiles!], $origin_product: OriginProduct){
                            fetchProductFromSite(
                                site_id: ${currentSite.id}, 
                                product_id:"${productId}", 
                                print_files: $print_files, 
                                origin_product: $origin_product
                            )
                        }`

                        gql.request(query, {
                            print_files: printFiles,
                            origin_product: originProduct,
                        })
                            .then((res) => {
                                this.setState({ loading: false })
                                notification['success']({
                                    message: 'Product created',
                                })
                                // this.props.history.push('/products')
                            })
                            .catch((err) => {
                                notification['error']({
                                    message: 'Push product error, please check your app connection or contact Administrator for assistance.',
                                })
                                //Need to delete the pushed product
                                platform.deleteProduct(currentSite.id, productId)
                                this.setState({ loading: false })
                            })
                    })
                    .catch((err) => {
                        notification['error']({
                            message: 'Push product error, please check your site connection or contact Administrator for assistance.', //JSON.stringify(_.get(err, 'response.data'))
                        })
                        this.setState({ loading: false })
                    })
            }
        })
    }

    componentDidMount() {
        if (this.props.match.path === '/products/clone/:product_id/:id') {
            this.setState({ type: 'clone' })
            this.loadSelectedProduct()
        } else {
            this.loadSelectedProductTypes()
        }
    }

    onChangeImage = (images) => {
        this.setState({ images })
    }

    onChangeUploadPrintFile = (value) => {
        this.setState({
            data: value,
        })
    }

    onChangeImages = (value) => {
        this.setState({
            images: value,
        })
    }

    loadSelectedProductTypes() {
        const id = JSON.parse('[' + this.props.match.params.id + ']')
        const query = `query productTypeByIds($ids: [Int!]!){
            productTypeByIds(ids: $ids){
                id
                title
                description
                sku
                images
                categories{
                    id, 
                    title
                    description
                }
                print_files{
                    mockup
                    title
                    width
                    height
                    note
                }
                product_type_variants{
                    id
                    product_type_id
                    sku
                    base_cost
                    sale_cost
                    retail_cost
                    attributes{
                        name, slug, option
                    }
                }
            }
        }`

        this.setState({ loading: true })
        gql.request(query, { ids: id }).then((res) => {
            this.setState({
                productTypes: res.productTypeByIds || [],
                variations: [].concat.apply(
                    [],
                    res.productTypeByIds.map((type) => type.product_type_variants),
                ),
                printFiles: res.productTypeByIds.map((type) => ({ product_type_id: type.id, print_files: type.print_files })),
                loading: false,
                selectedVariations: (res.productTypeByIds && res.productTypeByIds.length && res.productTypeByIds[0].product_type_variants) || [],
            })
        })
    }

    loadSelectedProduct() {
        const id = JSON.parse('[' + this.props.match.params.id + ']')
        const product_id = this.props.match.params.product_id
        const query = `query productByID{
            productByID(id: ${product_id}){
                id
                title
                description
                images
                variants{
                    id
                    price
                    regular_price
                    image
                    product_type_id
                    product_type{
                        title
                    }
                    print_files{
                        mockup
                        title
                        width
                        height
                        note
                        file_id
                        file {
                            id
                            source
                            key
                            file_name
                            url
                            sku
                        }
                    }
                    product_type_variant{
                        id
                        product_type_id
                        sku
                        attributes{
                            name
                            slug
                            option
                        }
                        retail_cost
                        base_cost
                        sale_cost
                    }
                }
            }
            productTypeByIds(ids: [${id}]){
                id
                sku
                product_type_variants{
                    id
                    sku
                    product_type_id
                    attributes{
                        name
                        option
                    }
                    retail_cost
                    base_cost
                    sale_cost
                }
                categories{
                    id, 
                    title
                    description
                }
                print_files{
                    mockup
                    title
                    width
                    height
                    note
                }
            }
        }`

        this.setState({ loading: true })

        gql.request(query).then((res) => {
            const product = res.productByID

            var arrImages = []

            if (Array.isArray(product.images) === true) {
                product.images.map((image) => {
                    arrImages.push({
                        url: image ? (image.indexOf('http') === 0 ? image : CLOUDFRONT_URL + '/' + image) : null,
                    })
                })
            }

            this.setState({
                title: product.title,
                images: arrImages,
            })

            const productVariants = product.variants
            const product_type_ids = [...new Set(product.variants.map((el) => el.product_type_id))]
            let initProductType = []

            product_type_ids.forEach((el) => {
                let product_type_variants = []
                let type = res.productTypeByIds.find((type) => type.id === el)

                type.product_type_variants.forEach((v) => {
                    let productv = productVariants.find((variant) => variant.product_type_variant.id === v.id)

                    if (typeof productv != 'undefined') {
                        product_type_variants.push({
                            ...v,
                            retail_cost: productv.regular_price ? productv.regular_price : v.retail_cost,
                            retail: productv.regular_price ? false : true,
                            sale_cost: productv.price ? productv.price : v.sale_cost,
                            sale: productv.price ? false : true,
                            image: {
                                // load image variant into array product_type_variants
                                url: productv.image
                                    ? productv.image.indexOf('http') === 0
                                        ? productv.image
                                        : CLOUDFRONT_URL + '/' + productv.image
                                    : null,
                            },
                        })
                    }
                })

                // assign printfile from product variant to product type
                if (productVariants.length > 0) {
                    type.print_files.filter((printFile) => {
                        productVariants.find((variant) => {
                            variant.print_files.filter((vPrintFile) => {
                                if (printFile.title === vPrintFile.title) {
                                    printFile.file_id = vPrintFile.file_id
                                }
                            })
                        })
                    })
                }

                const title = productVariants.find((p) => p.product_type_id === el)
                const item = {
                    id: el,
                    title: title.product_type.title,
                    description: product.description,
                    images: [],
                    categories: [],
                    print_files: type.print_files,
                    product_type_variants: product_type_variants,
                    sku: type.sku,
                }
                initProductType.push(item)
            })

            this.setState({
                productTypes: initProductType,
                variations: [].concat.apply(
                    [],
                    initProductType.map((type) => type.product_type_variants),
                ),
                printFiles: initProductType.map((type) => ({ product_type_id: type.id, print_files: type.print_files })),
                loading: false,
                selectedVariations: (initProductType && initProductType.length && initProductType[0].product_type_variants) || [],
            })
        })
    }

    selectedImage = (url) => {
        const { image } = this.state
        this.setState({
            image: [...image, url],
            visible: false,
            loading: false,
        })
    }

    handleUpload = (e) => {
        const { data } = this.state
        this.setState(
            {
                loading: false,
                data: [...data, e.key],
            },
            () => this.onChangeUploadPrintFile(this.state.data),
        )
    }

    handleCancel = () => this.setState({ previewVisible: false })

    showConfirmDeleteVariant = (variations) => {
        confirm({
            title: 'Are you sure to delete?',
            onOk: () => {
                const { selectedVariants, selectedVariations } = this.state
                this.setState({
                    selectedVariations: selectedVariations.filter((v) => !selectedVariants.includes(v.id)),
                    variations: variations.filter((v) => !selectedVariants.includes(v.id)),
                    selectedVariants: [],
                })
            },
        })
    }

    render() {
        const {
            images,
            productTypes,
            variations,
            selectedVariants,
            editVariantsImage,
            editVariantsPrice,
            selectedImage,
            bulkPrices,
            loading,
            selectedVariations,
            type,
        } = this.state

        const { currentSite } = this.props

        if (productTypes.length === 0 || !currentSite) return <Skeleton />

        const { getFieldDecorator } = this.props.form

        const columns = [
            {
                title: 'Image',
                dataIndex: 'id',
                key: 'image',
                render: (id, variant) => (
                    <Avatar
                        shape='square'
                        icon='picture'
                        size={50}
                        style={{ cursor: 'pointer' }}
                        src={variant.image ? variant.image.url : null}
                        onClick={(e) => {
                            e.preventDefault()
                            this.setState({ selectedVariants: [id], editVariantsImage: true })
                        }}
                    />
                ),
            },
            {
                title: 'Variant',
                dataIndex: 'sku',
                key: 'sku',
                render: (sku, { attributes }) => {
                    return (
                        <div>
                            {attributes.map((attr) => `${attr.name}: ${attr.option}`).join(', ')} <br />
                            <span style={{ color: '#999' }}>SKU: {sku}</span>
                        </div>
                    )
                },
            },
            {
                title: 'Base Cost',
                dataIndex: 'base_cost',
                key: 'base_cost',
                render: (base_cost) => `$${base_cost}`,
                align: 'center',
            },
            {
                title: 'Regular Price',
                dataIndex: 'retail_cost',
                key: 'retail_cost',
                render: (retail_cost, { id, base_cost, retail }) => (
                    <Form.Item validateStatus={retail ? 'error' : null} help={retail ? 'Default regular price' : null}>
                        <InputNumber
                            formatter={(value) => `$${value}`}
                            min={base_cost}
                            value={retail_cost}
                            onChange={(v) => {
                                const { variations } = this.state
                                variations.find((v) => v.id === id).retail_cost = v
                                this.setState({
                                    variations: variations,
                                })
                            }}
                        />
                    </Form.Item>
                ),
            },
            {
                title: 'Sale Price',
                dataIndex: 'sale_cost',
                key: 'sale_cost',
                render: (sale_cost, { base_cost, id, sale }) => (
                    <Form.Item validateStatus={sale ? 'error' : null} help={sale ? 'Default sale price' : null}>
                        <InputNumber
                            formatter={(value) => `$${value}`}
                            min={base_cost}
                            value={sale_cost}
                            onChange={(v) => {
                                const { variations } = this.state
                                variations.find((v) => v.id === id).sale_cost = v
                                this.setState({
                                    variations: variations,
                                })
                            }}
                        />
                    </Form.Item>
                ),
            },
            {
                title: 'Profit',
                dataIndex: 'id',
                key: 'profit',
                render: (id, { sale_cost, base_cost }) => (
                    <CurrencyFormat value={sale_cost - base_cost} displayType='text' prefix={'$'} decimalScale={2} />
                ),
            },
        ]
        return (
            <Container>
                <Form onSubmit={this.onSubmit}>
                    <Row gutter={20}>
                        <Col span={24} xl={16}>
                            <Card title='Detail'>
                                <Form.Item label='Title'>
                                    {getFieldDecorator('title', {
                                        initialValue: this.state.title ? this.state.title : productTypes[0].title,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input product title!',
                                            },
                                        ],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label='Decription'>
                                    {getFieldDecorator('description', {
                                        initialValue:
                                            type === 'clone'
                                                ? productTypes[0].description
                                                : productTypes.reduce(
                                                      (init, value, index) =>
                                                          (index === 0 ? value.categories[0].description : '') + init + value.description,
                                                      '',
                                                  ),
                                    })(<Wysiwyg />)}
                                </Form.Item>
                            </Card>
                            <DraggableTabs
                                style={{ marginTop: 20 }}
                                defaultActiveKey={`${productTypes[0].id}`}
                                onChange={(e) => {
                                    this.setState({
                                        selectedVariants: [],
                                        productTypeIndex: e,
                                        selectedVariations: variations.filter((v) => v.product_type_id == e),
                                    })
                                }}
                                onChangeTabs={([dragKey, hoverKey]) => {
                                    this.setState({
                                        productTypes: productTypes.map((type) =>
                                            type.id == dragKey
                                                ? productTypes.find((pt) => pt.id == hoverKey)
                                                : type.id == hoverKey
                                                ? productTypes.find((pt) => pt.id == dragKey)
                                                : type,
                                        ),
                                    })
                                }}
                                tabBarStyle={{ display: productTypes.length === 1 ? 'none' : 'block' }}>
                                {productTypes.map((productType, index) => (
                                    <TabPane tab={productType.title} key={productType.id}>
                                        <Card
                                            title='Variants'
                                            extra={
                                                <Dropdown
                                                    disabled={selectedVariants.length === 0}
                                                    overlay={
                                                        <Menu>
                                                            <Menu.Item
                                                                onClick={(e) => {
                                                                    const { selectedVariants, selectedVariations } = this.state
                                                                    if (selectedVariants.length === 1) {
                                                                        let v = selectedVariations.find((v) => v.id === selectedVariants[0])
                                                                        this.setState(
                                                                            {
                                                                                bulkPrices: {
                                                                                    retail_cost: v.retail_cost,
                                                                                    sale_cost: v.sale_cost,
                                                                                },
                                                                            },
                                                                            () => this.setState({ editVariantsPrice: true }),
                                                                        )
                                                                    } else {
                                                                        this.setState(
                                                                            {
                                                                                bulkPrices: {
                                                                                    retail_cost: null,
                                                                                    sale_cost: null,
                                                                                },
                                                                            },
                                                                            () => this.setState({ editVariantsPrice: true }),
                                                                        )
                                                                    }
                                                                }}>
                                                                <Icon type='dollar' /> Price
                                                            </Menu.Item>
                                                            <Menu.Item onClick={(e) => this.setState({ editVariantsImage: true })}>
                                                                <Icon type='picture' /> Image
                                                            </Menu.Item>

                                                            <Menu.Item onClick={() => this.showConfirmDeleteVariant(variations)}>
                                                                <Icon type='delete' style={{ color: 'red' }} /> Remove
                                                            </Menu.Item>
                                                        </Menu>
                                                    }>
                                                    <a className='ant-dropdown-link' href='/#' onClick={(e) => e.preventDefault()}>
                                                        <Icon type='edit' style={{ color: '#1890ff' }} /> Edit <Icon type='down' />
                                                    </a>
                                                </Dropdown>
                                            }>
                                            <DndProvider backend={HTML5Backend}>
                                                <Table
                                                    size='middle'
                                                    rowSelection={{
                                                        selectedRowKeys: selectedVariants,
                                                        onChange: (selectedVariants) => this.setState({ selectedVariants }),
                                                    }}
                                                    onRow={(record, index) => ({
                                                        index,
                                                        moveRow: this.moveRow,
                                                    })}
                                                    components={this.components}
                                                    dataSource={
                                                        selectedVariations && selectedVariations.length
                                                            ? selectedVariations
                                                            : variations.filter((v) => v.product_type_id == productType.id)
                                                    }
                                                    columns={columns}
                                                    rowKey='id'
                                                    pagination={false}
                                                />
                                            </DndProvider>
                                        </Card>
                                        <Card title='Print Areas' style={{ marginTop: 20 }}>
                                            <UploadDesigns
                                                printFiles={productType.print_files}
                                                onChange={(files) => {
                                                    const { printFiles } = this.state
                                                    printFiles.find((pfile) => pfile.product_type_id === productType.id).print_files = files
                                                    this.setState({ printFiles })
                                                }}
                                            />
                                        </Card>
                                    </TabPane>
                                ))}
                            </DraggableTabs>
                        </Col>
                        <Col span={24} xl={8}>
                            <Card title='Categories'>
                                {currentSite.platform === 'woocommerce' && (
                                    <WooCategories
                                        onChange={(categories) => {
                                            this.setState({ categories })
                                        }}
                                    />
                                )}
                                {currentSite.platform === 'shopify' && (
                                    <Collections
                                        onChange={(categories) => {
                                            this.setState({ categories })
                                        }}
                                    />
                                )}
                                {currentSite.platform === 'shopbase' && (
                                    <ShopbaseCategories
                                        onChange={(categories) => {
                                            this.setState({ categories })
                                        }}
                                    />
                                )}
                            </Card>
                            <Card title='Tags' style={{ marginTop: 20 }}>
                                {currentSite.platform === 'woocommerce' && (
                                    <WooTags
                                        onChange={(tags) => {
                                            this.setState({ tags })
                                        }}
                                    />
                                )}
                                {currentSite.platform === 'shopify' && (
                                    <ShopifyTags
                                        onChange={(tags) => {
                                            this.setState({ tags })
                                        }}
                                    />
                                )}
                                {currentSite.platform === 'shopbase' && (
                                    <ShopbaseTags
                                        onChange={(tags) => {
                                            this.setState({ tags })
                                        }}
                                    />
                                )}
                            </Card>
                            <Card title='Images' style={{ marginTop: 20 }}>
                                <Form.Item>
                                    <UploadImages
                                        value={images}
                                        onChange={this.onChangeImage}
                                        showButtonUpload={this.state.showButtonUpload}
                                        accept='.jpg, .png'
                                    />
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginTop: 20 }}>
                        <Button type='primary' htmlType='submit' loading={loading} size='large'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                <Modal
                    title='Select variant image'
                    visible={editVariantsImage}
                    onCancel={() => this.setState({ editVariantsImage: false })}
                    okButtonProps={{ disabled: selectedImage === null }}
                    onOk={() => {
                        const { selectedImage, selectedVariants, variations } = this.state
                        variations.forEach((variant) => {
                            if (selectedVariants.includes(variant.id)) {
                                variant.image = selectedImage
                            }
                        })
                        this.setState({ variations, selectedImage: null, editVariantsImage: false })
                    }}>
                    <UploadImages
                        selectMode={true}
                        value={images}
                        onChange={this.onChangeImage}
                        onSelect={(image) => this.setState({ selectedImage: image })}
                        showButtonUpload={this.state.showButtonUpload}
                        accept='.jpg, .png'
                    />
                </Modal>
                <Modal
                    title='Update variants price'
                    visible={editVariantsPrice}
                    onCancel={(e) => this.setState({ editVariantsPrice: false })}
                    onOk={(e) => {
                        e.preventDefault()
                        const { variations, selectedVariants } = this.state
                        if (bulkPrices.retail_cost) {
                            variations.forEach((v) => {
                                if (selectedVariants.includes(v.id)) {
                                    v.retail = false
                                    v.retail_cost = bulkPrices.retail_cost
                                }
                            })
                        }
                        if (bulkPrices.sale_cost) {
                            variations.forEach((v) => {
                                if (selectedVariants.includes(v.id)) {
                                    v.sale = false
                                    v.sale_cost = bulkPrices.sale_cost
                                }
                            })
                        }
                        this.setState({ variations, editVariantsPrice: false })
                    }}>
                    <Form.Item label='Regular Price'>
                        <InputNumber
                            formatter={(value) => `$${value}`}
                            value={bulkPrices.retail_cost}
                            onChange={(v) =>
                                this.setState({
                                    bulkPrices: { ...bulkPrices, retail_cost: v },
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item label='Sale Price'>
                        <InputNumber
                            formatter={(value) => `$${value}`}
                            value={bulkPrices.sale_cost}
                            onChange={(v) =>
                                this.setState({
                                    bulkPrices: { ...bulkPrices, sale_cost: v },
                                })
                            }
                        />
                    </Form.Item>
                </Modal>
            </Container>
        )
    }
}

export default connect(
    (state) => ({
        currentSite: state.app.currentSite,
        product_id: state.product,
    }),
    { setProduct },
)(Form.create({ name: 'selected-product-form' })(PushStepTwo))
