import { Avatar } from "antd";
import React, { useMemo } from "react";
import { CLOUDFRONT_URL, S3_BUCKET } from "../config";

const S3ImageNew = ({
  src,
  width,
  preview = true,
  style,
  onClick = () => {},
  className,
  fallback,
}) => {
  const imageUrl = useMemo(() => {
    const config = {
      bucket: S3_BUCKET,
      key: src,
    };
    if (width) {
      config.edits = {
        resize: {
          width: width,
          fit: "contain",
        },
      };
    }

    if (src?.indexOf("http") !== -1) {
      return src;
    } else {
      return `${CLOUDFRONT_URL}/${src}`;
    }
  }, [src, width]);

  return (
    <Avatar
      src={imageUrl}
      className={className}
      alt=""
      style={style}
      icon="picture"
      shape="square"
      size={width}
    />
  );
};

export default S3ImageNew;
