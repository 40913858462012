export const trackingstatus = [
  {
    name: "All",
    key: "all",
  },
  {
    name: "Unknown",
    key: "unknown",
  },
  {
    name: "Pre transit",
    key: "pre_transit",
  },
  {
    name: "In Transit",
    key: "in_transit",
  },
  {
    name: "Available For Pickup",
    key: "available_for_pickup",
  },
  {
    name: "Delivered",
    key: "delivered",
  },
  {
    name: "Out For Delivery",
    key: "out_for_delivery",
  },
  {
    name: "Return to Sender",
    key: "return_to_sender",
  },
  {
    name: "Failure",
    key: "failure",
  },
  {
    name: "Canceled",
    key: "canceled",
  },
  {
    name: "Error",
    key: "error",
  },
];
