export const adminMenu = [
    {
        title: 'Dashboard',
        router: '/dashboard',
        icon: 'dashboard',
        role: ['Administrator', 'Seller', 'Supplier'],
    },
    {
        title: 'Users',
        router: '/admin/users-manager',
        icon: 'user',
        role: ['Administrator'],
    },
    {
        title: 'Connect Sites',
        router: '/sites',
        icon: 'global',
        role: ['Administrator', 'Seller'],
    },
    {
        title: 'Products',
        router: '/products',
        icon: 'skin',
        role: ['Administrator', 'Seller'],
    },
    // {
    //   title: 'Product Types',
    //   router: '/product-types',
    //   icon: 'skin',
    //   role: ['Supplier']
    // },
    {
        title: 'Orders',
        // router: '/orders',
        icon: 'shopping-cart',
        // role: ["Administrator", "Seller", "Supplier", "Supporter"],
        key: 'orders',
        child: [
            {
                title: 'Internal Orders',
                router: '/orders',
                // icon: "shopping-cart",
            },
            {
                title: 'External Orders',
                router: '/orders/external',
                role: ['Seller'],
                // icon: "shopping-cart",
            },
            {
                title: 'External Orders',
                router: '/admin/orders/external',
                role: ['Administrator', 'Supporter'],
                // icon: "shopping-cart",
            },
            {
                title: 'Api/CSV Orders',
                router: '/orders/ApiOrders',
                // role: ["Seller", "Administrator", "Supporter"],
                // icon: "shopping-cart",
            },
            {
                title: 'Import History',
                router: '/orders/import-history',
                role: ['Seller'],
            },
            // {
            //   title: "Exported CSV",
            //   router: "/orders/export-orders",
            //   role: ["Administrator"],
            // },
            {
                title: 'Trackings',
                router: '/orders/trackings',
                // icon: "shopping-cart",
            },
            {
                title: 'Tranfers',
                router: '/orders/tranfers',
                role: ['Administrator'],
            },
        ],
    },
    {
        title: 'Download CSV',
        router: '/export-orders',
        icon: 'file',
        role: ['Administrator', 'Supplier'],
    },
    {
        title: 'Balance',
        router: '/balance',
        icon: 'dollar',
        role: ['Administrator', 'Seller', 'Supporter', 'Listing', 'Accounting'],
    },
    // {
    //   title: 'Productions',
    //   router: '/productions',
    //   icon: 'file-excel',
    //   role: ['Administrator']
    // },
    // {
    //   title: 'Transactions',
    //   router: '/transactions',
    //   icon: 'credit-card',
    //   role: ['Administrator', 'Supplier', 'Supporter', 'Listing', 'Accounting']
    // },
    // {
    //   title: 'Categories',
    //   router: '/admin/categories',
    //   icon: 'unordered-list',
    //   role: ['Supporter', 'Listing']
    // },
    // {
    //   title: 'Product Types',
    //   router: '/admin/product-types',
    //   icon: 'skin',
    //   role: ['Supporter', 'Listing']
    // },
    // {
    //   title: 'Carriers',
    //   router: '/admin/carriers',
    //   icon: 'car',
    //   role: ['Supporter', 'Listing']
    // },
    // {
    //   title: 'Seller Level',
    //   router: '/admin/seller-levels',
    //   icon: 'rise',
    //   role: ['Supporter', 'Listing']
    // },
    // {
    //   title: 'News',
    //   router: '/admin/news',
    //   icon: 'info-circle',
    //   role: ['Listing']
    // },
    {
        title: 'Guideline & Policy',
        router: '/guideline-policy',
        icon: 'hdd',
        role: ['Administrator', 'Seller', 'Supplier', 'Supporter', 'Listing', 'Accounting'],
    },
    {
        title: 'App Settings',
        icon: 'setting',
        role: ['Administrator'],
        key: 'admin',
        child: [
            {
                title: 'Categories',
                router: '/admin/categories',
                icon: 'unordered-list',
            },
            {
                title: 'Product Types',
                router: '/admin/product-types',
                icon: 'skin',
            },
            {
                title: 'Attributes',
                router: '/admin/attributes',
                icon: 'reconciliation',
            },
            {
                title: 'Attribute Terms',
                router: '/admin/attribute-terms',
                icon: 'schedule',
            },
            {
                title: 'Carriers',
                router: '/admin/carriers',
                icon: 'car',
            },
            {
                title: 'Tracking',
                router: '/admin/tracking',
                icon: 'file-search',
            },
            {
                title: 'Discount',
                router: '/admin/discount',
                icon: 'container',
            },
            // {
            //   title: 'Seller Levels',
            //   router: '/admin/seller-levels',
            //   icon: 'rise'
            // },
            // {
            //   title: 'Mail Templates',
            //   router: '/admin/mail-templates',
            //   icon: 'inbox'
            // },
            // {
            //   title: 'News',
            //   router: '/admin/news',
            //   icon: 'info-circle'
            // }
        ],
    },
    {
        title: 'Catalog',
        router: '/catalog',
        icon: 'snippets',
        role: ['Seller', 'isCheckSpecial'],
    },

    // {
    //   title: 'Sites',
    //   router: '/sites',
    //   icon: 'global'
    // },
    // {
    //   title: 'Products',
    //   router: '/products',
    //   icon: 'appstore'
    // },
    // {
    //   title: 'Orders',
    //   router: '/orders',
    //   icon: 'ordered-list'
    // },
    // {
    //   title: "Customers",
    //   router: '/customers',
    //   icon: 'user'
    // },
    // {
    //   title: "Members",
    //   router: '/members',
    //   icon: 'smile'
    // }
]
