import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import styled from "styled-components";
import { Layout, Icon, Drawer, Row, Col, Spin, Input } from "antd";
import { withWindowSizeListener } from "react-window-size-listener";
import LeftMenu from "../components/LeftMenu";
import { Helmet } from "react-helmet";
import UserMenu from "../components/UserMenu";
import SiteSelector from "../components/sites/SiteSelector";
import { history } from "../history";
import { Link } from "react-router-dom";
const { Search } = Input;

const { Header, Sider, Content } = Layout;
const Container = styled.div`
  min-height: 100vh;
  display: flex;
`;
const LeftMenuToggle = styled.span`
  display: inline-block;
  height: 44px;
  width: 44px;
  line-height: 44px;
  margin-right: 0px;
  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
  i {
    font-size: 18px;
    width: 100%;
    margin-top: 2px;
    line-height: 44px;
  }
`;

const MOBILEWIDTH = 991;

class defaultLayout extends React.Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { collapsed } = this.state;
    const {
      windowSize,
      appInit,
      title,
      toolbar,
      role,
      currentUser,
      site_selector,
      flatform,
    } = this.props;
    if (!appInit)
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Spin /> <p>Loading...</p>
          </div>
        </div>
      );
    if (role) {
      if (!currentUser) {
        history.push("/login");
      }
      let canAccess = false;
      role.forEach((r) => {
        if (currentUser?.roles?.map((role) => role.name).includes(r)) {
          canAccess = true;
        }
      });
      if (!canAccess) {
        return <div>Access denied</div>;
      }
    }
    return (
      <Container>
        {this.props.title && (
          <Helmet>
            <title>{this.props.title} - PODGASUS</title>
          </Helmet>
        )}
        <Layout>
          <div
            style={{
              width: "100%",
              position: "relative",
              zIndex: 2,
              overflow: "hidden",
              display: "grid",
              gridTemplateRows: "55px 50px auto",
            }}
          >
            <Header
              className="base_bg"
              style={{
                color: "#FFF",
                zIndex: 100,
                padding: 0,
                display: "flex",
                justifyItems: "center",
              }}
            >
              <Row
                type="flex"
                style={{ width: "calc(100%)", paddingRight: 15 }}
              >
                <Col
                  span={16}
                  lg={20}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Link to="/dashboard">
                    <img
                      src="/logo.png"
                      alt=""
                      style={{ padding: "5px 15px", width: 110 }}
                    />
                  </Link>
                  <Search
                    className="pod-search-box"
                    style={{ maxWidth: 250, marginLeft: 15 }}
                    placeholder="Search..."
                  />
                </Col>
                <Col span={8} lg={4}>
                  <UserMenu />
                </Col>
              </Row>
            </Header>
            <Row
              type="flex"
              style={{
                alignItems: "center",
                zIndex: 99,
                borderTop: "1px solid #e8ecee",
                boxShadow: "0 1px 6px 0 rgba(21,27,38,.15)",
                backgroundColor: "#FFF",
                padding: "0 20px 0 5px",
              }}
            >
              <Col span={16} md={12} style={{ display: "flex" }}>
                <LeftMenuToggle>
                  <Icon
                    className="trigger"
                    type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                    onClick={this.toggle}
                  />
                </LeftMenuToggle>
                <h2 style={{ fontSize: 16, margin: 0, lineHeight: "44px" }}>
                  {title}{" "}
                </h2>
              </Col>
              <Col span={8} md={12} style={{ textAlign: "right" }}>
                {site_selector != false && <SiteSelector flatform={flatform} />}
              </Col>
            </Row>
            <div
              style={{
                display: `${windowSize.windowWidth > MOBILEWIDTH ? "grid" : "unset"
                  }`,
                gridTemplateColumns: `${collapsed ? "80px" : "220px"} auto`,
              }}
            >
              <div>
                {windowSize.windowWidth > MOBILEWIDTH ? (
                  <Sider
                    width={220}
                    style={{ height: "100%" }}
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                  >
                    <LeftMenu />
                  </Sider>
                ) : (
                  <Drawer
                    headerStyle={{
                      padding: "7px 24px",
                      borderColor: "rgba(255, 255, 255, 0.25)",
                      height: "100%",
                    }}
                    drawerStyle={{
                      minHeight: "100%",
                      backgroundColor: "#151b26",
                    }}
                    bodyStyle={{
                      padding: 0,
                    }}
                    visible={collapsed}
                    placement="left"
                    closable={false}
                    maskClosable={true}
                    onClose={(e) => {
                      this.setState({
                        collapsed: false,
                      });
                    }}
                  >
                    <LeftMenu />
                  </Drawer>
                )}
              </div>
              <Content
                style={{
                  boxSizing: "border-box",
                  maxWidth: "100vw",
                  overflow: "hidden",
                  color: "#151b26",
                  /*minHeight: 'calc(100% - 117px)', maxHeight: 'calc(100% - 117px)',*/ borderTop:
                    "1px solid rgb(227, 233, 240)",
                  borderBottom: "1px solid rgb(227, 233, 240)",
                  display: "grid",
                  gridTemplateRows: "auto 55px",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    // padding: "15px",
                    // margin:"15px",
                    // backgroundColor:"#fff"
                    padding: "0px 15px 15px 15px",
                  }}
                >
                  <Route {...this.props} />
                </div>
                <div>
                  <Layout.Footer
                    style={{
                      height: 50,
                      padding: "15px 15px",
                      textAlign: "left",
                    }}
                  >
                    PODGASUS ©2021.
                  </Layout.Footer>
                </div>
              </Content>
            </div>
          </div>
        </Layout>
      </Container>
    );
  }
}

export default connect((state) => {
  return {
    appInit: state.app.appInit,
    currentUser: state.app.currentUser,
  };
})(withWindowSizeListener(defaultLayout));
