import { Tabs } from "antd";
import React from "react";
import ApiKey from "../sites/ApiKey";

const SellerSettings = () => {
  return (
    <div>
      <Tabs>
        <Tabs.TabPane tab="Generate Api key" key="apikey">
          <ApiKey />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default SellerSettings;
