import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { message, Table, Button, Popconfirm, Icon, Tag, Avatar, Col, Row, Select, Input, notification, PageHeader } from 'antd'
import _ from 'lodash'
import gql from '../../api/gql'
import { history } from '../../history'
import moment from 'moment'

const { Search } = Input
const { Option } = Select
export default class a extends Component {
    state = {
        loading: true,
        users: [],
        showModal: false,
        user: {},
        label: '',
        pagination: {
            results: 10,
            pageSize: 25,
            total: 0,
        },
        status: 'active',
        filter: {
            search: '',
        },
        roles: [],
        page: 1,
    }

    componentDidMount() {
        this.getListUser()
        this.getRole()
    }
    getRole() {
        const query = `query roles{roles{id name}}`
        gql.request(query, {}).then((res) => {
            this.setState({
                roles: res.roles ? res.roles.filter((r) => r.name !== 'Authenticated') : [],
                loading: false,
            })
        })
    }
    getListUser() {
        const { pagination, page } = this.state
        this.setState({ loading: true })
        const query = `query UsersList($filter: UserFilter!){
        users(filter: $filter){
          count
          hits{
            id 
            last_name
            first_name
            email
            pass
            phone
            address
            roles{
              id
              name
            }
            avatar{
              id
              url
            }
            status
            totalOrder
            created_at
          }
        }
      }`
        gql.request(query, {
            filter: {
                offset: (page - 1) * pagination.pageSize,
                limit: pagination.pageSize,
                role: null,
                ...this.state.filter,
            },
        })
            .then((res) => {
                this.setState({
                    users: res.users.hits || [],
                    loading: false,
                    pagination: {
                        ...pagination,
                        total: res.users.count,
                        results: res.users.count,
                    },
                })
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
                this.setState({ loading: false })
            })
    }

    deleteUser = (id) => {
        this.setState({ loading: true })
        const mutation = `
        mutation deleteUser($id: ID!){
          deleteUser(id: $id)
        }`
        gql.request(mutation, { id })
            .then((res) => {
                this.setState({ loading: false })
                if (res.deleteUser) {
                    message['success']('Delete success!')
                    this.getListUser()
                } else {
                    message['error'](_.get(res.errors, '[0].message'))
                }
            })
            .catch((err) => {
                this.setState({ loading: false })
                message['error'](_.get(err, '[0].message'))
            })
    }

    onChangeTable = async (e) => {
        await this.setState({ page: e.current })
        this.getListUser()
    }
    onChangeSelect = async (value) => {
        await this.setState({
            status: JSON.parse(value),
            filter: {
                ...this.state.filter,
                status: JSON.parse(value),
            },
        })
        this.getListUser()
    }
    onChangeRole = async (value) => {
        if (value !== 'all') {
            await this.setState({
                filter: {
                    ...this.state.filter,
                    role: value,
                },
            })
        } else {
            delete this.state.filter.role
            await this.setState({
                filter: this.state.filter,
            })
        }
        this.getListUser()
    }
    onShow = (user, label) => this.setState({ showModal: true, user, label })
    onClose = (showModal) => this.setState({ showModal })

    render() {
        const { currentUser } = this.props
        const { users, roles, filter } = this.state
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: 'Name',
                dataIndex: 'fullname',
                key: 'fullname',
                render: (id, { first_name, last_name, avatar }) => (
                    <div>
                        <Avatar src={avatar ? avatar.url : null} style={{ backgroundColor: '#41c8f5' }}>
                            {first_name.charAt(0)}
                            {last_name.charAt(0)}
                        </Avatar>{' '}
                        {first_name} {last_name}
                    </div>
                ),
            },
            { title: 'Email', dataIndex: 'email', key: 'email' },
            { title: 'Phone', dataIndex: 'phone', key: 'phone' },
            { title: 'Address', dataIndex: 'address', key: 'address' },
            // { title: "Total Order", dataIndex: "totalOrder", key: "totalOrder", align: "right" },
            {
                title: 'Roles',
                dataIndex: 'roles',
                key: 'roles',
                render: (roles) => (
                    <span>
                        {roles.map((role) => (
                            <Tag color='blue' key={role.id}>
                                {role.name}
                            </Tag>
                        ))}
                    </span>
                ),
            },
            {
                title: 'Status',
                key: 'status',
                width: 100,
                render: (text, record) =>
                    currentUser.id === record.id ? null : (
                        <Popconfirm
                            title={record.status ? 'Are you sure block this user?' : 'Are you sure unblock this user?'}
                            onConfirm={() => {
                                const mutation = `mutation blockUser($id: ID!, $action: Boolean!){
            blockUser(id: $id, action: $action){
              id
              status
            }
          }`
                                this.setState({ loading: true })
                                gql.request(mutation, { id: record.id, action: record.status })
                                    .then((res) => {
                                        let users = this.state.users.map((el) => {
                                            if (el.id === res.blockUser.id) {
                                                el.status = res.blockUser.status
                                            }
                                            return el
                                        })
                                        this.setState({ loading: false, users: users })
                                        notification['success']({
                                            message: res.blockUser.status ? 'User unblocked' : 'User blocked',
                                        })
                                    })
                                    .catch((err) => {
                                        notification['error']({
                                            message: 'ERROR',
                                            description: _.get(err, '[0].message'),
                                        })
                                        this.setState({ loading: false })
                                    })
                            }}>
                            <Button size='small'>{record.status ? 'Block' : 'UnBlock'}</Button>
                        </Popconfirm>
                    ),
            },
            {
                title: 'Created Date',
                width: 150,
                dataIndex: 'created_at',
                render: (created_at) => moment(created_at).format('MMM DD, YYYY'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 120,
                align: 'right',
                render: (text, record) => (
                    <span>
                        {
                            //Hide Hoa Function
                            currentUser.email !== 'knocktheme@gmail.com' ? (
                                <Button style={{ marginRight: 10 }} size='small'>
                                    <Link to={`/admin/users-manager/edit/${record.id}`}>
                                        <Icon type='edit' style={{ color: '#1890ff' }} />
                                    </Link>
                                </Button>
                            ) : (
                                ''
                            )
                        }
                        <Popconfirm title='Are you sure delete this user?' onConfirm={() => this.deleteUser(record.id)} okText='Yes' cancelText='No'>
                            <Button size='small'>
                                <Icon type='delete' style={{ color: 'red' }} />
                            </Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ]
        return (
            <div>
                <PageHeader
                    style={{ padding: '16px 0px' }}
                    title='Users'
                    onBack={() => {
                        history.goBack()
                    }}
                    extra={
                        //Hide Hoa Function
                        currentUser.email !== 'knocktheme@gmail.com' ? (
                            <Button type='primary' style={{ textAlign: 'right', marginBottom: '15px' }}>
                                <Link to='/admin/user-form'>
                                    <Icon type='user-add' /> New User
                                </Link>
                            </Button>
                        ) : (
                            ''
                        )
                    }
                />
                <Row>
                    <Col md={18}>
                        <Select defaultValue='true' onChange={this.onChangeSelect} style={{ width: 250, marginRight: '10px', marginBottom: 10 }}>
                            <Select.Option value='true'>Active</Select.Option>
                            <Select.Option value='false'>Inactive</Select.Option>
                        </Select>
                        <Select
                            showSearch
                            optionFilterProp='children'
                            placeholder='Fillter by roles'
                            onChange={this.onChangeRole}
                            style={{ width: 250, marginRight: '10px', marginBottom: 10 }}>
                            <Option value='all'>All roles</Option>
                            {roles.map((role) => (
                                <Option value={role.name} key={role.id}>
                                    {role.name}
                                </Option>
                            ))}
                        </Select>
                        <Search
                            style={{ width: 250, marginBottom: 10 }}
                            placeholder='Search text'
                            onSearch={(value) => this.setState({ filter: { ...filter, search: value } }, () => this.getListUser())}
                            enterButton
                        />
                        {/* <Input style={{ width: 180 }} placeholder="Search" value={filter.search} onChange={e => this.setState({ filter: { ...filter, search: e.target.value } }, () => this.getListUser())} /> */}
                    </Col>
                    {/* <Col md={6} style={{ textAlign: "right" }}>
           
          </Col> */}
                </Row>
                <Table
                    loading={this.state.loading}
                    columns={columns}
                    dataSource={users}
                    rowKey='id'
                    pagination={this.state.pagination}
                    onChange={this.onChangeTable}
                />
            </div>
        )
    }
}
