import gql from 'graphql-tag'

export const TRACKINGS = gql`
    query trackings($filter: TrackingFilterInput!) {
        trackingList(filter: $filter) {
            count
            aggs {
                key
                doc_count
            }
            hits {
                id
                order_id
                order {
                    id
                    origin_id
                    origin_number
                    supplier {
                        id
                        first_name
                        last_name
                    }
                    customer {
                        id
                        first_name
                        last_name
                        email
                    }
                    shipping {
                        first_name
                        last_name
                        address1
                        address2
                        city
                        state
                        state_code
                        zip
                        country
                        country_code
                        phone
                    }
                }
                carrier_code
                carrier {
                    url
                    id
                }

                tracking_code
                status
                submitted
                created_at
                updated_at
            }
        }
    }
`
export const AGGS = gql`
    query trackings($filter: TrackingFilter) {
        trackings(filter: $filter) {
            count
            aggs
            hits {
                id
            }
        }
    }
`
