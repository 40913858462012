import React from 'react'
import { Table, Input, InputNumber, Button, Icon, Progress, Radio, Popconfirm, TreeSelect, Spin } from 'antd'
import { DndProvider, DragSource, DropTarget } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import styled from 'styled-components'
import Upload from '../Upload'
import S3Image from '../S3Image'
import _ from 'lodash'
import rest from '../../api/rest'

const SortTable = styled.div`
    table tr.drop-over-downward td {
        border-bottom: 2px dashed #1890ff;
    }
    table tr.drop-over-upward td {
        border-top: 2px dashed #1890ff;
    }
`

const UploadPreview = styled.div`
    width: 68px;
    height: 50px;
    border: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 100%;
        max-height: 100%;
    }
`

const SelectAnyPrint = styled.div`
    display: inline-block;
    width: 100%;
    position: relative;
    .ant-spin {
        position: absolute;
        right: 10px;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
    }
`

let dragingIndex = -1

class BodyRow extends React.Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props
        const style = { ...restProps.style, cursor: 'move' }

        let { className } = restProps
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward'
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward'
            }
        }

        return connectDragSource(connectDropTarget(<tr {...restProps} className={className} style={style} />))
    }
}

const rowSource = {
    beginDrag(props) {
        dragingIndex = props.index
        return {
            index: props.index,
        }
    },
}

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index
        const hoverIndex = props.index

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex)

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex
    },
}

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource('row', rowSource, (connect) => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
)

export default class ProductTypePrintFiles extends React.Component {
    state = {
        data: this.props.value,
        editing: false,
        percent: [],
        uploading: [],
        value2: undefined,
        page: 2,
        productTypeAnyPrints: [],
        isLoadMoreData: true,
        isLoading: false,
    }

    componentDidMount() {
        let filter = {
            page: 1,
            limit: 200,
        }

        rest.getProductTypeAnyPrint(filter).then((response) => this.setState({ productTypeAnyPrints: response.data, page: 2 }))
    }

    components = {
        body: {
            row: DragableBodyRow,
        },
    }

    moveRow = (dragIndex, hoverIndex) => {
        const { data } = this.state
        const dragRow = data[dragIndex]

        this.setState(
            update(this.state, {
                data: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                },
            }),
            () => {
                this.props.onChange(this.state.data)
            },
        )
    }

    onChangeDataTre = (value, label, data, index) => {
        if (value === 'load-data') {
            this.setState({ isLoading: true })
            let filter = {
                page: this.state.page,
                limit: 200,
            }

            rest.getProductTypeAnyPrint(filter).then((response) => {
                if (response.data.length !== 0 && response.data <= 200) {
                    let isLoadMoreData = response.data < 200 ? false : true
                    let newData = this.state.productTypeAnyPrints.concat([...response.data])

                    this.setState({ productTypeAnyPrints: newData, page: ++filter.page, isLoadMoreData, isLoading: false })
                } else {
                    this.setState({ isLoadMoreData: false, isLoading: false })
                }
            })
        } else {
            data[index] = {
                ...data[index],
                any_print: {
                    anyprint_id: value,
                    anyprint_name: label[0],
                },
            }
            this.setState({ data })
        }
    }

    renderTreeData = (dataSource, disabled) => {
        if (Array.isArray(dataSource) && dataSource.length > 0) {
            const newData = []
            dataSource.map((x) => {
                let item = {
                    value: disabled ? `productTypes-${x?.id}` : `${x?.id}`,
                    title: disabled ? x?.title : x?.name,
                    disabled,
                }
                
                if (Array.isArray(x?.print_areas) && x?.print_areas?.length > 0) {
                    const children = this.renderTreeData(x?.print_areas, false)
                    item['children'] = [...children]
                }

                newData.push(item)
            })
            
            if (disabled && this.state.isLoadMoreData) {
                let item = {
                    value: 'load-data',
                    title: 'Click load data',
                }
                newData.push(item)
            }

            return newData
        }
    }

    render() {
        const { data, uploading, percent, productTypeAnyPrints, isLoading } = this.state

        const columns = [
            {
                title: 'Mockup',
                dataIndex: 'mockup',
                key: 'mockup',
                width: 100,
                render: (mockup, file, index) => {
                    return (
                        <Upload
                            multiple={false}
                            fileList={false}
                            onProcess={(e) => {
                                const { uploading, percent } = this.state
                                uploading[index] = true
                                percent[index] = Math.round((e.loaded / e.total) * 100)
                                this.setState({
                                    uploading,
                                    percent,
                                })
                            }}
                            onUpload={(file) => {
                                const { data, uploading } = this.state
                                data[index].mockup = file.key
                                uploading[index] = false
                                this.setState({ data, uploading }, () => this.props.onChange(data))
                            }}>
                            <UploadPreview>
                                {uploading[index] ? (
                                    <Progress type='circle' percent={percent[index]} width={40} strokeColor='#1890ff' />
                                ) : mockup ? (
                                    <S3Image src={mockup} />
                                ) : (
                                    <Icon type='plus' />
                                )}
                            </UploadPreview>
                        </Upload>
                    )
                },
            },
            {
                title: 'Name',
                dataIndex: 'title',
                key: 'name',
                width: 200,
                render: (name, file, index) => {
                    const isDisable = this.state.data[index]?.id ? true : false
                    return  (
                        <Input
                            placeholder='Name'
                            value={name}
                            disabled={isDisable}
                            onChange={(e) => {
                                const { data } = this.state
                                data[index].title = e.target.value
                                this.setState({ data }, () => this.props.onChange(data))
                            }}
                        />
                    )
                },
            },
            {
                title: 'Size',
                dataIndex: '',
                key: 'size',
                width: 200,
                render: (_, { width, height }, index) => (
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'auto 20px auto',
                            alignItems: 'center',
                        }}>
                        <InputNumber
                            placeholder='Width'
                            style={{ width: '100%' }}
                            value={width}
                            onChange={(v) => {
                                const { data } = this.state
                                data[index].width = v
                                this.setState({ data }, () => this.props.onChange(data))
                            }}
                        />
                        <div style={{ textAlign: 'center' }}>x</div>
                        <InputNumber
                            placeholder='Height'
                            style={{ width: '100%' }}
                            value={height}
                            onChange={(v) => {
                                const { data } = this.state
                                data[index].height = v
                                this.setState({ data }, () => this.props.onChange(data))
                            }}
                        />
                    </div>
                ),
            },
            {
                title: 'Note',
                dataIndex: 'note',
                key: 'note',
                width: 100,
                render: (note, file, index) => (
                    <Input
                        placeholder='Note'
                        value={note}
                        onChange={(e) => {
                            const { data } = this.state
                            data[index].note = e.target.value
                            this.setState({ data }, () => this.props.onChange(data))
                        }}
                    />
                ),
            },
        ]
        if (this.props.personalized) {
            let renderDataTree = this.renderTreeData(productTypeAnyPrints, true)
            columns.push(
                {
                    title: 'Default',
                    dataIndex: 'default',
                    key: 'default',
                    align: 'center',
                    width: 100,
                    render: (abc, record, index) => (
                        <Radio
                            checked={abc ? abc : false}
                            onClick={(e) => {
                                if (e.target.checked) {
                                    let newData = data.map((item, i) => {
                                        if (i === index) {
                                            return { ...item, default: true }
                                        } else {
                                            return { ...item, default: false }
                                        }
                                    })
                                    this.setState({ data: newData }, () => this.props.onChange(newData))
                                }
                            }}
                        />
                    ),
                },
                {
                    title: 'Any Print',
                    dataIndex: 'Anyprint',
                    key: 'Anyprint',
                    width: 300,
                    render: (_, record, index) => (
                        <SelectAnyPrint>
                            <TreeSelect
                                dropdownClassName='Anyprint'
                                treeData={renderDataTree}
                                value={
                                    (renderDataTree !== null && renderDataTree?.length > 0) || isLoading
                                        ? record?.any_print?.anyprint_id || null
                                        : null
                                }
                                placeholder='Select a Any Print'
                                treeLine={true}
                                disabled={(renderDataTree !== null && renderDataTree?.length > 0) || isLoading ? false : true}
                                style={{ width: '100%' }}
                                allowClear
                                treeDefaultExpandedKeys={[record?.any_print?.anyprint_id || null]}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                onChange={(value, label) => this.onChangeDataTre(value, label, this.state.data, index)}
                            />
                            {!((renderDataTree !== null && renderDataTree?.length > 0) || isLoading) && <Spin size='small' />}
                        </SelectAnyPrint>
                    ),
                },
            )
        }
        columns.push({
            title: 'action',
            align: 'center',
            fixed: 'right',
            width: 100,
            render: (_, record, index) => (
                <Popconfirm
                    title='Are you sure to delete?'
                    onConfirm={() => {
                        let newData = this.state.data.filter((item) => item !== record)
                        if (record.default) {
                            if (newData.length !== 0) {
                                newData[0].default = true
                            }
                        }
                        this.setState({ data: newData }, () => this.props.onChange(newData))
                    }}>
                    <Icon type='delete' style={{ color: 'red' }} />
                </Popconfirm>
            ),
        })

        const tableWidth = _.sum(columns.map((c) => c.width))
        return (
            <SortTable>
                <DndProvider backend={HTML5Backend}>
                    <Table
                        columns={columns}
                        dataSource={this.state.data}
                        components={this.components}
                        scroll={{ x: tableWidth }}
                        onRow={(record, index) => ({
                            index,
                            moveRow: this.moveRow,
                        })}
                        pagination={false}
                        footer={() => (
                            <Button
                                type='primary'
                                icon='plus'
                                style={{ alignContent: 'end' }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    data.push({
                                        title: '',
                                        width: null,
                                        height: null,
                                        note: '',
                                        default: data.length > 0 ? false : true,
                                    })
                                    this.setState({ data }, () => this.props.onChange(data))
                                }}>
                                Add
                            </Button>
                        )}
                        rowKey={(row, index) => index}
                    />
                </DndProvider>
            </SortTable>
        )
    }
}
