import React from 'react'
import gql from '../../api/gql'
import { message, Table, Select, Input, Modal, Row, Col } from 'antd'
import _ from 'lodash'
import DateRange from '../DateRange'
import moment from 'moment'
import styled from 'styled-components'
import { Button } from 'antd/lib/radio'


const Container = styled.div`
.filter{
  display: flex;
}
`
class Logs extends React.Component {
  state = {
    filter: {
      search: null,
      type: null,
      from: null,
      to: null,
      limit: 25,
      offset: 0
    },
    page: 1,
    logs: [],
    total: 0,
    loading: false,
    detail: false,
    selectedLog: null,
  }
  componentDidMount() {
    this.loadActivityLogs()
  }
  loadActivityLogs() {
    this.setState({ loading: true })
    const query = `query logs($filter: LogFilter){
      logs(filter: $filter){
        count
        hits{
          id
          type
          message
          created_at
        }
      }
    }`
    gql.request(query, { filter: this.state.filter })
      .then(res => {
        console.log("res", res)
        this.setState({ loading: false, total: res.logs.count, logs: res.logs.hits || [] })
      })
      .catch(err => {
        message["error"](_.get(err, "[0].message"))
      })
  }
  onChangeDate = (range, callback = () => { }) => {
    if (range.from !== null && range.to !== null) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            from: moment(range.from).format("YYYY-MM-DD[T]00:00:00[Z]"),
            to: moment(range.to).format("YYYY-MM-DD[T]00:00:00[Z]"),
          },
        },
        callback
      );
    } else {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            from: null,
            to: null,
          },
        },
        callback
      );
    }
  };
  onChangeType = (type, callback = () => { }) => {
    this.setState({ filter: { ...this.state.filter, type: type } }, callback)
  }
  render() {
    const { logs, filter, loading, total, page } = this.state
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: "20%"
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: '30%',
        render: (type) => type.slice(type.indexOf(".") + 1, type.length)
      },
      {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'date',
        width: '20%',
        render: (created_at) => <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{moment(created_at).format('MMM DD, YYYY')}</p>
      },
      {
        key: 'detail',
        width: '10%',
        render: (log) =>
          <Button onClick={() => this.setState({ detail: true, selectedLog: log })}>Detail</Button>
      }
    ]
    const types = [
      "order.PushElastic",
      "Order site_id",
      "order.updated",
      "Order Created",
      "order.on-hold",
      "duplicate order",
      "order.processing",
      "Order convert error",
      "Order Paid",
      "Shopbase Order Paid",
      "Order Updated",
      "product.updated",
      "order.paid",
      "order.ImportTracking",
      "woo token",
      "ImportTracking",
      "order.created",
    ]
    const pagination = {
      pageSize: filter.limit,
      total: total,
      current: page,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState({
          page: page,
          filter: {
            ...filter,
            offset: (page - 1) * pageSize
          }
        }, () => this.loadActivityLogs())
      }
    }
    return (
      <Container>
        <div className="filter">
          <DateRange
            style={{ marginBottom: 10 }}
            onChange={(dateRange) =>
              this.onChangeDate(dateRange, this.loadActivityLogs)
            }
          />
          <Select onChange={value => this.onChangeType(value, this.loadActivityLogs)} placeholder="Types" style={{ marginLeft: 10, marginBottom: 10, width: '100%', maxWidth: 250 }}>
            <Select.Option key="all" value="all">All types</Select.Option>
            {types.map(el => <Select.Option key={el} value={el}>{el}</Select.Option>)}
          </Select>
          <div
            style={{
              maxWidth: 300,
              width: "100%",
              marginLeft: 10,
              marginBottom: 10,
            }}
            >
        <Input.Search
              placeholder="search..."
              value={filter.search}
              onChange={(e) => {
                this.setState({ filter: { ...this.state.filter, search: e.target.value } })
              }}
              onSearch={(e) => {
                this.loadActivityLogs()
              }}
            />
          </div>
        </div>
        <Table pagination={pagination} loading={loading} columns={columns} dataSource={logs} />
        <Modal
          visible={this.state.detail}
          width={700}
          onCancel={() => this.setState({ detail: false })}
          footer={false}
        >
          {this.state.selectedLog && (
            <p>{this.state.selectedLog.message}</p>
          )}
        </Modal>
      </Container>
    )
  }
}

export default Logs