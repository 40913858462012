import React from "react";
import { connect } from "react-redux";
import ApiOrderList from "../../components/orders/ApiOrderList";
import SupplierApiOrder from "../../components/supplier/SupplierApiOrder";

const ApiOrder = (props) => {
  const { currentUser } = props;
  const isSupplier = currentUser.roles.find((role) =>
    ["Supplier"].includes(role.name)
  );
  return <div>{isSupplier ? <SupplierApiOrder /> : <ApiOrderList />}</div>;
};
export default connect((state) => ({
  currentUser: state.app.currentUser,
}))(ApiOrder);
