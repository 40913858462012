import React, { useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { LIST_GUIDELINE_POLICY } from '../../graphql/query/guideline-policy'

import GuidelinePolicyList from '../../components/admin/guideline-policy'

const GuidelinePolicyPage = (props) => {
    const { currentUser } = props
    const [filter] = useState({
        type: null,
    })

    const { loading, error, data } = useQuery(LIST_GUIDELINE_POLICY, {
        variables: {
            filter,
        },
    })

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    return (
        <GuidelinePolicyList dataSource={data?.policies?.hits || []} count={data?.policies?.count || 0} filter={filter} currentUser={currentUser} />
    )
}

export default GuidelinePolicyPage
