import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router'
import { CATALOG_PRODUCT_TYPES_ID } from '../../../graphql/query/productTypesQuery'

import Detail from '../../../components/catalog/detail'
import Page404 from '../../404'

const CatalogDetail = (props) => {
    const { currentUser } = props
    let { id } = useParams()

    const { loading, error, data } = useQuery(CATALOG_PRODUCT_TYPES_ID, {
        variables: { id: parseInt(id) },
    })

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    return <>{!currentUser?.is_special ? <Page404 /> : <Detail dataSource={data.productTypeById} />}</>
}

export default CatalogDetail
