import { message, notification, Upload } from "antd";
import Papa from "papaparse";
import React from "react";
import gql from "../../api/gql";
import _ from "lodash";
import styled from "styled-components";
const Container = styled.div`
  .ant-upload.ant-upload-select {
    width: 100%;
  }
`;
export default class AddImportOrder extends React.Component {
  state = {
    loading: false,
    showorders: false,
    hasFailed: false,
    downloading: false,
  };
  handleUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const parsed = Papa.parse(bstr, { skipEmptyLines: true });
      this.loadSheet(parsed.data, file);
    };
    reader.readAsBinaryString(file);
    return false;
  };
  loadSheet = (data, file) => {
    const { setLoading } = this.props;
    setLoading(true);
    let err = [];
    let orders = [];

    // let Shipping = null;
    let ShippingEmail = null;
    let FirstName = null;
    let LastName = null;
    let Phone = null;
    let Address1 = null;
    let Address2 = null;
    let State = null;
    let City = null;
    let ZipCode = null;
    let Country = null;
    let CountryCode = null;

    let OrderNumber = null;
    let VariantID = null;
    let ShippingMethod = null;
    
    let quantity = null;
    let Mockup = null;

    let Design1Name = null;
    let Design1Link = null;
    let Design2Name = null;
    let Design2Link = null;
    let Design3Name = null;
    let Design3Link = null;
    let Design4Name = null;
    let Design4Link = null;
    let Design5Name = null;
    let Design5Link = null;
    let Design6Name = null;
    let Design6Link = null;

    const header = data[0];
    for (var i = 0; i < header?.length; i++) {
      switch (header[i]) {
        case "Mockup":
          Mockup = i;
          break;
        case "Email*":
          ShippingEmail = i;
          break;
        case "First Name":
          FirstName = i;
          break;
        case "Last Name*":
          LastName = i;
          break;
        case "Phone":
          Phone = i;
          break;
        case "Address1*":
          Address1 = i;
          break;
        case "Address2":
          Address2 = i;
          break;
        case "State*":
          State = i;
          break;
        case "City*":
          City = i;
          break;
        case "Zip Code*":
          ZipCode = i;
          break;
        case "Country":
          Country = i;
          break;
        case "Country Code*":
          CountryCode = i;
          break;
        case "Order Number*":
          OrderNumber = i;
          break;
        case "Variant ID*":
          VariantID = i;
          break;
        case "Shipping Method*":
          ShippingMethod = i;
          break;
        case "Quantity*":
          quantity = i;
          break;
        case "Design_1_name":
          Design1Name = i;
          break;
        case "Design_1":
          Design1Link = i;
          break;
        case "Design_2_name":
          Design2Name = i;
          break;
        case "Design_2":
          Design2Link = i;
          break;
        case "Design_3_name":
          Design3Name = i;
          break;
        case "Design_3":
          Design3Link = i;
          break;
        case "Design_4_name":
          Design4Name = i;
          break;
        case "Design_4":
          Design4Link = i;
          break;
        case "Design_5_name":
          Design5Name = i;
          break;
        case "Design_5":
          Design5Link = i;
          break;
        case "Design_6_name":
          Design6Name = i;
          break;
        case "Design_6":
          Design6Link = i;
          break;

        // case "Designs":
        //   designs = i;
        //   break;
        default:
          break;
      }
    }

    let hasFailed = `${OrderNumber || OrderNumber === 0 ? "" : ",OrderNumber"}${
      VariantID ? "" : ", VariantID"
    }${quantity ? "" : ", quantity"}`;

    data.splice(0, 1);
    
    for (var i = 0; i < data.length; i++) {
      if (data[i].length) {
        if (
          !!data[i][OrderNumber] &&
          !!data[i][VariantID] &&
          !!data[i][quantity] &&
          !!data[i][LastName] &&
          !!data[i][Address1] &&
          !!data[i][ZipCode] &&
          !!data[i][State] &&
          !!data[i][CountryCode] &&
          !!data[i][City]
        ) {
          orders.push({
            shipping: {
              email: !!data[i][ShippingEmail] ? data[i][ShippingEmail]?.toString(): "",
              firstName: !!data[i][FirstName] ? data[i][FirstName].toString() : "",
              lastName: !!data[i][LastName] ? data[i][LastName].toString() : "",
              phone: !!data[i][Phone] ? data[i][Phone].toString() : "",
              address1: !!data[i][Address1] ? data[i][Address1].toString() : "",
              address2: !!data[i][Address2] ? data[i][Address2].toString() : "",
              state: !!data[i][State] ? data[i][State].toString() : "",
              city: !!data[i][City] ? data[i][City].toString() : "",
              zipCode: !!data[i][ZipCode] ? data[i][ZipCode].toString() : "",
              country: !!data[i][Country] ? data[i][Country].toString() : "",
              countryCode: !!data[i][CountryCode] ? data[i][CountryCode].toString() : "",
            },

            orderNumber: !!data[i][OrderNumber] ? data[i][OrderNumber].toString() : "",
            variantID: !!data[i][VariantID] ? data[i][VariantID].toString() : "",
            shippingMethod: !!data[i][ShippingMethod] ? data[i][ShippingMethod].toString() : "",
            quantity: !!data[i][quantity] ? parseInt(data[i][quantity]) : "",
            mockup: !!data[i][Mockup] ? data[i][Mockup].toString() : "",
            designs: [
              {
                name: !!data[i][Design1Name] ? data[i][Design1Name].toString(): "",
                design_link: !!data[i][Design1Link] ? data[i][Design1Link].toString() : "",
              },
              {
                name: !!data[i][Design2Name] ? data[i][Design2Name].toString(): "",
                design_link: !!data[i][Design2Link] ? data[i][Design2Link].toString() : "",
              },
              {
                name: !!data[i][Design3Name] ? data[i][Design3Name].toString() : "",
                design_link: !!data[i][Design3Link] ? data[i][Design3Link].toString() : "",
              },
              {
                name: !!data[i][Design4Name] ? data[i][Design4Name].toString() : "",
                design_link: !!data[i][Design4Link] ? data[i][Design4Link].toString() : "",
              },
              {
                name: !!data[i][Design5Name] ? data[i][Design5Name].toString() : "",
                design_link: !!data[i][Design5Link] ? data[i][Design5Link].toString() : "",
              },
              {
                name: !!data[i][Design6Name] ? data[i][Design6Name].toString() : "",
                design_link: !!data[i][Design6Link] ? data[i][Design6Link].toString() : "",
              },
            ],
          });
        } else {
          if (!data[i][OrderNumber] || data[i][OrderNumber].length === 0) {
            err.push("Order Number");
          }
          if (!data[i][VariantID] || data[i][VariantID].length === 0) {
            err.push("Variant ID");
          }
          if (!data[i][ShippingMethod] || data[i][ShippingMethod].length === 0) {
            err.push("Shipping Method");
          }
          if (!data[i][quantity] || data[i][quantity].length === 0) {
            err.push("quantity");
          }
          if (!data[i][Address1] || data[i][Address1].length === 0) {
            err.push("Address1");
          }
          if (!data[i][ZipCode] || data[i][ZipCode].length === 0) {
            err.push("Zip Code");
          }
          if (!data[i][CountryCode] || data[i][CountryCode].length === 0) {
            err.push("Country Code");
          }
          if (!data[i][City] || data[i][City].length === 0) {
            err.push("City");
          }
          if (!data[i][State] || data[i][State].length === 0) {
            err.push("State");
          }
        }
      }
    }

    if (hasFailed.length > 1) {
      notification["error"]({
        message: `Please add column [${hasFailed.substring(
          1
        )}] fields required`,
      });

      setLoading(false);
    } else if (err.length > 0) {
      const removeDuplicate = [...new Set(err)];
      notification["error"]({
        message: `The field '${removeDuplicate.toString()}' cannot be empty.`,
      });

      setLoading(false);
    } else {
      gql
        .s3Upload(file)
        .then((res) => {
          const query = `mutation importFromCSV($site_id: Int!,$input: [CSVInput!]!,$file_key: String!,$origin_name: String!){
            importFromCSV(input: $input,site_id: $site_id, file_key: $file_key,origin_name:$origin_name) {
              done
              failed
              result
            }
          }`;
          gql
            .request(query, {
              input: orders,
              site_id: localStorage.getItem("currentSiteID"),
              file_key: res.key,
              origin_name: res.file.name,
            })
            .then((res) => {
              console.log(res)

              data = res?.importFromCSV ?? null 
              if (data) {
                notification["success"]({
                  message: `Done: ${data?.done}, failed: ${data?.failed}, view in import history.`,
                });
              } else {
                notification["success"]({
                  message: "Imported Order successful, view in import history.",
                });
              }

              this.props.loadOrder();
              setLoading(false);
            })
            .catch((err) => {
              notification["error"]({
                message: _.get(err, "[0].message"),
              });
              setLoading(false);
            });
        })
        .catch((err) => {
          message["error"]("Could not upload file");
        });
    }
  };
  render() {
    return (
      <Container>
        <Upload
          accept=".csv"
          showUploadList={false}
          beforeUpload={this.handleUpload}
          multiple={false}
          customRequest={(file) => {}}
        >
          <div>Import Orders</div>
        </Upload>
      </Container>
    );
  }
}
