import gql from "graphql-tag";

export const EXPORTORDERS = gql`
  mutation exportOrders($filter: OrderFilter!, $emails: [String!]) {
    exportOrders(filter: $filter, emails: $emails)
  }
`

export const SUPPLIEREXPORTORDER = gql`
  mutation supplierExportOrder($filter: OrderFilter!, $emails: [String!]) {
    supplierExportOrder(filter: $filter, emails: $emails)
  }
`