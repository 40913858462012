import gql from 'graphql-tag'

export const GET_DISCOUNT_LIST = gql`
    query discounts($filter: DiscountFilter) {
        discounts(filter: $filter) {
            count
            hits {
                id
                discount
                name
                effective_time
                type
                start_time
                end_time
            }
        }
    }
`

export const GET_DISCOUNT_DETAILS = gql`
    query discount($id: Int!) {
        discount(id: $id) {
            id
            discount
            name
            effective_time
            type
            start_time
            end_time
        }
    }
`

export const GET_DISCOUNT_TYPES = gql`
    query discountTypes {
        discountTypes(filter: { limit: 20 })
    }
`
