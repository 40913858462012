import gql from 'graphql-tag'

export const CREATE_DISCOUNT = gql`
    mutation createDiscount($input: NewDiscount!) {
        createDiscount(input: $input) {
            id
        }
    }
`

export const UPDATE_DISCOUNT = gql`
    mutation updateDiscount($id: Int!, $input: EditDiscount!) {
        updateDiscount(id: $id, input: $input) {
            id
        }
    }
`

export const DELETE_DISCOUNT = gql`
    mutation deleteDiscount($id: Int!) {
        deleteDiscount(id: $id)
    }
`
