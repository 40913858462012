import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Col, Empty, Input, PageHeader, Pagination, Row, Spin } from 'antd'
import { CATALOG_PRODUCT_TYPES } from '../../graphql/query/productTypesQuery'
import { history } from '../../history'

import _ from 'lodash'
import S3Image from '../S3Image'
import styled from 'styled-components'

const BoxItemCatalog = styled.div`
    position: relative;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    padding: 10px;
    border-radius: 4px;
`

const BoxImage = styled.div`
    border: 1px solid rgba(243, 244, 246, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
`

const TitleCatalog = styled.h5`
    font-size: 14px;
    margin: 8px 0 4px 0;
    color: #003f70;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const PriceCatalog = styled.div`
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 4px;
    color: #002f54;
    small {
        font-size: 80%;
    }
`
const BoxPagination = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
`

const BoxSpin = styled.div`
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
`

const TagStyle = styled.div`
    position: absolute;
    right: 8px;
    top: 5px;
    span {
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        box-sizing: border-box;
        white-space: nowrap;
        font-size: 16px;
        display: inline-block;
        color: #fff;
        padding: 0 5px;
        &.tag-green {
            background: rgb(92, 163, 0);
            border-color: rgb(92, 163, 0);
        }
        &.tag-orange {
            background: rgb(254, 155, 0);
            border-color: rgb(254, 155, 0);
        }
        &.tag-blue {
            background: rgb(29, 146, 216);
            border-color: rgb(29, 146, 216);
        }
    }
`

const isStrEmpty = (str) => {
    return str === undefined || str === null || str.length === 0 || str.trim().length === 0
}

const Index = (props) => {
    const { dataSource, count, filter } = props

    const [state, setState] = useState({
        filter,
    })

    const [getProductTypes, { loading, error, data }] = useLazyQuery(CATALOG_PRODUCT_TYPES)

    const onChangePagination = (page) => {
        let filter = state.filter
        filter['offset'] = (page - 1) * filter.limit

        getProductTypes({
            variables: {
                filter,
            },
        })
        window.scroll({
            top: 0,
            left: 0,
        })
    }

    const renderClassNameTag = (tag) => {
        switch (true) {
            case tag === 'New':
                return 'tag-green'
            case tag === 'Best seller':
                return 'tag-orange'
            case tag === 'Premium':
                return 'tag-blue'
            default:
                break
        }
    }

    const renderCatalogItem = (item, index) => {
        const variantsBaseCost = _.minBy(item.variants, 'base_cost')
        return (
            <Col span={6} key={index}>
                <BoxItemCatalog>
                    <Link to={`/catalog/${item.id}`}>
                        {!isStrEmpty(item.tag) && (
                            <TagStyle>
                                <span className={renderClassNameTag(item.tag)}>{item.tag}</span>
                            </TagStyle>
                        )}

                        <BoxImage>
                            <S3Image
                                src={item.images ? item.images[0] : null}
                                style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto', display: 'inline-block', verticalAlign: 'middle' }}
                            />
                        </BoxImage>
                        <TitleCatalog title={item?.title}>{item?.title || ''}</TitleCatalog>
                        <PriceCatalog>
                            <small>Base cost from </small>
                            <strong>{`$${variantsBaseCost?.base_cost || 0}`}</strong>
                        </PriceCatalog>
                    </Link>
                </BoxItemCatalog>
            </Col>
        )
    }

    const handleChangeSearch = async (e) => {
        let filter = state.filter
        filter['search'] = e.target.value
        setState((s) => ({ ...s, filter }))
    }

    const applyButton = () => {
        let filter = state.filter
        getProductTypes({
            variables: {
                filter,
            },
        })
        setState((s) => ({ ...s, filter }))
        window.scroll({
            top: 0,
            left: 0,
        })
    }

    const resetButton = () => {
        let filter = state.filter
        filter['offset'] = 0
        filter['search'] = ''
        getProductTypes({
            variables: {
                filter,
            },
        })
        setState((s) => ({ ...s, filter }))
        window.scroll({
            top: 0,
            left: 0,
        })
    }

    const dataSourceRender = data?.productTypes?.hits ? data?.productTypes?.hits : dataSource

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, marginBottom: 20 }}>
                <PageHeader
                    style={{ padding: '16px 0px' }}
                    title='Catalog'
                    onBack={() => {
                        history.goBack()
                    }}
                />
                <div
                    style={{
                        width: '50%',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <Input.Search
                        value={state.filter?.search || ''}
                        style={{
                            width: '100%',
                            height: 'fit-content',
                        }}
                        placeholder='Search...'
                        onChange={handleChangeSearch}
                        onSearch={() => applyButton()}
                    />
                    <Button onClick={resetButton} style={{ marginLeft: 10 }}>
                        Reset
                    </Button>
                </div>
            </div>
            {Array.isArray(dataSourceRender) && dataSourceRender.length === 0 && <Empty />}
            {dataSourceRender.length > 0 && (
                <>
                    {loading || error ? (
                        <BoxSpin>
                            <Spin />
                        </BoxSpin>
                    ) : (
                        <Row gutter={[18, 18]}>{dataSourceRender.length > 0 && dataSourceRender.map((v, i) => renderCatalogItem(v, i))}</Row>
                    )}
                    <BoxPagination>
                        <Pagination total={count} onChange={onChangePagination} pageSize={20} />
                    </BoxPagination>
                </>
            )}
        </div>
    )
}

export default Index
