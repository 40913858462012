import React from 'react'

import styled from 'styled-components'

const BoxInfo = styled.div`
    h3 {
        font-size: 20px;
    }
    p {
        margin-bottom: 5px;
        color: #000;
    }
    .total-order {
        border-top: 1px solid #c1c1c1;
        margin-top: 20px;
        padding-top 10px;
        span {
            font-weight: bold;
        }
    }
`

const OrderInfo = (props) => {
    const { order } = props

    let totalOrder = order?.price ? order?.price * order?.quantity || 1 : 0

    return (
        <BoxInfo>
            <h3>Shipping Address</h3>
            <p>{`${order?.shipping?.first_name || ''} ${order?.shipping?.last_name || ''}`}</p>
            <p>{order?.shipping?.address1 || ''}</p>
            <p>{order?.shipping?.address2 || ''}</p>
            <p>{order?.shipping?.city || ''}</p>
            <p>{order?.shipping?.country || ''}</p>
            <p>{order?.shipping?.zip || ''}</p>
            <p>{order?.shipping?.state || ''}</p>
            <p>{order?.customer?.email || ''}</p>
            <p>{order?.shipping?.phone || ''}</p>
            <p className='total-order'>
                <span>Total Order:</span> ${totalOrder}
            </p>
        </BoxInfo>
    )
}

export default OrderInfo
