import React from 'react'

import { Button, Icon, notification, PageHeader, Popconfirm, Tooltip } from 'antd'
import { history } from '../../../history'

import { useState } from 'react'

import { Link } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { DELETE_DISCOUNT } from '../../../graphql/mutation/adminDiscount'
import { GET_DISCOUNT_LIST } from '../../../graphql/query/adminDiscount'

import Table from '../../Table'
import moment from 'moment'

const DiscountListComponent = (props) => {
    const [state, setState] = useState({
        dataSource: [...props.dataSource],
        currentPagination: 1,
        count: props.count,
    })

    const [loadDiscount] = useLazyQuery(GET_DISCOUNT_LIST, {
        onCompleted: (data) => {
            setState((s) => ({ ...s, dataSource: [...data.discounts.hits], count: data.discounts.count }))
        },
    })

    const [deleteDiscount] = useMutation(DELETE_DISCOUNT)

    const onChangePagination = (page) => {
        let filter = {
            limit: 20,
        }
        filter['offset'] = (page - 1) * 20

        loadDiscount({
            variables: {
                filter,
            },
        })
        setState((s) => ({ ...s, currentPagination: page }))
    }

    const onDeleteDiscount = (id) => {
        deleteDiscount({
            variables: {
                id,
            },
        })
            .then(() => {
                let dataSource = [...state.dataSource]
                let index = dataSource.findIndex((x) => x.id === id)
                if (index !== -1) {
                    dataSource.splice(index, 1)
                    if (dataSource.length === 0) {
                        let currentPagination = --state.currentPagination
                        let filter = {
                            limit: 20,
                        }
                        filter['offset'] = (currentPagination - 1) * 20

                        loadDiscount({
                            variables: {
                                filter,
                            },
                        })
                        setState((s) => ({ ...s, currentPagination }))
                    } else {
                        setState((s) => ({ ...s, dataSource: [...dataSource] }))
                    }
                }
                notification.success({ message: 'Delete Discount is successful!' })
            })
            .catch((err) => {
                notification.error({ message: err.message })
            })
    }

    const columns = [
        {
            title: 'NAME',
            key: 'name',
            dataIndex: 'name',
            render: (key, record) => {
                let time =
                    record.type === 'timeline'
                        ? `- (${moment(record?.start_time).format('YYYY-MM-DD')} to ${moment(record?.end_time).format('YYYY-MM-DD')})`
                        : `- ${record?.effective_time || 0} days`
                return `${key} ${time}`
            },
        },
        {
            title: '% DISCOUNT',
            key: 'discount',
            dataIndex: 'discount',
            render: (key) => {
                switch (true) {
                    case parseInt(key) < 10:
                        return `0${key}`
                    case parseInt(key) >= 10:
                        return key
                    default:
                        return 0
                }
            },
        },
        {
            title: 'Time',
            key: 'effective_time',
            dataIndex: 'effective_time',
            render: (key, record) => {
                let time =
                    record.type === 'timeline'
                        ? `${moment(record?.start_time).format('YYYY-MM-DD')} to ${moment(record?.end_time).format('YYYY-MM-DD')}`
                        : `${key || 0} days`

                return time
            },
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            width: 200,
            align: 'center',
            render: (id, record) => (
                <div>
                    <Button style={{ marginRight: 10 }} size='small'>
                        <Link to={`/admin/discount/edit/${id}`}>
                            <Icon type='edit' style={{ color: '#1890ff' }} />
                        </Link>
                    </Button>
                    <Popconfirm
                        title='Are you sure to delete this product type?'
                        onConfirm={() => onDeleteDiscount(record.id)}
                        okText='Yes'
                        placement='top'
                        cancelText='No'>
                        <Tooltip placement='top' title='Delete'>
                            <Button size='small'>
                                <Icon type='delete' style={{ color: 'red' }} />
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                </div>
            ),
        },
    ]

    let pagination = {
        pageSize: 20,
        total: state.count,
        current: state.currentPagination,
        onChange: (page) => onChangePagination(page),
    }
    return (
        <>
            <PageHeader
                style={{ padding: '16px 0px' }}
                title='Discount'
                onBack={() => {
                    history.goBack()
                }}
                extra={
                    <Button type='primary' icon='plus' onClick={() => history.push(`/admin/discount/create`)}>
                        Add New
                    </Button>
                }
            />
            <Table pagination={{ ...pagination }} dataSource={state.dataSource} columns={columns} rowKey={(row) => row.id} />
        </>
    )
}

export default DiscountListComponent
