import React from "react";
import gql from "../../api/gql";
import { Icon } from "antd";
import { getS3ThumbnailUrl } from "../S3Thumbnail";

export default class PrintFilePreview extends React.Component {
  state = {
    file: null,
    error: false,
  };

  componentDidMount() {
    const { file_id } = this.props;
    let query = `query{
      file(file_id: ${file_id}){
        id
        key
      }
    }`;
    gql
      .request(query)
      .then((res) => {
        this.setState({file: res.file}, () => {
          const { onKey } = this.props;
          if (typeof onKey === "function") {
            onKey(res?.file?.key);
          }
        });
      })
      .catch((err) => {
        this.setState({
          error: true,
        });
      });
  }

  render() {
    const { file, error } = this.state;
    if (file === null && !error)
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            backgroundSize: "contain",
          }}
        >
          <Icon style={{ fontSize: 30, color: "#1890ff" }} type="loading" />
        </div>
      );
    return (
      <div
        style={{
          backgroundImage: `url(${
            getS3ThumbnailUrl(file?.key, 200) || "/no-preview.png"
          })`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          height: "100%",
          width: "100%",
        }}
      />
    );
  }
}
