import React from "react";
import styled from "styled-components";
import { Button, Table, Input, Divider, Modal } from "antd";
import gql from "../../api/gql";
import moment from "moment";
import DateRange from "../DateRange";

const Container = styled.div``;

class ExportOrders extends React.Component {
  state = {
    files: [],
    loading: true,
    page: 1,
    limit: 20,
    total: 0,
    from: null,
    to: null,
    search: null,
    showOrders: false,
    orders: null,
  };

  componentDidMount() {
    this.loadFiles();
  }

  loadFiles = () => {
    this.setState({ loading: true });
    const { page, limit, from, to, search } = this.state;
    let query = `query exportOrders($from: String, $to: String, $search: String, $limit: Int!, $offset: Int!){
      orderExports(from: $from, to: $to, search: $search, limit: $limit, offset: $offset){
        count, hits{id, file_name, created_at, order_ids}
      }
    }`;
    gql
      .request(query, {
        limit: limit,
        offset: (page - 1) * limit,
        from: from,
        to: to,
        search: search,
      })
      .then((res) => {
        this.setState({
          files: res.orderExports.hits,
          total: res.orderExports.count,
          loading: false,
        });
      });
  };

  downloadFile = (id) => {
    let query = `query downloadFile{downloadFile(id: ${id})}`;
    gql.request(query).then((res) => {
      window.location = res.downloadFile;
    });
  };
  onChange = async (dateRange) => {
    if (dateRange.from !== null && dateRange.to !== null) {
      await this.setState({
        from: moment(dateRange.from).format("MMM DD, YYYY"),
        to: moment(dateRange.to).format("MMM DD, YYYY"),
      });
    } else {
      delete this.state.from && delete this.state.to;
      await this.setState({
        from: this.state.from,
        to: this.state.to,
        page: 1,
      });
    }
    //console.log('state', this.state)
    this.loadFiles();
  };

  onSearch = async (value) => {
    if (value !== null) {
      this.setState({
        search: value,
      });
      this.loadFiles();
    }
  };
  render() {
    const columns = [
      {
        title: "Filename",
        key: "Title",
        dataIndex: "file_name",
      },
      {
        title: "Created At",
        key: "created_at",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("MMM DD, YYYY"),
      },
      {
        title: "Actions",
        key: "action",
        width: 200,
        align: "center",
        dataIndex: "id",
        render: (_, record) => (
          <div>
            <Button
              icon="download"
              type="link"
              onClick={(e) => {
                e.preventDefault();
                this.downloadFile(record.id);
              }}
            >
              Download
            </Button>
            {/* <Divider type="vertical" />
          <Button icon="eye" type="link" onClick={(e) => {
            e.preventDefault()
            this.setState({showOrders:true, orders: record.order_ids})
          }}>View Orders IDs</Button> */}
          </div>
        ),
      },
    ];
    const pagination = {
      pageSize: this.state.limit,
      total: this.state.total,
      current: this.state.page,
      onChange: (page, pageSize) => {
        this.setState(
          {
            page: page,
            offset: (page - 1) * pageSize,
          },
          () => this.loadFiles()
        );
      },
    };
    return (
      <Container>
        <div className="filter" style={{ display: "flex" }}>
          <div style={{ marginBottom: 10 }}>
            <DateRange
              onChange={(dateRange) => this.onChange(dateRange)}
            ></DateRange>
          </div>
          <div style={{ marginBottom: 10, marginLeft: 5 }}>
            <Input.Search
              style={{
                maxWidth: 250,
                width: "100%",
                marginRight: 10,
                marginBottom: 10,
              }}
              placeholder="search order id..."
              value={this.state.search}
              onChange={(e) =>
                this.setState({
                  search: e.target.value,
                  page: 1,
                })
              }
              onSearch={(e) => {
                this.loadFiles();
              }}
            />
          </div>
          <div style={{ marginBottom: 10, marginLeft: 5 }}>
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                this.loadFiles();
              }}
            >
              Search
            </Button>
            <Button
              type="default"
              style={{ marginLeft: 5 }}
              onClick={(e) => {
                e.preventDefault();
                this.setState(
                  { search: null, from: null, to: null, page: 1 },
                  () => this.loadFiles()
                );
              }}
            >
              Reset
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={this.state.files}
          pagination={pagination}
          loading={this.state.loading}
          rowKey={(row) => row.id}
        />
        <Modal
          visible={this.state.showOrders}
          title="Exported Order IDs"
          onCancel={() => this.setState({ showOrders: false })}
          footer={null}
        >
          {this.state.orders}
        </Modal>
      </Container>
    );
  }
}

export default ExportOrders;
