import React from 'react'
import { gql } from 'apollo-boost'
import { Query } from '@apollo/react-components'
import { Button, Col, Input, InputNumber, Modal, notification, Row, Select, Switch, Table } from 'antd'
import { DndProvider, DragSource, DropTarget } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import styled from 'styled-components'
import ProductTypeVariantSelectModal from './ProductTypeVariantSelectModal'
import ProductVariantCostField from './ProductVariantCostField'
import VariantSupplierCost from './VariantSupplierCost'
import VariantCarrierCost from './VariantCarrierCost'
// import VariantPartners from './VariantsPartners'
import _ from 'lodash'
import graphql from '../../api/gql'
import { VariantPartners } from './VariantsPartners'
import { VariantPartnerDefault } from './VariantPartnerDefault'
import { BulkEditVariantPrintAreaWithPartnerPrintArea } from './BulkEditVariantPrintAreaWithPartnerPrintArea'

const Q = gql`
    query get($productTypeId: Int!) {
        productTypeById(id: $productTypeId) {
            title
            sku
            print_files {
                id
                title
            }
            attributes {
                name
                slug
                options
            }
            suppliers {
                id
                first_name
                last_name
                partner_fulfillment_id
            }
            product_type_carriers {
                carrier_id
                price
                default
                carrier {
                    name
                }
            }
            product_type_variants {
                id
                sku
                attributes {
                    option
                    name
                    slug
                }
                supplier_costs {
                    id
                    user_id
                    cost
                    selected
                }
                carrier_costs {
                    id
                    carrier_id
                    cost
                    default
                }
                retail_cost
                base_cost
                sale_cost
                original_cost
                is_active
                other_base_cost
                fee_ship
                partner_variants {
                    id
                    partner_variant_id
                    partner_fulfillment_id
                    sku
                }
                print_area_ids
                partner_default_id
                mapping_partner_print_areas {
                    partner_id
                    partner_key
                    product_type_print_area_id
                    product_type_print_area_title
                }
            }
        }
    }
`

const Container = styled.div`
    .actions {
        display: flex;
        .left-action {
            flex-grow: 1;
        }
        span {
            margin: 5px;
        }
    }
    tr.drop-over-downward td {
        border-bottom: 2px dashed #1890ff;
    }

    tr.drop-over-upward td {
        border-top: 2px dashed #1890ff;
    }
    .supplier-cost-container {
        display: flex;
        .ant-form-item {
            margin-right: 10px;
        }
    }
`
const FormContainer = styled.div`
    display: flex;
    .sku-container {
        @media (min-width: 992px) {
            min-width: 250px;
        }
    }
    div.cost {
        margin-left: 10px;
    }
`

let permutationArr = []
let draggingIndex = -1
let attributesTerms = {}

const JOIN = '-'

const revertDataSelectPartnerVariants = (arrOne, ArrTwo, partnerFulfillmentId) => {
    const dataRevert = {
        dataPartnerVariants: [],
        dateDeletePartnerVariants: [],
    }

    if (Array.isArray(arrOne) && arrOne.length > 0) {
        let item
        arrOne.forEach((v) => {
            const index = ArrTwo?.findIndex((x) => x.partner_variant_id === parseInt(v))
            if (index !== -1) {
                item = ArrTwo[index]
                delete item.__typename
                dataRevert.dataPartnerVariants.push(item)
            } else {
                item = {
                    partner_fulfillment_id: partnerFulfillmentId,
                    partner_variant_id: v,
                }
                dataRevert.dataPartnerVariants.push(item)
            }
        })

        ArrTwo.forEach((v) => {
            const index = arrOne.findIndex((x) => parseInt(x) === v.partner_variant_id)

            if (index === -1) {
                dataRevert.dateDeletePartnerVariants.push(v.id)
            }
        })
    } else if (Array.isArray(arrOne) && arrOne.length === 0) {
        ArrTwo.forEach((v) => {
            const index = arrOne.findIndex((x) => parseInt(x) === v.partner_variant_id)

            if (index === -1) {
                dataRevert.dateDeletePartnerVariants.push(v.id)
            }
        })
    } else {
        ArrTwo.forEach((v) => {
            delete v.__typename
            dataRevert.dataPartnerVariants.push(v)
        })
    }

    return dataRevert
}

class BodyRow extends React.Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props
        const style = { ...restProps.style, cursor: 'move' }

        let { className } = restProps
        if (isOver) {
            if (restProps.index > draggingIndex) {
                className += ' drop-over-downward'
            }
            if (restProps.index < draggingIndex) {
                className += ' drop-over-upward'
            }
        }

        return connectDragSource(connectDropTarget(<tr {...restProps} className={className} style={style} />))
    }
}

const rowSource = {
    beginDrag(props) {
        draggingIndex = props.index
        return {
            index: props.index,
        }
    },
}

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index
        const hoverIndex = props.index
        if (dragIndex === hoverIndex) {
            return
        }
        props.moveRow(dragIndex, hoverIndex)
        monitor.getItem().index = hoverIndex
    },
}

const DraggableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource('row', rowSource, (connect) => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
)

export default class ProductTypeVariants extends React.Component {
    state = {
        productTypeTitle: '',
        attributes: [],
        dataSource: [],
        loading: false,
        suppliers: [],
        carriers: [],
        productTypeSKU: '',
        printAreas: [],
        printAreaIds: [],
        modal: {
            visible: false,
            selected: [],
            missingVariants: [],
        },
        isOpenPrintAreModal: false,
        isOpenBulkEditPriceModal: false,
        isOpenBulkEditMappingPrintArea: false,
        selectedColorAttr: [],
        selectedPrintAreasMap: [],
        bulkEditAttributesSelected: null,
        bulkEditOptions: null,
        bulkEditOptionsSelected: [],
        bulkEditSellerCost: 0,
        bulkEditSupplierCost: 0,
        bulkEditRegularPrice: 0,
        bulkEditSalePrice: 0,
        partnerFulfillments: [],
        partnerVariantValues: [],
        partnerDefaultId: [],
        partnerApiCatalogs: [],
        partnerMappingPrintAreas: [],
        onChangeBulkEditMappingData: [],
        bulkEditDefaultPartnerId: [],
    }

    selectAttributeRef = React.createRef()
    selectOptionsRef = React.createRef()

    components = {
        body: {
            row: DraggableBodyRow,
        },
    }

    partnerFulfillmentIdActive = (dataSource, index, suppliers, isCheckSelect) => {
        let idActive
        let item = dataSource[index]

        if (item?.supplier_costs?.length > 0 && suppliers?.length > 0) {
            let indexSuppliers = -1
            const supplierCostsSelect = item.supplier_costs.filter((x) => x.selected)
            if (supplierCostsSelect.length > 0) {
                indexSuppliers = suppliers.findIndex((x) => x.id === supplierCostsSelect[0]?.user_id?.toString())
            } else {
                indexSuppliers = 0
            }

            if (indexSuppliers !== -1) {
                idActive = suppliers[indexSuppliers].partner_fulfillment_id
            }
        } else {
            idActive = suppliers[0]?.partner_fulfillment_id
        }

        if (isCheckSelect) {
            if (item?.idSupplierActive !== idActive) {
                delete item.dataSelectVariants
            }
        }
        item['idSupplierActive'] = idActive

        return idActive
    }

    moveRow = (dragIndex, hoverIndex) => {
        const { dataSource } = this.state
        const dragRow = dataSource[dragIndex]

        this.setState(
            update(this.state, {
                dataSource: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                },
            }),
        )
    }

    heapPermutation = (options = [], size = 0) => {
        if (size === 1) {
            // print
            let items = []
            for (let i = 0; i < options.length; i++) {
                items.push(options[i])
            }
            permutationArr.push(items.join(JOIN))
            return permutationArr
        }

        for (let i = 0; i < size; i++) {
            this.heapPermutation(options, size - 1, options.length)
            if (size % 2 === 1) {
                const tmp = options[0]
                options[0] = options[size - 1]
                options[size - 1] = tmp
            } else {
                const tmp = options[i]
                options[i] = options[size - 1]
                options[size - 1] = tmp
            }
        }
    }
    getCombine = () => {
        const { attributes, productTypeSKU } = this.state
        let res = []
        const n = attributes.length
        let indices = []
        let sortingIncrement = this.state.dataSource.length
        // fill zero
        for (let i = 0; i < n; i++) {
            indices[i] = 0
        }

        while (true) {
            let options = [productTypeSKU]
            let attrs = []
            for (let i = 0; i < n; i++) {
                const option = attributes[i].options[indices[i]]
                const attributeTerm = _.find(attributesTerms[attributes[i].name], ['option_name', option])
                if (typeof attributeTerm !== 'undefined') {
                    options.push(attributeTerm.sku)
                } else {
                    options.push(option)
                }
                attrs.push({
                    name: attributes[i].name,
                    slug: attributes[i].slug,
                    option: option,
                })
            }
            const sku = options.join(JOIN)
            sortingIncrement++
            res.push({
                sku: sku,
                attributes: attrs,
                base_cost: null,
                retail_cost: null,
                sale_cost: null,
                is_active: true,
                sorting: sortingIncrement,
                partner_variants: [],
            })

            let next = n - 1
            while (next >= 0 && indices[next] + 1 >= attributes[next].options.length) {
                next--
            }

            if (next < 0) {
                break
            }
            indices[next]++
            for (let i = next + 1; i < n; i++) {
                indices[i] = 0
            }
        }

        return res
    }
    getMissingVariants = () => {
        const { dataSource, attributes, productTypeSKU } = this.state

        if (attributes.length === 0) {
            notification.warning({ message: 'There is no attribute.' })
            return []
        }
        
        let dataSourceByOptionsMap = []

        for (let i = 0; i < dataSource.length; i++) {
            let options = [productTypeSKU]
            for (let j = 0; j < dataSource[i].attributes.length; j++) {
                options.push(dataSource[i].attributes[j].option)
            }
            permutationArr = []
            this.heapPermutation(options, options.length)
            for (let j = 0; j < permutationArr.length; j++) {
                const key = permutationArr[j]
                dataSourceByOptionsMap[key] = dataSource[i]
            }
        }

        let missingVariants = []
        const combine = this.getCombine()

        for (let i = 0; i < combine.length; i++) {
            let isExistVariant = false
            if (dataSource.length > 0) {
                for (let k = 0; k < dataSource.length; k++) {
                    if (dataSource[k]?.sku === combine[i]?.sku) {
                        isExistVariant = true
                    }
                }
            }

            if (isExistVariant) {
                continue
            }

            if (typeof dataSourceByOptionsMap[combine[i].sku] === 'undefined') {
                missingVariants.push(combine[i])
            }
        }
        

        return missingVariants
    }

    addMissingVariants = () => {
        const { dataSource } = this.state
        const missingVariants = this.getMissingVariants()

        if (missingVariants.length > 0) {
            this.setState({
                dataSource: [...dataSource, ...missingVariants],
            })

            notification.info({ message: 'Add '+missingVariants.length+' variant(s)' })
        } else {
            notification.info({ message: 'No missing variant' })
        }
    }

    getAttributeTerms(attr) {
        const query = `query getAttributeTermByAttributeName{
      getAttributeTermByAttributeName(attribute_name: "${attr}"){
        count
        hits {
          id
          attribute_id
          option_name
          sku
        }
      }
    }`

        graphql
            .request(query, {})
            .then((res) => {
                attributesTerms[attr] = res.getAttributeTermByAttributeName.hits
            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    getListPartnerFulfillments() {
        const query = `query partnerFulfillments{ partnerFulfillments(filter: {limit: 20})
      {
       hits {
        id
        name
        domain
        api_key
        api_signature
        print_areas
      }
      }
    }`

        graphql
            .request(query, {})
            .then((res) => {
                this.setState({
                    partnerFulfillments: res?.partnerFulfillments && res.partnerFulfillments?.hits,
                })

                if (res.partnerFulfillments.hits.length > 0) {
                    res.partnerFulfillments.hits.map((partner) => {
                        let query_partner_api_catalogs = `query partnerCatalogs{partnerCatalogs(PartnerFulfillmentID:${partner?.id}){
                            count
                            hits {
                                product_id
                                blank
                                sku
                                quantity
                                type
                                size
                                color
                                method
                                placement
                            }
                        }}`

                        graphql
                        .request(query_partner_api_catalogs, {})
                        .then((res) => {
                            let partnerCatalogs = res.partnerCatalogs

                            if (partnerCatalogs?.hits.length > 0) {
                                let partnerApiCatalogsState = [...this.state.partnerApiCatalogs] 

                                let partnerCatalogsData = {
                                    id: partner?.id,
                                    data: partnerCatalogs?.hits ?? []
                                }

                                partnerApiCatalogsState.push(partnerCatalogsData)
                                
                                this.setState({
                                    partnerApiCatalogs: partnerApiCatalogsState,
                                })
                            }
                        })
                    })
                }
            })
            .catch((err) => {
                console.log('Get List partner fulfillments fails')
            })
    }

    handleVariantPartnersValue = (id, variantSku, variantId, partnerId, value) => {
        let isExist = false
        let partnerVariantValues = [...this.state.partnerVariantValues]

        if (partnerVariantValues.length > 0) {
            partnerVariantValues.map((el, index) => {
                if (el.variant_sku === variantSku && el.partner_fulfillment_id === partnerId) {
                    partnerVariantValues[index].sku = value
                    isExist = true
                }
            })
            
            if (! isExist) {
                partnerVariantValues.push({
                    id: id,
                    variant_sku: variantSku,
                    variant_id: variantId,
                    partner_fulfillment_id: partnerId,
                    sku: value
                })
            } 
        } else {
            partnerVariantValues.push({
                id: id,
                variant_sku: variantSku,
                variant_id: variantId,
                partner_fulfillment_id: partnerId,
                sku: value
            })
        }

        this.setState({
            partnerVariantValues: partnerVariantValues
        })
    }

    handleOnChangeBulkEditMapping = (v) => {
        let isExist = false
        let onChangeBulkEditMappingData = this.state.onChangeBulkEditMappingData

        if (onChangeBulkEditMappingData.length > 0) {
            onChangeBulkEditMappingData.map((el, index) => {
                if (el.partner_id === v.partner_id && el.print_area_id === v.print_area_id) {
                    onChangeBulkEditMappingData[index].key = v.key
                    isExist = true
                }
            })

            if (! isExist) {
                onChangeBulkEditMappingData.push(v)
            }
        } else {
            onChangeBulkEditMappingData.push(v)
        }

        this.setState({
            onChangeBulkEditMappingData: onChangeBulkEditMappingData
        })
    }

    handleVariantMappingChange = (variantId, variantSku, v) => {
        let isExist = false
        let partnerMappingPrintAreas = this.state.partnerMappingPrintAreas

        if (partnerMappingPrintAreas.length > 0) {
            partnerMappingPrintAreas.map((el, index) => {
                if (el.variant_sku === variantSku && el.partner_id === v.partner_id && el.print_area_id === v.print_area_id) {
                    partnerMappingPrintAreas[index].key = v.key
                    isExist = true
                }
            })
            
            if (! isExist) {
                partnerMappingPrintAreas.push({
                    partner_id: v?.partner_id,
                    print_area_id: v?.print_area_id,
                    print_area_title: v?.print_area_title,
                    key: v?.key,
                    variant_id: variantId,
                    variant_sku: variantSku,
                })
            } 
        } else {
            partnerMappingPrintAreas.push({
                partner_id: v?.partner_id,
                print_area_id: v?.print_area_id,
                print_area_title: v?.print_area_title,
                key: v?.key,
                variant_id: variantId,
                variant_sku: variantSku,
            })
        }

        this.setState({
            partnerMappingPrintAreas: partnerMappingPrintAreas
        })
    }

    handleChangeDefaultPartner = (variantSku, value) => {
        let isExist = false
        let partnerDefaultId = this.state.partnerDefaultId

        if (partnerDefaultId.length > 0) {
            partnerDefaultId.map((el) => {
                if (el.variant_sku === variantSku) {
                    el.partner_default_id = value
                    isExist = true
                }
            })

            if (! isExist) {
                partnerDefaultId.push({
                    variant_sku: variantSku,
                    partner_default_id: value
                })
            } 
        } else {
            partnerDefaultId.push({
                variant_sku: variantSku,
                partner_default_id: value
            })
        }

        this.setState({
            partnerDefaultId: partnerDefaultId
        })
    }

    handleSave = (client) => {
        const { dataSource, carriers, suppliers } = this.state

        const productTypeId = parseInt(this.props.match.params.id)
        const args = []
        const mutationArr = []
        let variables = {}
        for (let i = 0; i < dataSource.length; i++) {
            const item = dataSource[i]
            if (item.base_cost == null || item.retail_cost == null || item.sale_cost == null) {
                notification.error({ message: 'Seller cost is not correct, please check again.' })
                this.setState({ loading: false })
                return
            }
            if (item.fee_ship < 0) {
                notification.error({ message: 'Fee ship is not correct, please check again.' })
                this.setState({ loading: false })
                return
            }
            if (item.other_base_cost == null) {
                item.other_base_cost = 0
            }
            if (item.original_cost == null) {
                item.original_cost = 0
            }
            // if (item.base_cost > item.sale_cost || item.base_cost > item.retail_cost){
            //   notification.error({ message: "Base cost must be less than or equal to sale price and regular price." })
            //   this.setState({loading: false})
            //   return
            // }
            if (item.sale_cost > item.retail_cost) {
                notification.error({ message: 'Sale price must be less than or equal to regular price.' })
                this.setState({ loading: false })
                return
            }
            item.attributes = item.attributes.map((it) => {
                if (typeof it.__typename !== 'undefined') {
                    delete it.__typename
                }
                return it
            })
            if (typeof item.__typename !== 'undefined') {
                delete item.__typename
            }
            if (item.supplier_costs && item.supplier_costs.length) {
                item.supplier_costs = item.supplier_costs.map((sc) => {
                    if (typeof sc.__typename !== 'undefined') {
                        delete sc.__typename
                    }
                    return sc
                })
            } else if (suppliers && suppliers.length > 0) {
                item.supplier_costs = suppliers.map((supplier, idx) => {
                    return {
                        user_id: supplier.id,
                        cost: 0,
                        selected: idx == 0 ? true : false,
                    }
                })
            }
            if (item.carrier_costs && item.carrier_costs.length) {
                item.carrier_costs = item.carrier_costs.map((cc) => {
                    if (typeof cc.__typename !== 'undefined') {
                        delete cc.__typename
                    }
                    if (cc.cost === null) {
                        cc.cost = carriers.filter((f) => f.carrier_id === cc.carrier_id)[0].price
                    }
                    return cc
                })
            } else if (carriers && carriers.length > 0) {
                item.carrier_costs = carriers.map((carrier) => {
                    return {
                        carrier_id: carrier.carrier_id,
                        cost: carrier.price,
                        default: carrier.default,
                    }
                })
            }

            const dataRevert = revertDataSelectPartnerVariants(item.dataSelectVariants, item.partner_variants, item.idSupplierActive)
            if (Array.isArray(item.dataSelectVariants)) {
                delete item.dataSelectVariants
            }

            const partner_variants = this.state.partnerVariantValues.filter(el => el.variant_sku === item.sku).map(temp => {
                return {
                    id: temp.id ?? 0,
                    partner_fulfillment_id: temp.partner_fulfillment_id ?? 0,
                    sku: temp.sku
                }
            })

            item.partner_variants = partner_variants

            const mapping_partner_print_areas = this.state.partnerMappingPrintAreas.filter(el => el.variant_sku === item.sku).map(temp => {
                return {
                    partner_id: temp.partner_id,
                    partner_key: temp.key,
                    product_type_print_area_id: temp.print_area_id,
                    product_type_print_area_title: temp.print_area_title,
                }
            })

            item.mapping_partner_print_areas = mapping_partner_print_areas

            item.partner_default_id = this.state.partnerDefaultId.find(el => el.variant_sku === item.sku)?.partner_default_id ?? null

            item.productTypeId = productTypeId
            item.sorting = i + 1

            variables[`input_${i}`] = _.omit(item, 'idSupplierActive')
            args.push(`$input_${i}: NewProductTypeVariant!`)

            mutationArr.push(`m_${i}: createProductTypeVariant(input: $input_${i}){
                id
                sku
                attributes {
                    option
                    name
                    slug
                }
                supplier_costs {
                    id
                    user_id
                    cost
                    selected
                }
                carrier_costs {
                    id
                    carrier_id
                    cost
                    default
                }
                retail_cost
                base_cost
                sale_cost
                original_cost
                is_active
                other_base_cost
                fee_ship
                partner_variants {
                    id
                    partner_fulfillment_id
                    sku
                }
                print_area_ids
                partner_default_id
                mapping_partner_print_areas {
                    partner_id
                    partner_key
                    product_type_print_area_id
                    product_type_print_area_title
                }
            }`)

            if (Array.isArray(dataRevert.dateDeletePartnerVariants) && dataRevert.dateDeletePartnerVariants.length > 0) {
                args.push(`$ids_${dataSource.length + i}: [Int!]!`)
                variables[`ids_${dataSource.length + i}`] = dataRevert.dateDeletePartnerVariants

                mutationArr.push(`m_${dataSource.length + i}: deletePartnerVariants(ids: $ids_${dataSource.length + i})`)
            }
        }

        const q = `mutation execute(${args.join(',')}){
          ${mutationArr.join('\n')}
        }`
        client
            .mutate({
                mutation: gql`
                    ${q}
                `,
                variables,
            })
            .then((res) => {
                notification.success({ message: 'Variants has been saved!' })

                if (res.data) {
                    for (let i = 0; i < dataSource.length; i++) {
                        dataSource[i] = res.data[`m_${i}`]
                    }

                    this.setState({
                        loading: false,
                        dataSource: dataSource,
                    })
                } else {
                    this.setState({ loading: false })
                }
            })
            .catch((e) => {
                this.setState({ loading: false })
                notification.error({ message: e.toString() })
            })
    }

    componentDidMount() {
        this.getListPartnerFulfillments()
    }

    render() {
        const { productTypeTitle, attributes, dataSource, suppliers, carriers, 
            printAreas, isOpenPrintAreModal, isOpenBulkEditPriceModal, 
            selectedColorAttr, selectedPrintAreasMap,
            bulkEditSellerCost, bulkEditSupplierCost, bulkEditRegularPrice, bulkEditSalePrice,
            bulkEditAttributesSelected, bulkEditOptions, bulkEditOptionsSelected, partnerFulfillments, partnerDefaultId, partnerApiCatalogs,
            partnerMappingPrintAreas, isOpenBulkEditMappingPrintArea, onChangeBulkEditMappingData, bulkEditDefaultPartnerId } = this.state

        const { match } = this.props
        const { id } = match.params

        const attributeColor = attributes.find(item => item.slug === 'color')
        let addPrintAreaBtn = null
        let printAreaModal = null

        if (typeof attributeColor !== 'undefined') {
            addPrintAreaBtn = <Button
                onClick={() => {
                    this.setState({
                        isOpenPrintAreModal: true
                    })
                }}
                style={{
                    margin: "15px 0 15px 0"
                }}
                type={'primary'}
            >
                Add print areas by Color attribute
            </Button>

            printAreaModal = <Modal
                title={"Choose print areas by color attribute"}
                visible={isOpenPrintAreModal}
                onCancel={() => {
                    this.setState({
                        isOpenPrintAreModal: false
                    })
                }}
                onOk={() => {
                    // eslint-disable-next-line no-unused-expressions
                    dataSource?.forEach((variant, index) => {
                        if (variant?.attributes.length > 0) {
                            // eslint-disable-next-line no-unused-expressions
                            variant?.attributes?.forEach(attr => {
                                if (selectedColorAttr.includes(attr?.option)) {
                                    dataSource[index].print_area_ids = selectedPrintAreasMap
                                }
                            })
                        }
                    });

                    this.setState({
                        selectedColorAttr: [],
                        selectedPrintAreasMap: [],
                        isOpenPrintAreModal: false,
                        dataSource
                    })          
                }}
            >
                <div
                    style={{marginBottom: "15px"}}
                >
                    <div>Choose colors:</div>
                    <Select
                        allowClear
                        showArrow
                        mode="multiple"
                        placeholder="choose colors"
                        style={{minWidth: "180px"}}
                        onChange={(values) => {
                            this.setState({
                                selectedColorAttr: values
                            })
                        }}
                        value={selectedColorAttr}
                    >
                        {attributeColor?.options?.map((attr) => (
                            <Select.Option value={attr} key={attr+Math.random(99999)}>{attr.charAt(0).toUpperCase() + attr.slice(1)}</Select.Option>
                        ))}
                    </Select>
                </div>
                
                <div
                    style={{marginBottom: "15px"}}
                >
                    <div>Choose print areas:</div>
                    <Select
                        allowClear
                        showArrow
                        mode="multiple"
                        placeholder="choose print areas"
                        style={{minWidth: "180px"}}
                        onChange={(values) => {
                            this.setState({
                                selectedPrintAreasMap: values
                            })
                        }}
                        value={selectedPrintAreasMap}
                    >
                        {printAreas?.map((area) => (
                            <Select.Option value={area?.id} key={area?.id+Math.random(99999)}>{area?.title}</Select.Option>
                        ))}
                    </Select>
                </div>
            </Modal>
        }

        let bulkEditPriceBtn = <Button
            onClick={() => {
                this.setState({
                    isOpenBulkEditPriceModal: true
                })
            }}
            style={{
                marginBottom: "15px"
            }}
            type={'primary'}
        >
            Bulk edit price
        </Button>

        let bulkEditPriceModal = <Modal
            title={"Choose attributes to bulk edit price"}
            visible={isOpenBulkEditPriceModal}
            onCancel={() => {
                this.setState({
                    isOpenBulkEditPriceModal: false
                })
            }}
            onOk={() => {
                if (! bulkEditAttributesSelected) {
                    notification.error({message: 'Select attribute!'})
                    // eslint-disable-next-line no-unused-expressions
                    this.selectAttributeRef?.current?.focus()

                    return
                }

                if (bulkEditOptionsSelected.length <= 0) {
                    notification.error({message: 'Select Options!'})
                    // eslint-disable-next-line no-unused-expressions
                    this.selectOptionsRef?.current?.focus()

                    return
                }

                // eslint-disable-next-line no-unused-expressions
                dataSource?.forEach((variant, index) => {
                
                    if (variant?.attributes.length > 0) {
                        for (let i = 0; i < variant?.attributes.length; i++) {
                            if (variant?.attributes[i]?.slug === bulkEditOptions?.slug && bulkEditOptionsSelected?.includes(variant?.attributes[i]?.option)) {
                                if (bulkEditSellerCost > 0) {
                                    dataSource[index].base_cost = bulkEditSellerCost
                                }
                                if (bulkEditRegularPrice > 0) {
                                    dataSource[index].retail_cost = bulkEditRegularPrice
                                }
                                if (bulkEditSalePrice > 0) {
                                    dataSource[index].sale_cost = bulkEditSalePrice
                                }
                                if (bulkEditSupplierCost > 0) {
                                    if (typeof dataSource[index].supplier_costs === 'undefined') {
                                        dataSource[index].supplier_costs = [{
                                            id: null,
                                            user_id: suppliers?.[0]?.id,
                                            cost: bulkEditSupplierCost,
                                            selected: true
                                        }]
                                    } else {
                                        dataSource[index].supplier_costs[0].cost = bulkEditSupplierCost
                                    }
                                }
                            }
                        }
                    }
                });

                this.setState({
                    bulkEditAttributesSelected: null,
                    bulkEditOptions: null,
                    bulkEditOptionsSelected: [],
                    bulkEditSellerCost: 0,
                    bulkEditSupplierCost: 0,
                    bulkEditRegularPrice: 0,
                    bulkEditSalePrice: 0,
                    isOpenBulkEditPriceModal: false,
                    dataSource
                })           
            }}
        >
            {attributes.length > 0 && (
                <Row
                    style={{marginBottom: "15px"}}
                >
                    <Col style={{marginBottom: "15px"}}>
                        <div>Choose attributes to map:</div>
                        <Select
                            ref={this.selectAttributeRef}
                            allowClear
                            showArrow
                            placeholder="Attributes"
                            style={{
                                minWidth: "180px"
                            }}
                            onChange={(value) => {
                                this.setState({
                                    bulkEditAttributesSelected: value,
                                    bulkEditOptions: attributes?.find(attr => attr?.slug === value),
                                    bulkEditOptionsSelected: []
                                })
                            }}
                            value={bulkEditAttributesSelected}
                        >
                            {attributes?.map((attr) => (
                                <Select.Option value={attr?.slug} key={attr?.slug+Math.random(99999)}>{attr?.name.charAt(0).toUpperCase() + attr?.name.slice(1)}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                    
                    {bulkEditOptions && (
                        <Select 
                            ref={this.selectOptionsRef}
                            mode="multiple"
                            allowClear
                            showArrow
                            placeholder={`Choose ${bulkEditOptions?.name}`}
                            style={{minWidth: "180px"}}
                            onChange={(values) => {
                                if (values.includes('all')) {
                                    this.setState({
                                        bulkEditOptionsSelected: bulkEditOptions?.options
                                    })
                                } else {
                                    this.setState({
                                        bulkEditOptionsSelected: values
                                    })
                                }
                            }}
                            value={bulkEditOptionsSelected}
                        >
                            <Select.Option value={'all'} key={'all'+Math.random(99999)}>All</Select.Option>
                            {bulkEditOptions?.options?.map(option => (
                                <Select.Option value={option} key={option+Math.random(99999)}>{option}</Select.Option>
                            ))}
                        </Select>
                    )}
                </Row>
            )}
            
            <Row>
                <label>Supplier cost:</label>

                <InputNumber
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',')}
                    onChange={(val) => {
                        this.setState({
                            bulkEditSupplierCost: val
                        })
                    }}
                    value={bulkEditSupplierCost}
                />
            </Row>
            <Row>
                <label>Seller cost:</label>

                <InputNumber
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',')}
                    onChange={(val) => {
                        this.setState({
                            bulkEditSellerCost: val
                        })
                    }}
                    value={bulkEditSellerCost}
                />
            </Row>
            <Row>
                <label>Regular price:</label>

                <InputNumber
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',')}
                    onChange={(val) => {
                        this.setState({
                            bulkEditRegularPrice: val
                        })
                    }}
                    value={bulkEditRegularPrice}
                />
            </Row>
            <Row>
                <label>Sale price:</label>

                <InputNumber
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g,
                        ',')}
                    onChange={(val) => {
                        this.setState({
                            bulkEditSalePrice: val
                        })
                    }}
                    value={bulkEditSalePrice}
                />
            </Row>
        </Modal>

        let bulkEditMappingPrintAreas, mappingModalBulkEditMappingPrintAreas = null
        
        if (partnerFulfillments?.length > 0) {
            bulkEditMappingPrintAreas = <Button
                onClick={() => {
                    this.setState({
                        isOpenBulkEditMappingPrintArea: true
                    })
                }}
                style={{
                    marginBottom: "15px"
                }}
                type={'primary'}
                >
                Bulk edit mapping print areas
            </Button>

            mappingModalBulkEditMappingPrintAreas = <Modal
                title={"Mapping product type variant print areas with partner print areas"}
                visible={isOpenBulkEditMappingPrintArea}
                onCancel={() => {
                    this.setState({
                        isOpenBulkEditMappingPrintArea: false
                    })
                }}
                onOk={() => {
                    if (dataSource?.length < 1) {
                        notification.warning({ message: 'Missing variants or bulk edit' })
                        return
                    }

                    dataSource.map(variant => {
                        this.handleChangeDefaultPartner(variant.sku, bulkEditDefaultPartnerId)

                        onChangeBulkEditMappingData
                            .filter(
                                mapping => variant?.print_area_ids?.includes(mapping?.print_area_id)
                            )
                            .map(
                                mappingArea => (this.handleVariantMappingChange(variant.id, variant.sku, mappingArea))
                            )
                    })

                    this.setState({
                        isOpenBulkEditMappingPrintArea: false
                    })
                }}
            >
                {printAreas?.length < 1 && (
                    <>
                        Product type not has print areas.
                    </>
                )}

                {printAreas?.length > 0 && (
                    <BulkEditVariantPrintAreaWithPartnerPrintArea
                        printAreas={printAreas}
                        partnerFulfillments={partnerFulfillments}
                        onChangeBulkEditMapping={(v) => this.handleOnChangeBulkEditMapping(v)}
                    />
                )}

                <div>Set default partner: </div>
                <Select
                    showArrow
                    allowClear
                    style={{width: '100%'}}
                    placeholder="Default partner"
                    onChange={value => {
                        this.setState({
                            bulkEditDefaultPartnerId: value
                        })
                    }}
                    value={bulkEditDefaultPartnerId}
                >
                    {partnerFulfillments.length> 0 && partnerFulfillments?.map(el => {
                        return (
                            <Select.Option
                                key={el?.id + Math.random(99999)}
                                value={el?.id}
                            >
                                {el.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Modal>
        }

        return (
            <>
                {addPrintAreaBtn}

                {printAreaModal}

                <div>{bulkEditPriceBtn}</div>

                <div>{bulkEditPriceModal}</div>

                {bulkEditMappingPrintAreas}

                {mappingModalBulkEditMappingPrintAreas}

                <div>Product type: {productTypeTitle}</div>

                <Query
                    fetchPolicy='no-cache'
                    onCompleted={(data) => {
                        if (data.productTypeById.attributes.length) {
                            const attributesName = _.map(data.productTypeById.attributes, 'name')
                            attributesName.map((attr) => {
                                if (!(attr in attributesTerms)) {
                                    this.getAttributeTerms(attr)
                                }
                            })
                        }

                        this.setState({
                            attributes: data.productTypeById.attributes ? data.productTypeById.attributes : [],
                            suppliers: data.productTypeById.suppliers,
                            dataSource: data.productTypeById.product_type_variants,
                            productTypeTitle: data.productTypeById.title,
                            productTypeSKU: data.productTypeById.sku,
                            carriers: data.productTypeById.product_type_carriers,
                            printAreas: data?.productTypeById?.print_files ?? []
                        })

                        let partner_variants_data = []
                        let partner_default_id_data = []
                        let mapping_partner_print_areas_data = []
                        if (data.productTypeById.product_type_variants.length > 0) {
                            data.productTypeById.product_type_variants.map(variant => {
                                if (variant?.partner_variants?.length > 0) {
                                    variant.partner_variants.map(el => {
                                        partner_variants_data.push({
                                            id: el.id,
                                            variant_sku: variant.sku,
                                            variant_id: variant.id,
                                            partner_fulfillment_id: el.partner_fulfillment_id,
                                            sku: el.sku,
                                        })
                                    })
                                }

                                if (variant?.partner_default_id > 0) {
                                    partner_default_id_data.push({
                                        variant_sku: variant.sku,
                                        partner_default_id: variant?.partner_default_id
                                    })
                                }

                                if (variant?.mapping_partner_print_areas?.length > 0) {
                                    variant.mapping_partner_print_areas.map(area => {
                                        mapping_partner_print_areas_data.push({
                                            partner_id: area?.partner_id,
                                            print_area_id: area?.product_type_print_area_id,
                                            print_area_title: area?.product_type_print_area_title,
                                            key: area?.partner_key,
                                            variant_id: variant?.id,
                                            variant_sku: variant?.sku,
                                        })
                                    })
                                }
                            })
                        }

                        this.setState({
                            partnerVariantValues: partner_variants_data,
                            partnerDefaultId: partner_default_id_data,
                            partnerMappingPrintAreas: mapping_partner_print_areas_data,
                        })
                    }}
                    query={Q}
                    variables={{ productTypeId: id }}
                >
                    {({ error, loading, client }) => {
                        if (error) return <div>{error.toString()}</div>
                        if (loading) return <div>loading</div>
                        let columns = [
                            {
                                dataIndex: 'variant',
                                title: 'Variant',
                                width: 150,
                                render: (_, record, index) => record.attributes.map((attr) => attr.option).join('/'),
                            },
                            {
                                dataIndex: 'sku',
                                title: 'SKU',
                                width: 150,
                                render: (_, record, index) => (
                                    <Input
                                        disabled
                                        onChange={(e) => {
                                            dataSource[index].sku = e.target.value
                                        }}
                                        defaultValue={record.sku}
                                    />
                                ),
                            },
                        ]

                        columns.push({
                            dataIndex: 'partner_variants',
                            title: 'Partners Variant',
                            width: 450,
                            render: (_, record, index) => {
                                const variantId = record.id ?? null
                                const variantSku = record.sku ?? null
                                let variantPrintAreas = printAreas
                                let partnerMappingPrintAreasState = partnerMappingPrintAreas?.filter(item => item.variant_sku === variantSku)

                                if (record.print_area_ids?.length > 0) {
                                    variantPrintAreas = printAreas?.filter(area => record.print_area_ids.includes(area.id))
                                } 

                                return (
                                    <>
                                        <VariantPartners
                                            partnerMappingPrintAreas={partnerMappingPrintAreas ?? null}
                                            partnerFulfillments={partnerFulfillments ?? null}
                                            partnerApiCatalogs={partnerApiCatalogs ?? null}
                                            partnerVariantValues={record?.partner_variants ?? []}
                                            variantPrintAreas={variantPrintAreas}
                                            onChange={(id, partnerId, value) => this.handleVariantPartnersValue(id, variantSku, variantId, partnerId, value)}
                                            onMappingChange={(v) => this.handleVariantMappingChange(variantId, variantSku, v)}
                                            partnerMappingPrintAreasState={partnerMappingPrintAreasState}
                                        />
                                    </>
                                )
                            },
                        })

                        columns.push({
                            dataIndex: 'partner_default_id',
                            title: 'Default partner',
                            width: 150,
                            render: (_, record, index) => {
                                const variantSku = record?.sku ?? null
                                let partner_default_id = record?.partner_default_id

                                const partnerDefaultIdState = partnerDefaultId?.find(el => el.variant_sku === variantSku)

                                if (partnerDefaultIdState) {
                                    partner_default_id = partnerDefaultIdState.partner_default_id
                                }

                                return (
                                    <VariantPartnerDefault
                                        partnerFulfillments={partnerFulfillments ?? null}
                                        defaultValue={partner_default_id}
                                        onChange={(value) => {
                                            this.handleChangeDefaultPartner(variantSku, value)
                                        }}
                                    />
                                )
                            },
                        })

                        columns.push({
                            dataIndex: 'print_areas',
                            title: 'Print areas',
                            width: 200,
                            render: (_, record, index) => {
                                const values = record.print_area_ids ?? printAreas?.map(area => area.id)
                                dataSource[index].print_area_ids = values

                                return (
                                    <>
                                        <Select
                                            showArrow
                                            showSearch
                                            mode="multiple" 
                                            placeholder="Select print areas"
                                            style={{width: '100%'}}
                                            onChange={(values) => {
                                                dataSource[index].print_area_ids = values
                                                this.setState({
                                                    dataSource,
                                                })
                                            }}
                                            value={values}
                                        >
                                            {printAreas?.map((area) => (
                                                <Select.Option key={area?.id} value={area.id}>
                                                    {area.title}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </>
                                )
                            },
                        })

                        columns.push({
                            dataIndex: 'suppliers',
                            title: 'Supplier[Cost]',
                            width: 330,
                            render: (_, record, index) => (
                                <VariantSupplierCost
                                    onChange={(updateSupplierCosts) => {
                                        this.partnerFulfillmentIdActive(dataSource, index, suppliers, true)
                                        dataSource[index].supplier_costs = updateSupplierCosts
                                        this.setState({ dataSource })
                                    }}
                                    supplierCosts={record.supplier_costs ? record.supplier_costs : []}
                                    suppliers={suppliers}
                                />
                            ),
                        })
                        columns.push({
                            dataIndex: 'carriers',
                            title: 'Carriers[Cost]',
                            width: 250,
                            render: (_, record, index) => (
                                <VariantCarrierCost
                                    onChange={(updateCarrierCosts) => {
                                        dataSource[index].carrier_costs = updateCarrierCosts
                                        this.setState({ dataSource })
                                    }}
                                    carrierCosts={record.carrier_costs ? record.carrier_costs : []}
                                    carriers={carriers}
                                />
                            ),
                        })
                        columns.push({
                            dataIndex: 'form',
                            title: 'Seller',
                            width: 750,
                            render: (_, record, index) => (
                                <div>
                                    <FormContainer>
                                    <div className={'cost original-cost'}>
                                            <ProductVariantCostField
                                                value={record.original_cost || 0}
                                                onChange={(value) => {
                                                    dataSource[index].original_cost = value
                                                    this.setState({
                                                        dataSource,
                                                    })
                                                }}
                                                label={'Original Cost'}
                                            />
                                        </div>
                                        <div className={'cost base-cost'}>
                                            <ProductVariantCostField
                                                value={record.base_cost || 0}
                                                onChange={(value) => {
                                                    dataSource[index].base_cost = value
                                                    this.setState({
                                                        dataSource,
                                                    })
                                                }}
                                                label={'Base Cost'}
                                            />
                                        </div>
                                        <div className={'cost other-base-cost'}>
                                            <ProductVariantCostField
                                                value={record.other_base_cost || 0}
                                                onChange={(value) => {
                                                    dataSource[index].other_base_cost = value
                                                    this.setState({
                                                        dataSource,
                                                    })
                                                }}
                                                label={'Other Base Cost'}
                                            />
                                        </div>
                                        <div className={'cost fee-ship'}>
                                            <ProductVariantCostField
                                                value={record.fee_ship || 0}
                                                onChange={(value) => {
                                                    dataSource[index].fee_ship = value
                                                    this.setState({
                                                        dataSource,
                                                    })
                                                }}
                                                label={'Fee Ship(item >= 2)'}
                                            />
                                        </div>
                                        <div className={'cost retail-cost'}>
                                            <ProductVariantCostField
                                                value={record.retail_cost || 0}
                                                onChange={(value) => {
                                                    dataSource[index].retail_cost = value
                                                    this.setState({
                                                        dataSource,
                                                    })
                                                }}
                                                label={'Regular Price'}
                                            />
                                        </div>
                                        <div className={'cost sale-cost'}>
                                            <ProductVariantCostField
                                                value={record.sale_cost || 0}
                                                onChange={(value) => {
                                                    dataSource[index].sale_cost = value
                                                    this.setState({
                                                        dataSource,
                                                    })
                                                }}
                                                label={'Sale Price'}
                                            />
                                        </div>
                                    </FormContainer>
                                </div>
                            ),
                        })

                        columns.push({
                            dataIndex: 'actions',
                            title: 'Status',
                            align: 'center',
                            width: 150,
                            fixed: 'right',
                            render: (_, record, index) => (
                                <Switch
                                    size='small'
                                    onChange={(value) => {
                                        dataSource[index].is_active = value
                                        this.setState({ dataSource })
                                    }}
                                    checked={record.is_active}
                                />
                            ),
                        })

                        const tableWidth = _.sum(columns.map((c) => c.width))

                        return (
                            <Container>
                                <DndProvider backend={HTML5Backend}>
                                    <Table
                                        onRow={(record, index) => ({
                                            index,
                                            moveRow: this.moveRow,
                                        })}
                                        components={this.components}
                                        rowKey={'sku'}
                                        locale={{
                                            emptyText: (
                                                <div>
                                                    <p>No variants added.</p>
                                                </div>
                                            ),
                                        }}
                                        scroll={{ x: tableWidth }}
                                        pagination={false}
                                        dataSource={dataSource}
                                        columns={columns}
                                        className={'product-variants'}
                                        
                                    />

                                    <div 
                                        className={'actions'} 
                                        style={{
                                            position: "fixed", 
                                            background: '#fff',
                                            padding: '16px 15px',
                                            bottom: 0,
                                            left: 0,
                                            width: '100%',
                                            zIndex:99,
                                        }}
                                    >
                                        <div className={'left-action'}>
                                            <Button onClick={() => this.addMissingVariants()} icon={'plus'}>
                                                Add all missing variants
                                            </Button>
                                            <span>Or</span>
                                            <Button
                                                onClick={() => {
                                                    const missingVariants = this.getMissingVariants()
                                                    if (missingVariants.length > 0) {
                                                        this.setState({
                                                            modal: {
                                                                ...this.state.modal,
                                                                visible: true,
                                                                missingVariants,
                                                            },
                                                        })
                                                    } else {
                                                        notification.info({
                                                            message: 'No missing variants',
                                                        })
                                                    }
                                                }}>
                                                Select Variants
                                            </Button>
                                        </div>
                                        <div className={'right-action'}>
                                            <Button
                                                loading={this.state.loading}
                                                onClick={() => {
                                                    if (!dataSource.length) {
                                                        return
                                                    }
                                                    this.setState({ loading: true })

                                                    this.handleSave(client)
                                                }}
                                                type={'primary'}
                                                icon={'save'}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </DndProvider>
                                {this.state.modal.visible && (
                                    <Modal
                                        className='product-type-modal'
                                        width={700}
                                        title={'Select variants'}
                                        onOk={() => {
                                            if (this.state.modal.selected.length) {
                                                this.setState({
                                                    modal: {
                                                        ...this.state.modal,
                                                        visible: false,
                                                        selected: [],
                                                    },
                                                    dataSource: [...dataSource, ...this.state.modal.selected],
                                                })
                                            }
                                        }}
                                        onCancel={() => {
                                            this.setState({
                                                modal: {
                                                    ...this.state.modal,
                                                    visible: false,
                                                },
                                            })
                                        }}
                                        visible={this.state.modal.visible}>
                                        <ProductTypeVariantSelectModal
                                            onChange={(variants) => {
                                                this.setState({
                                                    modal: {
                                                        ...this.state.modal,
                                                        selected: variants,
                                                    },
                                                })
                                            }}
                                            variants={this.state.modal.missingVariants}
                                            attributes={attributes}
                                        />
                                    </Modal>
                                )}
                            </Container>
                        )
                    }}
                </Query>
            </>
        )
    }
}
