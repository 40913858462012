import React from 'react'
import gql from '../../api/gql'
import { Table, Icon,Row, Col, Button, Select, message, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'
import S3Image from '../../components/S3Image'
import AdminProductTypes from '../../components/admin/ProductTypes'
import SupplierProductTypes from '../../components/supplier/ProductTypes'
const {Option} = Select

export default class ProductTypesPage extends React.Component{
  render() {
    const {currentUser} = this.props
    if (!currentUser) return null
    if (currentUser.roles.map(role => ['Supporter', 'Administrator', 'Listing'].includes(role.name))) {
      return <AdminProductTypes currentUser={currentUser}/>
    }
    if (currentUser.roles.map(role => role.name).includes('Supplier')) {
      return <SupplierProductTypes/>
    }
    return null
  } 
}