import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Form, notification, Select, Skeleton } from "antd";
import React from "react";
import { RE_MAP_ORDER_VARIANT } from "../../graphql/mutation/reMapVariant";
import { PRODUCT_TYPE_VARIANT } from "../../graphql/query/productTypeVariant";
import _ from "lodash";

const ChangeVariant = (props) => {
  const { order, refetch } = props;
  const { getFieldDecorator, validateFields } = props.form;
  const { data, loading } = useQuery(PRODUCT_TYPE_VARIANT, {
    variables: { product_id: order?.product.id },
  });
  const [reMapVariant,{loading:reLoading}] = useMutation(RE_MAP_ORDER_VARIANT);
  const dataVariant = data ? data.productVariant : [];
  const onSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        reMapVariant({
          variables: {
            orderId: parseInt(order.id),
            variantId: values.variantId,
          },
        }).then((res) => {
          notification.success({ message: "Change Variant Success" });
          refetch();
        }).catch(err =>{
          notification["error"]({
            message: 'Error',
            description: _.get(err, "[0].message")
          })
        });
      }
    });
  };
  if (loading) return <Skeleton />;
  return (
    <div>
      <Form onSubmit={onSubmit}>
        <Form.Item name="variantId">
          {getFieldDecorator("variantId", {
            initialValue: order?.variant.id,
          })(
            <Select>
              {dataVariant.map((item) => (
                <Select.Option
                  value={item.id}
                  key={item.id}
                >
                  {item.product_type_variant.attributes.map((atri) => (
                    <>
                      <b style={{ textTransform: "capitalize" }}>{atri.slug}</b>
                      : {atri.option}{" "}
                    </>
                  ))}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <div style={{display:"flex", justifyContent:"flex-end"}}>
        <Button type="primary" htmlType="submit" loading={reLoading}>
            Save
          </Button>
        </div>
         
      
      </Form>
    </div>
  );
};
export default Form.create({ name: "ChangeVariant" })(ChangeVariant);
