import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import {
  Form,
  Input,
  Button,
  notification,
  message,
} from 'antd'
import _ from 'lodash'
import gql from '../../api/gql'

const Container = styled.div`
margin: auto;
text-align: center;
padding: 25px
`
class LoginPage extends Component {
  state = {
    loading: false,
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const query = `mutation resetPassword($email: String!){
          resetPassword(email: $email)
        }`
        this.setState({ loading: true })
        gql.request(query, { email: values.email })
          .then(res => {
            this.setState({
              loading: false,
            })
            message["success"]("Further instructions have been sent to your email address.")
          })
          .catch(err => {
            const msg = _.get(err, "[0].message", "Register error");
            if (msg.search(/email_UNIQUE/)) {
              notification["error"]({
                message: _.get(err, "[0].message")
              });
            } else {
              notification["error"]({
                message: msg
              });
            }
            this.setState({ loading: false });
          })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    return (
      <div layout="register">
        <Container className={'register-container'}>
          <img src="/logo-black.png" style={{width:150, marginBottom: 20}} alt="POD"/>
          <h1 style={{ color: '#41566B', fontSize: 28, textAlign: 'center' }}>Forgot Password</h1>
          <h5 style={{ color: '#41566B', fontSize: 20, textAlign: 'center' }}>Enter your email to reset password</h5>
          <Form onSubmit={this.handleSubmit} className="register-form" style={{ textAlign: 'initial', marginTop: 40 }}
            layout="horizontal">
            <Form.Item label={'Email'}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    type: 'email',
                    message: 'Please input your email!',
                  }],
              })(
                <Input placeholder="Email" />,
              )}
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit"
                className="login-form-button btn-primary"
                loading={this.state.loading}
                style={{
                  height: 40,
                  width: "100%",
                }}>
                Proceed
                  </Button>
            </Form.Item>
          </Form>
        </Container>
      </div>
    )
  }
}

const LoginPageWrapper = Form.create({ name: 'normal_login' })(LoginPage)

function mapStateToProps(state) {
  const { currentUser } = state.app
  return { currentUser }
}

export default connect(
  mapStateToProps,
)(LoginPageWrapper)