import gql from 'graphql-tag'

export const PRODUCT_TYPES = gql`
    query productTypes($filter: ProductTypeFilter) {
        productTypes(filter: $filter) {
            count
            hits {
                id
                title
                status
                variants: product_type_variants {
                    id
                    is_active
                    attributes {
                        name
                        option
                    }
                }
            }
        }
    }
`

export const CATALOG_PRODUCT_TYPES = gql`
    query productTypes($filter: ProductTypeFilter) {
        productTypes(filter: $filter) {
            count
            hits {
                id
                title
                status
                images
                tag
                variants: product_type_variants {
                    id
                    is_active
                    base_cost
                    attributes {
                        name
                        option
                    }
                }
            }
        }
    }
`

export const CATALOG_PRODUCT_TYPES_ID = gql`
    query productTypeById($id: Int!) {
        productTypeById(id: $id) {
            id
            title
            status
            images
            description_special
            production_time
            shipping_time
            link_template
            file_type_supported
            dpl
            origin
            carriers: product_type_carriers {
                item: carrier {
                    name
                }
            }
            attributes {
                name
                options
            }
            variants: product_type_variants {
                id
                is_active
                base_cost
                other_base_cost
                sku
                fee_ship
                attributes {
                    name
                    option
                }
            }
        }
    }
`
