import gql from 'graphql-tag'

export const LIST_GUIDELINE_POLICY = gql`
    query policies($filter: PolicyFilter) {
        policies(filter: $filter) {
            count
            hits {
                id
                title
                description
                type
                sorting
            }
        }
    }
`

export const DETAIL_GUIDELINE_POLICY = gql`
    query policyById($id: Int!) {
        policyById(id: $id) {
            id
            title
            description
            type
            sorting
        }
    }
`
