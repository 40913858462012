import gql from "graphql-tag";

export const MAPPING_ORDERS = gql`
  query listMappingOrder($filter: MappingFilter!) {
    listMappingOrder(filter: $filter) {
      count
      hits {
        id
        site_id
        site {
          id
          title
          platform
        }
        product_origin_id
        order_origin_id
        variant_origin_id
        line_item_id
        price
        sku
        quantity
        metaData
        variants
        images
        product
        created_at
        mapped
        platform
        personalized
        print_file_url
        tags
      }
    }
  }
`;
