import React from 'react'
import { connect } from 'react-redux'
import { setOrdersFilter } from '../../actions'
import { Select, Input, Button, Row, Col, message, Popover, Icon } from 'antd'
import gql from '../../api/gql'
import _ from 'lodash'
import moment from 'moment'
import DateRange from '../DateRange'

class SellerOrdersFilter extends React.Component {
    state = {
        sites: [],
        productTypes: [],
        suppliers: [],
        sellers: [],
        filter: { ...this.props.filter },
    }

    componentDidMount() {
        this.loadSites()
        this.loadProductType()
    }
    componentWillUnmount() {
        this.resetFilter()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filter !== this.props.filter) {
            this.setState({
                filter: { ...this.props.filter },
            })
        }
    }

    loadSites() {
        const query = `query sites{sites{hits{id,title,platform}}}`
        gql.request(query).then((res) => {
            this.setState({ sites: res.sites.hits || [] })
        })
    }

    loadProductType() {
        const query = `query productTypes{
      productTypes(filter: {limit: 9999}){
        hits{
          id title
        }
      }
    }`
        gql.request(query)
            .then((res) => {
                this.setState({ productTypes: res.productTypes.hits || [] })
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
            })
    }

    onChangeDate = (range, callback = () => {}) => {
        if (range.from !== null && range.to !== null) {
            this.setState(
                {
                    filter: {
                        ...this.state.filter,
                        from: moment(range.from).format('YYYY-MM-DD[T]00:00:00[Z]'),
                        to: moment(range.to).format('YYYY-MM-DD[T]00:00:00[Z]'),
                    },
                },
                callback,
            )
        } else {
            this.setState(
                {
                    filter: {
                        ...this.state.filter,
                        from: null,
                        to: null,
                    },
                },
                callback,
            )
        }
    }

    handleChange(key, value, callback = () => {}) {
        if (_.isArray(value)) {
            value = value.filter((item) => item !== null)
        }
        const { filter } = this.state
        filter[key] = value
        this.setState({ filter: { ...filter } }, callback)
    }

    applyFilter = () => {
        const { setOrdersFilter } = this.props
        setOrdersFilter(this.state.filter)
    }

    resetFilter = () => {
        const { setOrdersFilter } = this.props
        setOrdersFilter({
            site_ids: Array.isArray(this.props.filter?.site_ids) ? this.props.filter?.site_ids : null,
            product_type_ids: null,
            supplier_ids: null,
            seller_ids: null,
            from: null,
            to: null,
            search: null,
            status: null,
            field: 'all',
        })
    }

    render() {
        const { sellers, suppliers, sites, productTypes, filter } = this.state
        const { currentUser } = this.props
        const content = (
            <div style={{ width: 250 }}>
                <Select
                    showSearch
                    optionFilterProp='children'
                    value={filter.product_type_ids ? filter.product_type_ids[0] : []}
                    placeholder='Product types'
                    style={{
                        Width: 250,
                        width: '100%',
                        marginTop: 10,
                        marginBottom: 15,
                    }}
                    onChange={(v) => this.handleChange('product_type_ids', [v], this.applyFilter)}>
                    <Select.Option value={null}>All Product Types</Select.Option>
                    {productTypes.map((type) => (
                        <Select.Option key={type.id}>{type.title}</Select.Option>
                    ))}
                </Select>
                {currentUser.roles.find((role) => ['Administrator', 'Supporter'].includes(role.name)) && (
                    <Select
                        showSearch
                        optionFilterProp='children'
                        value={filter.supplier_ids ? filter.supplier_ids[0] : []}
                        placeholder='Supplier'
                        style={{
                            maxWidth: 250,
                            width: '100%',
                            marginBottom: 15,
                        }}
                        onChange={(v) => this.handleChange('supplier_ids', [v], this.applyFilter)}>
                        <Select.Option value={null}>All Suppliers</Select.Option>
                        <Select.Option value={0}>No supplier</Select.Option>
                        {suppliers.map((supplier) => (
                            <Select.Option key={supplier.id}>
                                {supplier.first_name} {supplier.last_name}
                            </Select.Option>
                        ))}
                    </Select>
                )}
                {currentUser.roles.find((role) => ['Administrator', 'Supporter'].includes(role.name)) && (
                    <Select
                        showSearch
                        optionFilterProp='children'
                        value={filter.seller_ids ? filter.seller_ids[0] : []}
                        placeholder='Seller'
                        style={{
                            maxWidth: 250,
                            width: '100%',
                            marginBottom: 15,
                        }}
                        onChange={(v) => this.handleChange('seller_ids', [v], this.applyFilter)}>
                        <Select.Option value={null}>All Sellers</Select.Option>
                        {sellers.map((seller) => (
                            <Select.Option key={seller.id}>
                                {seller.first_name} {seller.last_name}
                            </Select.Option>
                        ))}
                    </Select>
                )}
                {currentUser.roles.find((role) => ['Administrator', 'Supporter'].includes(role.name)) && (
                    <Select
                        showSearch
                        optionFilterProp='children'
                        value={filter.site_ids ? filter.site_ids[0] : []}
                        placeholder='Site'
                        style={{
                            maxWidth: 250,
                            width: '100%',
                            marginBottom: 15,
                        }}
                        onChange={(v) => this.handleChange('site_ids', [v], this.applyFilter)}>
                        <Select.Option value={null}>All Sites</Select.Option>
                        {sites
                            .filter((item) => item.platform === 'api')
                            .map((site) => (
                                <Select.Option key={site.id}>{site.title}</Select.Option>
                            ))}
                    </Select>
                )}
                <Button
                    type='default'
                    style={{ marginBottom: 10 }}
                    onClick={(e) => {
                        e.preventDefault()
                        this.resetFilter()
                    }}>
                    Reset Filter
                </Button>
            </div>
        )
        return (
            <div style={{ width: '100%', display: 'flex' }}>
                {/* <Row className="filter" type="flex"> */}
                {currentUser.roles.find((role) => ['Administrator', 'Supporter'].includes(role.name)) && (
                    <div style={{ width: 90, marginBottom: 20, marginRight: 10 }}>
                        <Popover placement='bottomLeft' content={content} trigger='click'>
                            <Button>
                                Filter <Icon type='filter'></Icon>
                            </Button>
                        </Popover>
                    </div>
                )}
                <div style={{ marginRight: 10, marginBottom: 10 }}>
                    <DateRange onChange={(dateRange) => this.onChangeDate(dateRange, this.applyFilter)}></DateRange>
                </div>

                <Select
                    showSearch
                    placeholder='Product types'
                    style={{
                        maxWidth: 150,
                        width: '100%',
                        marginRight: 10,
                        marginBottom: 10,
                    }}
                    onChange={(v) => this.handleChange('product_type_ids', [v], this.applyFilter)}>
                    <Select.Option value={null}>All Product Types</Select.Option>
                    {productTypes.map((type) => (
                        <Select.Option key={type.id}>{type.title}</Select.Option>
                    ))}
                </Select>
                <Input.Group compact style={{ width: '100%' }}>
                    <Select
                        showSearch
                        optionFilterProp='children'
                        value={filter.field}
                        placeholder='Select Field'
                        style={{
                            width: 200,
                        }}
                        onChange={(v) => {
                            this.handleChange('field', v, null)
                            if (filter.search) {
                                this.applyFilter()
                            }
                        }}>
                        <Select.Option value='all'>All Fields</Select.Option>
                        <Select.Option value='origin_id'>Origin ID</Select.Option>
                        <Select.Option value='origin_number'>Origin Number</Select.Option>
                        <Select.Option value="amazon_order_id">Amazon Order Id</Select.Option>
                        <Select.Option value='id'>POD Order ID</Select.Option>
                        <Select.Option value='method_title'>Method Title</Select.Option>
                        <Select.Option value='sku'>SKU</Select.Option>
                        <Select.Option value='customer_email'>Customer Email</Select.Option>
                    </Select>
                    <Input.Search
                        style={{
                            // maxWidth: 500,
                            width: 'calc(100% - 200px)',
                            marginBottom: 10,
                        }}
                        placeholder='Search...'
                        value={filter.search}
                        onChange={(e) => this.handleChange('search', e.target.value)}
                        onSearch={(e) => {
                            this.applyFilter()
                        }}
                    />
                </Input.Group>

                <div style={{ marginRight: 10, marginBottom: 10, maxWidth: 200 }}>
                    {/* <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                this.applyFilter();
              }}
            >
              Search
            </Button> */}
                    <Button
                        type='default'
                        style={{ marginLeft: 5 }}
                        onClick={(e) => {
                            e.preventDefault()
                            this.resetFilter()
                        }}>
                        Reset
                    </Button>
                </div>
                {/* </Row> */}
            </div>
        )
    }
}

export default connect(
    (state) => ({
        filter: state.orders.filter,
    }),
    { setOrdersFilter },
)(SellerOrdersFilter)
