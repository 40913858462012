import React from 'react'
import S3Upload  from '../Upload'
import { Upload, Button, Modal } from 'antd'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default class ProductImages extends React.Component{
  state = {
    images: this.props.images,
    previewVisible: false,
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };
  
  handleChange = ({ file }) => this.setState({ images: this.state.images.filter((image) =>  image !== file.url) });
  
  uploadImage = ((file) => {
    this.setState ({
      images: [...this.state.images,file.url]
    })
  })

  render () {
    const {images,previewVisible,previewImage} = this.state
    return (
      <div className="clearfix">
        <Upload
        multiple="true"
          accept=".png,.jpg,.dxf" 
          listType="picture-card"
          fileList={ images.map((image,index)=>({
            uid: index,
            url: image,
            thumbUrl: image,
            name: image
          }))}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        > 
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <S3Upload accept=".png,.jpg,.dxf" multiple="true" onUpload={this.uploadImage} fileList={false} style={{color:'white'}} > 
          <Button type="primary" icon="upload">
            Upload
          </Button>
        </S3Upload>
      </div>
    )
  }
}