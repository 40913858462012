import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  notification,
  Row,
  Select,
} from "antd";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { PRODUCT_TYPES } from "../../graphql/query/productTypesQuery";
import { MAPPING_ORDER, MAPPING_ORDER_WITH_PRODUCT_ID } from "../../graphql/mutation/mappingOrder";
import _ from "lodash";
import GetProductLikeSku from "./GetProductLikeSku";
import ProductVariants from "./ProductVariants";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const JOIN_KEY = "/";
const getAttributeKey = (attributes) => {
  return attributes.map((att) => att.option).join(JOIN_KEY);
};
const MappingOrder = (props) => {
  const { order, refetch } = props;
  const [filter, setFilter] = useState({
    status: true,
    personalized: order.personalized,
    limit: -1,
  });
  const { data, loading } = useQuery(PRODUCT_TYPES, {
    fetchPolicy: "no-cache",
    variables: { filter: filter },
  });
  const [mappingOrder, { loading: loadingSave }] = useMutation(MAPPING_ORDER);
  const [mappingOrderWidthProductId, { loading: loadingSave2 }] = useMutation(MAPPING_ORDER_WITH_PRODUCT_ID);
  const [selectType, setSelectType] = useState(null);
  const [variant, setVariant] = useState(null);
  const dataProductTypes = data?.productTypes?.hits;
  const [showAllVariants, setShowAllVariant] = useState(false);
  const { getFieldDecorator, validateFieldsAndScroll } = props.form;
  const [productId, setProductId] = useState(null);
  const [productVariant, setProductVariant] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(true)
  
  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validateFieldsAndScroll((err, values) => {
            if (!err) {
              if (!order.variants) {
                let currentSiteID = localStorage.getItem('currentSiteID') ?? null

                if (productVariant?.id === null || productId === null) {
                  notification.error({ 
                    message: "Select a product and product type variant"
                  });

                  return
                }

                // action to backend
                var variables = {
                  input: {
                    siteId : currentSiteID,
                    productTypeId: productVariant?.product_type_id,
                    productTypeVariantId: productVariant?.product_type_variant?.id,
                    mappingOrderId: order.id,
                    mappingProductId: productId,
                    mappingProductVariantId: productVariant?.id,
                  },
                }

                mappingOrderWidthProductId({
                  variables: variables,
                })
                .then((res) => {
                  console.log(res)
                  notification.success({
                    message: "Order has been mapped",
                  });
                  refetch();
                })
                .catch((err) => {
                  console.log(err)
                  notification.error({ message: _.get(err, "[0].message") });
                });
              
              } else {

                var variants_map = Object.keys(values)
                  .filter(
                    (key) => key.indexOf("variant_id_") === 0 && values[key]
                  )
                  .map((key) => {
                    let arraykey = key.split("_");
                    return {
                      productTypeVariantId: values[key],
                      originVariantId: arraykey[2],
                    };
                  });

                if (variants_map?.length === 0) {
                  notification.error({
                    message: "Variant map empty!"
                  });

                  return
                }

                var variables = {
                  input: {
                    productType: selectType,
                    mappingOrderId: order.id,
                    mappingProduct: variants_map,
                  },
                }
                  
                mappingOrder({
                  variables: variables,
                })
                .then((res) => {
                  notification.success({
                    message: "Order has been mapped",
                  });
                  refetch();
                })
                .catch((err) => {
                  console.log(err)
                  notification.error({ message: _.get(err, "[0].message") });
                });
              }
            }
          });
        }}
      >
      {order.variants ? (
        <div>
          <Checkbox
            style={{ marginBottom: 10 }}
            checked={filter.personalized}
            onChange={(e) => {
              setFilter({ ...filter, personalized: e.target.checked });
              // setVariant(null);
              // setSelectType(null)
              // props.form.resetFields()
            }}
          >
            Personalized
          </Checkbox>
          <Form.Item label="Product type">
            <Select
              style={{ width: "600px" }}
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
              onChange={(value, data) => {
                setSelectType(value);
                props.form.resetFields();
                var variantMap = data.props.variants.map((item) => {
                  return { ...item, key: getAttributeKey(item.attributes) };
                })

                setVariant(
                  data.props.variants.map((item) => {
                    return { ...item, key: getAttributeKey(item.attributes) };
                  })
                );

                setButtonDisable(false)
              }}
            >
              {dataProductTypes?.map((type) => (
                <Select.Option variants={type.variants} key={type.id}>
                  {type.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {selectType && variant ? (
            <div>
                <Form.Item {...layout} label="Variants">  
                    {order.variants?.filter((el) => {
                      if (!showAllVariants) {
                        if (order.variant_origin_id === el.id.toString()) {
                          return el;
                        }
                      } else {
                        return el;
                      }
                    })
                    ?.map((data) => {
                      return (
                        <Row
                          type="flex"
                          gutter={20}
                          key={data.id}
                          style={{
                            // alignItems: "center",
                            borderBottom: "1px solid #f5f5f5",
                            paddingTop: 10,
                            color:
                              data.id.toString() === order.variant_origin_id
                                ? "green"
                                : "unset",
                          }}
                        >
                          <Col span={12}>
                            <div
                              style={{
                                paddingTop: 10,
                                lineHeight: 1.6,
                              }}
                            >
                              {getAttributeKey(data.attributes)}
                              {data.id}
                            </div>
                          </Col>
                          <Col span={12}>
                            <Form.Item className="form-select">
                              {getFieldDecorator(`variant_id_${data.id}`, {
                                rules: [
                                  {
                                    required:
                                      data.id.toString() ===
                                      order.variant_origin_id,
                                    message: "This field is required",
                                  },
                                ],
                              })(
                                <Select
                                  allowClear={true}
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {variant.map((item) => (
                                    <Select.Option key={item.id}>
                                      {item.key}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    })}
                </Form.Item>

                <Form.Item>
                  <div
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowAllVariant(e.target.checked);
                      }}
                    >
                      Show all variants
                    </Checkbox>
                  </div>
                </Form.Item>
              </div>
            ) : ("")
          }
          </div>
        ) : (
          <div>  
            <Form.Item label="SKU">
              {order?.metaData?.map((item) => (
                  <Row key={item.id}>
                    <Col 
                      style={{
                        color: "green",
                      }}
                    >
                      <div>{item.value}</div>
                    </Col>
                    
                    <Form.Item label="Suggest Mapping Product">
                      <GetProductLikeSku 
                        sku={item.value} 
                        onValueChange={(val) => {
                          setProductId(val)
                        }}
                      />
                    </Form.Item>
                    
                    {
                      productId ? (
                        <Form.Item label="Suggest Mapping Product Variant">
                          <ProductVariants 
                            productId={productId}
                            onValueChange={(variant) => {
                              setProductVariant(variant)
                              setButtonDisable(false)
                              console.log(variant)
                            }}
                          />
                        </Form.Item>
                      ) : ""
                    }
                    
                  </Row>
              ))}
            </Form.Item>
          </div>
        )
      }

        <Button
          type="primary"
          htmlType="submit"
          disabled={buttonDisable}
          loading={loadingSave || loadingSave2}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default Form.create({ name: "xx" })(MappingOrder);
