import { Badge, PageHeader, Table, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { trackingstatus } from './StatusTrackings'
import TrackingAction from './TrackingAction'
import { sum } from 'lodash'
import TrackingFilter from './TrackingFilter'
import ExportTracking from './ExportTracking'
import moment from 'moment'
import { TRACKINGS } from '../../graphql/query/trackingQuery'
import { useQuery } from '@apollo/react-hooks'
import { history } from '../../history'
import { connect } from 'react-redux'
import _ from 'lodash'

const TrackingList = (props) => {
    const [filter, setFilter] = useState({
        status: null,
        search: null,
        field: 'all',
        // range: null,
        offset: 0,
        limit: 20,
        site_ids: props.currentUser.roles.map((el) => el.name).includes('Administrator')
            ? null
            : props.currentSite?.id
            ? [props.currentSite.id.toString()]
            : null,
        aggs_by: 'status',
    })

    const [page, setPage] = useState(1)
    const { data, loading, refetch } = useQuery(TRACKINGS, {
        fetchPolicy: 'no-cache',
        variables: { filter: filter.search ? filter : { ...filter, field: 'all' } },
    })
    useEffect(() => {
        if (!props.currentUser.roles.map((el) => el.name).includes('Administrator')) {
            if (props.currentUser.roles.map((el) => el.name).includes('Supplier')) {
                setFilter({
                    ...filter,
                    site_ids: null,
                })
            } else {
                setFilter({
                    ...filter,
                    site_ids: props.currentSite?.id ? [props.currentSite?.id.toString()] : null,
                })
            }
        }
    }, [props.currentSite])
    const columns = [
        {
            title: 'Order ID',
            key: 'orderId',
            dataIndex: 'order_id',
            width: 100,
            render: (_, record) => (
                <div>
                    <p>{`N${record.order_id.toString().padStart(6, '0')}`}</p>
                </div>
            ),
        },
        {
            title: 'Origin Number',
            dataIndex: 'origin_number',
            width: 100,
            render: (_, record) => <div>{record.order?.origin_number && <p>{`${record.order?.origin_number}`}</p>}</div>,
        },
        {
            title: 'Tracking Code',
            key: 'trackingCode',
            dataIndex: 'tracking_code',
            width: 250,
            render: (tracking_code, record) => {
                let trackingUrl = record.carrier_code ? record.carrier.url : ''
                if (trackingUrl) {
                    trackingUrl = trackingUrl.replace('TRACKING_NUMBER', tracking_code)
                }
                return (
                    <a href={trackingUrl} target='_blank'>
                        {tracking_code}
                    </a>
                )
            },
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: 120,
            render: (status) => <div style={{ textTransform: 'capitalize' }}>{status}</div>,
        },
        {
            title: 'Supplier',
            width: 150,
            key: 'supplier',
            render: (record) => (
                <div>
                    {record.order.supplier?.first_name} {record.order.supplier?.last_name}
                </div>
            ),
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            width: 150,
            render: (_, record) => (
                <div>
                    <p>{`${record.order.customer?.first_name} ${record.order.customer?.last_name}`}</p>
                    <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{record.order.customer?.email}</p>
                </div>
            ),
        },
        {
            title: 'Created',
            key: 'created',
            dataIndex: 'created_at',
            width: 150,
            render: (created_at) => <div>{moment(created_at).format('MMM DD, YYYY')}</div>,
        },
        {
            title: 'Updated',
            key: 'updated',
            dataIndex: 'updated_at',
            width: 150,
            render: (updated_at) => <div>{moment(updated_at).format('MMM DD, YYYY')}</div>,
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'left',
            width: 150,
            render: (record) => <TrackingAction refetch={refetch} record={record} />,
        },
    ]
    const pagination = {
        current: page,
        total: data?.trackingList?.count,
        pageSize: filter.limit,
        onChange: (page, pageSize) => {
            setPage(page)
            setFilter({ ...filter, offset: (page - 1) * pageSize })
        },
    }
    // if (loading) return <Skeleton />;
    const tableWidth = sum(columns.map((c) => c.width))
    return (
        <div>
            <PageHeader
                style={{ padding: '16px 0px' }}
                title='Trackings'
                onBack={() => {
                    history.goBack()
                }}
                extra={
                    // button export tracking
                    <ExportTracking filter={filter} currentUser={props.currentUser}></ExportTracking>
                }
            />
            <TrackingFilter setFilter={setFilter} filter={filter} />
            <Tabs
                // type="card"
                onChange={(value) => setFilter({ ...filter, status: value === 'all' ? null : [value] })}
                activeKey={filter.status ? filter.status[0] : 'all'}>
                {trackingstatus?.map((item) => (
                    <Tabs.TabPane
                        key={item.key}
                        tab={
                            <div>
                                <Badge
                                    count={
                                        item.key === 'all'
                                            ? _.sum(data?.trackingList?.aggs?.map((agg) => agg.doc_count))
                                            : _.sum(data?.trackingList?.aggs?.filter((agg) => agg.key === item.key).map((a) => a.doc_count))
                                    }
                                    overflowCount={9999999}
                                    showZero
                                    style={{
                                        right: -10,
                                        top: -5,
                                        backgroundColor: ['notfound', 'exception', 'undelivered'].includes(item.key)
                                            ? 'red'
                                            : item.key === 'delivered'
                                            ? '#52c41a'
                                            : '#999',
                                    }}>
                                    {item.name}
                                </Badge>
                            </div>
                        }>
                        <div>
                            <Table
                                scroll={{ x: tableWidth }}
                                rowKey={(record, index) => record.id}
                                columns={columns}
                                dataSource={data?.trackingList?.hits}
                                pagination={pagination}
                                loading={loading}
                            />
                        </div>
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    )
}

export default connect((state) => ({
    currentUser: state.app.currentUser,
    currentSite: state.app.currentSite,
}))(TrackingList)
