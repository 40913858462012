import React from "react";
import { VariantPartnerMappingPrintAreaSelect } from "./VariantPartnerMappingPrintAreaSelect";

export const BulkEditVariantPrintAreaWithPartnerPrintArea = ({printAreas, partnerFulfillments, onChangeBulkEditMapping}) => {
    const handleSelectChange = (value, partner) => {
        value.partner_id = partner.id
        onChangeBulkEditMapping(value)
    }

    return (
        <>
            {partnerFulfillments?.length > 0 && partnerFulfillments?.map(partner => (
                <div
                    style={{
                        marginBottom: "15px"
                    }}
                >
                    <b>{partner.name}</b>

                    {printAreas?.map(area => (
                        <>
                            <VariantPartnerMappingPrintAreaSelect
                                area={area}
                                partnerAreas={partner.print_areas ?? null}
                                onChange={(value) => handleSelectChange(value, partner)}
                            />
                        </>
                    ))}
                </div>
            ))}
        </>
    )
}