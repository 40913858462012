
import React, { useState } from "react"
import { useQuery } from "@apollo/react-hooks";
import { PRODUCT_TYPE_VARIANT } from "../../graphql/query/productTypeVariant";
import {Select} from "antd";

export default function ProductVariants(props)
{
    const {productId} = props

    const { data, loading } = useQuery(PRODUCT_TYPE_VARIANT, {
        variables: { product_id: productId },
    });

    const productVariants = data?.productVariant

    const [state, setState] = useState({
        id: null
    })

    const handleOnChange = (val) => {
        setState({...state, id: val});

        if (props.onValueChange) {
            const variant = productVariants.filter((variant) => {
                if (parseInt(variant.id) === parseInt(val)) {
                    return variant
                }
            })

            props.onValueChange(variant?.[0])
        }
    }

    return (
        <>
            <Select
                style={{ width: "600px", maxWidth: "600px" }}
                allowClear={true}
                placeholder="Get product variant"
                onChange={handleOnChange}
                value={state.id}
                loading={loading}
            >
                {productVariants?.map((variant) => (
                    <Select.Option key={variant.id}>
                        {variant.sku}
                    </Select.Option>
                ))}
            </Select>
        </>
    )
}