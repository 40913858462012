import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks'
import { Button, notification, Modal, Tag, Input, Icon, Menu } from 'antd'
import { EXPORTORDERS, SUPPLIEREXPORTORDER } from '../../graphql/mutation/exportOrder'

ExportOrder.propTypes = {};

function ExportOrder(props) {

    // regex string to validate email input
    const regexEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g)

    // get props field value from list order
    const { filter, currentUser, order_id, displayBtn } = props

    const [modalExportOrder, setModalExportOrder] = useState({
        modalVisible: false,
        loadingConfirm: false,
        inputVisible: false,
        inputAddEmail: '',
    })

    const [receiverEmail, setReceiverEmail] = useState([])

    // init array email when render and first item is email of user
    useEffect(() => {
        if (currentUser.email && regexEmail.test(currentUser.email)) {
            setReceiverEmail([currentUser.email])
        }
    }, [])

    // init instance api export order
    const [exportOrder, { }] = useMutation(EXPORTORDERS)
    const [supplierExportOrder, { }] = useMutation(SUPPLIEREXPORTORDER)

    // call api export order and return response
    function showExportOrder() {

        setModalExportOrder({
            ...modalExportOrder,
            modalVisible: true,
        })
    }

    // cancel modal export order and reset all value
    function cancelExportOrder() {
        setModalExportOrder({
            ...modalExportOrder,
            modalVisible: false,
            loadingConfirm: false,
        })
    }

    // processing data and call api export order
    function handleExportOrder() {

        if (receiverEmail == null || receiverEmail.length == 0) {
            notification.warning({ message: "you have not entered the receiver's email address" })
            return
        }

        setModalExportOrder({
            ...modalExportOrder,
            loadingConfirm: true,
        })

        // check currentUser is seller or supplier
        let isSupplier = currentUser.roles.find(
            (role) => ['Supplier'].includes(role.name)
        )

        if (isSupplier && isSupplier.name === 'Supplier') {

            // cal api supllier export order with params and show notification
            supplierExportOrder({
                variables: {
                    filter: {
                        aggs_by: filter.aggs_by,
                        field: filter.field,
                        from: filter?.from ? filter.from : null,
                        search: filter.search,
                        site_ids: filter?.site_ids ? filter.site_ids : null,
                        status: filter?.status ? filter.status : null,
                        to: filter?.to ? filter.to : null,
                        order_id: order_id,
                        supplier_ids: filter.supplier_ids,
                        api_order: filter.api_order,
                        limit: filter.limit,
                        offset: filter.offet,
                        product_type_ids: filter.product_type_ids,
                    },
                    emails: receiverEmail,
                },
            }).then(() => {
                notification.success({ message: 'Export orders success! Please check your email again' })
                setReceiverEmail([currentUser.email])
                setModalExportOrder({
                    ...modalExportOrder,
                    loadingConfirm: false,
                    modalVisible: false,
                    inputVisible: false,
                    inputAddEmail: '',
                })
            }).catch((err) => {
                notification.error({ message: err.message })
                setReceiverEmail([currentUser.email])
                setModalExportOrder({
                    ...modalExportOrder,
                    loadingConfirm: false,
                    modalVisible: false,
                    inputVisible: false,
                    inputAddEmail: '',
                })
            })

        } else {

            // cal api export order with params and show notification
            exportOrder({
                variables: {
                    filter: {
                        aggs_by: filter.aggs_by,
                        field: filter.field,
                        from: filter?.from ? filter.from : null,
                        search: filter.search,
                        site_ids: filter?.site_ids ? filter.site_ids : null,
                        status: filter?.status ? filter.status : null,
                        to: filter?.to ? filter.to : null,
                        order_id: order_id,
                        supplier_ids: filter.supplier_ids,
                        api_order: filter.api_order,
                        limit: filter.limit,
                        offset: filter.offet,
                        product_type_ids: filter.product_type_ids,
                    },
                    emails: receiverEmail,
                },
            }).then(() => {
                notification.success({ message: 'Export orders success! Please check your email again' })
                setReceiverEmail([currentUser.email])
                setModalExportOrder({
                    ...modalExportOrder,
                    loadingConfirm: false,
                    modalVisible: false,
                    inputVisible: false,
                    inputAddEmail: '',
                })
            }).catch((err) => {
                notification.error({ message: err.message })
                setReceiverEmail([currentUser.email])
                setModalExportOrder({
                    ...modalExportOrder,
                    loadingConfirm: false,
                    modalVisible: false,
                    inputVisible: false,
                    inputAddEmail: '',
                })
            })
        }
    }

    // remove email that client inputed
    function removeEmail(selectedEmail) {
        const arrEmail = receiverEmail.filter((email) => email != selectedEmail)
        setReceiverEmail(arrEmail)
    }

    // show input add email when client click btn add email
    function showInputAddEmail() {
        setModalExportOrder({
            ...modalExportOrder,
            inputVisible: true,
            inputFocus: true,
        })
    }

    // get value of input
    function changeInputAddEmail(e) {
        setModalExportOrder({
            ...modalExportOrder,
            inputAddEmail: e.target.value,
        })
    }

    // handle when client input email and push it into list email
    function handleInputAddEmail() {
        const newEmail = modalExportOrder.inputAddEmail

        const arrEmail = receiverEmail

        if (newEmail && receiverEmail.indexOf(newEmail) === -1 && regexEmail.test(newEmail) && receiverEmail.length < 5) {
            arrEmail.push(newEmail)
        }

        setReceiverEmail(arrEmail)

        setModalExportOrder({
            ...modalExportOrder,
            inputVisible: false,
            inputAddEmail: '',
        })
    }

    return (
        <div>
            {displayBtn ? (
                <Button type='primary' icon='export' onClick={() => showExportOrder()}
                    disabled={currentUser.roles.find((role) => ['Supplier'].includes(role.name) &&
                        (!filter.status.includes("ready") || filter.status.length > 1))}
                >
                    Export Order
                </Button>
            ) : (
                <span onClick={() => showExportOrder()}>
                    Export Orders
                </span>
            )}

            <Modal
                title='Receiver Email'
                visible={modalExportOrder.modalVisible}
                onOk={() => handleExportOrder()}
                confirmLoading={modalExportOrder.loadingConfirm}
                onCancel={() => cancelExportOrder()}>
                <div>
                    {/* DOM show list email */}
                    {receiverEmail.map((email) => {
                        const element = (
                            <Tag color='#2db7f5' style={{ marginBottom: '10px' }} key={email} closable={true} onClose={() => removeEmail(email)}>
                                {email}
                            </Tag>
                        )

                        return element
                    })}

                    {/* DOM insert email */}
                    <div>
                        {modalExportOrder.inputVisible && (
                            <Input
                                type='text'
                                size='small'
                                style={{ width: '100%' }}
                                value={modalExportOrder.inputAddEmail}
                                onChange={changeInputAddEmail}
                                onBlur={handleInputAddEmail}
                                onPressEnter={handleInputAddEmail}
                            />
                        )}

                        {!modalExportOrder.inputVisible && (
                            <Tag onClick={showInputAddEmail} style={{ background: '#fff', borderStyle: 'dashed' }}>
                                <Icon type='plus' /> Add Email
                            </Tag>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ExportOrder;