import React, { Component } from "react";
import gql from "../../api/gql";
import { notification, Form, Select, Input } from "antd";
import _ from "lodash";

class AddTracking extends Component {
  state = {
    loading: false,
    carriers: [],
    carriersSelect: this.props.order?.carrier?.id,
  };
  onSubmit = (e) => {
    const { order, setLoading } = this.props;
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        let query = `mutation addTracking($order_id: Int!, $carrier_id: Int!, $tracking_code: String!, $base_cost: Float!, $shipping_cost: Float!){
      addTracking(order_id: $order_id, carrier_id: $carrier_id, tracking_code: $tracking_code, base_cost: $base_cost, shipping_cost: $shipping_cost){
        id
      }
    }`;

        gql
          .request(query, {
            order_id: order.id,
            carrier_id: parseInt(values.carrier_id),
            tracking_code: values.tracking_code,
            base_cost: (values.base_cost !== '' && values.base_cost >= 0) ? values.base_cost : -1,
            shipping_cost: (values.shipping_cost !== '' && values.shipping_cost >= 0) ? values.shipping_cost : -1,
          })
          .then(() => {
            this.setState({ loading: false });
            notification.success({
              message: "Tracking added",
            });
            this.props.onClose();
          })
          .catch((err) => {
            this.setState({ loading: false });
            setLoading(false);
            notification.error({
              message: _.get(err, "[0].message"),
            });
          });
      }
    });
  };

  reSubmitTrack = (e) => {
    const { order } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        let query = `mutation addTracking($order_id: Int!, $carrier_id: Int!, $tracking_code: String!, $base_cost: Float!, $shipping_cost: Float!){
      addTracking(order_id: $order_id, carrier_id: $carrier_id, tracking_code: $tracking_code, base_cost: $base_cost, shipping_cost: $shipping_cost){
        id
      }
    }`;
        gql
          .request(query, {
            order_id: order.id,
            carrier_id: parseInt(values.carrier_id),
            tracking_code: values.tracking_code,
            base_cost: (values.base_cost !== '' && values.base_cost >= 0) ? values.base_cost : -1,
            shipping_cost: (values.shipping_cost !== '' && values.shipping_cost >= 0) ? values.shipping_cost : -1,
          })
          .then((res) => {
            let reSubmitTM = `mutation reSubmitTrackingMore($id: ID!) {
            reSubmitTrackingMore(id: $id)
          }`;
            gql
              .request(reSubmitTM, {
                id: res.addTracking.id,
              })
              .then(() => {
                this.setState({ loading: false });
                notification.success({
                  message: "Save and re-submit tracking success!",
                });
              })
              .catch((err) => {
                this.setState({ loading: false });
                notification.error({
                  message: `Re-submit tracking error: ${_.get(
                    err,
                    "[0].message"
                  )}`,
                });
              });
          })
          .catch((err) => {
            this.setState({ loading: false });
            notification.error({
              message: `Save tracking error: ${_.get(err, "[0].message")}`,
            });
          });
      }
    });
    this.props.done();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.onOk !== this.props.onOk) {
      if (this.props.onOk) {
        this.reSubmitTrack();
      }
    }
  }

  loadOrderCarriers = (order_id) => {
    let query = `query order{
      order(id: ${order_id}){
        id,
        carrier{
          id
          name
        }
        custom_order
        variant{
          product_type{
            product_type_carriers{
              carrier{
                id
                name
              }
              price
            }
          }
        }
        product_type_variant{
          id
          product_type_id
          product_type{
            id
            title
            product_type_carriers{
              carrier{
                id
                name
              }
              price
            }
          }
        }
      }
    }`;
    this.setState({ loadingMenuIcon: "loading" });
    gql
      .request(query)
      .then((res) => {
        this.setState({
          carriers: res.order.custom_order
            ? res.order.product_type_variant.product_type.product_type_carriers
            : res.order.variant.product_type.product_type_carriers,
          loadingMenuIcon: "down",
        });
        const missingCarrier = res.order.custom_order
          ? res.order.product_type_variant.product_type.product_type_carriers?.find(
            (car) => car.carrier.id === this.props.order?.carrier?.id
          )
          : res.order.variant.product_type.product_type_carriers?.find(
            (car) => car.carrier.id === this.props.order?.carrier?.id
          );
        if (!missingCarrier) {
          this.props.setDisbaleTracking(true);
        }
        //console.log("carriers", this.state.carriers, "order-id", order_id)
      })
      .catch((err) => {
        this.setState({
          loadingMenuIcon: "down",
        });
        notification.error({
          message: _.get(err, "[0].message"),
        });
      });
  };

  componentDidMount() {
    const { order } = this.props;
    if (order) {
      this.loadOrderCarriers(order.id);
    }
  }

  render() {
    const { order, setDisbaleTracking } = this.props;
    const { getFieldDecorator } = this.props.form;
    //const carriers = order.variant.product_type.product_type_carriers
    const { carriers, carriersSelect } = this.state;
    const missingCarrier =
      carriers && carriers.length > 0
        ? carriers.find((car) => car.carrier.id === carriersSelect)
        : true;
    return (
      <div>
        <Form id="add-tracking" onSubmit={this.onSubmit}>
          <Form.Item
            style={{ marginBottom: 20 }}
            validateStatus={!missingCarrier ? "error" : null}
            help={!missingCarrier ? `Carrier ${order?.carrier?.name} has been removed, Please select
            carrier again!` : null}
          >
            {getFieldDecorator("carrier_id", {
              initialValue: order.carrier ? order.carrier.id : null,
              rules: [
                {
                  required: true,
                  message: "Please select carrier!",
                },
              ],
            })(
              <Select
                style={{ width: 300, marginRight: 10 }}
                placeholder="Please select Carrier"
                onChange={(value) => {
                  this.setState({ carriersSelect: value });
                  setDisbaleTracking(false);
                }}
              >
                {carriers &&
                  carriers.map((carrier) => (
                    <Select.Option
                      key={carrier?.carrier?.id}
                      value={carrier?.carrier?.id}
                    >
                      {carrier?.carrier?.name}
                    </Select.Option>
                  ))}
                {!missingCarrier && (
                  <Select.Option
                    key={order?.carrier?.id}
                    value={order?.carrier?.id}
                  >
                    {order?.carrier?.name}
                  </Select.Option>
                )}
              </Select>
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator("tracking_code", {
              initialValue: order.tracking
                ? order.tracking.tracking_code
                : null,
              rules: [
                {
                  required: true,
                  message: "Please input tracking code!",
                },
              ],
            })(<Input placeholder="Tracking code" />)}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator("base_cost", {
              initialValue: order.base_cost
                ? order.base_cost
                : 0,
              rules: [
                {
                  required: true,
                  message: "Please enter total cost!",
                },
              ],
            })(<Input placeholder="Total Cost" type="number" />)}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator("shipping_cost", {
              initialValue: order.shipping_cost
                ? order.shipping_cost
                : 0,
              rules: [
                {
                  required: false,
                },
              ],
            })(<Input placeholder="Shipping Cost" type="number" />)}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create({ name: "add-tracking" })(AddTracking);
