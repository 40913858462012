import React from "react";
import { connect } from "react-redux";
import { Select, Row, Col, message, Button, Input } from "antd";
import gql from "../../api/gql";
import _ from "lodash";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;
class ProductsFilter extends React.Component {
  constructor(props) {
    super(props);
    var state = {
      fetching: false,
      sites: [],
      productTypes: [],
      filter: {
        search: null,
        site_ids: null,
        product_type_ids: null,
      },
      productTypeFilter: {
        status: true,
        limit: 1000,
      },
    };
    const { currentUser, currentSite } = props;
    if (currentUser.roles.map((role) => role.name).includes("Seller")) {
      state.filter.site_ids = currentSite ? [currentSite.id] : [];
    }
    this.state = state;
  }

  componentDidMount() {
    this.fetchSites();
    this.loadProductType();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentSite !== prevProps.currentSite) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            site_ids: [this.props.currentSite.id],
          },
        },
        () => this.applyFilter()
      );
    }
  }

  loadProductType() {
    const query = `query productTypes($filter: ProductTypeFilter){
      productTypes(filter: $filter){
        hits{
          id title
        }
      }
    }`;
    gql
      .request(query, {
        filter: { ...this.state.productTypeFilter, personalized: false },
      })
      .then((res) => {
        this.setState({ productTypes: res.productTypes.hits || [] });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  }

  fetchSites() {
    const query = `query sites{sites{hits{id,title}}}`;
    gql
      .request(query)
      .then((res) => {
        this.setState({ sites: res.sites.hits || [] });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  }

  applyFilter = () => {
    const { onChange } = this.props;
    if (typeof onChange === "function") {
      onChange(this.state.filter);
    }
  };

  handleChange(key, value) {
    if (_.isArray(value)) {
      value = value.filter((item) => item !== null);
    }
    const { filter } = this.state;
    filter[key] = value;
    this.setState({ filter: { ...filter } }, () =>
      this.props.onChange(this.state.filter)
    );
  }
  resetFilter = () => {
    const { currentUser, currentSite } = this.props;
    this.setState(
      {
        filter: {
          search: null,
          site_ids: currentUser.roles
            .map((role) => role.name)
            .includes("Seller")
            ? [currentSite.id]
            : null,
          product_type_ids: null,
        },
      },
      () => this.props.onChange(this.state.filter)
    );
    // this.props.onChange(this.state.filter);
  };
  render() {
    const { sites, productTypes, filter } = this.state;
    const { currentUser } = this.props;
    if (!currentUser) return null;
    return (
      <Container>
        <div style={{ display: "flex", gap: 10 }}>
          {!currentUser.roles.map((role) => role.name).includes("Seller") && (
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Site"
              value={filter.site_ids}
              onChange={(v) => this.handleChange("site_ids", [v])}
              style={{
                maxWidth: 250,
                width: "100%",
                marginRight: 10,
                marginBottom: 10,
              }}
            >
              <Select.Option value={null}>All Sites</Select.Option>
              {sites.map((s) => (
                <Select.Option key={s.id} value={s.id}>
                  {s.title}
                </Select.Option>
              ))}
            </Select>
          )}
          <Input.Group compact>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Product types"
              style={{
                width: 200,
                marginBottom: 10,
              }}
              value={filter.product_type_ids}
              onChange={(v) => this.handleChange("product_type_ids", [v])}
            >
              <Select.Option value={null}>All Product Types</Select.Option>
              {productTypes.map((type) => (
                <Select.Option key={type.id} value={type.id}>
                  {type.title}
                </Select.Option>
              ))}
            </Select>
            <Input.Search
              style={{
                width: "calc(100% - 200px)",
                height: "fit-content",
                marginBottom: 10,
              }}
              value={filter.search}
              placeholder="Search..."
              onChange={(e) => this.handleChange("search", e.target.value)}
              onSearch={() => {
                this.applyFilter();
              }}
            />
          </Input.Group>

          {/* <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              this.applyFilter();
            }}
          >
            Apply
          </Button> */}
          <Button
            type="default"
            style={{ marginLeft: 5 }}
            onClick={(e) => {
              e.preventDefault();
              this.resetFilter();
            }}
          >
            Reset
          </Button>
        </div>
      </Container>
    );
  }
}

export default connect((state) => ({
  currentSite: state.app.currentSite,
  currentUser: state.app.currentUser,
}))(ProductsFilter);
