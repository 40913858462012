import {
  Button,
  Card,
  Collapse,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
} from "antd";
import React, { Component } from "react";
import gql from "../../api/gql";
import _ from "lodash";

class SettingTracking extends Component {
  onFinish = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const mutation = `mutation settingTrackingMore($apiKey: String!, $limitTime: String!){
          settingTrackingMore(apiKey: $apiKey,limitTime:$limitTime)
        }`;

        gql
          .request(mutation, {
            apiKey: values.apiKey,
            limitTime: `${values.numberTime} ${values.stringTime}`,
          })
          .then((res) => {
            notification["success"]({
              message: " setting tracking successfully ",
            });
          })
          .catch((err) => {
            notification.error({ message: _.get(err, "[0].message") });
          });
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { data } = this.props;

    return (
      <Card>
        <Form onSubmit={this.onFinish}>
          <Collapse defaultActiveKey={["1"]}>
            <Collapse.Panel header="General settings" key="1">
              <p>On Hold Order</p>
              <div style={{ display: "flex" }}>
                <Form.Item name="numberTime">
                  {getFieldDecorator("numberTime", {
                    initialValue:
                      data
                        .find(
                          (item) => item.name === "tracking_more_delay_time"
                        )
                        ?.value.split(" ")[0] ?? null,
                    rules: [
                      {
                        required: true,
                        message: "Please input your limitTime!",
                      },
                    ],
                  })(<InputNumber min={0} />)}
                </Form.Item>
                <Form.Item name="stringTime">
                  {getFieldDecorator("stringTime", {
                    initialValue:
                      data
                        .find(
                          (item) => item.name === "tracking_more_delay_time"
                        )
                        ?.value.split(" ")[1] ?? "hour",
                    rules: [
                      {
                        required: true,
                        message: "Please input your limitTime!",
                      },
                    ],
                  })(
                    <Select style={{ width: 100, marginLeft: 20 }}>
                      <Select.Option value="hour">Hour(s)</Select.Option>
                      <Select.Option value="day">Day(s)</Select.Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              Trackingmore API
              <Form.Item name="apiKey">
                {getFieldDecorator("apiKey", {
                  initialValue:
                    data.find((item) => item.name === "tracking_more_api_key")
                      ?.value ?? "",
                  rules: [
                    { required: true, message: "Please input your apiKey!" },
                  ],
                })(<Input />)}
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

export default Form.create({ name: "form" })(SettingTracking);
