export const API_URL = 'https://staging.podgasus.com/api'
export const APP_URL = 'https://staging.podgasus.com'
export const SHOPIFY_API = 'a225a875beddddf64f353f3e246862f1'
export const SHOPBASE_API = 'bcbbb3567ee40943462324d482048f2e'
export const CLOUDFRONT_URL = 'https://da1901i86t6i4.cloudfront.net'
export const PAYPAL_ENV = 'sandbox' //(MODE === 'dev' && origin === 'http://localhost:3000') ? 'sandbox' : 'production'; // you can set here to 'production' for production (sandbox)
export const CURRENCY = 'USD' // or you can set this value from your props or state
// Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/
export const PAYPAL_SANDBOX_KEY = 'xxx'
export const PAYPAL_PRODUCTION_KEY = 'xxx'
export const CLOUDFRONT_THUMB_URL = 'https://images.podgasus.com'
export const S3_BUCKET = 'niftypod'
export const URL_ORDER = "https://assets.podgasus.com"