import { useMutation } from "@apollo/react-hooks";
import { Button, Icon, Popconfirm, Popover } from "antd";
import React, { useState } from "react";
import { REMOVE_TRACKING_ORDER } from "../../graphql/mutation/mappingOrder";
import MappingOrder from "./MappingOrder";
import MappingOrderShopify from "./MappingOrderShopify";

const ExternalOrdersAction = ({ record, refetch }) => {
  const [removerOrdermapping] = useMutation(REMOVE_TRACKING_ORDER);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
        gap: 10,
      }}
    >
      <Popover
        title="Mapping Order"
        trigger="click"
        placement="left"
        content={
          record.site.platform === "shopify" ? (
            <MappingOrderShopify order={record} refetch={refetch} />
          ) : (
            <MappingOrder order={record} refetch={refetch} />
          )
        }
      >
        <Button type="primary" ghost>
          <Icon type="api" /> Mapping
        </Button>
      </Popover>
      <Popconfirm
        title="Are you sure remove order?"
        onConfirm={() => {
          removerOrdermapping({ variables: { ids: [record.id] } }).then(
            (res) => {
              refetch();
            }
          );
        }}
      >
        <Button type="danger" ghost>
          <Icon type="delete" />
          Remove
        </Button>
      </Popconfirm>
    </div>
  );
};

export default ExternalOrdersAction;
