import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, PageHeader, Card, Input, Divider, notification, Row, Col, Select } from 'antd'
import { history } from '../../../history'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ATTRIBUTE_TERM_BY_ID } from '../../../graphql/query/attributeTerm'
import { ATTRIBUTES } from '../../../graphql/query/attribute'
import { CREATEATTRIBUTETERM, UPDATEATTRIBUTETERM } from '../../../graphql/mutation/attributeTerm'

AttributeTermForm.propTypes = {}

function AttributeTermForm(props) {
    // init state component
    const [params, setParams] = useState({
        loading: false,
        attrTermID: props.match.params.id ? props.match.params.id : null,
    })

    // get list attribute by call api
    const { data: listAttributes } = useQuery(ATTRIBUTES, {
        fetchPolicy: 'no-cache',
        variables: {
            limit: null,
            offset: null,
        },
    })

    // get attribute term by id if attrTermID is exist
    const { data: attributeTerm } = useQuery(ATTRIBUTE_TERM_BY_ID, {
        skip: params.attrTermID ? false : true,
        fetchPolicy: 'no-cache',
        variables: {
            id: params.attrTermID,
        },
    })

    // init function call api create attribute term
    const [createAttributeTerm, {}] = useMutation(CREATEATTRIBUTETERM)

    // init function call api update attribute term
    const [updateAttributeTerm, {}] = useMutation(UPDATEATTRIBUTETERM)

    // init validate function for form
    const { getFieldDecorator } = props.form

    // func handle event when client change select attribute
    function changeAttribute(e) {}

    // func handle event when client submit form
    function saveAttributeTerm(e) {
        e.preventDefault()

        props.form.validateFields((err, value) => {
            if (!err) {
                // validate each field before send request
                if (!value.attribute || value.attribute === 0) {
                    notification.warning({ message: 'Please select a Attribute!' })
                    return
                } else {
                    var isSize = false

                    // check selected attribute is Size
                    listAttributes.getAttributes.hits.map((attribute) => {
                        if (value.attribute === attribute.id && attribute.name === 'size') {
                            isSize = true
                            return
                        }
                    })

                    if (isSize && value.sku.length > 10) {
                        // if attribute is size and sku larger 10 character, return error
                        notification.warning({ message: 'With size attribute, the maximum length of sku is 10 characters!' })
                        return
                    } else if (!isSize && value.sku.length > 5) {
                        // if attribute is not size and sku larger 5 character, return error
                        notification.warning({ message: 'With other attribute, the maximum length of sku is 5 characters!' })
                        return
                    }
                }

                if (params.attrTermID) {
                    // if attrTermID is exist, proccessing is edit attribute term
                    updateAttributeTerm({
                        variables: {
                            input: {
                                id: params.attrTermID,
                                option_name: value.option_name,
                                sku: value.sku,
                            },
                        },
                    })
                        .then(() => {
                            notification.success({ message: 'Update Attribute Term is successful!' })
                            history.goBack()
                        })
                        .catch((err) => {
                            notification.error({ message: err.message })
                        })
                } else {
                    // if attrTermID is not exist, proccessing is create attribute term
                    createAttributeTerm({
                        variables: {
                            input: {
                                attribute_id: value.attribute,
                                option_name: value.option_name,
                                sku: value.sku,
                            },
                        },
                    })
                        .then(() => {
                            notification.success({ message: 'Create Attribute Term is successful!' })
                            history.goBack()
                        })
                        .catch((err) => {
                            notification.error({ message: err.message })
                        })
                }
            }
        })
    } // end func handle event when client submit form

    return (
        <div>
            <PageHeader
                style={{ padding: '16px 0px' }}
                title={params.attrTermID ? 'Edit Attribute Term' : 'Add Attribute Term'}
                onBack={() => {
                    history.goBack()
                }}
            />

            <Form onSubmit={saveAttributeTerm}>
                <Row gutter={20}>
                    <Col>
                        <Card title='Detail' style={{ marginBottom: 20 }}>
                            {/* input option_name attribute term */}
                            <Form.Item label='Name'>
                                {getFieldDecorator('option_name', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input name',
                                        },
                                    ],
                                    initialValue: attributeTerm ? attributeTerm?.getAttributeTermById?.option_name : '',
                                })(<Input maxLength={50} />)}
                            </Form.Item>

                            {/* input sku attribute term */}
                            <Form.Item label='SKU'>
                                {getFieldDecorator('sku', {
                                    rules: [{ required: true, message: 'Please input sku' }],
                                    initialValue: attributeTerm ? attributeTerm?.getAttributeTermById?.sku : '',
                                })(<Input maxLength={10} />)}
                            </Form.Item>

                            {/* select attribute */}
                            <Form.Item label='Attribute'>
                                {getFieldDecorator('attribute', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select attribute',
                                        },
                                    ],
                                    initialValue: attributeTerm ? attributeTerm?.getAttributeTermById?.attribute_id : null,
                                })(
                                    <Select placeholder='Attributes' onChange={changeAttribute} disabled={attributeTerm ? true : false}>
                                        {listAttributes?.getAttributes?.hits.map((attr) => (
                                            <Select.Option key={attr.id} value={attr.id}>
                                                {attr.name}
                                            </Select.Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>

                            {/* button save and cancel */}
                            <Form.Item>
                                <Button type='primary' htmlType='submit' loading={params.loading}>
                                    Save
                                </Button>
                                <Divider type='vertical' />
                                <Button onClick={() => history.goBack()}>Cancel</Button>
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default Form.create({ name: 'form' })(AttributeTermForm)
