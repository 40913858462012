import rest from '../api/rest'
import _ from 'lodash'
import shortuuid from 'short-uuid'
import { randomAlphaBets } from './helper'

export const Woocommerce = {
  async pushProduct(site_id, productTypes, product) {
    var attributes = [];
    let default_attributes = [];
    if (productTypes.length > 1) {
      attributes.push({
        name: 'Type',
        visible: true,
        variation: true,
        options: productTypes.map(product_type => product_type.title)
      })
    }

    product.variants.forEach(variant => {
      variant.attributes.forEach(attribute => {
        let index = _.findIndex(attributes, attr => attr.name === attribute.name)
        if (index !== -1) {
          attributes[index].options = _.union(attributes[index].options, [attribute.option])
        } else {
          attributes.push({
            name: attribute.name,
            visible: true,
            variation: true,
            options: [attribute.option]
          })
        }
      })
    })
    if (attributes.length) {
      attributes.forEach(attribute => {
        default_attributes.push({
          name: attribute.name,
          option: attribute.options[0]
        })
      })
    }

    return new Promise(async (resolve, reject) => {
      //Create master product
      let uuidProduct = randomAlphaBets(2) + (Math.floor(Math.random() * 90000) + 10000).toString()
      let productSku = `PGS${uuidProduct}-${productTypes.map(type => type.sku).join("-")}`
      var wpProduct = await rest.request("post", site_id, "/wp-json/wc/v3/products", {
        type: 'variable',
        name: product.title,
        description: product.description,
        attributes: attributes,
        default_attributes: default_attributes,
        sku: productSku,
        images: product.images.map(image => ({ src: image.url })),
        categories: product.categories.map(cat => ({ id: parseInt(cat) })),
        tags: product.tags.map(tag => ({ id: parseInt(tag) })),
        meta_data: [{ key: 'pod_product_types', value: productTypes.map(type => type.id) }]
      }).then(createProduct => {
        return createProduct
      }).catch(err => {
        reject(err)
      })
      if (wpProduct) {
        //Create variants
        let variants = product.variants.map(variant => {
          let variant_attributes = variant.attributes.map(attr => {
            return {
              id: 0,
              name: attr.name,
              option: attr.option
            }
          })
          if (productTypes.length > 1) {
            variant_attributes = [{
              id: 0,
              name: 'Type',
              option: productTypes.find(type => type.id === variant.product_type_id).title,
            }, ...variant_attributes]
          }
          let variant_att_sku = variant.attributes.map(attr => attr.option.replaceAll(" ", "-")).join("-")
          let variantImageIndex = variant.image ? _.findIndex(product.images, image => image.key === variant.image.key) : null
          return {
            regular_price: `${variant.retail_cost}`,
            sale_price: `${variant.sale_cost}`,
            meta_data: [{ key: 'pod_product_type_variant_id', value: variant.id }],
            sku: `PGS${uuidProduct}-${variant.sku}`,
            attributes: variant_attributes,
            ...(variantImageIndex !== null ? { image: { id: wpProduct.images[variantImageIndex].id } } : null)
          }
        })
        await rest.request("POST", site_id, `/wp-json/wc/v3/products/${wpProduct.id}/variations/batch`, {
          create: variants
        }).then(() => resolve(wpProduct)).catch(err => {
          //reject(err)
          console.log("Error here")
          return Promise.reject(err)
        })
      }
    })
  },

  async mapProduct(site_id, origin_id, productTypes, variations) {
    //Create product attributes
    var attributes = []
    if (productTypes.length > 1) {
      attributes.push({
        name: 'Type',
        visible: true,
        variation: true,
        options: productTypes.map(product_type => product_type.title)
      })
    }
    variations.forEach(variant => {
      variant.attributes.forEach(attribute => {
        let index = _.findIndex(attributes, attr => attr.name === attribute.name)
        if (index !== -1) {
          attributes[index].options = _.union(attributes[index].options, [attribute.option])
        } else {
          attributes.push({
            name: attribute.name,
            visible: true,
            variation: true,
            options: [attribute.option]
          })
        }
      })
    })

    //Update master product with new attributes
    await rest.request("PUT", site_id, `/wp-json/wc/v3/products/${origin_id}`, {
      meta_data: [{ key: 'pod_product_types', value: productTypes.map(type => type.id) }],
      attributes: attributes,
    }).catch(err => Promise.reject(err))

    //Get old variants
    let update = await rest.request("GET", site_id, `/wp-json/wc/v3/products/${origin_id}/variations?per_page=100&status=publish`).then(variants => {
      return variants.map(v => ({ id: v.id, status: 'private' }))
    }).catch(err => {
      return Promise.reject(err)
    })
    let create = variations.map(variant => {
      let variant_attributes = variant.attributes.map(attr => {
        return {
          id: 0,
          name: attr.name,
          option: attr.option
        }
      })
      if (productTypes.length > 1) {
        variant_attributes = [{
          id: 0,
          name: 'Type',
          option: productTypes.find(type => type.id === variant.product_type_id).title,
        }, ...variant_attributes]
      }
      //let variantImageIndex = variant.image ? _.findIndex(product.images, image => image.key === variant.image.key) : null
      return {
        regular_price: `${variant.retail_cost}`,
        sale_price: `${variant.sale_cost}`,
        meta_data: [{ key: 'pod_product_type_variant_id', value: variant.id }],
        sku: `${variant.sku}:${shortuuid.generate()}`,
        attributes: variant_attributes
      }
    })
    //Batch update variants
    await rest.request("POST", site_id, `/wp-json/wc/v3/products/${origin_id}/variations/batch`, {
      create: create,
      update: update
    }).catch(err => { return Promise.reject(err) })
    return Promise.resolve(origin_id)
  },

  async deleteProduct(site_id, origin_id) {
    await rest.request("DELETE", site_id, `/wp-json/wc/v3/products/${origin_id}`).catch(err => Promise.reject(err))
    return Promise.resolve(origin_id)
  }
}