import { Input, Select } from "antd";
import React, { useState, useCallback, useEffect } from "react";
import { VariantPartnerMappingPrintArea } from "./VariantPartnerMappingPrintArea";

export const VariantPartnerSKU = ({partner, existValue, onChange, apiCatalogsData, variantPrintAreas, onMappingChange, partnerMappingPrintAreasState}) => {
    const [value, setValue] = useState(existValue?.sku ?? null)
    const [selectValue, setSelectValue] = useState(existValue?.sku)
    const [filteredOptions, setFilteredOptions] = useState(apiCatalogsData);

    const handleChange = useCallback((value) => {
        setValue(value);
        setSelectValue(value)
        onChange(value)
    }, []);

    const handleMappingChange = (v) => {
        onMappingChange(v)
    }

    const handleSearch = (v) => {
        if (v) {
            const filtered = apiCatalogsData.filter(item =>
                item.label.toLowerCase().includes(v.toLowerCase())
            );

            if (filtered.length < 1) {
                setFilteredOptions(apiCatalogsData);
            } else {
                setFilteredOptions(filtered);
            }            
        } else {
            setFilteredOptions(apiCatalogsData);
        }
    }

    useEffect(() => {
        setFilteredOptions(apiCatalogsData)
    }, [apiCatalogsData])

    return (
        <div
            style={{
                marginBottom: "15px"
            }}
        >
            {`${partner.name} Variant: `}

            {! filteredOptions && (
                <Input 
                    placeholder={`${partner.name} Variant`}
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                />
            )}

            {filteredOptions?.length > 0 && (
                <Select
                    showArrow
                    showSearch
                    allowClear
                    value={selectValue}
                    placeholder={`${partner.name} Variant`}
                    style={{
                        width: "100%",
                        fontSize: "12px"
                    }}
                    onChange={(v) => handleChange(v)}
                    onSearch={handleSearch}
                    filterOption={false}
                >
                    {filteredOptions.map((item) => (
                        <Select.Option key={item.value} style={{fontSize: "12px"}}>{item.label}</Select.Option>
                    ))}
                </Select>
            )}

            <VariantPartnerMappingPrintArea 
                selectedPartnerVariant={apiCatalogsData?.find(el => el.value === value)}
                variantPrintAreas={variantPrintAreas}
                partner={partner}
                onChange={(v) => handleMappingChange(v)}
            />

            {partnerMappingPrintAreasState && partnerMappingPrintAreasState.map(item => (
                <div>
                    Variant area: <b>{item.print_area_title}</b> - Partner area: <b>{item.key}</b>
                </div>
            ))}
        </div>
    )
}