import gql from "graphql-tag";

export const ORDER_ID = gql`
  query order($id: Int!) {
    order(id: $id) {
      id
      personalized
      print_files {
        id
        product_type_print_file_id
        title
        mockup
        fileID
        width
        height
      }
      design_status
    }
  }
`;
