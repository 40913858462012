import React, { Component } from 'react'
import { connect } from 'react-redux'
import Products from '../../components/Products'
import ProductsFilter from '../../components/products/ProductsFilter'
import { Row, Col, PageHeader, Button } from 'antd'
import ProductToolbar from '../../components/products/ProductToolbar'
import { history } from '../../history'
import ExportProduct from '../../components/products/exportProduct'

class ProductsPage extends Component {
    constructor(props) {
        super(props)
        var state = {
            fetching: false,
            sites: [],
            productTypes: [],
            filter: {
                search: null,
                site_ids: null,
                product_type_ids: null,
            },
        }
        const { currentUser, currentSite } = props
        if (currentUser.roles.map((role) => role.name).includes('Seller')) {
            state.filter.site_ids = currentSite ? [currentSite.id] : []
        }
        this.state = state
    }
    render() {
        const { currentSite, currentUser } = this.props
        const allowProductAction =
            currentUser.roles.map((role) => role.name).includes('Administrator') || (currentSite && currentSite.type !== 'offline')

        return (
            <div>
                <PageHeader
                    style={{ padding: '16px 0px' }}
                    title='Products'
                    onBack={() => {
                        history.goBack()
                    }}
                    extra={<ExportProduct currentUser={currentUser} filter={this.state.filter} />}
                />

                <div style={{ display: 'flex', gap: 10 }}>
                    <ProductsFilter onChange={(filter) => this.setState({ filter })} {...this.props} />
                    {allowProductAction && <ProductToolbar />}
                </div>

                <Products filter={this.state.filter} {...this.props} />
            </div>
        )
    }
}

export default connect((state) => ({
    currentSite: state.app.currentSite,
}))(ProductsPage)
