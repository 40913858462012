import gql from "graphql-tag";

export const ADMIN_EXTERNAL_ORDERS = gql`
  query adminExternalOrders($filter: MappingFilterExternal!) {
    adminExternalOrders(filter: $filter) {
      count
      hits {
        id
        site_id
        site {
          id
          title
          platform
          domain
        }
        product_origin_id
        order_origin_id
        variant_origin_id
        line_item_id
        price
        sku
        quantity
        metaData
        variants
        images
        product
        created_at
        mapped
        platform
        personalized
        print_file_url
      }
    }
  }
`;
