import React, { useState } from 'react'

import { Button, Card, DatePicker, Form, Input, InputNumber, notification, PageHeader, Radio } from 'antd'
import { history } from '../../../../history'
import { useMutation } from '@apollo/react-hooks'
import { CREATE_DISCOUNT } from '../../../../graphql/mutation/adminDiscount'

import moment from 'moment'

const { RangePicker } = DatePicker

const DiscountCreateComponent = (props) => {
    const { dataDiscountTypes } = props
    const { getFieldDecorator } = props.form

    const [loading, setLoading] = useState(false)
    const [typeDiscount, setTypeDiscount] = useState('timeline')

    const [createDiscount] = useMutation(CREATE_DISCOUNT)

    const onChangeTypeDiscount = (e) => {
        let { value } = e.target
        setTypeDiscount(value)
    }

    const onHandleSubmit = (e) => {
        props.form.validateFieldsAndScroll((err, values) => {
            e.preventDefault()
            if (!err) {
                setLoading(true)
                if (values.type === 'timeline') {
                    values['start_time'] = moment(values.rangePicker[0]).format('YYYY-MM-DD[T]00:00:00[Z]')
                    values['end_time'] = moment(values.rangePicker[1]).format('YYYY-MM-DD[T]00:00:00[Z]')
                    delete values.rangePicker
                }
                createDiscount({
                    variables: {
                        input: {
                            ...values,
                        },
                    },
                })
                    .then(() => {
                        notification.success({ message: 'Create Discount is successful!' })
                        window.location.href = '/admin/discount'
                    })
                    .catch((err) => {
                        notification.error({ message: err.message })
                        setLoading(false)
                    })
            }
        })
    }

    const rangeConfig = {
        rules: [{ type: 'array', required: true, message: 'Please select Time!' }],
    }

    return (
        <>
            <PageHeader
                style={{ padding: '16px 0px' }}
                title='Create Discount'
                onBack={() => {
                    history.goBack()
                }}
            />
            <Form onSubmit={onHandleSubmit}>
                <Card title='Create' style={{ marginBottom: 20 }}>
                    <Form.Item label='Name'>
                        {getFieldDecorator('name', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input Name',
                                },
                            ],
                        })(<Input placeholder='Name...' />)}
                    </Form.Item>
                    <Form.Item label='% Discount'>
                        {getFieldDecorator('discount', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input Discount',
                                },
                            ],
                        })(<InputNumber min={1} max={100} placeholder='% Discount...' style={{ width: '130px' }} />)}
                    </Form.Item>
                    <Form.Item label='Type'>
                        {getFieldDecorator('type', {
                            initialValue: 'timeline',
                        })(
                            <Radio.Group onChange={(e) => onChangeTypeDiscount(e)}>
                                {dataDiscountTypes?.length > 0 &&
                                    dataDiscountTypes.map((x, i) => (
                                        <Radio value={x} key={i}>
                                            <span style={{ textTransform: 'capitalize' }}>{x.replaceAll('_', ' ')}</span>
                                        </Radio>
                                    ))}
                            </Radio.Group>,
                        )}
                    </Form.Item>
                    {typeDiscount === 'time_amount' ? (
                        <Form.Item label='Time'>
                            {getFieldDecorator('effective_time', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input Time',
                                    },
                                ],
                            })(<InputNumber placeholder='Time...' style={{ width: '130px' }} />)}
                        </Form.Item>
                    ) : (
                        <Form.Item label='Time'>{getFieldDecorator('rangePicker', rangeConfig)(<RangePicker />)}</Form.Item>
                    )}

                    <Form.Item>
                        <Button type='primary' htmlType='submit' loading={loading} style={{ marginRight: '10px' }}>
                            Save
                        </Button>
                        <Button
                            onClick={() => {
                                history.push('/admin/discount')
                            }}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Card>
            </Form>
        </>
    )
}

export default Form.create({ name: 'form' })(DiscountCreateComponent)
