import rest from '../api/rest'
import _ from 'lodash'
import shortuuid from 'short-uuid'
import { randomAlphaBets } from './helper'

export const Shopify = {
  async pushProduct(site_id, productTypes, product) {
    return new Promise(async (resolve, reject) => {

      let productAttributes = []
      let variantImages = []
      let uuidProduct = randomAlphaBets(2) + (Math.floor(Math.random() * 90000) + 10000).toString()
      let productSku = `PGS${uuidProduct}-${productTypes.map(type => type.sku).join("-")}`
      var _attributes = [].concat.apply([], product.variants.map(variant => variant.attributes))
      _attributes.forEach(attr => {
        let tmpAttr = productAttributes.find(at => at.slug === attr.slug) || { name: attr.name, slug: attr.slug, terms: [] }
        tmpAttr.terms = _.union([...tmpAttr.terms, attr.option])
        productAttributes = _.uniqBy([...productAttributes, tmpAttr], 'slug')
      })

      if (productTypes.length > 1) {
        let productTypeAttribute = {
          name: "Type",
          terms: productTypes.map(type => type.title)
        }
        productAttributes = [productTypeAttribute, ...productAttributes]
      }

      let shopifyProduct = {
        product: {
          title: product.title,
          body_html: product.description,
          published_scope: 'global',
          vendor: 'PGS',
          tags: product.tags,
          images: product.images.map(image => ({ src: image.url })),
          options: productAttributes.map(attr => ({
            name: attr.name
          })),
          metafields: [
            {
              namespace: 'pod',
              type: 'json_string',
              key: 'pod_product_types',
              value: JSON.stringify(productTypes.map(type => type.id.toString()))
            }, {
              namespace: 'pod_sku',
              type: 'string',
              key: 'pod_product_sku',
              value: productSku,
            }
          ],
          variants: product.variants.map((variant, i) => {
            let options = {}
            let variant_att_sku = variant.attributes.map(attr => attr.option.replaceAll(" ", "-")).join("-")
            productAttributes.forEach((attr, index) => {
              let existsAttr = variant.attributes.find(at => at.name === attr.name)
              if (existsAttr) {
                options[`option${index + 1}`] = existsAttr.option
              } else {
                if (attr.name === "Type" && productTypes.length > 1) {
                  options[`option${index + 1}`] = productTypes.find(type => type.id === variant.product_type_id).title
                } else {
                  options[`option${index + 1}`] = ""
                }
              }
            })

            // get index of variant images
            // let variantImageIndex = variant.image ? _.findIndex(product.images, image => image.key === variant.image.key) : null
            let variantImageIndex = 0

            product.images.filter(
              (image, index) => {
                if (image.url === variant.image.url) {
                  variantImageIndex = index
                }
              }
            )

            variantImages.push({
              position: i + 1,
              index: variantImageIndex
            })

            return {
              compare_at_price: variant.retail_cost,
              fulfillment_service: 'manual',
              price: variant.sale_cost,
              sku: `PGS${uuidProduct}-${variant.sku}`,
              ...options,
              metafields: [
                {
                  namespace: 'pod',
                  type: 'integer',
                  key: 'pod_product_type_variant_id',
                  value: variant.id
                }
              ]
            }
          })
        }
      }

      console.log("variantImages",variantImages)

      rest.request('post', site_id, '/admin/api/2021-01/products.json', shopifyProduct).then(res => {
        if (res.product && product.categories.length > 0) {
          product.categories.forEach(cat => {
            let collection = {
              custom_collection: {
                id: parseInt(cat),
                collects: [{
                  product_id: res.product.id
                }]
              }
            }
            rest.request('put', site_id, `/admin/api/2021-07/custom_collections/${cat}.json`, collection)
          });
        }

        //Update variant image
        res.product && res.product.variants.forEach(variant => {
          return new Promise(async (resolve, reject) => {

            let variantImageIndex = _.find(variantImages, { position: variant.position })

            if (variantImageIndex && variantImageIndex.index !== null) {
              await rest.request("Put", site_id, `/admin/api/2021-01/variants/${variant.id}.json`, {
                variant: {
                  id: variant.id,
                  image_id: variantImageIndex.index !== null ? res.product.images[variantImageIndex.index].id : null
                }
              }).then(updateVariant => {
                return updateVariant
              }).catch(err => {
                reject(err)
              })
            }
          })
        });

        resolve(res.product)

      }).catch(err => {
        reject(err)
      })
    })
  },

  async mapProduct(site_id, origin_id, productTypes, variations) {
    //get all variants
    let existsVariants = await rest.request("GET", site_id, `/admin/api/2021-01/products/${origin_id}/variants.json`, {
      product: {
        variants: []
      }
    }).then(res => {
      return res.variants.map(v => v.id)
    }).catch(err => {
      return Promise.reject({ error: [{ message: 'Could not retrieves product variants' }] })
    })
    //Delete all variants

    return Promise.reject("Sorry")
  },
  async deleteProduct(site_id, origin_id) {
    await rest.request("DELETE", site_id, `/admin/api/2021-01/products/${origin_id}.json`).catch(err => Promise.reject(err))
    return Promise.resolve(origin_id)
  }
}