import React, { useState } from 'react'
import { Button, Input, Select } from 'antd'
import { connect } from 'react-redux'
import DateRange from '../DateRange'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
export const SITES = gql`
    query sites($filter: SiteFilter) {
        sites(filter: $filter) {
            hits {
                id
                title
            }
        }
    }
`

const TrackingFilter = ({ filter, setFilter, currentUser }) => {
    const [valueSearch, setValueSearch] = useState(filter?.search || '')

    const { data } = useQuery(SITES, {
        skip: !currentUser.roles.map((el) => el.name).includes('Administrator'),
    })
    const sites = data ? data.sites.hits : []
    const onChangeDate = (range, callback = () => {}) => {
        if (range.from !== null && range.to !== null) {
            setFilter(
                {
                    ...filter,
                    from: moment(range.from).format('YYYY-MM-DD[T]00:00:00[Z]'),
                    to: moment(range.to).format('YYYY-MM-DD[T]00:00:00[Z]'),
                },
                callback,
            )
        } else {
            setFilter(
                {
                    ...filter,
                    from: null,
                    to: null,
                },
                callback,
            )
        }
    }

    return (
        <div style={{ display: 'flex', gap: 10, marginBottom: '10px' }}>
            {currentUser.roles.map((el) => el.name).includes('Administrator') ? (
                <Select
                    style={{ width: 250 }}
                    placeholder='Site'
                    defaultValue={null}
                    onChange={(e) => {
                        setFilter({
                            ...filter,
                            site_ids: e ? [e.toString()] : null,
                        })
                    }}>
                    <Select.Option value={null} key={null}>
                        All Site
                    </Select.Option>
                    {sites.map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                            {item.title}
                        </Select.Option>
                    ))}
                </Select>
            ) : null}

            <DateRange
                range={null}
                defaultDate={[filter?.from ? moment(filter?.from)?.startOf('day') : null, filter?.to ? moment(filter?.to)?.endOf('day') : null]}
                onChange={(value) => {
                    if (value) {
                        onChangeDate(value)
                    }
                }}
            />
            <Input.Group compact>
                <Select
                    showSearch
                    optionFilterProp='children'
                    value={filter.field}
                    placeholder='Select Field'
                    style={{
                        width: 150,
                    }}
                    onChange={(v) => {
                        setFilter({ ...filter, field: v })
                    }}>
                    <Select.Option value='all'>All Fields</Select.Option>
                    <Select.Option value='id'>Tracking ID</Select.Option>
                    <Select.Option value='tracking_code'>Tracking Code</Select.Option>
                    <Select.Option value='customer'>Customer</Select.Option>
                    <Select.Option value='supplier'>Supplier</Select.Option>
                    <Select.Option value='origin_id'>Origin Order ID</Select.Option>
                    <Select.Option value='origin_number'>Origin Order Number</Select.Option>
                </Select>
                <Input.Search
                    placeholder='Search...'
                    style={{ width: 'calc(100% - 150px)' }}
                    value={valueSearch}
                    onChange={(e) => setValueSearch(e.target.value)}
                    onSearch={(value) => {
                        setFilter({ ...filter, search: value })
                    }}
                />
            </Input.Group>
            <Button
                type='default'
                disabled={filter.status || filter.search || filter.field !== 'all' || filter.from || filter.to ? false : true}
                onClick={(e) => {
                    e.preventDefault()
                    setFilter({
                        status: null,
                        search: null,
                        field: 'all',
                        // range: null,
                        offset: 0,
                        limit: 20,
                        site_ids: filter?.site_ids || [],
                        aggs_by: 'status',
                        from: null,
                        to: null,
                    })
                    setValueSearch('')
                }}>
                Reset
            </Button>
        </div>
    )
}
export default connect((state) => ({
    currentUser: state.app.currentUser,
    currentSite: state.app.currentSite,
}))(TrackingFilter)
