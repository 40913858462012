import gql from "graphql-tag";

export const ATTRIBUTE_TERMS = gql`
    query getAttributeTerms($attribute_id: Int, $limit: Int, $offset: Int) {
        getAttributeTerms(
            attribute_id: $attribute_id
            limit: $limit
            offset: $offset
        ) {
            count
            hits {
                id
                attribute_id
                attribute {
                    id
                    name
                }
                option_name
                sku
            }
        }
    }
`
export const ATTRIBUTE_TERM_BY_ID = gql`
    query getAttributeTermById($id: Int!) {
        getAttributeTermById(id: $id) {
            id
            attribute_id
            option_name
            sku
        }
    }
`