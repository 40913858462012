import React from 'react'

import { useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { GET_DISCOUNT_DETAILS, GET_DISCOUNT_TYPES } from '../../../graphql/query/adminDiscount'

import DiscountEditComponent from '../../../components/admin/discount/edit'

const DiscountEditPage = () => {
    let { id } = useParams()

    const { loading: discountLoading, error: discountError, data: discountData } = useQuery(GET_DISCOUNT_TYPES)

    const { loading, error, data } = useQuery(GET_DISCOUNT_DETAILS, {
        variables: {
            id,
        },
    })

    if (loading || discountLoading) return 'Loading...'
    if (error || discountError) return `Error! ${error.message}`

    return <DiscountEditComponent dataSource={data?.discount || {}} dataDiscountTypes={discountData?.discountTypes || []} />
}

export default DiscountEditPage
