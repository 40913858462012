import { Avatar, Button, Drawer, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import ExternalOrdersAction from "./ExternalOrdersAction";
import PageHeader from "antd/es/page-header";
import { MAPPING_ORDERS } from "../../graphql/query/listMappingOrder";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import { history } from "../../history";
import FetchOrders from "./FetchOrders";
import { useSelector } from "react-redux";

const ExternalOrders = () => {
  const currentSite = useSelector((state) => state.app.currentSite);

  const [filter, setFilter] = useState({
    offset: 0,
    limit: 20,
    siteId: localStorage.getItem("currentSiteID")
      ? localStorage.getItem("currentSiteID")
      : "",
  });
  const { data, refetch } = useQuery(MAPPING_ORDERS, {
    fetchPolicy: "no-cache",
    variables: {
      filter: filter,
    },
  });

  useEffect(() => {
    setFilter({ ...filter, siteId: currentSite?.id });
  }, [localStorage.getItem("currentSiteID")]);
  const [page, setPage] = useState(1);
  const [showFetchOrders, setShowFetchOrders] = useState(false);
  const columns = [
    { title: "Id", key: "id", dataIndex: "id", width: 100 },
    {
      title: "Order ID",
      key: "orderId",
      dataIndex: "order_origin_id",
      width: 120,
    },
    {
      title: "Product",
      key: "product",
      dataIndex: "product",
      width: 300,
      render: (product, record) => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "100px auto",
            gap: 10,
            alignItems: "center",
          }}
        >
          <Avatar
            shape="square"
            size={100}
            src={record?.images && record?.images[0]}
          />
          <span>{product?.name ? product?.name : product?.product?.title}</span>
        </div>
      ),
    },
    {
      title: "Site",
      key: "site",
      dataIndex: "site",
      width: 180,
      render: (site) => {
        switch (site?.platform) {
          case "shopbase":
            return <img src="/shopbase-logo.svg" width="80" alt=""></img>;
          case "shopify":
            return <img src="/shopify-logo.png" width="80" alt=""></img>;
          case "woocommerce":
            return <img src="/logo-woocommerce.png" width="100" alt=""></img>;
          default:
            return <img src="/import-site.png" width="100" alt=""></img>;
        }
      },
    },
    {
      title: "Meta Data",
      key: "tags",
      dataIndex: "tags",
      render: (tags) => (
        <>
          {tags}
        </>
      )
    },
    {
      title: "Personalized",
      key: "personalized",
      dataIndex: "personalized",
      render: (personalized, { print_file_url }) => {
        if (personalized) {
          return (
            <a href={print_file_url} target="_blank" rel="noopener noreferrer">
              <Tag color="blue" style={{ cursor: "pointer" }}>
                personalized
              </Tag>
            </a>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "created_at",
      width: 150,
      render: (date) => moment(date).format("MMM DD, YYYY"),
    },
    {
      title: "Actions",
      key: "actions",
      width: 180,
      align: "right",
      render: (record) => (
        <ExternalOrdersAction record={record} refetch={refetch} />
      ),
    },
  ];
  const pagination = {
    current: page,
    total: data?.listMappingOrder?.count,
    pageSize: filter?.limit,
    onChange: (page, pageSize) => {
      setPage(page);
      setFilter({ ...filter, offset: (page - 1) * pageSize });
    },
  };

  return (
    <div>
      <PageHeader
        style={{ padding: "16px 0px" }}
        title="External Orders"
        onBack={() => {
          history.goBack();
        }}
        extra={
          <Button
            onClick={() => {
              setShowFetchOrders(true);
            }}
            type="primary"
            icon="cloud-download"
            style={{ marginLeft: 5 }}
            disabled={currentSite === null || currentSite === undefined}
          >
            Fetch Orders
          </Button>
        }
      />
      <Table
        rowKey={(record, id) => record.id}
        columns={columns}
        dataSource={data?.listMappingOrder?.hits}
        pagination={pagination}
      />
      <Drawer
        width="500px"
        title="Fetch Orders"
        onClose={(e) => {
          setShowFetchOrders(false);
        }}
        visible={showFetchOrders}
      >
        <FetchOrders
          site_id={parseInt(localStorage.getItem("currentSiteID"))}
          onClose={(e) => setShowFetchOrders(false)}
          refetch={refetch}
          currentSite={currentSite}
        />
      </Drawer>
    </div>
  );
};

export default ExternalOrders;
