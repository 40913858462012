import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { VariantPartnerMappingPrintAreaSelect } from "./VariantPartnerMappingPrintAreaSelect";

export const VariantPartnerMappingPrintArea = ({selectedPartnerVariant, variantPrintAreas, partner, onChange}) => {
    const [visible, setVisible] = useState(false)
    const [disable, setDisable] = useState(true)
    const [partnerAreas, setPartnerAreas] = useState(partner.print_areas)

    useEffect(() => {
        if (selectedPartnerVariant) {
            setDisable(false)
            setPartnerAreas(selectedPartnerVariant?.placement)
        } else {
            setDisable(true)
        }
    }, [selectedPartnerVariant])

    if (! partnerAreas) {
        return "Partner not set print areas."
    }

    const handleSelectChange = (value) => {
        value.partner_id = partner.id
        onChange(value)
    }

    return (
        <>
            <Button
                disabled={disable}
                onClick={(e) => {
                    setVisible(true)
                }}
            >
                Mapping print areas
            </Button>

            <Modal
                visible={visible}
                onCancel={(e) => setVisible(false)}
                footer={[
                    <Button
                        type={"primary"}
                        onClick={(e) =>
                            setVisible(false)
                        }>
                        Ok
                    </Button>
                ]}
            >
                Mapping product type variant print area with partner print area

                {variantPrintAreas?.map((area) => (
                    <VariantPartnerMappingPrintAreaSelect
                        area={area}
                        partnerAreas={partnerAreas}
                        onChange={(selectValue) => handleSelectChange(selectValue)}
                    />
                ))}
            </Modal>
        </>
    )
}