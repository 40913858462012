import React, { Component } from 'react'
import { notification, Icon, Avatar, Button, Drawer, Tooltip, Popconfirm, Divider, Modal, Dropdown, Menu, Tag } from 'antd'
import Table from './Table'
import gql from '../api/gql'
import _ from 'lodash'
import UploadDesigns from './products/UploadDesigns'
import PrintFilePreview from './file/PrintFilePreview'
import moment from 'moment'
import { connect } from 'react-redux'
import { history } from '../history'
import { setProduct } from '../actions'
import S3ImageNew from './S3ImageNew'

const _targetBlank = {
    target: '_blank',
    rel: 'noopener noreferrer',
}
class Products extends Component {
    state = {
        orders: [],
        total: 0,
        filter: {
            offset: 0,
            limit: 20,
            ...this.props.filter,
        },
        selectedProducts: [],
        selectedProduct: null,
        showUploadDesign: false,
        loading: false,
        showDeleteConfirm: false,
        page: 1,
    }

    componentDidMount() {
        if (this.props.currentUser.roles.map((role) => role.name).includes('Seller') && this.props.currentSite) {
            this.loadProducts()
        } else if (!this.props.currentUser.roles.map((role) => role.name).includes('Seller')) {
            this.loadProducts()
        }
    }

    componentDidUpdate(prevProps) {
        const { filter } = this.state
        if (prevProps.filter !== this.props.filter) {
            this.setState(
                {
                    page: 1,
                    filter: { ...filter, offset: 0, ...this.props.filter },
                },
                () => {
                    if (this.props.currentUser.roles.map((role) => role.name).includes('Seller') && this.props.currentSite) {
                        this.loadProducts()
                    } else if (!this.props.currentUser.roles.map((role) => role.name).includes('Seller')) {
                        this.loadProducts()
                    }
                },
            )
        }
    }

    loadProducts() {
        const query = `query products($filter: ProductFilter){
      products(filter: $filter){
        count
        hits{
          id
          sku
          title
          images
          created_at
          personalized
          productStore {
            id
            site_id
            origin_id
            permalink
            site {
              title
              domain
            }
          }
          variants{
            id
            sku
            design_status
            product_type_id
            print_files{
              title
              width
              height
              file_id
            }
          }
          ideaId
          short_link
        }
      }
    }`
        this.setState({ loading: true })
        gql.request(query, {
            filter: this.state.filter,
        })
            .then((res) => {
                this.setState({
                    products: res.products.hits || [],
                    total: res.products.count,
                    loading: false,
                })
            })
            .catch((err) => {
                this.setState({ loading: false })
                //notification.error({ message: _.get(err, "[0].message") });
            })
    }

    updateProductFormSite = (id) => {
        const query = `mutation updateProduct{
      updateProductFromSite(id: "${id}")
    }`
        gql.request(query).then((res) => {
            notification['success']({
                message: 'Product updated',
            })
        })
    }

    deleteProduct = (id, isDelOnStore) => {
        const query = `mutation deleteProduct{deleteProduct(product_id: ${id}, isDelOnStore: ${isDelOnStore})}`
        gql.request(query)
            .then((res) => {
                notification['success']({
                    message: 'Product has been deleted',
                })
                const products = this.state.products.filter((item) => item.id !== id)
                this.setState({ products: products, showDeleteConfirm: false })
            })
            .catch((err) => {
                notification['error']({
                    message: _.get(err, '[0].message'),
                })
                this.setState({ showDeleteConfirm: false })
            })
    }

    resetDesignFiles = (id) => {
        const query = `mutation resetVariantsPrintFiles{resetVariantsPrintFiles(product_id: ${id}) {id}}`
        
        this.setState({ loading: true })

        gql.request(query)
            .then((res) => {
                notification['success']({
                    message: 'Reset print areas success!',
                })

                this.loadProducts()
            })
            .catch((err) => {
                notification['error']({
                    message: _.get(err, '[0].message'),
                })
            })
    }

    handleYes = () => {
        this.deleteProduct(this.state.selectedProduct.id, true)
    }
    handleNo = () => {
        this.deleteProduct(this.state.selectedProduct.id, false)
    }
    handleCancel = () => {
        this.setState({ showDeleteConfirm: false })
    }

    render() {
        const { products, selectedProduct, showUploadDesign, total, filter, loading } = this.state
        const { currentSite, currentUser } = this.props

        const isAdministrator = currentUser.roles.map((role) => role.name).includes('Administrator')
        const allowCloneProduct = currentUser.roles.map((role) => role.name).includes('Seller') && currentSite && currentSite.type !== 'offline'
        // const allowDeleteProduct = !currentUser.roles.map((role) => role.name).includes('Seller') && currentSite && currentSite.type !== 'offline'
        let isAdmin = this.props.currentUser.roles.find((el) => ['Administrator', 'Seller', 'Listing'].includes(el.name))

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedProducts: selectedRows,
                })
            },
        }
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: 'Title',
                dataIndex: 'title',
                render: (title, { id, permalink, images, sku, variants, productStore, ideaId }) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {ideaId ? (
                            <S3ImageNew src={images ? images[0] : null} width={60} style={{ marginRight: '10px' }} />
                        ) : (
                            <Avatar icon='picture' src={images ? images[0] : null} shape='square' size={60} style={{ marginRight: '10px' }} />
                        )}

                        <div>
                            {title} <br />
                            {variants && <em style={{ color: '#8D8D8D' }}>{sku}</em>}
                        </div>
                    </div>
                ),
            },
            {
                title: 'Sites',
                dataIndex: 'productStore',
                width: 220,
                render: (productStore) => (
                    <div>
                        {filter.site_ids?.[0] ? (
                            <a target='_blank' href={productStore?.find((item) => item.site_id == filter.site_ids?.[0])?.permalink} {..._targetBlank}>
                                {productStore?.find((item) => parseInt(item.site_id) === filter.site_ids?.[0])?.site.title}
                                {' - '}
                                {productStore?.find((item) => parseInt(item.site_id) === filter.site_ids?.[0])?.site.domain}
                            </a>
                        ) : (
                            <Dropdown
                                overlay={
                                    <div>
                                        {productStore.length >= 2 && (
                                            <Menu>
                                                {productStore.map((item, key) => (
                                                    <Menu.Item key={key}>
                                                        <a target='_blank' rel='noopener noreferrer' href={item.permalink}>
                                                            {item.site?.title}
                                                            {' - '}
                                                            {item.site?.domain}
                                                        </a>
                                                    </Menu.Item>
                                                ))}
                                            </Menu>
                                        )}
                                    </div>
                                }>
                                <a target='_blank' className='ant-dropdown-link' href={productStore ? productStore[0]?.permalink : null}>
                                    {productStore ? productStore[0]?.site?.title : null}
                                    {' - '}
                                    {productStore ? productStore[0]?.site?.domain : null}
                                    {productStore.length > 1 ? <Icon type='down' /> : null}
                                </a>
                            </Dropdown>
                        )}
                    </div>
                ),
            },
            {
                title: 'Short Link',
                width: 220,
                dataIndex: 'short_link',
            },
            {
                title: 'Designs',
                dataIndex: 'file',
                key: 'file',
                render: (files, product) => {
                    // Find variant has most print areas 
                    // const variants = product?.variants?.reduce((max, current) => {
                    //     return (current?.print_files?.length > max?.print_files?.length) ? current : max;
                    // }, product?.variants[0])

                    let printAreas = []
                    const uniqueIds = new Set();
                    
                    // eslint-disable-next-line no-unused-expressions
                    product?.variants?.forEach((item) => {
                        const printFiles = item?.print_files

                        // eslint-disable-next-line no-unused-expressions
                        printFiles?.forEach(area => {
                            if (!uniqueIds.has(area.title)) {
                                uniqueIds.add(area.title);
                                printAreas.push(area);
                            }
                        });
                    })

                    return product.personalized ? (
                        <Tag>Personalized</Tag>
                    ) : (
                        <div
                            style={{ display: 'flex', width: 200, gap: 5 }}
                            onClick={(e) => {
                                e.preventDefault()
                                this.setState({
                                    selectedProduct: product,
                                    showUploadDesign: true,
                                })
                            }}
                        >
                            {
                                printAreas?.map((pfile, index) => {
                                    if (!pfile) {
                                        return null
                                    }
                                    return pfile.file_id ? (
                                        <div
                                            key={index}
                                            style={{
                                                width: 70,
                                                height: 60,
                                                backgroundColor: '#ccc',
                                                marginRight: 5,
                                            }}>
                                            <PrintFilePreview file_id={pfile.file_id} />
                                        </div>
                                    ) : null
                                })
                            }
                        </div>
                    )
                },
            },
            {
                title: 'Created Date',
                width: 150,
                dataIndex: 'created_at',
                render: (created_at) => moment(created_at).format('MMM DD, YYYY'),
            },
            isAdmin
                ? {
                      title: 'Actions',
                      width: 180,
                      dataIndex: 'id',
                      key: 'actions',
                      render: (id, product) => {
                          return product.personalized ? (
                              ''
                          ) : (
                              <>
                                  {allowCloneProduct && (
                                      <Tooltip title='Clone product'>
                                          <Button
                                              size='small'
                                              onClick={(e) => {
                                                  e.preventDefault()
                                                  const product_type_ids = [...new Set(product.variants.map((el) => el.product_type_id))]
                                                  history.push(`/products/clone/${id}/${product_type_ids.join(',')}`)
                                                  // this.setState({
                                                  //   selectedProduct: product,
                                                  //   showUploadDesign: true,
                                                  // })
                                              }}>
                                              <Icon type='copy' />
                                          </Button>
                                      </Tooltip>
                                  )}
                                  {allowCloneProduct && <Divider type='vertical' />}
                                  <Tooltip title='Upload design files'>
                                      <Button
                                          size='small'
                                          onClick={(e) => {
                                              e.preventDefault()
                                              this.setState({
                                                  selectedProduct: product,
                                                  showUploadDesign: true,
                                              })
                                          }}>
                                          <Icon type='upload' style={{ color: '#1890ff' }}/>
                                      </Button>
                                  </Tooltip>
                                  {isAdministrator && <Divider type='vertical' />}
                                  {isAdministrator && 
                                    <Tooltip title='Reset design files'>
                                        <Popconfirm
                                            title="Are you sure to reset design files?"
                                            onConfirm={(e) => {
                                                e.preventDefault()
                                                this.resetDesignFiles(id)
                                            }}
                                        >
                                            <Button
                                                size='small'
                                            >
                                                <Icon type='warning' style={{ color: 'red' }}/>
                                            </Button>
                                        </Popconfirm>
                                     </Tooltip>
                                  }
                                  {/* {allowProductAction && allowDeleteProduct && <Divider type='vertical' />} */}
                                  {/* {allowProductAction && allowDeleteProduct && (
                                      <Tooltip title='Delete product'>
                                          <Popconfirm 
    title="Are you sure to delete this product?"
    onConfirm={() => this.deleteProduct(id)}
    okText="Yes"
    cancelText="No">
    <Button size="small">
      <Icon type="delete" style={{ color: "red" }} />
    </Button>
  </Popconfirm>
                                          <Button
                                              size='small'
                                              onClick={(e) => {
                                                  e.preventDefault()
                                                  this.setState({
                                                      selectedProduct: product,
                                                      showDeleteConfirm: true,
                                                  })
                                              }}>
                                              <Icon type='delete' style={{ color: 'red' }} />
                                          </Button>
                                      </Tooltip>
                                  )} */}
                              </>
                          )
                      },
                      // <div><Button icon="upload" onClick={e => {
                      //   e.preventDefault()
                      //   this.setState({
                      //     selectedProduct: product,
                      //     showUploadDesign: true,
                      //   })
                      // }}>Upload</Button></div>
                  }
                : {},
        ]
        const pagination = {
            pageSize: filter.limit,
            total: total,
            current: this.state.page,
            showTotal: (total, range) => `${range} of ${total}`,
            onChange: (page, pageSize) => {
                this.setState(
                    {
                        filter: {
                            ...filter,
                            offset: (page - 1) * pageSize,
                        },
                        page: page,
                    },
                    () => this.loadProducts(),
                )
            },
        }
        return (
            <div>
                <Table
                    columns={columns}
                    dataSource={products}
                    rowSelection={rowSelection}
                    pagination={pagination}
                    rowKey={({ id }) => id}
                    loading={loading}
                />
                <Drawer
                    width='800px'
                    title='Upload Designs'
                    onClose={(e) => this.setState({ selectedProduct: null, showUploadDesign: false })}
                    visible={showUploadDesign}>
                    {selectedProduct && (
                        <UploadDesigns
                            product_id={selectedProduct.id}
                            onClose={(e) => {
                                this.setState({
                                    selectedProduct: null,
                                    showUploadDesign: false,
                                })

                                this.loadProducts()
                            }}
                        />
                    )}
                </Drawer>
                {selectedProduct && (
                    <Modal
                        visible={this.state.showDeleteConfirm}
                        title='Delete Product Confirmation'
                        onCancel={this.handleCancel}
                        footer={[
                            <Button key='cancel' onClick={this.handleCancel}>
                                Cancel
                            </Button>,
                            <Button key='no' loading={loading} onClick={this.handleNo}>
                                No
                            </Button>,
                            <Button key='yes' type='primary' loading={loading} onClick={this.handleYes}>
                                Yes
                            </Button>,
                        ]}>
                        <p>Do you want to delete the product on your store also?</p>
                        <p style={{ color: 'red' }}>Note: This action cannot be undo</p>
                    </Modal>
                )}
            </div>
        )
    }
}

export default connect(
    (state) => ({
        currentUser: state.app.currentUser,
    }),
    { setProduct },
)(Products)
