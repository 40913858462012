import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router'
import { DETAIL_GUIDELINE_POLICY } from '../../graphql/query/guideline-policy'

import GuidelinePolicyForm from '../../components/admin/guideline-policy/form'
import Page404 from '../404'

const GuidelinePolicyEdit = (props) => {
    let isAdmin = props.currentUser.roles.find((el) => ['Administrator'].includes(el.name))

    let { id } = useParams()

    const { loading, error, data } = useQuery(DETAIL_GUIDELINE_POLICY, {
        variables: {
            id,
        },
    })

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    return isAdmin ? <GuidelinePolicyForm dataSource={data?.policyById || {}} /> : <Page404 />
}

export default GuidelinePolicyEdit
