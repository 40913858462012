import React from 'react'
import { Row, Form, Col, Input, Card, Button, notification, Skeleton, Divider, InputNumber, Icon, Radio, PageHeader, Checkbox, Select } from 'antd'
import Wysiwyg from '../../components/wysiwyg'
import ProductTypeAttributes from '../../components/admin/ProductTypeAttributes'
import gql from '../../api/gql'
import _ from 'lodash'
import { history } from '../../history'
import CategoriesSelect from '../../components/product_type/CategoriesSelect'
import ProductTypePrintFiles from '../../components/product_type/PrintFiles'
import Images from '../../components/product_type/Images'
import ItemProduct from './itemProduct'
import SupplierSelect from '../../components/supplier/SupplierSelect'
import { emitter } from '../../routes'
import CarrierSelect from '../../components/carrier/CarrierSelect'
import styled from 'styled-components'
import rest from '../../api/rest'

const CarrierFormItem = styled.div`
    .ant-form-item-control {
        line-height: 0px;
    }
`

const CardTitleRequired = styled.div`
    position: relative;
    display: inline-block;
    &:before {
        position: absolute;
        right: -2px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
    &:after {
        content: ':';
        position: relative;
        top: -0.5px;
        margin: 0 8px 0 2px;
    }
`

const isStrEmpty = (str) => {
    return str === undefined || str === null || str.length === 0 || str.trim().length === 0
}

class ProductTypeForm extends React.Component {
    state = {
        productType: null,
        attributes: [],
        loading: false,
        showModal: false,
        items: [],
        onShow: false,
        data: '',
        visible: false,
        carrier: [],
        defaultCarrier: null,
    }

    onFocus = () => {
        this.setState({ onShow: true })
    }

    addItem = () => {
        const { items } = this.state
        this.setState({
            items: [...items, this.state.data],
            data: '',
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    handleOk = (e) => {
        this.setState({
            visible: false,
        })
    }

    handleCancel = (e) => {
        this.setState({
            visible: false,
        })
    }

    componentDidMount() {
        const { id } = this.props.match.params
        if (id) {
            const query = `query productTypeById{productTypeById(id: ${id}){
        id, title, description, sku, mockup
        categories{
          id
        }
        attributes{
          name, slug, options
        }
        print_files{
          id
          title
          mockup
          width
          height
          note
          default
          any_print{
            anyprint_id
            anyprint_name
          }
        }
        product_type_carriers{
          carrier_id
          price
          default
          carrier{
            name
          }
        }
        suppliers{
          id
        }
        images
        personalized
        is_special
        production_time
        shipping_time
        tag
        link_template
        description_special
        file_type_supported
        dpl
        origin
      }
    }`
            gql.request(query).then((res) => {
                const initCarrier = res.productTypeById.product_type_carriers.map((el) => {
                    return {
                        value: el.carrier_id,
                        children: el.carrier.name,
                        default: el.default,
                        price: el.price,
                    }
                })
                const initDefault = res.productTypeById.product_type_carriers.find((el) => el.default)

                const initAttributes = res.productTypeById.attributes.map((el) => {
                    return {
                        name: el.name,
                        options: el.options,
                        slug: el.slug,
                        attributeTerms: [],
                        loading: false,
                    }
                })

                this.setState({
                    productType: res.productTypeById || {},
                    attributes: initAttributes || [],
                    carrier: initCarrier || [],
                    defaultCarrier: (initDefault && initDefault.carrier_id) || null,
                })
            })
        }

        this.subcriber = emitter.addListener('saveProductType', () => {
            this.saveProductType()
        })
    }

    componentWillUnmount() {
        if (this.subcriber) {
            this.subcriber.remove()
        }
    }

    isSpecialCharacters(string) {
        var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        return format.test(string)
    }

    onBlurRemoveSpace(string) {
        const formText = this.props.form.getFieldValue(string)
        if (string === 'sku') {
            this.props.form.setFieldsValue({ sku: String(formText).replace(/\s+/g, '').toUpperCase() })
        } else {
            this.props.form.setFieldsValue({ mockup: formText.replace(/\s/g, '') })
        }
    }

    saveProductType = (e) => {
        if (e) e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // check length of sku less is 0 or biger 7
                if (values.sku.length > 7 || values.sku <= 0) {
                    notification['error']({
                        message: 'ERROR',
                        description: "SKU's number of characters cannot be greater than 7 and less than 1",
                    })
                    this.setState({ loading: false })
                    return
                }

                if (this.isSpecialCharacters(values.sku)) {
                    notification['error']({
                        message: 'ERROR',
                        description: `Invalid SKU. SKU does not include special characters.`,
                    })
                    this.setState({ loading: false })
                    return
                }

                if (values.print_files.length == 0) {
                    notification['error']({
                        message: 'ERROR',
                        description: 'Print Areas is required',
                    })
                    this.setState({ loading: false })
                    return
                } else {
                    values.print_files = values.print_files.map((x) => {
                        let itemCover = {
                            ...x,
                            anyprint_type: null,
                        }

                        if (x?.any_print?.anyprint_id || x?.any_print?.anyprint_id.length > 0) {
                            itemCover['anyprint_type'] = {
                                anyprint_id: x?.any_print?.anyprint_id,
                                anyprint_name: x?.any_print?.anyprint_name,
                            }
                        }

                        delete itemCover.any_print
                        return {
                            ...itemCover,
                        }
                    })
                }
            }
            if (values.attributes.length == 0) {
                notification['error']({
                    message: 'ERROR',
                    description: 'Attributes is required',
                })
                this.setState({ loading: false })
                return
            } else {
                values.attributes = _.map(values.attributes, (attr) => _.omit(attr, ['attributeTerms', 'loading']))
                if (_.some(_.map(values.attributes, 'options'), ['length', 0])) {
                    notification['error']({
                        message: 'ERROR',
                        description: 'Attributes is required',
                    })
                    this.setState({ loading: false })
                    return
                }
            }

            values.dpl = values.dpl || 0

            const carrier = []
            values.product_type_carrriers.map((el) => {
                let car = {
                    carrier_id: el,
                    price: values.shippingcost[el],
                    default: el === this.state.defaultCarrier,
                }
                carrier.push(car)
            })
            values.product_type_carrriers = carrier
            delete values.shippingcost
            const { id } = this.props.match.params
            var query = `mutation createProductType($input: NewProductType!){
          createProductType(input: $input){
            id
          }
        }`
            if (id) {
                query = `mutation updateProductType($input: NewProductType!){
            updateProductType(id: ${id}, input: $input){
              id
            }
          }`
            }
            this.setState({ loading: true })
            gql.request(query, {
                input: values,
            })
                .then((res) => {
                    notification['success']({
                        message: 'Product type has been ' + (id ? 'updated' : 'created'),
                    })
                    this.setState({ loading: false })
                    history.goBack()
                })
                .catch((err) => {
                    let error = _.get(err, '[0].message').includes('pq: duplicate key value violates unique constraint "product_type_sku_key"')
                        ? 'SKU is duplicated'
                        : _.get(err, '[0].message')
                    notification['error']({
                        message: 'ERROR',
                        description: error,
                    })
                    this.setState({ loading: false })
                })
        })
    }
    showModal = (action) => {
        this.setState({
            showModal: action,
        })
    }

    onAddAttr = (formData) => {
        const { attributes } = this.state
        this.setState({
            attributes: [...attributes, formData],
        })
    }

    onShow = (name, slug) => this.setState({ showModal: true, name, slug })

    // func generate string sku when create product type
    generateSKU = () => {
        // get value of title and sku from Form
        const formTitle = this.props.form.getFieldValue('title')
        const formSKU = this.props.form.getFieldValue('sku')

        // check title is empty, dont generate sku
        if (!formTitle || formTitle == '') {
            return
        }

        // check value form sku if it is not empty, dont generate
        if (formSKU && formSKU != '') {
            return
        }

        // if title product type is 7 character
        if (formTitle.length <= 7) {
            // set sku is title and uppercase string
            this.props.form.setFieldsValue({
                sku: String(formTitle).replace(/\s+/g, '').toUpperCase(),
            })
        } else {
            var genSKU = ''

            // convert title to array with space is separate
            const arrTitle = String(formTitle).split(' ')

            // get each letter of title and get the first character
            arrTitle.map((value) => {
                genSKU += value.charAt(0)
            })

            // if genSKU is not empty
            if (genSKU.length > 0) {
                // if genSKU is bigger than 7 character, cut from 0 to 7 position
                if (genSKU.length > 7) {
                    genSKU = genSKU.slice(0, 7)
                }

                // set sku is title and uppercase string
                this.props.form.setFieldsValue({
                    sku: String(genSKU).replace(/\s+/g, '').toUpperCase(),
                })
            }
        }
    }

    // onChange tab productType
    onChangTabProductType = (value) => {
        this.props.form.setFieldsValue({
            tag: value,
        })
    }

    render() {
        const { productType, attributes, loading } = this.state
        const { getFieldDecorator } = this.props.form
        const { id } = this.props.match.params
        if (id && !productType) return <Skeleton />
        return (
            <div>
                <PageHeader
                    style={{ padding: '16px 0px' }}
                    title='Product Types'
                    onBack={() => {
                        history.goBack()
                    }}
                />
                <Form onSubmit={this.saveProductType}>
                    <Row gutter={20}>
                        <Col span={24} lg={16}>
                            <Card title='Detail' style={{ marginBottom: 20 }}>
                                <Form.Item label='Title'>
                                    {getFieldDecorator('title', {
                                        rules: [{ required: true, message: 'Please input title' }],
                                        initialValue: productType ? productType.title : '',
                                    })(<Input onBlur={() => this.generateSKU()} />)}
                                </Form.Item>
                                {/* input sku for product type */}
                                <Form.Item label='SKU'>
                                    {getFieldDecorator('sku', {
                                        rules: [{ required: true, message: 'Please input sku' }],
                                        initialValue: productType ? productType.sku : '',
                                    })(<Input maxLength={7} onBlur={() => this.onBlurRemoveSpace('sku')} />)}
                                </Form.Item>

                                <Form.Item label='Mockup Link'>
                                    {getFieldDecorator('mockup', {
                                        //rules: [{ required: true, message: "Please input sku" }],
                                        initialValue: productType ? productType.mockup : '',
                                    })(<Input onBlur={() => this.onBlurRemoveSpace('mockup')} />)}
                                </Form.Item>
                                <Form.Item label='Description'>
                                    {getFieldDecorator('description', {
                                        initialValue: productType ? productType.description : '',
                                    })(<Wysiwyg />)}
                                </Form.Item>
                                <Form.Item label='Description Special:'>
                                    {getFieldDecorator('description_special', {
                                        initialValue: productType ? productType.description_special : '',
                                    })(<Wysiwyg />)}
                                </Form.Item>
                            </Card>
                            <Card title={<CardTitleRequired>Attributes</CardTitleRequired>} style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('attributes', {
                                        initialValue: attributes,
                                    })(<ProductTypeAttributes values={this.state.attributes} />)}
                                </Form.Item>

                                <ItemProduct onSubmit={this.onAddAttr} onCancel={() => this.showModal(false)} showModal={this.state.showModal} />
                            </Card>
                            <Card title={<CardTitleRequired>Print Areas</CardTitleRequired>} style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('print_files', {
                                        initialValue: productType ? productType.print_files || [] : [],
                                    })(
                                        <ProductTypePrintFiles
                                            personalized={
                                                this.props.form.getFieldValue('personalized') !== undefined
                                                    ? this.props.form.getFieldValue('personalized')
                                                    : productType
                                                    ? productType.personalized
                                                    : false
                                            }
                                        />,
                                    )}
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={24} lg={8}>
                            <Card title='Suppliers' style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('suppliers', {
                                        roles: [{ required: true }],
                                        initialValue: productType ? productType.suppliers.map((sup) => sup.id) : [],
                                    })(<SupplierSelect />)}
                                </Form.Item>
                            </Card>
                            <Card title='Shipping Carries' style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('product_type_carrriers', {
                                        roles: [{ required: true }],
                                        initialValue: productType ? productType.product_type_carriers.map((car) => car.carrier_id) : [],
                                    })(
                                        <CarrierSelect
                                            onChange={(_, option) => {
                                                let carrier = option.map((el) => el.props)
                                                let a = carrier.map((el) => {
                                                    let item = this.state.carrier.find((e) => e.value === el.value)
                                                    if (item) {
                                                        return item
                                                    } else {
                                                        return el
                                                    }
                                                })
                                                let defaultItem = a.find((el) => el.default)
                                                this.setState({
                                                    carrier: a,
                                                    defaultCarrier: defaultItem ? defaultItem.value : a[0]?.value,
                                                })
                                            }}
                                        />,
                                    )}
                                </Form.Item>
                                {this.state.carrier.length > 0 && (
                                    <Radio.Group
                                        style={{ width: '100%' }}
                                        value={this.state.defaultCarrier ? this.state.defaultCarrier : 0}
                                        onChange={(e) => this.setState({ defaultCarrier: e.target.value })}>
                                        {this.state.carrier.map((el, index) => (
                                            <Radio
                                                value={el.value}
                                                style={{
                                                    display: 'block',
                                                    height: '30px',
                                                    lineHeight: '30px',
                                                    marginBottom: 5,
                                                }}>
                                                <div style={{ display: 'inline-block', width: '90%' }}>
                                                    <Row gutter={16}>
                                                        <Col span={16}>{el.children}:</Col>
                                                        <Col style={{ textAlign: 'right', paddingRight: 0 }}>
                                                            <CarrierFormItem>
                                                                <Form.Item>
                                                                    {getFieldDecorator(`shippingcost[${el.value}]`, {
                                                                        initialValue:
                                                                            this.state.carrier && this.state.carrier[index].price
                                                                                ? this.state.carrier[index].price
                                                                                : 0,
                                                                    })(
                                                                        <InputNumber
                                                                            min={0}
                                                                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                        />,
                                                                    )}
                                                                </Form.Item>
                                                            </CarrierFormItem>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                )}
                            </Card>
                            <Card title='Categories' style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('categories', {
                                        initialValue: productType ? productType.categories.map((cat) => cat.id) : [],
                                    })(<CategoriesSelect />)}
                                </Form.Item>
                            </Card>
                            <Card title='Made In' style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('origin', {
                                        initialValue: productType ? productType.origin : '',
                                    })(<Input placeholder='Made in ...' />)}
                                </Form.Item>
                            </Card>
                            <Card title='Production time' style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('production_time', {
                                        initialValue: productType ? productType.production_time : '',
                                    })(<Input placeholder='Production time...' />)}
                                </Form.Item>
                            </Card>
                            <Card title='Shipping time' style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('shipping_time', {
                                        initialValue: productType ? productType.shipping_time : '',
                                    })(<Input placeholder='Shipping time...' />)}
                                </Form.Item>
                            </Card>
                            <Card title='Files Type Supported' style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('file_type_supported', {
                                        initialValue: productType ? productType.file_type_supported : '',
                                    })(<Input placeholder='Files Type Supported...' />)}
                                </Form.Item>
                            </Card>
                            <Card title='DPI' style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('dpl', {
                                        initialValue: productType ? productType.dpl : 0,
                                    })(<InputNumber placeholder='DPI...' style={{ width: '100%' }} />)}
                                </Form.Item>
                            </Card>
                            <Card title='Link Template' style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('link_template', {
                                        initialValue: productType ? productType.link_template : '',
                                    })(<Input placeholder='Link Template...' />)}
                                </Form.Item>
                            </Card>

                            <Card title='Tag' style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('tag', {
                                        initialValue: productType?.tag || undefined,
                                    })(
                                        <Select placeholder='Select...' onChange={this.onChangTabProductType}>
                                            <Select.Option value='Best seller'>Best seller</Select.Option>
                                            <Select.Option value='New'>New</Select.Option>
                                            <Select.Option value='Premium'>Premium</Select.Option>
                                        </Select>,
                                    )}
                                </Form.Item>
                            </Card>
                            {/* <Card title='Personalized type' style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('personalized', {
                                        initialValue: productType && productType.personalized ? productType.personalized : false,
                                        valuePropName: 'checked',
                                    })(<Checkbox>Personalized type</Checkbox>)}
                                </Form.Item>
                            </Card> */}
                            <Card title='Is Special' style={{ marginBottom: 20 }}>
                                <Form.Item>
                                    {getFieldDecorator('is_special', {
                                        initialValue: productType && productType.is_special ? productType.is_special : false,
                                        valuePropName: 'checked',
                                    })(<Checkbox>Is Special</Checkbox>)}
                                </Form.Item>
                            </Card>
                            <Card title='Images'>
                                <Form.Item>
                                    {getFieldDecorator('images', {
                                        initialValue: productType ? productType.images : [],
                                    })(<Images />)}
                                </Form.Item>
                            </Card>
                        </Col>

                        <Col span={24}>
                            <Button type='primary' loading={loading} icon='save' htmlType='submit'>
                                Save
                            </Button>
                            <Divider type='vertical' />
                            <Button icon='undo' onClick={() => history.goBack()}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

export default Form.create({ name: 'product-type-form' })(ProductTypeForm)
