import gql from "graphql-tag";

export const ATTRIBUTES = gql`
    query getAttributes($limit: Int, $offset: Int) {
        getAttributes(
            limit: $limit
            offset: $offset
        ) {
            count
            hits {
                id
                name
            }
        }
    }
`

export const GETATTRIBUTEBYID = gql`
    query getAttributeById($id: Int!) {
        getAttributeById(id: $id) {
            id
            name
        }
    }
`