import React from 'react'
import { connect } from 'react-redux'
import { Button, Dropdown, Icon, Menu, Modal, notification, Select } from 'antd'
//import { AWS_CLOUDFRONT_URL } from '../../config'
import ImportTrackings from '../tracking/ImportTracking'
import gql from '../../api/gql'
import _ from 'lodash'
import { setOrderId } from '../../actions'
import AddImportOrder from './AddImportOrder'
import { CLOUDFRONT_URL } from '../../config'
import ExportOrder from './ExportOrder'
import { URL_ORDER } from '../../config/stag'

const query = `query productTypes($filter: ProductTypeFilter){
  productTypes(filter: $filter){
    count,
    hits{
      id
      sku
      title
      images
      state
      categories{
        id, title
      }
      attributes{
        name
      }
      suppliers{
        first_name last_name id
      }
    }
  }
}`

class OrdersToolbar extends React.Component {
    state = {
        loading: false,
        productTypes: [],
        visible: false,
        page: 1,
        countProductType: 0,
        productsTypeIds: [],
    }
    componentDidMount() {
        if (this.props.menu) {
            this.loadProductType()
        }
    }
    exportCatalog = (e) => {
        e.preventDefault()
        this.setState({ visible: false, loading: true })
        let query = `mutation exportCatalog($ids: [Int!]){
            exportCatalog(ids: $ids)
        }`

        gql.request(query, {
            ids: this.state.productsTypeIds,
        })
            .then((res) => {
                window.location = res.exportCatalog
                this.setState({
                    productsTypeIds: [],
                    loading: false,
                })
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                })
                notification['error']({
                    message: _.get(err, '[0].message'),
                })
            })
    }

    exportOrders = (e) => {
        e.preventDefault()
        this.setState({
            loading: true,
        })
        const { filter, currentUser } = this.props
        let isSupplier = currentUser.roles.find((role) => ['Supplier'].includes(role.name))
        let isSeller = currentUser.roles.find((role) => ['Seller'].includes(role.name))
        let query = `mutation exportOrders($filter: OrderFilter){
            exportOrders(filter: $filter)
        }`

        let supplier = `mutation supplierExportOrder($filter: OrderFilter){
            supplierExportOrder(filter: $filter)
        }`

        gql.request(isSupplier ? supplier : query, {
            filter: {
                ...filter,
                order_id: this.props.order_id,
                site_ids: isSeller ? [localStorage.getItem('currentSiteID')] : filter.site_ids,
            },
        })
            .then((res) => {
                window.location = `${isSupplier ? res.supplierExportOrder : res.exportOrders}`
                this.setState({
                    loading: false,
                })
                this.props.setOrderId(null)
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                })
                notification['error']({
                    message: _.get(err, '[0].message'),
                })
            })
    }

    loadProductType() {
        gql.request(query, {
            filter: {
                limit: 20,
                offset: (this.state.page - 1) * 20,
            },
        }).then((res) => {
            this.setState({
                productTypes: res.productTypes.hits || [],
                countProductType: res.productTypes.count,
            })
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }

    handleScroll = (e) => {
        const element = e.target
        if (element.scrollHeight === element.clientHeight + element.scrollTop) {
            if (this.state.countProductType && this.state.page * 20 < this.state.countProductType) {
                gql.request(query, {
                    filter: {
                        limit: 20,
                        offset: this.state.page * 20,
                    },
                }).then((res) => {
                    this.setState({
                        page: this.state.page + 1,
                        productTypes: [...this.state.productTypes, ...res.productTypes.hits],
                    })
                })
            }
        } else {
        }
    }

    render() {
        const { loading } = this.state
        const { currentUser, filter, menu } = this.props

        let isSeller = currentUser.roles.find((role) => ['Seller'].includes(role.name))
        const status = _.get(filter, 'status', [])

        return (
            <div>
                {menu ? (
                    <Dropdown
                        overlay={
                            <Menu>
                                {!isSeller && (
                                    <Menu.Item>
                                        <ImportTrackings />
                                    </Menu.Item>
                                )}

                                <Menu.Item disabled={!status || (status && status.length === 0) || status.includes('pending') || status.includes('pending_design')}>
                                    <ExportOrder filter={filter} currentUser={currentUser} order_id={this.props.order_id} displayBtn={false}></ExportOrder>
                                </Menu.Item>

                                {isSeller && (
                                    <Menu.Item>
                                        <AddImportOrder setLoading={(status) => this.setState({ loading: status })} loadOrder={this.props.loadOrder} />
                                    </Menu.Item>
                                )}

                                <Menu.Item onClick={this.showModal}>Sample CSV</Menu.Item>
                            </Menu>
                        }>
                        <Button loading={loading}>
                            Export / Import <Icon type='down' />
                        </Button>
                    </Dropdown>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {!isSeller && <ImportTrackings displayBtn={true} />}
                        <ExportOrder filter={filter} currentUser={currentUser} order_id={this.props.order_id} displayBtn={true}></ExportOrder>
                    </div>
                )}
                <Modal title='Sample CSV' visible={this.state.visible} onOk={() => this.setState({ visible: false })} onCancel={() => this.setState({ visible: false })}>
                    <ul>
                        <li>
                            <a href={`${CLOUDFRONT_URL}/sample/PGS-api-Sample-CSV.csv`}>Download File Sample Order</a>
                        </li>
                        <li>
                            View <a href="https://documenter.getpostman.com/view/28591846/2sA3kYizzF#cf70cbe7-9e39-461c-aee3-d4a7b63b04f1" target="_blank">Catalogs API</a> to get product Id.
                        </li>
                    </ul>
                </Modal>
            </div>
        )
    }
}

export default connect(
    (state) => ({
        currentUser: state.app.currentUser,
        filter: state.orders.filter,
        order_id: state.orders.order_id,
    }),
    { setOrderId }
)(OrdersToolbar)
