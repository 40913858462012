import React, { Component } from 'react'
import { Form, notification, Input, Skeleton, DatePicker, Button, Checkbox, Card, Select } from 'antd'
import gql from '../../api/gql'
import { history } from '../../history'
import _ from 'lodash'
import moment from 'moment'

const isStrEmpty = (str) => {
    return str === undefined || str === null || str.length === 0 || str.trim().length === 0
}

class UserDetail extends Component {
    state = {
        users: null,
        loading: false,
        roles: [],
        templates: [],
        isSupplier: false,
        partnerFulfillments: [],
        listDiscounts: [],
        printAreasDefault: [
            {
                id: "Front",
                name: "Front"
            },
            {
                id: "Back",
                name: "Back"
            },
            {
                id: "Right_Sleeve",
                name: "Right Sleeve"
            },
            {
                id: "Left_Sleeve",
                name: "Left Sleeve"
            },
            {
                id: "Back_Neck",
                name: "Back Neck"
            },
            {
                id: "Front_Neck",
                name: "Front Neck"
            },
        ]
    }

    componentDidMount() {
        this.getListTemplate()
        this.getIdUser()
        this.getListPartnerFulfillments()
        this.getListDiscount()
    }

    getListTemplate() {
        const query = `query templates($filter: TemplateFilter!){
      templates(filter: $filter){
        count
        hits{
          id 
          name
          type
        }
      }
    }`
        gql.request(query, {
            filter: {
                offset: 0,
                limit: 100,
            },
        })
            .then((res) => {
                this.setState({
                    templates: res.templates.hits || [],
                })
            })
            .catch((err) => {
                console.log('Get List Template fails')
            })
    }

    getIdUser() {
        this.setState({ loading: false })
        const { id } = this.props
        if (id) {
            const query = `query userById{userById(id:${id}){
            id 
            last_name
            first_name
            email
            pass
            phone
            birthday
            address
            roles{
              id
              name
            }
            avatar{
              id
              url
            }
            is_subscription
            is_special
            template_id
            partner_fulfillment {
              id
              name
              domain
              api_key
              api_signature
              print_areas
            }
            discount {
                id
            }
          }
      }`
            this.setState({ loading: true })
            gql.request(query, {}).then((res) => {
                this.setState({ users: res.userById || {} })
                if (Object.keys(res.userById).length) {
                    const isSupplier = res.userById.roles.some((el) => ['Supplier'].includes(el.name))
                    this.setState({
                        isSupplier,
                    })
                }
            })
            const query_role = `query roles{roles{id name}}`

            gql.request(query_role).then((res) => {
                this.setState({
                    // roles: res.roles
                    roles: res.roles ? res.roles.filter((r) => r.name !== 'Authenticated') : [],
                })
            })
        }
    }

    getListPartnerFulfillments() {
        const query = `query partnerFulfillments{ partnerFulfillments(filter: {limit: 20})
      {
       hits {
        id
        name
      }
      }
    }`

        gql.request(query)
            .then((res) => {
                this.setState({
                    partnerFulfillments: res?.partnerFulfillments && res.partnerFulfillments?.hits,
                })
            })
            .catch((err) => {
                console.log('Get List partner fulfillments fails')
            })
    }

    getListDiscount() {
        const query = `query discounts{ discounts
      {
      hits {
      id
      name
      discount
      type
      effective_time
      start_time
      end_time
      }
      count
      }
      }`

        gql.request(query)
            .then((res) => {
                this.setState({
                    listDiscounts: res?.discounts && res.discounts?.hits,
                })
            })
            .catch((err) => {
                console.log('Get List discounts fails')
            })
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (!this.state.isSupplier) {
                    delete values.partner_fulfillment
                } else {
                    let partnerFulfillment = values.partner_fulfillment
                    switch (true) {
                        case !isStrEmpty(partnerFulfillment.domain) &&
                            !isStrEmpty(partnerFulfillment.name) &&
                            !isStrEmpty(partnerFulfillment.api_key) &&
                            !isStrEmpty(partnerFulfillment.api_signature):
                            if (this.state.users?.partner_fulfillment?.id !== 0) {
                                values.partner_fulfillment['id'] = this.state.users?.partner_fulfillment?.id
                            }
                            break
                        case isStrEmpty(partnerFulfillment.domain) &&
                            isStrEmpty(partnerFulfillment.name) &&
                            isStrEmpty(partnerFulfillment.api_key) &&
                            isStrEmpty(partnerFulfillment.api_signature):
                            if (this.state.users?.partner_fulfillment?.id !== 0) {
                                values.partner_fulfillment['id'] = this.state.users?.partner_fulfillment?.id
                            }
                            break
                        case isStrEmpty(partnerFulfillment.name) ||
                            isStrEmpty(partnerFulfillment.domain) ||
                            isStrEmpty(partnerFulfillment.api_key) ||
                            isStrEmpty(partnerFulfillment.api_signature):
                            notification['error']({
                                message: 'Please fill out the form partner fulfillment',
                            })
                            return false
                        default:
                            return
                    }
                }
                values.birthday = values.birthday && moment(values.birthday).format()
                values['discount_id'] = parseInt(values.discount_id)
                const mutation = `mutation updateUser($id: ID! $input: UpdateUser!){
            updateUser(input: $input, id: $id){
              id
              first_name
              last_name
              email
            }
          }`
                this.setState({ loading: true })
                gql.request(mutation, {
                    input: values,
                    id: this.props.id,
                })
                    .then((res) => {
                        notification['success']({
                            message: 'User has been updated',
                        })
                        this.setState({ loading: false })
                        history.push('/admin/users-manager')
                    })
                    .catch((err) => {
                        notification['error']({
                            message: _.get(err, '[0].message'),
                        })
                        this.setState({ loading: false })
                    })
            }
        })
    }

    render() {
        const { id, form } = this.props
        const { users } = this.state
        const { getFieldDecorator } = form
        if (id && !users) return <Skeleton />

        return (
            <Form onSubmit={this.onSubmit}>
                <Card title='Detail' style={{ marginBottom: 20 }}>
                    <Form.Item label='First name'>
                        {getFieldDecorator('first_name', {
                            initialValue: users.first_name,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input first name',
                                },
                                { max: 50, message: 'First Name must maximum 50 characters.' },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label='Last name'>
                        {getFieldDecorator('last_name', {
                            initialValue: users.last_name,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input last name',
                                },
                                { max: 50, message: 'Last Name must maximum 50 characters.' },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label='Email'>
                        {getFieldDecorator('email', {
                            initialValue: users.email,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input email',
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label='Birthday'>
                        {getFieldDecorator('birthday', {
                            initialValue: users.birthday && moment(users.birthday),
                        })(<DatePicker style={{ width: 300 }} />)}
                    </Form.Item>
                    <Form.Item label='Address'>
                        {getFieldDecorator('address', {
                            initialValue: users.address,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label='Phone'>
                        {getFieldDecorator('phone', {
                            initialValue: users.phone,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input phone',
                                },
                                { max: 11, message: 'Phone must maximum 11 characters.' },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label='Discount'>
                        {getFieldDecorator('discount_id', {
                            initialValue: users?.discount?.id || '0',
                        })(
                            <Select defaultValue='1'>
                                <Select.Option value='0'>Select discount</Select.Option>
                                {this.state.listDiscounts.length > 0 &&
                                    this.state.listDiscounts.map((v, i) => {
                                        let time =
                                            v.type === 'timeline'
                                                ? `- (${moment(v?.start_time).format('YYYY-MM-DD')} to ${moment(v?.end_time).format('YYYY-MM-DD')})`
                                                : `- ${v?.effective_time || 0} days`
                                        return (
                                            <Select.Option value={v.id} key={i}>
                                                {v.name} {time}
                                            </Select.Option>
                                        )
                                    })}
                            </Select>,
                        )}
                    </Form.Item>

                    {this.state.isSupplier && (
                        <>
                            <Form.Item label='Partner Fulfillment Name '>
                                {getFieldDecorator('partner_fulfillment.name', {
                                    initialValue: users.partner_fulfillment.name,
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label='Partner Fulfillment Domain '>
                                {getFieldDecorator('partner_fulfillment.domain', {
                                    initialValue: users.partner_fulfillment.domain,
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label='Partner Fulfillment Api Key '>
                                {getFieldDecorator('partner_fulfillment.api_key', {
                                    initialValue: users.partner_fulfillment.api_key,
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label='Partner Fulfillment Api Signature '>
                                {getFieldDecorator('partner_fulfillment.api_signature', {
                                    initialValue: users.partner_fulfillment.api_signature,
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label='Print areas defaults'>
                                {getFieldDecorator('partner_fulfillment.print_areas', {
                                    initialValue: users.partner_fulfillment.print_areas,
                                })(
                                    <Select 
                                        showArrow
                                        showSearch
                                        style={{ width: 300 }}
                                        placeholder={"Set print areas of partner"}
                                        mode={"multiple"}
                                    >
                                        {this.state.printAreasDefault &&
                                            this.state.printAreasDefault.map((el) => (
                                                <Select.Option key={el.id} value={el.id}>
                                                    {el.name}
                                                </Select.Option>
                                            ))}
                                    </Select>,
                                )}
                            </Form.Item>
                        </>
                    )}

                    <Form.Item label='Subscription'>
                        {getFieldDecorator('is_subscription', {
                            valuePropName: 'checked',
                            initialValue: users.is_subscription,
                        })(<Checkbox>Receive updates about new products and email marketing</Checkbox>)}
                    </Form.Item>
                    <Form.Item label='Roles'>
                        {getFieldDecorator('role', {
                            rules: [{ required: true, message: 'Please choose user roles' }],
                            initialValue: users.roles.map((role) => role.name),
                        })(
                            <Checkbox.Group
                                options={this.state.roles
                                    .filter((r) => r.id)
                                    .map((role) => {
                                        return { label: role.name, value: role.name }
                                    })}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label='Special Seller'>
                        {getFieldDecorator('is_special', {
                            valuePropName: 'checked',
                            initialValue: users.is_special,
                        })(<Checkbox>Special Seller</Checkbox>)}
                    </Form.Item>
                    {this.state.isSupplier && (
                        <>
                            <Form.Item label='Export Template'>
                                {getFieldDecorator('template_id', {
                                    initialValue: users.template_id,
                                })(
                                    <Select style={{ width: 200 }}>
                                        {users.template_id === 0 && (
                                            <Select.Option key={0} value={0}>
                                                Select export template
                                            </Select.Option>
                                        )}
                                        {this.state.templates &&
                                            this.state.templates.map((el) => (
                                                <Select.Option key={el.id} value={el.id}>
                                                    {el.name}
                                                </Select.Option>
                                            ))}
                                    </Select>,
                                )}
                            </Form.Item>
                        </>
                    )}
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Save
                        </Button>
                        <Button
                            onClick={() => {
                                history.push('/admin/users-manager')
                            }}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Card>
            </Form>
        )
    }
}

export default Form.create({ name: 'form' })(UserDetail)
