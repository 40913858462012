import { Select } from "antd";
import React, { useEffect, useState } from "react";

export const VariantPartnerDefault = ({partnerFulfillments, defaultValue, onChange}) => {
    let makeDefaultValue 

    if (defaultValue > 0) {
        makeDefaultValue = defaultValue
    }

    const [value, setValue] = useState(makeDefaultValue)

    useEffect(() => {
        if (defaultValue > 0) { 
            setValue(defaultValue)
        }
    }, [defaultValue])

    return (
        <>
            <Select
                showArrow
                allowClear
                style={{width: '100%'}}
                key={'default_partner' + Math.random(99999)}
                placeholder="Default partner"
                onChange={value => {
                    setValue(value)
                    onChange(value)
                }}
                value={value}
            >
                {partnerFulfillments.length> 0 && partnerFulfillments?.map(el => {
                    return (
                        <Select.Option
                            key={el?.id + Math.random(99999)}
                            value={el?.id}
                        >
                            {el.name}
                        </Select.Option>
                    )
                })}
            </Select>
        </>
    )
}