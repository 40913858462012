import { useQuery } from "@apollo/react-hooks";
import { Button, Card, Input } from "antd";
import gql from "graphql-tag";
import React from "react";
import _ from "lodash";

export const SITEID = gql`
  query siteById($id: Int!) {
    siteById(id: $id) {
      id
      title
      domain
      platform
      status
      verified
      created_at
      updated_at
      type
      api_key
    }
  }
`;
export const CREATE_CHANNEL = gql`
  mutation createChannel($input: NewChannel!) {
    createChannel(input: $input) {
      id
      name
      apiKey
      active
    }
  }
`;

const ApiKey = (props) => {
  const { id } = props;
  const { data, refetch } = useQuery(SITEID, { variables: { id: id } });
  return (
    <Card title="API Key">
      <div style={{ display: "flex", gap: 10 }}>
        <Input.Password
          value={data?.siteById.api_key}
          readOnly
        ></Input.Password>
        <Button
          onClick={() => {
            let shortcode = document.createElement("textarea");
            shortcode.innerText = data?.siteById.api_key;
            document.body.appendChild(shortcode);
            shortcode.select();
            document.execCommand("copy");
            shortcode.remove();
          }}
        >
          Copy
        </Button>
      </div>
      {/* <div style={{ display: "flex", gap: 10 }}>
        <Input value={data?.channel.apiKey} readOnly />
        {data?.channel.apiKey ? (
          <Popconfirm
            placement="topRight"
            title={"Are you sure?"}
            onConfirm={() =>
              generate({
                variables: {
                  input: {
                    name: data?.channel.name,
                    reGen: data?.channel.apiKey ? true : false,
                    genSite: false,
                  },
                },
              })
                .then((ress) => {
                  refetch();
                })
                .catch((err) => {
                  notification.error({ message: _.get(err, "[0].message") });
                })
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">{"Re Generate"}</Button>
          </Popconfirm>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              generate({
                variables: {
                  input: {
                    name: data?.channel.name,
                    reGen: data?.channel.apiKey ? true : false,
                    genSite: false,
                  },
                },
              })
                .then((ress) => {
                  refetch();
                })
                .catch((err) => {
                  notification.error({ message: _.get(err, "[0].message") });
                });
            }}
          >
            Generate
          </Button>
        )}
      </div> */}
    </Card>
  );
};

export default ApiKey;
