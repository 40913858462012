import React from 'react'
import { connect } from 'react-redux'
import { Select } from 'antd'
import _ from 'lodash'

class Tags extends React.Component{
  state = {
    value: []
  }

  handleOnChange = () => {
    const {onChange} = this.props
    if (typeof onChange === 'function'){
      onChange(this.state.value)
    }
  }

  render() {
    const {value} = this.state
    return (
      <div>
        <Select 
          value={value}
          onChange={value => {
            this.setState({value}, this.handleOnChange)
          }}
          placeholder="Enter Tags"
          tokenSeparators={[',']}
          showArrow={true}
          allowClear={true}
          style={{width: '100%'}} 
          mode="tags" 
          />
      </div>
    )
  }
}

export default connect(state => ({
  currentSite: state.app.currentSite
}))(Tags)