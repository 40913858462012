import gql from "graphql-tag";

export const UPLOAD_ORDER_PRINTFILE = gql`
  mutation updateOrderPrintFiles(
    $order_id: Int!
    $print_files: [OrderPrintFileInput]
    $is_ready: Boolean!
  ) {
    updateOrderPrintFiles(
      order_id: $order_id
      print_files: $print_files
      is_ready: $is_ready
    )
  }
`;
