import React from 'react'
import styled from 'styled-components'
import DateRange from '../DateRange'
import { Row, Col, Card, Table, List, Skeleton, Button } from 'antd'
import gql from '../../api/gql'
import { connect } from 'react-redux'
import { setCurrentUser } from '../../actions'
import _ from 'lodash'
import moment from 'moment'
import { history } from '../../history'
import Chart from 'react-google-charts'
const Container = styled.div``

class SupplierDashboard extends React.Component {
  state = {
    loading: false,
    pendingAmount: 0,
    approvedAmount: 0,
    listNews: [],
    totalNews: 0,
    dateRange: {
      from: null,
      to: null
    },
    topProductTypeList: [],
    statistics: [],
    dataChart: [],
    aggFilter: 'month',
    otherType: 0,
  }
  componentDidMount(){
    this.getTransaction()
    this.getNews()
    this.getStatistics()
  }
  onChangeDate = async (value) => {
    const between = Math.abs(moment(value.from).diff(moment(value.to))/86400000)
    if(between<15){this.setState({aggFilter: 'day'})} else {
    if(between<61){this.setState({aggFilter: 'week'})} else this.setState({aggFilter: 'month'})}
    if(!value.from){this.setState({aggFilter: 'month'})}
    await this.setState({ dateRange: value, 
      topProductTypeList: [],
      statistics: [],
      dataChart: [],
     })
    this.getStatistics()
  }
  getStatistics() {
    const { dataChart, aggFilter } = this.state
    let other = 0
    const query = `query supplierStats($filter: StasFilter){
      supplierStats(filter: $filter)
    }`
    this.setState({ loading: true })
    gql.request(query,{filter: {
      from: this.state.dateRange.from?moment.utc(this.state.dateRange.from).toISOString() : null,
      to: this.state.dateRange.to?moment.utc(this.state.dateRange.to).toISOString(): null,
      agg_by: aggFilter
    }})
      .then(res => {
        const items = res.supplierStats.aggregations.period.buckets.map(data => {
          const statistics = {
            date: data.key_as_string,
            revenue: data.revenue.value,
            topProductType: data.top_product_types.product_types.buckets
          }
          other+= data.top_product_types.product_types.sum_other_doc_count
          return statistics
        })
        this.setState({
          otherType: other,
          statistics: items,
          loading: false
        }, () => {
          let itemChart = []
          this.state.statistics.map(el => {
            let item = [moment(el.date).format(aggFilter==='month'?"MM YYYY" : 'DD MM YYYY'), el.revenue]
            itemChart.push(item)
          })
          this.setState({ dataChart: [...dataChart, ...itemChart] })
          const type = _.flattenDeep(this.state.statistics.map(el => el.topProductType))
          this.getTopProductType(type)
        })
      })
  }
  getTopProductType = (type) => {
    let middle = Object.keys(_.groupBy(type, 'key')).map( el => {
      if(_.groupBy(type, 'key')[el].length>1){
        let doc_count = 0
        _.groupBy(type, 'key')[el].map(e => doc_count+=e.doc_count)
        return {key: el, doc_count: doc_count}
      } else {
        return {key: el, doc_count: _.groupBy(type, 'key')[el][0].doc_count}
      }
    })
    const ids = middle.map(el => el.key)
    const query = `query productTypeByIds($ids: [Int!]!){
      productTypeByIds(ids: $ids){
        id
        sku
        title
        images
        categories{
          id, title
        }
        attributes{
          name
        }
        suppliers{
          first_name last_name id
        }
      }
    }`
    this.setState({ loading: true })
    gql.request(query, { ids: ids })
      .then(res => {
        const top = res.productTypeByIds.map(el => {
          const check = middle.find(e => e.key == el.id)
          return {...el, total: check.doc_count}
        }).sort(function(a,b){return b.total-a.total})
        this.setState({ topProductTypeList: top, loading: false })
      })
  }
  getTransaction (){
    const query = `query supplierTransactions($filter: SupplierTransactionFilter){
      supplierTransactions(filter: $filter){
        count
        pendingAmount
        approvedAmount
        hits{
          id
          title
        }
      }
    }`
    this.setState({loading: true})
    gql.request(query, {
      filter: {
        supplier_id: this.props.currentUser.id
      }
    }).then( res => {
      this.setState({loading: false, pendingAmount: res.supplierTransactions.pendingAmount, approvedAmount: res.supplierTransactions.approvedAmount})
    })
  }
  getNews(){
    const query = `query news($filter: NewsFilter){
      news(filter:$filter){
        count
        hits{
          id
          title
          content
          status
          notification
          send_mail
          creater{
            id
            first_name
            last_name
            email
            address
            level{
              id
              name
              order_total
              discount
            }
          }
        }
      }
    }`
    this.setState({loading: true})
    gql.request(query, {
      filter: {status: true, limit: 3, offset: this.state.listNews.length}
    }).then(res => this.setState({totalNews: res.news.count,listNews: [...this.state.listNews,...res.news.hits],loading: false})
      // this.setState({
      //   news: res.news.hits || [],
      //   totalNews: res.news.count,
      //   loading: false
      // })
    )
  }
  onLoadMore = () => {
    this.getNews()
  }
  render() {
    const { approvedAmount, pendingAmount, totalNews, listNews, dataChart } =this.state
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
      },
      {
        title: 'SKU',
        key: 'sku',
        dataIndex: 'sku'
      },
      {
        title: 'Suppliers',
        dataIndex: 'suppliers',
        render: (supplier) => supplier.map(supplier => `${supplier.first_name} ${supplier.last_name}`).join(', ')
      },
      {
        title: 'Categories',
        dataIndex: 'categories',
        render: (categories) => categories.map(cat => cat.title).join(', ')
      },
      {
        title: 'Total Order',
        dataIndex: 'total'
      }
    ]
    const loadMore =
      !this.state.loading && totalNews > listNews.length ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button onClick={() => this.onLoadMore()}>loading more</Button>
        </div>
      ) : null;
      let pieChart = [['Product Types', 'Orders']]
      let otherTotal = 0
      this.state.topProductTypeList.map((el,index) => {
        if(index<10){
        let item = [el.title, el.total]
        pieChart.push(item)} else {
          otherTotal+= el.total
        }
      })
      pieChart.push(['Other Product Types', this.state.otherType+otherTotal])
    return (
      <Container>
        <DateRange defaultValue='all' onChange={this.onChangeDate}/>
        {/* <Row type='flex' gutter={10} style={{marginTop: 10}}>
          <Col span={24} md={14}>
          <Chart
            height='400px'
            loader={<div>Loading Chart</div>}
            data={dataChart.length>0? 
              [['Month', 
                `Revenue ($${dataChart.reduce((a,b) => a+b[1],0)})`
              ],...dataChart]:
              [['Month', 'Revenue'],['0',0]]}
            chartType="ComboChart"
            options={{
              title: 'Revenue',
              vAxis: { title: 'Dollar ($)' },
              hAxis: { title: this.state.aggFilter.charAt(0).toUpperCase() + this.state.aggFilter.slice(1) },
              series: {
                0: { color: 'rgb(0, 143, 251)'},
              },
              chartArea: { width: '69%', left: 100 },
            }}
            rootProps={{ 'data-testid': '1' }}
          />
          </Col>
          <Col span={24} md={10}>
          <Chart
            height={'400px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={pieChart}
            options={{
              title: 'Top 10 Best Selling Product Types',
              pieHole: 0.4,
            }}
            rootProps={{ 'data-testid': '3' }}/>
            </Col>
        </Row> */}
        <Table columns={columns} style={{ marginTop: "20px" }} dataSource={this.state.topProductTypeList.slice(0,10)} pagination={{pageSize: 5}} />
        {/* <Card title="News" style={{marginTop: 20}}>
          <List
            dataSource={this.state.listNews}
            itemLayout="horizontal"
            loadMore={loadMore}
            renderItem={item => (
              <List.Item>
                <Skeleton avatar title={false} loading={item.loading} active>
                  <div onClick={()=> history.push(`/news/${item.id}`)}><a>{item.title}</a></div>
                </Skeleton>
              </List.Item>
            )}
          />
        </Card> */}
      </Container>
    )
  }
}
export default connect(
  state => {
    return {
      currentUser: state.app.currentUser
    }
  },
  { setCurrentUser }
)(SupplierDashboard)
