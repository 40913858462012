import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Button, Card, Form, Input, InputNumber, notification, PageHeader, Select } from 'antd'
import { CREATE_GUIDELINE_POLICY, UPDATE_GUIDELINE_POLICY } from '../../../graphql/mutation/guideline-policy'

import styled from 'styled-components'
import Wysiwyg from '../../wysiwyg'

const BoxButton = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
    button {
        :not(:last-child) {
            margin-right: 10px;
        }
    }
`

const GuidelinePolicyForm = (props) => {
    let history = useHistory()

    const { dataSource, form } = props
    const [loading, setLoading] = useState(false)

    const [updatePolicy] = useMutation(UPDATE_GUIDELINE_POLICY)
    const [createPolicy] = useMutation(CREATE_GUIDELINE_POLICY)

    const onFinishForm = (e) => {
        if (e) e.preventDefault()
        form.validateFields((err, values) => {
            if (!err) {
                values['type'] = parseInt(values.type)
                setLoading(true)
                if (dataSource?.id > 0) {
                    updatePolicy({
                        variables: {
                            id: dataSource.id,
                            input: values,
                        },
                    })
                        .then(() => {
                            notification.success({ message: 'Update Guideline & Policy success' })
                            window.location.href = '/guideline-policy'
                        })
                        .catch((err) => {
                            notification.error({ message: err.message })
                            setLoading(false)
                        })
                } else {
                    createPolicy({
                        variables: {
                            input: values,
                        },
                    })
                        .then(() => {
                            notification.success({ message: 'Create Guideline & Policy success' })
                            window.location.href = '/guideline-policy'
                        })
                        .catch((err) => {
                            notification.error({ message: err.message })
                            setLoading(false)
                        })
                }
            }
        })
    }
    const { getFieldDecorator } = form

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, marginBottom: 20 }}>
                <PageHeader
                    style={{ padding: '16px 0px' }}
                    title='Guideline & Policy Edit'
                    onBack={() => {
                        history.goBack()
                    }}
                />
            </div>
            <Card title='Guideline & Policy Edit'>
                <Form onSubmit={(e) => onFinishForm(e)}>
                    <Form.Item label='Title'>
                        {getFieldDecorator('title', {
                            rules: [{ required: true, message: 'Please input title' }],
                            initialValue: dataSource ? dataSource.title : '',
                        })(<Input placeholder='Title...' />)}
                    </Form.Item>
                    <Form.Item label='Type'>
                        {getFieldDecorator('type', {
                            initialValue: dataSource ? dataSource?.type?.toString() || '1' : '1',
                            rules: [{ required: true, message: 'Please select your type!' }],
                        })(
                            <Select>
                                <Select.Option value='1'>Guideline</Select.Option>
                                <Select.Option value='2'>Policy</Select.Option>
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item label='Sorting'>
                        {getFieldDecorator('sorting', {
                            initialValue: dataSource ? dataSource?.sorting?.toString() || '1' : '1',
                        })(<InputNumber placeholder='Sorting...' />)}
                    </Form.Item>
                    <Form.Item label='Description'>
                        {getFieldDecorator('description', {
                            initialValue: dataSource ? dataSource.description : '',
                        })(<Wysiwyg />)}
                    </Form.Item>
                    <BoxButton>
                        <Button type='primary' loading={loading} icon='save' htmlType='submit'>
                            Save
                        </Button>
                        <Button icon='undo' onClick={() => history.goBack()}>
                            Cancel
                        </Button>
                    </BoxButton>
                </Form>
            </Card>
        </>
    )
}

export default Form.create({ name: 'guideline-policy-form' })(GuidelinePolicyForm)
