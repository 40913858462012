import React, { useEffect, useState } from 'react'

import { useMutation } from '@apollo/react-hooks'
import { Button, notification, Modal, Tag, Input, Icon } from 'antd'
import { EXPORT_PRODUCT } from '../../graphql/mutation/exportProduct'

// regex string to validate email input
const regexEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g)

const ExportProduct = (props) => {
    // get props field value from list product
    const { filter, currentUser } = props

    const [modalExportProduct, setModalExportProduct] = useState({
        modalVisible: false,
        loadingConfirm: false,
        inputVisible: false,
        inputAddEmail: '',
    })

    const [receiverEmail, setReceiverEmail] = useState([])

    // init array email when render and first item is email of user
    useEffect(() => {
        if (currentUser.email && regexEmail.test(currentUser.email)) {
            setReceiverEmail([currentUser.email])
        }
    }, [currentUser])

    // init instance api export product
    const [exportProduct] = useMutation(EXPORT_PRODUCT)

    // call api export product and return response
    const showExportProduct = () => {
        setModalExportProduct((s) => ({
            ...s,
            modalVisible: true,
        }))
    }

    // cancel modal export product and reset all value
    const cancelExportProduct = () => {
        setModalExportProduct((s) => ({
            ...s,
            modalVisible: false,
            loadingConfirm: false,
        }))
    }

    // processing data and call api export product
    const handleExportProduct = () => {
        if (receiverEmail == null || receiverEmail.length === 0) {
            notification.warning({ message: "you have not entered the receiver's email address" })
            return
        }

        setModalExportProduct((s) => ({
            ...s,
            loadingConfirm: true,
        }))

        // cal api export product with params and show notification
        exportProduct({
            variables: {
                filter: {
                    emails: receiverEmail,
                    product_type_ids: filter.product_type_ids ? filter.product_type_ids : null,
                    site_ids: filter?.site_ids ? filter.site_ids : null,
                    search: filter?.search ? filter.search : null,
                },
            },
        })
            .then(() => {
                notification.success({ message: 'Export Product success! Please check your email again' })
                setReceiverEmail([currentUser.email])
                setModalExportProduct({
                    ...modalExportProduct,
                    loadingConfirm: false,
                    modalVisible: false,
                    inputVisible: false,
                    inputAddEmail: '',
                })
            })
            .catch((err) => {
                notification.error({ message: err.message })
                setReceiverEmail([currentUser.email])
                setModalExportProduct({
                    ...modalExportProduct,
                    loadingConfirm: false,
                    modalVisible: false,
                    inputVisible: false,
                    inputAddEmail: '',
                })
            })
    }

    // remove email that client inputed
    const removeEmail = (selectedEmail) => {
        const arrEmail = receiverEmail.filter((email) => email !== selectedEmail)
        setReceiverEmail(arrEmail)
    }

    // show input add email when client click btn add email
    const showInputAddEmail = () => {
        setModalExportProduct((s) => ({
            ...s,
            inputVisible: true,
            inputFocus: true,
        }))
    }

    // get value of input
    const changeInputAddEmail = (e) => {
        let { value } = e.target
        setModalExportProduct((s) => ({
            ...s,
            inputAddEmail: value,
        }))
    }

    // handle when client input email and push it into list email
    const handleInputAddEmail = () => {
        const newEmail = modalExportProduct.inputAddEmail

        const arrEmail = receiverEmail

        if (newEmail && receiverEmail.indexOf(newEmail) === -1 && regexEmail.test(newEmail) && receiverEmail.length < 5) {
            arrEmail.push(newEmail)
        }

        setReceiverEmail(arrEmail)

        setModalExportProduct((s) => ({
            ...s,
            inputVisible: false,
            inputAddEmail: '',
        }))
    }

    return (
        <div>
            <Button type='primary' icon='export' onClick={() => showExportProduct()}>
                Export Product
            </Button>

            <Modal
                title='Receiver Email'
                visible={modalExportProduct.modalVisible}
                onOk={() => handleExportProduct()}
                confirmLoading={modalExportProduct.loadingConfirm}
                onCancel={() => cancelExportProduct()}>
                <div>
                    {/* DOM show list email */}
                    {receiverEmail.map((email) => {
                        const element = (
                            <Tag color='#2db7f5' style={{ marginBottom: '10px' }} key={email} closable={true} onClose={() => removeEmail(email)}>
                                {email}
                            </Tag>
                        )

                        return element
                    })}

                    {/* DOM insert email */}
                    <div>
                        {modalExportProduct.inputVisible && (
                            <Input
                                type='text'
                                size='small'
                                style={{ width: '100%' }}
                                onChange={changeInputAddEmail}
                                onBlur={handleInputAddEmail}
                                onPressEnter={handleInputAddEmail}
                            />
                        )}

                        {!modalExportProduct.inputVisible && (
                            <Tag onClick={showInputAddEmail} style={{ background: '#fff', borderStyle: 'dashed' }}>
                                <Icon type='plus' /> Add Email
                            </Tag>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ExportProduct
