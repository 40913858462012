import React from 'react'

import styled from 'styled-components'

const BoxInfo = styled.div`
    h3 {
        font-size: 20px;
    }
    p {
        margin-bottom: 5px;
        color: #000;
    }
`

const InfoTracking = (props) => {
    const { order } = props
    return (
        <BoxInfo>
            <h3>Shipping Address</h3>
            <p>{`${order?.shipping?.first_name || ''} ${order?.shipping?.last_name || ''}`}</p>
            <p>{order?.shipping?.address1 || ''}</p>
            <p>{order?.shipping?.address2 || ''}</p>
            <p>{order?.shipping?.city || ''}</p>
            <p>{order?.shipping?.country || ''}</p>
            <p>{order?.shipping?.zip || ''}</p>
            <p>{order?.shipping?.state || ''}</p>
            <p>{order?.customer?.email || ''}</p>
            <p>{order?.shipping?.phone || ''}</p>
        </BoxInfo>
    )
}

export default InfoTracking
