import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import gql from '../../api/gql'
import { Link } from 'react-router-dom'
import { message, Row, Tooltip, Col, Icon, Button, Drawer, Skeleton } from 'antd'
import _ from 'lodash'
import Upload from '../../components/Upload'
import UploadMyProfile from './UploadMyProfile'
import moment from 'moment'

const Container = styled.div`
  .name {
    display: flex;
    justify-content: space-between;
  }
`

class MyProfilePage extends Component {
  _isMounted = false;

  state = {
    loading: true,
    me: null,
    percent: 0,
    uploading: false,
    avatar: ""
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.getProfile();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getProfile() {
    this.setState({ loading: true })
    const query = `query me{me{ id, first_name, last_name, email, birthday, phone, avatar{url},address}}`
    gql.request(query).then(res => {
      this.setState({ me: res.me || {}, loading: false })
    }).catch(err => {
      message['error'](_.get(err, '[0].message'))
      this.setState({ loading: false })
    })
  }

  onUploadAvatar = file => {
    console.log("file", file)
  }
  render() {
    const { me } = this.state
    //console.log('me', me)
    if (!me) {
      return <Skeleton />
    }
    return (
      <Container>
        <Upload
          style={{ cursor: 'pointer' }}
          fileList={false}
          onUpload={this.onUploadAvatar}
        >
          <Tooltip title="Update Avatar">
            <img style={{ width: 200 }} alt="" src={'https://mannatthemes.com/metrica/light/assets/images/users/user-4.jpg'} icon="user" size={80} />
          </Tooltip>
        </Upload>
      </Container>
    )
  }
}

export default connect(
  state => {
    return {
    }
  }
)(MyProfilePage)