import React from "react";
import {
  Skeleton,
  Row,
  Col,
  Card,
  Button,
  notification,
  Progress,
  Tooltip,
  Icon,
} from "antd";
import Upload from "../Upload";
import gql from "../../api/gql";
import _ from "lodash";
import PrintFilePreview from "../file/PrintFilePreview";
import styled from "styled-components";
import { CLOUDFRONT_URL } from "../../config";

const Container = styled.div`
  .ant-upload.ant-upload-select.ant-upload-select-text {
    width: 100%;
  }
  .pod-file-download {
    position: absolute;
    font-size: 14px;
    bottom: 12px;
    right: 8px;
    border-radius: 50%;
    background-color: #ff6666;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    i {
      color: #fff;
    }
  }
`;
const UploadContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 67%;
  .upload-wrapper {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 67%;
    .upload-area {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
class UploadDesign extends React.Component {
  state = {
    file: this.props.file,
    uploading: false,
    percent: 0,
  };

  onUpload = (file) => {
    let query = `mutation createFile($input: NewFile!){
      createFile(input: $input){id}
    }`;
    gql
      .request(query, {
        input: {
          source: "aws",
          key: file.key,
          file_name: file.file.name,
          file_mime: file.file.type,
          file_size: file.file.size,
        },
      })
      .then(({ createFile }) => {
        const { file } = this.state;
        this.setState(
          {
            uploading: false,
            file: { ...file, file_id: createFile.id },
          },
          () => this.props.onChange(this.state.file)
        );
      })
      .catch((err) => {
        this.setState({
          uploading: false,
          percent: 0,
        });
      });
  };

  render() {
    const { file, uploading, percent } = this.state;
    return (
      <UploadContainer>
        <Upload
          onUpload={this.onUpload}
          onProcess={(e) => {
            this.setState({
              uploading: true,
              percent: Math.round((e.loaded / e.total) * 100),
            });
          }}
          fileList={false}
          accept=".jpg, .png, .tiff, .psd, .dxf"
          className="upload-wrapper"
        >
          <div className="upload-area">
            {uploading ? (
              <Progress
                type="circle"
                percent={percent}
                strokeColor="#1890ff"
                width={50}
              />
            ) : file.file_id ? (
              <PrintFilePreview file_id={file.file_id} />
            ) : (
              <span style={{ textAlign: "center" }}>
                Missing, click to upload or drag to upload
              </span>
            )}
          </div>
        </Upload>
      </UploadContainer>
    );
  }
}

export default class UploadDesigns extends React.Component {
  state = {
    product: null,
    printFiles: [],
    loading: true,
    percent: 0,
    submitLoading: false,
    uploadLoading: {},
    productVariantId: null,
  };

  componentDidMount() {
    const { product_id, product_type_id, product_variant_id } = this.props;

    let query = `query productByID($id: Int!, $productVariantId: Int){
      productByID(id: $id, productVariantId: $productVariantId){
        id
        title
        images
        variants{
          id
          product_type{
            title
            id
            print_files{
              id
              title
              note
              width
              height
            }
          }
          product_type_variant{
            id
            print_area_ids
          }
          print_files{
            id
            mockup
            title
            file_id
            note
            width
            height
            file{
              id
              key
            }
          }
        }
      }
    }`;

    gql
      .request(query, {
        id: product_id,
        productVariantId: product_variant_id ?? null,
      })
      .then((res) => {
        let printFiles = []

        if (product_variant_id > 0) {

          printFiles = _.uniqBy(
            res.productByID.variants,
            "product_type.id"
          ).map((variant) => {
              const productTypePrintAreas = variant?.product_type?.print_files?.filter(area => variant?.product_type_variant?.print_area_ids?.includes(area?.id))
              const printFilesMap = variant.print_files?.filter(area => {
                return productTypePrintAreas?.some(item => item.title === area.title)
              })

              return ({
                product_type: variant.product_type,
                print_files: printFilesMap.length > 0 ? printFilesMap : variant.print_files
              })
          });

        } else {

          let areas = []
          let productType = 0
          const uniqueIds = new Set();
            
          // eslint-disable-next-line no-unused-expressions
          res.productByID.variants?.forEach((variant) => {
              const files = variant?.print_files
              productType = variant.product_type

              // eslint-disable-next-line no-unused-expressions
              files?.forEach(area => {
                  if (!uniqueIds.has(area.title)) {
                      uniqueIds.add(area.title);
                      areas.push(area);
                  }
              });
          })

          printFiles.push({
            product_type: productType,
            print_files: areas
          })
        }
        
        if (product_type_id) {
          printFiles = printFiles.filter(
            (printFile) => printFile.product_type.id === product_type_id
          );
        }

        this.setState({
          product: res.productByID,
          printFiles: printFiles,
          loading: false,
          productVariantId: product_variant_id
        });
      });
  }

  savePrintFiles = (e) => {
    e.preventDefault();
    this.setState({ submitLoading: true });
    const { product, printFiles, productVariantId } = this.state;

    let query = `mutation updatePrintFiles($product_id: Int!, $print_files: [ProductPrintFiles!]!, $product_variant_id: Int!){
      updatePrintFiles(product_id: $product_id, print_files: $print_files, product_variant_id: $product_variant_id)
    }`;

    gql
      .request(query, {
        product_id: product.id,
        print_files: printFiles.map((printFile) => ({
          product_type_id: printFile.product_type.id,
          print_files: printFile.print_files.map((pr) => _.omit(pr, ["file"])),
        })),
        product_variant_id: productVariantId ?? 0
      })
      .then((res) => {
        notification["success"]({
          message: "Print Files saved",
        });
        this.props.onClose();
      })
      .catch((err) => {
        this.setState({ submitLoading: false });
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
      });
  };
  render() {
    const { product, printFiles, loading } = this.state;
    const { variant_image } = this.props

    if (loading || !product) {
      return <Skeleton />;
    }
    return (
      <Container>
        {printFiles.map((variant, index) => (
          <div key={index} style={{ marginBottom: 30 }}>
            <h4>{variant.product_type.title}</h4>
            <Row type="flex" gutter={10}>
              {variant.print_files &&
                variant.print_files.map((print_file, index2) => (
                  <Col key={`${variant.id}-${index2}`} span={6}>
                    <Card
                      hoverable
                      cover={
                        <UploadDesign
                          file={print_file}
                          onChange={(file) => {
                            const { printFiles } = this.state;
                            printFiles[index].print_files[index2] = file;
                            this.setState({ printFiles });
                          }}
                        />
                      }
                    >
                      <Card.Meta
                        title={print_file.title}
                        description={`${print_file.width} x ${print_file.height}`}
                      />
                      {print_file.note && (
                        <Card.Meta title={null} description={print_file.note} />
                      )}
                      {print_file.file && (
                        <div className="pod-file-download">
                          <a
                            href={`${CLOUDFRONT_URL}/${print_file.file.key}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Download Print File"
                          >
                            <Tooltip
                              placement="topRight"
                              title="Download print file"
                            >
                              <Icon type="download"></Icon>
                            </Tooltip>
                          </a>
                        </div>
                      )}
                    </Card>
                  </Col>
                ))}
            </Row>
            {product.images && product.images.length > 0 && (
              <div>
                <h4 style={{ marginTop: 50 }}>Product Mockup</h4>
                <img
                  alt=""
                  src={
                    (variant_image && variant_image.indexOf("http") === 0) ? variant_image :
                      product.images ? product.images[0].indexOf("http") === 0 ? product.images[0] : `${CLOUDFRONT_URL}/${product.images[0]}` : null
                  }
                  style={{ width: "50%" }}
                />
              </div>
            )}
          </div>
        ))}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            padding: "10px 16px",
            textAlign: "right",
            left: 0,
            background: "#fff",
            borderRadius: "0 0 4px 4px",
          }}
        >
          <Button
            type="primary"
            onClick={this.savePrintFiles}
            loading={this.state.submitLoading}
          >
            Save
          </Button>
        </div>
      </Container>
    );
  }
}
