import React from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Icon, Menu, notification } from "antd";
//import { AWS_CLOUDFRONT_URL } from '../../config'
import ImportTrackings from "../tracking/ImportTracking";
import gql from "../../api/gql";
import _ from "lodash";
import { setOrderId } from "../../actions";
import AddImportOrder from "./AddImportOrder";
import ExportOrder from './ExportOrder';

class SupplierOrdersToolbar extends React.Component {
  state = {
    loading: false,
  };

  exportOrders = (e) => {

    e.preventDefault();
    this.setState({
      loading: true,
    });
    const { filter, currentUser } = this.props;
    let isSupplier = currentUser.roles.find((role) =>
      ["Supplier"].includes(role.name)
    );
    let query = `mutation exportOrders($filter: OrderFilter){
      exportOrders(filter: $filter)
    }`;
    let supplier = `mutation supplierExportOrder($filter: OrderFilter){
      supplierExportOrder(filter: $filter)
    }`;

    gql
      .request(isSupplier ? supplier : query, {
        filter: { ...filter, order_id: this.props.order_id },
      })
      .then((res) => {
        window.location = `${isSupplier ? res.supplierExportOrder : res.exportOrders
          }`;
        this.setState({
          loading: false,
        });
        this.props.setOrderId(null);
        _.get(this, "props.onDownload", () => { })();
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        notification["error"]({
          message: _.get(err, "[0].message"),
        });
      });
  };

  render() {
    const { loading } = this.state;
    const { currentUser, filter, menu } = this.props;
    const status = _.get(filter, "status", []);
    let isSeller = currentUser.roles.find((role) =>
      ["Seller"].includes(role.name)
    );

    return (
      <div>
        {menu ? (
          <Dropdown
            overlay={
              <Menu>
                {!isSeller && (
                  <Menu.Item>
                    <ImportTrackings />
                  </Menu.Item>
                )}

                <Menu.Item disabled={!status || (status && status.length === 0)}>
                  <ExportOrder
                    filter={filter}
                    currentUser={currentUser}
                    order_id={this.props.order_id}
                    displayBtn={false}>
                  </ExportOrder>
                </Menu.Item>

                {isSeller && (
                  <Menu.Item>
                    <AddImportOrder
                      setLoading={(status) =>
                        this.setState({ loading: status })
                      }
                      loadOrder={this.props.loadOrder}
                    />
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button loading={loading}>
              Export / Import <Icon type="down" />
            </Button>
          </Dropdown>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {!isSeller && <ImportTrackings displayBtn={true} />}
            <ExportOrder
              filter={filter}
              currentUser={currentUser}
              order_id={this.props.order_id}
              displayBtn={true}>
            </ExportOrder>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    currentUser: state.app.currentUser,
    filter: state.orders.filter,
    order_id: state.orders.order_id,
  }),
  { setOrderId }
)(SupplierOrdersToolbar);
