import { Query } from "@apollo/react-components";
import { PageHeader, Skeleton } from "antd";
import { gql } from "apollo-boost";
import React, { Component } from "react";
import SettingTracking from "../../components/admin/settingTracking";
import { history } from "../../history";

const getAppsetting = gql`
  query getAppSettings($input: [String!]) {
    getAppSettings(input: $input) {
      id
      name
      value
    }
  }
`;
class Tracking extends Component {
  render() {
    return (
      <div>
        <PageHeader
          style={{ padding: "16px 0px" }}
          title="Setting"
          onBack={() => {
            history.goBack();
          }}
        />
        <Query
          fetchPolicy="network-only"
          query={getAppsetting}
          variables={{
            input: ["tracking_more_api_key", "tracking_more_delay_time"],
          }}
        >
          {({ data, loading }) => {
            if (loading) return <Skeleton />;
            return <SettingTracking data={data?.getAppSettings} />;
          }}
        </Query>
      </div>
    );
  }
}

export default Tracking;
