import React from 'react'
import gql from '../../api/gql'
import { Table, Icon, Row, Col, Button, Select, message, Popconfirm, Input, PageHeader, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import S3Image from '../../components/S3Image'
import { connect } from 'react-redux'
import ProductTypeToolbar from '../product_type/ProductTypeToolbar'
import { Divider } from 'rc-menu'
import _ from 'lodash'
import { history } from '../../history'
import Search from 'antd/lib/input/Search'
import Grid from 'antd/lib/card/Grid'
const { Option } = Select

class SupplierProductTypes extends React.Component {
    _isMounted = false
    state = {
        productTypes: [],
        loading: true,
        filter: {
            search: null,
            categories: [],
            supplier_id: [],
            status: true,
            personalized: null,
        },
        categories: [],
        suppliers: [],
        pagination: {
            results: 100,
            pageSize: 50,
            total: 0,
        },
        page: 1,
    }

    resetFilter = () => {
        this.setState({
            filter: {
                search: null,
                categories: [],
                supplier_id: [],
                status: true,
                personalized: null,
            },
        })
    }

    componentDidMount() {
        this.loadCategories()
        this.loadProductType()
        this.loadSupplier()
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    loadProductType() {
        const { pagination } = this.state
        const query = `query productTypes($filter: ProductTypeFilter){
      productTypes(filter: $filter){
        count,
        hits{
          id
          sku
          title
          images
          state
          categories{
            id, title
          }
          attributes{
            name
          }
          suppliers{
            first_name last_name id
          }
        }
      }
    }`
        this.setState({ loading: true })
        gql.request(query, {
            filter: {
                ...this.state.filter,
                limit: pagination.pageSize,
                offset: (this.state.page - 1) * pagination.pageSize,
            },
        }).then((res) => {
            this.setState({
                productTypes: res.productTypes.hits || [],
                loading: false,
                pagination: {
                    ...pagination,
                    total: res.productTypes.count,
                    results: res.productTypes.count,
                },
            })
        })
    }
    loadCategories() {
        const query = `query categories {
      categories(filter:{status:true, limit: 9999, offset: 0}){
        hits{id title}
      }
    }
    `
        gql.request(query)
            .then((res) => this.setState({ categories: res.categories.hits || [], loading: false }))
            .catch((err) => {
                message['error']('Something wrong!')
            })
    }

    loadSupplier() {
        const { currentUser } = this.props
        if (currentUser.roles && currentUser.roles[0].name !== 'Supplier') {
            const query = `query user{
				users(filter:{role:"Supplier"}){
					hits{
						id first_name last_name
					}
				}
			}`
            gql.request(query)
                .then((res) => this.setState({ suppliers: res.users.hits || [] }))
                .catch((err) => {
                    message['error']('Something wrong!')
                })
        }
    }
    onChangeTable = async (e) => {
        await this.setState({ page: e.current })
        this.loadProductType()
    }
    handleChangeCategory = async (value) => {
        if (value !== 'All') {
            await this.setState({
                filter: {
                    ...this.state.filter,
                    categories: [value],
                },
            })
        } else {
            delete this.state.filter.categories
            await this.setState({
                filter: this.state.filter,
            })
        }
        this.loadProductType()
    }
    handleChangeType = async (value) => {
        await this.setState({
            filter: { ...this.state.filter, personalized: value },
        })
        this.loadProductType()
    }

    handleChangeSupplier = async (value) => {
        if (value !== 'All') {
            await this.setState({
                filter: {
                    ...this.state.filter,
                    supplier_id: [value],
                },
            })
        } else {
            delete this.state.filter.supplier_id
            await this.setState({
                filter: this.state.filter,
            })
        }
        this.loadProductType()
    }
    handleChangeStatus = async (value) => {
        await this.setState({
            filter: {
                ...this.state.filter,
                status: value.key === 'active' ? true : false,
            },
        })
        this.loadProductType()
    }
    handleChangeSearch = async (e) => {
        this.setState({ filter: { ...this.state.filter, search: e.target.value } })
    }
    applyButton = () => {
        this.loadProductType()
    }
    resetButton = async () => {
        await this.resetFilter()
        this.loadProductType()
    }
    deleteProductType(record) {
        const mutation = `mutation deleteProductType($id: Int!){
      deleteProductType(id: $id)
    }`
        this.setState({ loading: true })
        gql.request(mutation, { id: record })
            .then((res) => {
                this.setState({ loading: false })
                message['success']('Delete success')
                this.loadProductType()
            })
            .catch((err) => {
                message['error']('Something wrong!')
            })
    }
    pauseOrResume = (id, state) => {
        const mutation = `mutation updateProductTypeState($id: Int!, $state: String!){
      updateProductTypeState(id: $id, state: $state)
    }`
        this.setState({ loading: true })
        gql.request(mutation, { id: id, state: state })
            .then((res) => {
                let type = 'resumed'
                if (state === 'active') {
                    type = 'paused'
                }
                message['success'](`Product Type has been ${type}`)
                this.loadProductType()
                this.setState({ loading: false })
            })
            .catch((err) => {
                message['error'](_.get(err, '[0].message'))
            })
    }

    render() {
        const { productTypes, loading, categories, suppliers, filter } = this.state

        const isAdmin = this.props.currentUser.roles.find((role) => ['Administrator', 'Listing'].includes(role.name))
        
        let columns = [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: 'Title',
                dataIndex: 'title',
                width: 400,
                render: (title, { images }) => (
                    <div style={{ display: 'grid', gridTemplateColumns: '60px auto' }}>
                        {/* {images && (
              <S3Image
                src={images[0]}
                style={{ maxWidth: 60, maxHeight: 60 }}
              />
            )} */}
                        <div
                            style={{
                                background: '#c1bfbf',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <S3Image src={images ? images[0] : null} style={{ maxWidth: 60, maxHeight: 60 }} />
                        </div>

                        <span style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>{title}</span>
                    </div>
                ),
            },
            {
                title: 'SKU',
                width: 120,
                key: 'sku',
                dataIndex: 'sku',
            },
            {
                title: 'Suppliers',
                dataIndex: 'suppliers',
                render: (supplier) => supplier.map((supplier) => `${supplier.first_name} ${supplier.last_name}`).join(', '),
            },
            {
                title: 'Categories',
                dataIndex: 'categories',
                render: (categories) => categories.map((cat) => cat.title).join(', '),
            },
            isAdmin
                ? {
                      title: 'Actions',
                      dataIndex: 'id',
                      width: 200,
                      align: 'center',
                      render: (id, record) => (
                          <div>
                              <Link to={`/admin/product-types/edit/${id}`}>
                                  <Icon type='edit' style={{ color: '#1890ff' }} /> Edit
                              </Link>
                              |<Link to={`/admin/product-types/${id}/variants`}>Variants</Link>
                              <Popconfirm
                                  title='Are you sure to delete this product type?'
                                  onConfirm={() => this.deleteProductType(record.id)}
                                  okText='Yes'
                                  placement='top'
                                  cancelText='No'>
                                  <Tooltip placement='top' title='Delete'>
                                      <Button type='link' icon='delete' style={{ color: 'red' }}></Button>
                                  </Tooltip>
                              </Popconfirm>
                              <Divider type='vertical' />
                              <Popconfirm
                                  title={`Are you sure to ${record.state === 'active' ? 'pause' : 'resume'} this product type?`}
                                  onConfirm={() => this.pauseOrResume(id, record.state === 'active' ? 'paused' : 'active')}
                                  okText='Yes'
                                  placement='top'
                                  cancelText='No'>
                                  <Button style={{ padding: 0 }} type='link' icon={record.state === 'active' ? 'pause' : 'step-forward'}>
                                      {record.state === 'active' ? 'Pause' : 'Resume'}
                                  </Button>
                              </Popconfirm>
                          </div>
                      ),
                  }
                : {},
        ]
        return (
            <div>
                <PageHeader
                    style={{ padding: '16px 0px' }}
                    title='Product Types'
                    onBack={() => {
                        history.goBack()
                    }}
                    extra={<ProductTypeToolbar />}
                />
                <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
                    {/* <Col
            span={24}
            lg={20}
            style={{ display: "flex", alignItems: "center" }}
          > */}
                    {/* <Select style={{ maxWidth: 250, width: '100%'}} labelInValue defaultValue={{ key: 'active' }} onChange={this.handleChangeStatus}>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select> */}
                    {/* <Tag.CheckableTag
              checked={this.state.filter.personalized}
              onChange={(e) => {
                this.setState({
                  filter: { ...filter, personalized: e },
                });
                this.loadProductType();
              }}
              style={{
                cursor: "pointer",
                border: "1px solid #d9d9d9",
                padding: "5px 10px",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Personalized
            </Tag.CheckableTag> */}
                    <Select
                        style={{ margin: '0px 10px 0px 0px', width: 200 }}
                        value={this.state.filter.personalized}
                        onChange={this.handleChangeType}>
                        <Option value={"null"} key='1'>
                            All Type
                        </Option>
                        <Option value={true} key='2'>
                            Personalized
                        </Option>
                    </Select>
                    <Select
                        showSearch
                        value={this.state.filter.categories}
                        optionFilterProp='children'
                        placeholder='Filter by Category'
                        style={{ margin: '0px 10px 0px 0px', width: 200 }}
                        onChange={this.handleChangeCategory}>
                        <Option value='All'>All categories</Option>
                        {categories.map((category) => (
                            <Option key={category.id} value={category.id}>
                                {category.title}
                            </Option>
                        ))}
                    </Select>

                    <Select 
                        showSearch
                        value={this.state.filter.supplier_id}
                        optionFilterProp='children'
                        placeholder="Filter by Supplier" 
                        style={{ width: 200 }} 
                        onChange={this.handleChangeSupplier}
                    >
                        <Option value="All">All</Option>
                        {suppliers.map(supplier => 
                            <Option key={supplier.id} value={supplier.id}>{supplier.first_name} {supplier.last_name}</Option>
                        )}
                    </Select>

                    <Input.Search
                        value={filter.search}
                        style={{
                            width: 'calc(100% - 200px )',
                            height: 'fit-content',
                        }}
                        placeholder='Search...'
                        onChange={this.handleChangeSearch}
                        onSearch={() => this.applyButton()}
                    />
                    {/* <Button
              onClick={this.applyButton}
              style={{ marginLeft: 10 }}
              type="primary"
            >
              Apply
            </Button> */}
                    <Button onClick={this.resetButton} style={{ marginLeft: 10 }}>
                        Reset
                    </Button>
                    {/* </Col> */}
                    {/* <Col span={0} lg={4} style={{ textAlign: "right" }}>
           
          </Col> */}
                </div>
                <Table
                    pagination={this.state.pagination}
                    onChange={this.onChangeTable}
                    dataSource={productTypes}
                    loading={loading}
                    columns={columns}
                    rowKey={(row) => row.id}
                />
            </div>
        )
    }
}

export default connect((state) => ({
    currentUser: state.app.currentUser,
}))(SupplierProductTypes)
