import React, { useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_DISCOUNT_LIST } from '../../../graphql/query/adminDiscount'

import DiscountListComponent from '../../../components/admin/discount'

const DiscountPage = () => {
    const [filter] = useState({
        limit: 20,
    })

    const { loading, error, data } = useQuery(GET_DISCOUNT_LIST, {
        variables: {
            filter,
        },
    })

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    return <DiscountListComponent dataSource={data?.discounts?.hits || []} count={data?.discounts?.count || 0} />
}

export default DiscountPage
