import gql from "graphql-tag";

export const LIST_IMPORT_HISTORY = gql`
  query listImportHistory($filter: ImportHistoryFilter) {
    listImportHistory(filter: $filter) {
      count
      hits {
        id
        origin_key
        origin_name
        imported_key
        done
        failed
        created_at
      }
    }
  }
`;
