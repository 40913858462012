import gql from "graphql-tag";

export const CREATEATTRIBUTETERM = gql`
    mutation createAttributeTerm($input: NewAttributeTerm!) {
        createAttributeTerm(input: $input) {
            id
            attribute_id
            option_name
            sku
        }
    }
`

export const UPDATEATTRIBUTETERM = gql`
    mutation updateAttributeTerm($input: UpdateAttributeTerm!) {
        updateAttributeTerm(input: $input) {
            id
            attribute_id
            option_name
            sku
        }
    }
`

export const DELETEATTRIBUTETERM = gql`
    mutation deleteAttributeTerm($id: Int!) {
        deleteAttributeTerm(id: $id)
    }
`